import {takeEvery} from "redux-saga/effects";

import * as actionTypes from "../actions/actionTypes";
import {
    authSignUpSaga,
    logoutSaga,
    newPasswordSaga,
    requestNewPasswordSaga,
    signInSaga,
    tryAutoSignInSaga
} from "./auth";
import {
    adminUtilisateursAjouterGroupeSaga,
    adminUtilisateursFetchGroupeListeGroupesSaga,
    adminUtilisateursFetchGroupeListeUtilisateursSaga,
    adminUtilisateursFetchListeGroupesSaga,
    adminUtilisateursFetchListeUtilisateursSaga,
} from "./admin";
import {
    guestExplorerCoursCoursFetchListeRessourcesSaga,
    guestExplorerCoursFetchCategorieListeCategoriesSaga,
    guestExplorerCoursFetchCategorieListeCoursSaga,
    guestExplorerCoursFetchCategorieSaga,
    guestExplorerCoursFetchCoursSaga,
    guestExplorerCoursFetchListeCategoriesSaga,
    guestExplorerCoursFetchListeCoursSaga,
    guestHomeFetchListeCategoriesSpecialsSaga,
    guestHomeFetchListeCoursSpecialsSaga
} from "./guest";

import * as etudiantSagas from "./etudiant";
import {parametresFetchParametresSaga} from "./settings";
import {adminUtilisateursAjouterUtilisateurSaga} from "./admin/utilisateurs/ajouterUtilisateur";
import {adminUtilisateursSupprimerGroupeSaga} from "./admin/utilisateurs/supprimerGroupe";
import {adminUtilisateursSupprimerUtilisateurSaga} from "./admin/utilisateurs/supprimerUtilisateur";
import {adminUtilisateursFetchGroupeSaga} from "./admin/utilisateurs/groupe";
import {
    adminUtilisateursModifierGroupeAjouterSousGroupeSaga,
    adminUtilisateursModifierGroupeSaga,
    adminUtilisateursModifierGroupeSupprimerSousGroupeSaga
} from "./admin/utilisateurs/modifierGroupe";
import {adminAssignementsGroupeCoursAjouterAssignementGroupeCoursSaga} from "./admin/assignementsGroupeCours/ajouterAssignementGroupeCours";
import {adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursSaga} from "./admin/assignementsGroupeCours/supprimerAssignementGroupeCours";
import {
    adminCoursFetchCategorieListeCategoriesSaga,
    adminCoursFetchCategorieListeCoursSaga,
    adminCoursFetchListeCategoriesSaga
} from "./admin/cours/listeCategories";
import {adminCoursFetchListeCoursSaga} from "./admin/cours/listeCours";
import {adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieSaga} from "./admin/assignementsGroupeCategorie/supprimerAssignementGroupeCategorie";
import {adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieSaga} from "./admin/assignementsGroupeCategorie/ajouterAssignementGroupeCategorie";
import {adminUtilisateursAjouterUtilisateurAuGroupeSaga} from "./admin/utilisateurs/ajouterUtilisateurAuGroupe";
import {adminUtilisateursSupprimerUtilisateurAuGroupeSaga} from "./admin/utilisateurs/supprimerUtilisateurAuGroupe";
import {adminUtilisateursFetchUtilisateurSaga} from "./admin/utilisateurs/utilisateur";
import {
    adminUtilisateursModifierUtilisateurChangerMotDePasseSaga,
    adminUtilisateursModifierUtilisateurSaga
} from "./admin/utilisateurs/modifierUtilisateur";
import {adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorieSaga} from "./admin/assignementsUtilisateurCategorie/supprimerAssignementUtilisateurCategorie";
import {adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieSaga} from "./admin/assignementsUtilisateurCategorie/ajouterAssignementUtilisateurCategorie";
import {adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCoursSaga} from "./admin/assignementsUtilisateurCours/supprimerAssignementUtilisateurCours";
import {adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursSaga} from "./admin/assignementsUtilisateurCours/ajouterAssignementUtilisateurCours";
import {adminCoursSupprimerCoursSaga} from "./admin/cours/supprimerCours";
import {adminCoursSupprimerCategorieSaga} from "./admin/cours/supprimerCategorie";
import {adminCoursAjouterCategorieSaga} from "./admin/cours/ajouterCategorie";
import {adminCoursAjouterCoursSaga} from "./admin/cours/ajouterCours";
import {utilisateurFetchTopNotificationsSaga} from "./utilisateur/notifications";
import {etudiantAjouterNoteSaga, etudiantFetchNotesSaga, etudiantSupprimerNoteSaga} from "./etudiant/mesCours/notes";
import {enseignantMesCoursFetchListeCoursSaga} from "./enseignant/mesCours/listeCours";
import {enseignantMesCoursFetchCoursSaga} from "./enseignant/mesCours/cours";
import {enseignantMesCoursFetchCoursRessourcesSaga} from "./enseignant/mesCours/listeRessources";
import {enseignantMesCoursAjouterSectionSaga} from "./enseignant/mesCours/ajouterSection";
import {enseignantMesCoursAjouterFichierSaga} from "./enseignant/mesCours/ajouterFichier";
import {adminParametresModifierParametresSaga} from "./admin/parametres/modifierParametres";
import {
    utilisateurModifierProfilChangerMotDePasseSaga,
    utilisateurModifierProfilSaga
} from "./utilisateur/profil/modifierProfil";
import {guestExplorerOffresFetchListeOffresSaga} from "./guest/explorerOffres/listeOffres";
import {
    loadCartSaga,
    resetCartSaga,
    utilisateurAddOffreToCartSaga,
    utilisateurRemoveOffreFromCartSaga
} from "./utilisateur/cart";
import {utilisateurCommandeCreerCommandeSaga} from "./utilisateur/commande/creerCommande";
import {utilisateurCommandeFetchCommandeSaga} from "./utilisateur/commande/fetchCommande";
import {utilisateurCommandePayerCommandeSaga} from "./utilisateur/commande/payerCommande";
import {adminOffresFetchListeOffresSaga} from "./admin/offres/listeOffres";
import {adminCommandesFetchListeCommandesSaga} from "./admin/commandes/listeCommandes";
import {etudiantExplorerOffresFetchListeOffresSaga} from "./etudiant/explorerOffres/listeOffres";
import {etudiantCommandesFetchListeCommandesSaga} from "./etudiant/commandes/listeCommandes";
import {enseignantDemandeFichiersAjouterDemandeFichierSaga} from "./enseignant/demandeVideos/ajouterDemandeFichier";
import {enseignantDemandeVideosAjouterDemandeVideoSaga} from "./enseignant/demandeVideos/ajouterDemandeVideo";
import {technicianCoursesFetchCoursesListSaga} from "./technician/courses/coursesList";
import {technicianVideoRequestsFetchVideoRequestsListSaga} from "./technician/videoRequests/videoRequests";
import {technicianVideoRequestsFetchVideoRequestSaga} from "./technician/videoRequests/videoRequest";
import {technicianTagsFetchTagsListSaga} from "./technician/tags/tagsList";
import {technicianVideosAddVideoSaga} from "./technician/videos/addVideo";
import {technicianVideoRequestsTerminateVideoRequestSaga} from "./technician/videoRequests/terminateVideoRequest";

function* watch() {
    yield takeEvery(actionTypes.AUTH_INITIATE_SIGNIN,signInSaga);
    yield takeEvery(actionTypes.AUTH_INITIATE_LOGOUT,logoutSaga);
    yield takeEvery(actionTypes.AUTH_TRY_AUTO_SIGNIN,tryAutoSignInSaga);

    yield takeEvery(actionTypes.ETUDIANT_MES_COURS_INITIATE_FETCH_LISTE_COURS,etudiantSagas.etudiantMesCoursFetchListeCoursSaga);

    yield takeEvery(actionTypes.ETUDIANT_MES_COURS_INITIATE_FETCH_COURS,etudiantSagas.etudiantMesCoursFetchCoursSaga);

    yield takeEvery(actionTypes.ETUDIANT_MES_COURS_INITIATE_FETCH_COURS_RESSOURCES,etudiantSagas.etudiantMesCoursFetchCoursRessourcesSaga);

    yield takeEvery(actionTypes.ETUDIANT_MES_COURS_INITIATE_FETCH_RESSOURCE,etudiantSagas.etudiantMesCoursFetchRessourceSaga);


    yield takeEvery(actionTypes.GUEST_HOME_INITIATE_FETCH_LISTE_CATEGORIES_SPECIALS,guestHomeFetchListeCategoriesSpecialsSaga);
    yield takeEvery(actionTypes.GUEST_HOME_INITIATE_FETCH_LISTE_COURS_SPECIALS,guestHomeFetchListeCoursSpecialsSaga);


    yield takeEvery(actionTypes.GUEST_EXPLORER_COURS_INITIATE_FETCH_LISTE_CATEGORIES,guestExplorerCoursFetchListeCategoriesSaga);
    yield takeEvery(actionTypes.GUEST_EXPLORER_COURS_INITIATE_FETCH_LISTE_COURS,guestExplorerCoursFetchListeCoursSaga);

    yield takeEvery(actionTypes.GUEST_EXPLORER_COURS_INITIATE_FETCH_CATEGORIE,guestExplorerCoursFetchCategorieSaga);
    yield takeEvery(actionTypes.GUEST_EXPLORER_COURS_INITIATE_FETCH_COURS,guestExplorerCoursFetchCoursSaga);
    yield takeEvery(actionTypes.GUEST_EXPLORER_COURS_COURS_INITIATE_FETCH_LISTE_RESSOURCES,guestExplorerCoursCoursFetchListeRessourcesSaga);



    yield takeEvery(actionTypes.PARAMETRES_INITIATE_FETCH_PARAMETRES,parametresFetchParametresSaga);


    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_FETCH_LISTE_GROUPES,adminUtilisateursFetchListeGroupesSaga);
    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_FETCH_LISTE_UTILISATEURS,adminUtilisateursFetchListeUtilisateursSaga);

    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_FETCH_GROUPE_LISTE_GROUPES,adminUtilisateursFetchGroupeListeGroupesSaga);
    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_FETCH_GROUPE_LISTE_UTILISATEURS,adminUtilisateursFetchGroupeListeUtilisateursSaga);


    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_AJOUTER_GROUPE,adminUtilisateursAjouterGroupeSaga);
    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_SUPPRIMER_GROUPE,adminUtilisateursSupprimerGroupeSaga);
    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_MODIFIER_GROUPE,adminUtilisateursModifierGroupeSaga);

    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_AJOUTER_UTILISATEUR,adminUtilisateursAjouterUtilisateurSaga);
    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_SUPPRIMER_UTILISATEUR,adminUtilisateursSupprimerUtilisateurSaga);


    yield takeEvery(actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_INITIATE_AJOUTER_ASSIGNEMENT_GROUPE_COURS,adminAssignementsGroupeCoursAjouterAssignementGroupeCoursSaga);
    yield takeEvery(actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_INITIATE_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS,adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursSaga);


    yield takeEvery(actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_INITIATE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE,adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieSaga);
    yield takeEvery(actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_INITIATE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE,adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieSaga);



    yield takeEvery(actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_INITIATE_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS,adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursSaga);
    yield takeEvery(actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_INITIATE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS,adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCoursSaga);


    yield takeEvery(actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_INITIATE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE,adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieSaga);
    yield takeEvery(actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_INITIATE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE,adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorieSaga);


    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_FETCH_GROUPE,adminUtilisateursFetchGroupeSaga);



    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_INITIATE_AJOUTER_SOUS_GROUPE,adminUtilisateursModifierGroupeAjouterSousGroupeSaga);
    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_INITIATE_SUPPRIMER_SOUS_GROUPE,adminUtilisateursModifierGroupeSupprimerSousGroupeSaga);





    yield takeEvery(actionTypes.ADMIN_COURS_INITIATE_FETCH_LISTE_CATEGORIES,adminCoursFetchListeCategoriesSaga);
    yield takeEvery(actionTypes.ADMIN_COURS_INITIATE_FETCH_LISTE_COURS,adminCoursFetchListeCoursSaga);



    yield takeEvery(actionTypes.ADMIN_COURS_INITIATE_FETCH_CATEGORIE_LISTE_CATEGORIES,adminCoursFetchCategorieListeCategoriesSaga);
    yield takeEvery(actionTypes.ADMIN_COURS_INITIATE_FETCH_CATEGORIE_LISTE_COURS,adminCoursFetchCategorieListeCoursSaga);


    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_AJOUTER_UTILISATEUR_AU_GROUPE,adminUtilisateursAjouterUtilisateurAuGroupeSaga);
    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_SUPPRIMER_UTILISATEUR_AU_GROUPE,adminUtilisateursSupprimerUtilisateurAuGroupeSaga);



    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_FETCH_UTILISATEUR,adminUtilisateursFetchUtilisateurSaga);
    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_MODIFIER_UTILISATEUR,adminUtilisateursModifierUtilisateurSaga);


    yield takeEvery(actionTypes.GUEST_EXPLORER_COURS_INITIATE_FETCH_CATEGORIE_LISTE_CATEGORIES,guestExplorerCoursFetchCategorieListeCategoriesSaga);
    yield takeEvery(actionTypes.GUEST_EXPLORER_COURS_INITIATE_FETCH_CATEGORIE_LISTE_COURS,guestExplorerCoursFetchCategorieListeCoursSaga);






    yield takeEvery(actionTypes.ADMIN_UTILISATEURS_INITIATE_MODIFIER_UTILISATEUR_CHANGER_MOT_DE_PASSE,adminUtilisateursModifierUtilisateurChangerMotDePasseSaga);




    yield takeEvery(actionTypes.ADMIN_COURS_INITIATE_SUPPRIMER_CATEGORIE,adminCoursSupprimerCategorieSaga);
    yield takeEvery(actionTypes.ADMIN_COURS_INITIATE_SUPPRIMER_COURS,adminCoursSupprimerCoursSaga);


    yield takeEvery(actionTypes.ADMIN_COURS_INITIATE_AJOUTER_CATEGORIE,adminCoursAjouterCategorieSaga);
    yield takeEvery(actionTypes.ADMIN_COURS_INITIATE_AJOUTER_COURS,adminCoursAjouterCoursSaga);


    yield takeEvery(actionTypes.UTILISATEUR_INITIATE_FETCH_TOP_NOTIFICATIONS,utilisateurFetchTopNotificationsSaga);



    yield takeEvery(actionTypes.ETUDIANT_INITIATE_FETCH_NOTES,etudiantFetchNotesSaga);
    yield takeEvery(actionTypes.ETUDIANT_INITIATE_SUPPRIMER_NOTE,etudiantSupprimerNoteSaga);
    yield takeEvery(actionTypes.ETUDIANT_INITIATE_AJOUTER_NOTE,etudiantAjouterNoteSaga);







    yield takeEvery(actionTypes.ENSEIGNANT_MES_COURS_INITIATE_FETCH_LISTE_COURS,enseignantMesCoursFetchListeCoursSaga);

    yield takeEvery(actionTypes.ENSEIGNANT_MES_COURS_INITIATE_FETCH_COURS,enseignantMesCoursFetchCoursSaga);


    yield takeEvery(actionTypes.ENSEIGNANT_MES_COURS_INITIATE_FETCH_COURS_RESSOURCES,enseignantMesCoursFetchCoursRessourcesSaga);



    yield takeEvery(actionTypes.ENSEIGNANT_MES_COURS_INITIATE_AJOUTER_SECTION,enseignantMesCoursAjouterSectionSaga);


    yield takeEvery(actionTypes.ENSEIGNANT_MES_COURS_INITIATE_AJOUTER_FICHIER,enseignantMesCoursAjouterFichierSaga);



    yield takeEvery(actionTypes.ADMIN_PARAMETRES_INITIATE_MODIFIER_PARAMETRES,adminParametresModifierParametresSaga);


    yield takeEvery(actionTypes.UTILISATEUR_INITIATE_MODIFIER_PROFIL,utilisateurModifierProfilSaga);
    yield takeEvery(actionTypes.UTILISATEUR_INITIATE_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE,utilisateurModifierProfilChangerMotDePasseSaga);




    yield takeEvery(actionTypes.GUEST_EXPLORER_OFFRES_INITIATE_FETCH_LISTE_OFFRES,guestExplorerOffresFetchListeOffresSaga);


    yield takeEvery(actionTypes.AUTH_SIGNIN_SUCCESS,loadCartSaga);


    yield takeEvery(actionTypes.UTILISATEUR_CART_INITIATE_ADD_OFFRE,utilisateurAddOffreToCartSaga);
    yield takeEvery(actionTypes.UTILISATEUR_CART_INITIATE_REMOVE_OFFRE,utilisateurRemoveOffreFromCartSaga);
    yield takeEvery(actionTypes.UTILISATEUR_CART_INITIATE_RESET_CART,resetCartSaga);


    yield takeEvery(actionTypes.UTILISATEUR_COMMANDE_INITIATE_CREER_COMMANDE,utilisateurCommandeCreerCommandeSaga);
    yield takeEvery(actionTypes.UTILISATEUR_COMMANDE_INITIATE_FETCH_COMMANDE,utilisateurCommandeFetchCommandeSaga);

    yield takeEvery(actionTypes.ETUDIANT_COMMANDE_INITIATE_PAYER_COMMANDE,utilisateurCommandePayerCommandeSaga);

    yield takeEvery(actionTypes.ADMIN_OFFRES_INITIATE_FETCH_LISTE_OFFRES,adminOffresFetchListeOffresSaga);
    yield takeEvery(actionTypes.ADMIN_COMMANDES_INITIATE_FETCH_LISTE_COMMANDES,adminCommandesFetchListeCommandesSaga);




    yield takeEvery(actionTypes.AUTH_INITIATE_SIGNUP,authSignUpSaga);


    yield takeEvery(actionTypes.ETUDIANT_EXPLORER_OFFRES_INITIATE_FETCH_LISTE_OFFRES,etudiantExplorerOffresFetchListeOffresSaga);
    yield takeEvery(actionTypes.ETUDIANT_COMMANDES_INITIATE_FETCH_LISTE_COMMANDES,etudiantCommandesFetchListeCommandesSaga);




    yield takeEvery(actionTypes.ENSEIGNANT_DEMANDE_FICHIERS_INITIATE_AJOUTER_DEMANDE_FICHIER,enseignantDemandeFichiersAjouterDemandeFichierSaga);
    yield takeEvery(actionTypes.ENSEIGNANT_DEMANDE_VIDEOS_INITIATE_AJOUTER_DEMANDE_VIDEO,enseignantDemandeVideosAjouterDemandeVideoSaga);




    /*** TECHNICIAN ****/
    yield takeEvery(actionTypes.TECHNICIAN_COURSES_INITIATE_FETCH_COURSES_LIST,technicianCoursesFetchCoursesListSaga);

    yield takeEvery(actionTypes.TECHNICIAN_TAGS_INITIATE_FETCH_TAGS_LIST,technicianTagsFetchTagsListSaga);

    yield takeEvery(actionTypes.TECHNICIAN_VIDEO_REQUESTS_INITIATE_FETCH_VIDEO_REQUESTS_LIST, technicianVideoRequestsFetchVideoRequestsListSaga);
    yield takeEvery(actionTypes.TECHNICIAN_VIDEO_REQUESTS_INITIATE_TERMINATE_VIDEO_REQUEST, technicianVideoRequestsTerminateVideoRequestSaga);


    yield takeEvery(actionTypes.TECHNICIAN_VIDEO_REQUESTS_INITIATE_FETCH_VIDEO_REQUEST, technicianVideoRequestsFetchVideoRequestSaga);
    yield takeEvery(actionTypes.TECHNICIAN_VIDEOS_INITIATE_ADD_VIDEO, technicianVideosAddVideoSaga);


    yield takeEvery(actionTypes.AUTH_INITIATE_REQUEST_NEW_PASSWORD, requestNewPasswordSaga);
    yield takeEvery(actionTypes.AUTH_INITIATE_NEW_PASSWORD, newPasswordSaga);


}

export default watch;