import React, {useEffect} from "react";
import * as actions from "../../store/actions";
import {connect} from "react-redux";

function Error404(props) {

    useEffect(()=> {
        return () => {
            props.removeError404();
        }
    });

    return (
        <div>404 ERROR</div>
    );

}

const mapDispatchToProps =(disptatch) => {
    return {
        removeError404 : () => {disptatch(actions.errorsRemoveError404())}
    }
};


export default connect(null,mapDispatchToProps)(Error404);