import * as actionTypes from "../../../../../actions/actionTypes";
import {updateObject} from "../../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    listeRessources: []
};

const guestExplorerCoursCoursFetchListeRessourcesStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        listeRessources: null
    });
};

const guestExplorerCoursCoursFetchListeRessourcesSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        listeRessources: action.payload.listeRessources
    });
};

const guestExplorerCoursCoursFetchListeRessourcesFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        listeRessources: null
    });
};

function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_START : return guestExplorerCoursCoursFetchListeRessourcesStart(state,action);
        case actionTypes.GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_SUCCESS : return guestExplorerCoursCoursFetchListeRessourcesSuccess(state,action);
        case actionTypes.GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_FAIL : return guestExplorerCoursCoursFetchListeRessourcesFail(state,action);

        default : return state;
    }

}

export default reducer;