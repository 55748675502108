import React, {useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {CoursePage, ressourcePage} from "../../../../../routes/studentRoutes";
import Modal from "@material-ui/core/Modal";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import VideoPlayer from "../../../../../components/VideoPlayer";
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import VideocamIcon from '@material-ui/icons/Videocam';


import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import {connect} from "react-redux";
import {
    etudiantMesCoursFetchRessource,
    etudiantMesCoursFetchRessourceReset
} from "../../../../../store/actions/etudiant/mesCours/ressourceActions";
import {generateDynamicRoute} from "../../../../../utils/utils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Grid from "@material-ui/core/Grid";
import {Box} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";


import {withStyles} from '@material-ui/core/styles';


const CustomTab = withStyles((theme) => ({
    root : {
        minWidth: 72,
    }
}))((props) => <Tab {...props} />);


const initialState = {
    allVideos : [],
    tags : [],
    section : null
};



function RessourcePage(props) {






    const [state,setState] = useState(initialState);


    let { sectionId } = useParams();


    useEffect(() => {
        if(!props.loadingRessources) {

            const sections = props.listeRessources;

            const section = sections.find(sec => sec.id == sectionId);


            setState(prevState => {
                return {
                    ...prevState,
                    section : section
                }
            });

            const videos = section.videos;


            const tags = [];
            videos.forEach(video => {

                console.log("OUT",tags);
                var test = false;
                var i = 0;
                while(i<tags.length && !test) {
                    console.log("IN",tags,i);
                    if(tags[i].id === video.tag.id)
                        test = true;
                    i++;
                }
                if(!test) {
                    console.log(video);
                    tags.push(video.tag)
                }
            });

            setState(prevState => {
                return {
                    ...prevState,
                    allVideos: videos,
                    tags : tags
                }
            })
        }

    },[props.loadingRessources]);




    const history = useHistory();

    let { coursId } = useParams();
    let { ressourceId } = useParams();


    const handleClose = () => {
        history.push(generateDynamicRoute(CoursePage.path,[{toReplace:":coursId",value:coursId}]));
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const generateRessource = (video) => {
       return <VideoPlayer video={video}/>;
    };

    useEffect(()=> {
        if(ressourceId) {
            props.fetchRessource(ressourceId);
            return () => {
                props.resetRessource()
            }
        }
    },[ressourceId]);


    function a11yProps(index) {
        return {
            id: `scrollable-force-tab-${index}`,
            'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }






    const ressourceListItem = (ressource,last) => {
        return ressourceId == ressource.id ?
                <TimelineItem className={"bg-secondary text-white mx-0 px-2"} >
                    <TimelineSeparator>
                        <TimelineDot color="white">
                            <VideocamIcon />
                        </TimelineDot>
                        {!last && <TimelineConnector/>}
                    </TimelineSeparator>
                    <TimelineContent>{ressource.name}</TimelineContent>
                </TimelineItem>

            :
            <Link to={generateDynamicRoute(ressourcePage.path,[{toReplace:":coursId",value:coursId},{toReplace:":sectionId",value:sectionId},{toReplace:":ressourceId",value:ressource.id}])}>
                <TimelineItem className={"mx-0 px-2"} >
                    <TimelineSeparator>
                        <TimelineDot color="primary">
                            <VideocamIcon />
                        </TimelineDot>
                        {!last && <TimelineConnector className={"bg-primary"}/>}
                    </TimelineSeparator>
                    <TimelineContent>{ressource.name}</TimelineContent>
                </TimelineItem>
            </Link>


    };


    return (
        <>
            <Modal open={true} onClose={handleClose} >

                <div style={{height:"100%",width:"100%",overflowY: "auto"}} className={"bg-white"}>

                    <div className={"my-3 position-relative"}>

                        <IconButton onClick={handleClose} className={"position-absolute"}>
                            <ArrowBackIcon />
                        </IconButton>
                        {state.section && <h3 className={"text-center"}>{state.section.name}</h3>}

                    </div>


                    <div>
                        {props.loadingRessources &&
                        <div className={"d-flex align-items-center justify-content-center"} style={{minHeight:"100vh"}}>
                            <div className={"mx-auto loadingContainer"}>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <div>
                                                            <div>
                                                                <div> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        {!props.loadingRessources &&

                            <>
                                <Grid container className={"p-3"} alignItems="stretch" justify={"center"} spacing={0}>
                                    <Grid item sm={8} className={"border border-right-0"}>

                                        {props.loading &&
                                        <div className={"h-100 d-flex align-items-center justify-content-center"}>
                                            <div className={"mx-auto loadingContainer"}>
                                                <div>
                                                    <div>
                                                        <div>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                    <div>
                                                                        <div>
                                                                            <div> </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
</div>
                                        }

                                        {!props.loading && !props.error && (<>

                                        <AppBar position="static" color="primary" style={{minHeight:72}}>
                                            <h3 className={"text-center my-3"} >{props.ressource.name}</h3>
                                        </AppBar>
                                        {
                                            generateRessource(props.ressource)
                                        }

                                        <List className={"p-0"}>
                                            {props.ressource.videoFiles.map(file => {return (<>
                                                <Divider component="li" /><ListItem>
                                                <ListItemAvatar>
                                                    <PictureAsPdfIcon color="primary"/>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={file.name}
                                                />
                                                <ListItemSecondaryAction>

                                                    <a href={file.link} download target={"_blank"}>
                                                        <IconButton edge="end" aria-label="delete">
                                                            <CloudDownloadIcon />
                                                        </IconButton>
                                                    </a>
                                                </ListItemSecondaryAction>
                                            </ListItem></>);
                                            })}

                                        </List>

                                        </>)}

                                    </Grid>
                                    <Grid item sm={4} className={"border"}>
                                        <AppBar position="static" color="primary">
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                                variant="scrollable"
                                                scrollButtons="on"
                                                aria-label="scrollable force tabs example"
                                            >
                                                <CustomTab label="Tous" icon={<Icon
                                                    className="w-100 fa fa-list"/>} {...a11yProps(0)} />
                                                {
                                                    state.tags.map((tag,index) => {
                                                        return (
                                                            <CustomTab label={tag.name} icon={<Icon className={"w-100 fa fa-" + tag.icon} />} {...a11yProps(index+1)} />);
                                                    })
                                                }
                                            </Tabs>
                                        </AppBar>
                                        <div
                                            style={{overflowY: "scroll",
                                                maxHeight: "calc(100vh - 210px)"}}
                                            role="tabpanel"
                                            hidden={value !== 0}
                                            id={`scrollable-force-tabpanel-${0}`}
                                            aria-labelledby={`scrollable-force-tab-${0}`}
                                        >
                                            {value === 0 && (
                                                    <Box className={"py-2"}>
                                                        <Timeline className={"px-0"}>
                                                            {
                                                                state.allVideos.map((video,index) => {
                                                                    return ressourceListItem(video,index >= state.allVideos.length -1);
                                                                })
                                                            }
                                                        </Timeline>
                                                    </Box>
                                            )}
                                        </div>
                                        {
                                            state.tags.map((tag,index) => {

                                                const ressources =  state.allVideos.filter(res => res.tag && res.tag.id == tag.id);
                                                return (
                                                    <div
                                                        style={{overflowY: "scroll",
                                                            maxHeight: "calc(100vh - 210px)"}}
                                                        role="tabpanel"
                                                        hidden={value !== index+1}
                                                        id={`scrollable-force-tabpanel-${index+1}`}
                                                        aria-labelledby={`scrollable-force-tab-${index+1}`}
                                                    >
                                                        {value === index+1 && (
                                                            <Box className={"py-2"}>
                                                                <Timeline className={"px-0"}>
                                                                    {

                                                                        ressources.map((ressource,index) => {
                                                                            return ressourceListItem(ressource,index >= ressources.length -1);
                                                                        })
                                                                    }
                                                                </Timeline>
                                                            </Box>
                                                        )}
                                                    </div>
                                                );
                                            })
                                        }
                                    </Grid>
                                </Grid>
</>

                        }
                    </div>
                </div>


            </Modal>
        </>

    );
}

const mapStateToProps = (state) => {
    return {
        loading : state.etudiant.mesCours.ressource.loading,
        error : state.etudiant.mesCours.ressource.error,
        ressource : state.etudiant.mesCours.ressource.ressource,
        utilisateur : state.authSignIn.utilisateur,

        loadingRessources : state.etudiant.mesCours.listeRessources.loading,
        errorRessources : state.etudiant.mesCours.listeRessources.error,
        listeRessources : state.etudiant.mesCours.listeRessources.listeRessources,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchRessource : (ressourceId) => dispatch(etudiantMesCoursFetchRessource(ressourceId)),
        resetRessource: () => dispatch(etudiantMesCoursFetchRessourceReset())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(RessourcePage);
