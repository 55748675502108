import * as actionTypes from "../../../actions/actionTypes";
import {updateObject} from "../../../../utils/utils";

const initialState = {
    articles: []
};

const utilisateurCartAddOffreSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        articles : [...state.articles,action.payload.article]
    });
};

const utilisateurCartRemoveOffreSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        articles : state.articles.filter((article) => article.offer.id !== action.payload.offer.id)
    });
};


const utilisateurCartLoadCart = (state,action) => {
    return updateObject(state,{
        ...state,
        articles : action.payload.articles
    });
};

const utilisateurCartResetCart = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};


function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.UTILISATEUR_CART_ADD_OFFRE_SUCCESS : return utilisateurCartAddOffreSuccess(state,action);
        case actionTypes.UTILISATEUR_CART_REMOVE_OFFRE_SUCCESS : return utilisateurCartRemoveOffreSuccess(state,action);
        case actionTypes.UTILISATEUR_CART_LOAD_CART : return utilisateurCartLoadCart(state,action);
        case actionTypes.UTILISATEUR_CART_RESET_CART_SUCCESS : return utilisateurCartResetCart(state,action);

        default : return state;
    }

}

export default reducer;