import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* enseignantMesCoursAjouterFichierSaga (action) {

    yield put(actions.enseignantMesCoursAjouterFichierStart());

    const fichier = action.payload.fichier;
    const fichier2 = action.payload.fichier2;


    const formData = yield new FormData();
    yield formData.append('fichier', new Blob([JSON.stringify(fichier)], {
        type: "application/json"
    }));
    yield formData.append("fichier2",fichier2);


    try {
        const response = yield axios.post(api.enseignantAjouterFichier,formData);
        yield put(actions.enseignantMesCoursAjouterFichierSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.enseignantMesCoursAjouterFichierFail(error.response.data.error));
        }else {
            yield put(actions.enseignantMesCoursAjouterFichierFail("There's an error!"));
        }
    }
}

