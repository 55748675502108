import * as actionTypes from "../../../actionTypes";

export const technicianVideoRequestsTerminateVideoRequest = (videoRequestId) => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_INITIATE_TERMINATE_VIDEO_REQUEST,
        payload : {
            videoRequestId : videoRequestId
        }
    }
};

export const technicianVideoRequestsTerminateVideoRequestStart = () => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_TERMINATE_VIDEO_REQUEST_START
    }
};

export const technicianVideoRequestsTerminateVideoRequestSuccess = (videoRequest) => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_TERMINATE_VIDEO_REQUEST_SUCCESS,
        payload : {
            videoRequest : videoRequest
        }
    }
};

export const technicianVideoRequestsTerminateVideoRequestFail = (error) => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_TERMINATE_VIDEO_REQUEST_FAIL,
        payload : {
            error: error
        }
    }
};

export const technicianVideoRequestsTerminateVideoRequestReset = () => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_TERMINATE_VIDEO_REQUEST_RESET
    }
};
