import * as actionTypes from "../../../../../actions/actionTypes";

export const utilisateurCommandePayerCommande= (commandeId,paiement) => {
    return {
        type: actionTypes.ETUDIANT_COMMANDE_INITIATE_PAYER_COMMANDE,
        payload : {
            commandeId : commandeId,
            paiement: paiement
        }
    }
};

export const utilisateurCommandePayerCommandeStart = () => {
    return {
        type: actionTypes.ETUDIANT_COMMANDE_PAYER_COMMANDE_START
    }
};

export const utilisateurCommandePayerCommandeSuccess = (paiement) => {
    return {
        type: actionTypes.ETUDIANT_COMMANDE_PAYER_COMMANDE_SUCCESS,
        payload : {
            paiement: paiement
        }
    }
};

export const utilisateurCommandePayerCommandeFail = (error) => {
    return {
        type: actionTypes.ETUDIANT_COMMANDE_PAYER_COMMANDE_FAIL,
        payload : {
            error : error
        }
    }
};

export const utilisateurCommandePayerCommandeReset = () => {
    return {
        type: actionTypes.ETUDIANT_COMMANDE_PAYER_COMMANDE_RESET
    }
};
