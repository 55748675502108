import React, {useEffect, useState} from "react";
import RessourceModal from "../../../../components/RessourceModal";
import {Route, Link, useLocation, useHistory, useParams} from "react-router-dom";
import {explorerCoursPage, homePage} from "../../../../routes/guestRoutes";
import {useLastLocation} from "react-router-last-location";
import CoursCategorieTopCard from "../../../../components/CoursCategorieTopCard";
import RessourceList from "../../../../components/RessourceList";
import {CoursePage, StudentMyCoursesPage, ressourcePage} from "../../../../routes/studentRoutes";
import {
    enseignantMesCoursFetchCours,
    enseignantMesCoursFetchCoursReset,
    enseignantMesCoursFetchCoursRessources,
    enseignantMesCoursFetchCoursRessourcesReset
} from "../../../../store/actions/enseignant/mesCours";
import {connect} from "react-redux";
import CoursCategorieTopCardSkeleton from "../../../../UI/LoadingSkeleton/CoursCategorieTopCardSkeleton";
import RessourceListSkeleton from "../../../../UI/LoadingSkeleton/RessourceListSkeleton";
import RessourcePage from "./Ressource";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {generateDynamicRoute} from "../../../../utils/utils";
import AjouterSection from "./AjouterSectionModal";
import AddIcon from "@material-ui/icons/Add";
import NoteIcon from "@material-ui/icons/Note";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {
    AdminUtilisateursAjouterGroupePage,
    AdminUtilisateursAjouterUtilisateurPage
} from "../../../../routes/administratorRoutes";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {Box} from "@material-ui/core";

import PermMediaIcon from '@material-ui/icons/PermMedia';
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import AjouterFicher from "./AjouterFichierModal";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const initialState = {
    ajouterSectionModalOpen : false,
    ajouterFichierModalOpen : false
};

function Cours(props) {

    const [state,setState] = useState(initialState);

    const handleOpenAjouterSectionModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                ajouterSectionModalOpen : true
            }
        })
    };

    const handleOpenAjouterFichierModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                ajouterFichierModalOpen : true
            }
        })
    };


    const handleCloseAjouterSectionModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                ajouterSectionModalOpen : false
            }
        })
    };

    const handleCloseAjouterFichierModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                ajouterFichierModalOpen : false
            }
        })
    };


    let { coursId } = useParams();

    useEffect(() => {

        if(props.ajouterSectionState.success)
            props.fetchRessources(coursId);

        setState(initialState);

    },[props.ajouterSectionState.success]);

    useEffect(() => {

        if(props.ajouterFichierState.success)
            props.fetchRessources(coursId);

        setState(initialState);

    },[props.ajouterFichierState.success]);


    useEffect(()=> {
        props.fetchCours(coursId);
        props.fetchRessources(coursId);

        return () => {
            props.resetCours();
            props.resetRessources();
        }
    },[]);



    return (

        <>
            <Link to={StudentMyCoursesPage.path}>
                    <IconButton className={"my-3"}>
                        <ArrowBackIcon />
                    </IconButton>
            </Link>

            {props.loading && <CoursCategorieTopCardSkeleton/>}
            {!props.loading && !props.error && <CoursCategorieTopCard item={props.cours}/>}

            {props.loadingRessources && <RessourceListSkeleton/>}
            {(!props.loading && !props.loadingRessources && !props.errorRessources) && <>
                <Grid container className={"p-3"} alignItems="center" justify={"center"} spacing={3}>
                    <Grid item >
                        <IconButton onClick={handleOpenAjouterSectionModal} >
                            <AddIcon color="primary" /> <PermMediaIcon color="primary"  />
                        </IconButton>
                    </Grid>
                    <Grid item >
                        <IconButton onClick={handleOpenAjouterFichierModal} >
                            <AddIcon color="primary" />
                            <PictureAsPdfIcon color="primary"/>
                        </IconButton>
                    </Grid>
                    <Grid item >
                        <IconButton onClick={handleOpenAjouterSectionModal} >
                            <AddIcon color="primary" />
                            <OndemandVideoIcon color="primary"/>
                        </IconButton>
                    </Grid>


                </Grid>
                <RessourceList coursId={props.cours.id} openRessource={true} sectionsRessources={props.listeRessources}/>
                </>}

            <AjouterSection coursId={coursId} listeSections={props.listeRessources} open={state.ajouterSectionModalOpen} handleClose={handleCloseAjouterSectionModal}/>


            <AjouterFicher coursId={coursId} listeSections={props.listeRessources} open={state.ajouterFichierModalOpen} handleClose={handleCloseAjouterFichierModal}/>

            <Route path={ressourcePage.path} component={RessourcePage} />

        </>


    );

}

const mapStateToProps = (state) => {
    return {
        loading : state.enseignant.mesCours.cours.loading,
        error : state.enseignant.mesCours.cours.error,
        cours : state.enseignant.mesCours.cours.cours,
        loadingRessources : state.enseignant.mesCours.listeRessources.loading,
        errorRessources : state.enseignant.mesCours.listeRessources.error,
        listeRessources : state.enseignant.mesCours.listeRessources.listeRessources,
        ajouterSectionState : state.enseignant.mesCours.ajouterSection,
        ajouterFichierState : state.enseignant.mesCours.ajouterFichier
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCours : (coursId) => dispatch(enseignantMesCoursFetchCours(coursId)),
        resetCours : () => dispatch(enseignantMesCoursFetchCoursReset()),
        fetchRessources : (coursId) => dispatch(enseignantMesCoursFetchCoursRessources(coursId)),
        resetRessources : () => dispatch(enseignantMesCoursFetchCoursRessourcesReset())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Cours);