import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    listeCommandes: []
};

const etudiantCommandesFetchListeCommandesStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        listeCommandes: []
    });
};

const etudiantCommandesFetchListeCommandesSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        listeCommandes: action.payload.listeCommandes
    });
};

const etudiantCommandesFetchListeCommandesFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        listeCommandes: []
    });
};

const etudiantCommandesFetchListeCommandesReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};


function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ETUDIANT_COMMANDES_FETCH_LISTE_COMMANDES_START : return etudiantCommandesFetchListeCommandesStart(state,action);
        case actionTypes.ETUDIANT_COMMANDES_FETCH_LISTE_COMMANDES_SUCCESS : return etudiantCommandesFetchListeCommandesSuccess(state,action);
        case actionTypes.ETUDIANT_COMMANDES_FETCH_LISTE_COMMANDES_FAIL : return etudiantCommandesFetchListeCommandesFail(state,action);
        case actionTypes.ETUDIANT_COMMANDES_FETCH_LISTE_COMMANDES_RESET : return etudiantCommandesFetchListeCommandesReset(state,action);


        default : return state;
    }

}

export default reducer;