import React, {useEffect, useState} from "react";

import Icon from "@material-ui/core/Icon";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import IconButton from '@material-ui/core/IconButton';
import {connect} from "react-redux";
import * as actions from "../../../store/actions";






function TopHeader(props) {


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    const [open4, setOpen4] = React.useState(false);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };




    const anchorRef = React.useRef(null);



    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open4 === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open4;
    }, [open4]);

    
    return (
        <div>
            <AppBar position="static">
                <Toolbar className={"d-flex justify-content-between"}>

                    <div className={"d-flex position-absolute"} style={{right:10}}>

<>
                            <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Icon className={"fa fa-user-circle"} />
                        </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem><h5 className={"my-3"}><b>{props.user.firstName} {props.user.lastName}</b></h5></MenuItem>
                                <MenuItem onClick={() => {handleClose(); props.onLogout()}}>Se déconnecter</MenuItem>
                            </Menu>


                        </>


                    </div>
                </Toolbar>
            </AppBar>
        </div>
    )

}


const mapStateToProps = (state) => {
    return {

        isAuthenticated: state.authSignIn.token != null,
        user: state.authSignIn.user,
        token: state.authSignIn.token
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

        onSideNavOpen:  () => dispatch(actions.openSideNav()),

        onLogout : () => dispatch(actions.logout())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(TopHeader)