import * as actionTypes from "../../../../actions/actionTypes";

export const utilisateurFetchTopNotifcations = (utilisateurId) => {
    return {
        type: actionTypes.UTILISATEUR_INITIATE_FETCH_TOP_NOTIFICATIONS,
        payload : {
            utilisateurId : utilisateurId
        }
    }
};

export const utilisateurFetchTopNotifcationsStart = () => {
    return {
        type: actionTypes.UTILISATEUR_FETCH_TOP_NOTIFICATIONS_START
    }
};

export const utilisateurFetchTopNotifcationsSuccess = (notifications) => {
    return {
        type: actionTypes.UTILISATEUR_FETCH_TOP_NOTIFICATIONS_SUCCESS,
        payload : {
            notifications: notifications
        }
    }
};

export const utilisateurFetchTopNotifcationsFail = (error) => {
    return {
        type: actionTypes.UTILISATEUR_FETCH_TOP_NOTIFICATIONS_FAIL,
        payload : {
            error : error
        }
    }
};


export const UtilisateurAddTopNotificaiton = (notification) => {
    return {
        type: actionTypes.UTILISATEUR_ADD_TOP_NOTIFICATION,
        payload : {
            notification: notification
        }
    }
};
