import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieSaga (action) {

    yield put(actions.adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieStart());

    const assignementGroupeCategorie = action.payload.assignementGroupeCategorie;

    try {
        const response = yield axios.post(api.ajouterAssignementGroupeCategorie,assignementGroupeCategorie);
        yield put(actions.adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieFail(error.response.data.error));
        }else {
            yield put(actions.adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieFail("There's an error!"));
        }
    }
}

