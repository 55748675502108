import * as actionTypes from "../../../../actions/actionTypes2";


export const etudiantCommandesFetchListeCommandes = (utilisateurId) => {
    return {
        type: actionTypes.ETUDIANT_COMMANDES_INITIATE_FETCH_LISTE_COMMANDES,
        payload : {
            utilisateurId : utilisateurId
        }
    }
};

export const etudiantCommandesFetchListeCommandesStart = () => {
    return {
        type: actionTypes.ETUDIANT_COMMANDES_FETCH_LISTE_COMMANDES_START
    }
};

export const etudiantCommandesFetchListeCommandesSuccess = (listeCommandes) => {
    return {
        type: actionTypes.ETUDIANT_COMMANDES_FETCH_LISTE_COMMANDES_SUCCESS,
        payload : {
            listeCommandes: listeCommandes
        }
    }
};

export const etudiantCommandesFetchListeCommandesFail = (error) => {
    return {
        type: actionTypes.ETUDIANT_COMMANDES_FETCH_LISTE_COMMANDES_FAIL,
        payload : {
            error : error
        }
    }
};


export const etudiantCommandesFetchListeCommandesReset = () => {
    return {
        type: actionTypes.ETUDIANT_COMMANDES_FETCH_LISTE_COMMANDES_RESET
    }
};


