import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    listeOffres: []
};

const guestExplorerOffresFetchListeOffresStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        listeOffres: []
    });
};

const guestExplorerOffresFetchListeOffresSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        listeOffres: action.payload.listeOffres
    });
};

const guestExplorerOffresFetchListeOffresFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        listeOffres: []
    });
};

function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.GUEST_EXPLORER_OFFRES_FETCH_LISTE_OFFRES_START : return guestExplorerOffresFetchListeOffresStart(state,action);
        case actionTypes.GUEST_EXPLORER_OFFRES_FETCH_LISTE_OFFRES_SUCCESS : return guestExplorerOffresFetchListeOffresSuccess(state,action);
        case actionTypes.GUEST_EXPLORER_OFFRES_FETCH_LISTE_OFFRES_FAIL : return guestExplorerOffresFetchListeOffresFail(state,action);

        default : return state;
    }

}

export default reducer;