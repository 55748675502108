import React from "react";
import {Switch} from "react-router-dom";
import CustomRoute from "../../hoc/CustomRoute";

function Auth(props) {

    return (
        <div className={"p-3 p-md-5"}>

            <Switch>
                {props.routes.map(route => {
                    return CustomRoute(route);
                })}
            </Switch>

        </div>

    );


}

export default Auth;