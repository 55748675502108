import * as actionTypes from "../../../../actions/actionTypes";

export const adminUtilisateursAjouterUtilisateur = (utilisateur,photo,sendEmail,generatePassword) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_AJOUTER_UTILISATEUR,
        payload : {
            utilisateur : utilisateur,
            photo : photo,
            sendEmail : sendEmail,
            generatePassword : generatePassword
        }
    }
};

export const adminUtilisateursAjouterUtilisateurStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_START
    }
};

export const adminUtilisateursAjouterUtilisateurSuccess = (utilisateur) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_SUCCESS,
        payload : {
            utilisateur: utilisateur
        }
    }
};

export const adminUtilisateursAjouterUtilisateurFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_FAIL,
        payload : {
            error : error
        }
    }
};

export const adminUtilisateursAjouterUtilisateurReset = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_RESET
    }
};
