



/**** ASSIGNEMENTS UTILISATEUR CATEGORIE *****/

export const UTILISATEUR_INITIATE_MODIFIER_PROFIL = 'UTILISATEUR_INITIATE_MODIFIER_PROFIL';
export const UTILISATEUR_MODIFIER_PROFIL_START = 'UTILISATEUR_MODIFIER_PROFIL_START';
export const UTILISATEUR_MODIFIER_PROFIL_SUCCESS = 'UTILISATEUR_MODIFIER_PROFIL_SUCCESS';
export const UTILISATEUR_MODIFIER_PROFIL_FAIL = 'UTILISATEUR_MODIFIER_PROFIL_FAIL';
export const UTILISATEUR_MODIFIER_PROFIL_RESET = 'UTILISATEUR_MODIFIER_PROFIL_RESET';


export const UTILISATEUR_INITIATE_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE = 'UTILISATEUR_INITIATE_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE';
export const UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_START = 'UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_START';
export const UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_SUCCESS = 'UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_SUCCESS';
export const UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_FAIL = 'UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_FAIL';
export const UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_RESET = 'UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_RESET';