import { put } from "redux-saga/effects";

import axios from "../../../../../api/axios";

import * as actions from "../../../../actions";
import * as api from "../../../../../api";

export function* guestExplorerCoursFetchListeCoursSaga(action) {
  yield put(actions.guestExplorerCoursFetchListeCoursStart());

  try {
    const { category } = action.payload;
    const response = yield axios.get(`${api.getListeCoursRoot}/${category}`);
    const listeCours = yield response.data;

    yield listeCours.forEach((cours) => {
      cours["type"] = "Cours";
    });
    yield put(actions.guestExplorerCoursFetchListeCoursSuccess(listeCours));
  } catch (error) {
    if (error.response) {
      yield put(
        actions.guestExplorerCoursFetchListeCoursFail(error.response.data.error)
      );
    } else {
      yield put(
        actions.guestExplorerCoursFetchListeCoursFail("There's an error!")
      );
    }
  }
}
