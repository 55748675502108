import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null
};

const adminUtilisateursSupprimerUtilisateurStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
    });
};

const adminUtilisateursSupprimerUtilisateurSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null
    });
};

const adminUtilisateursSupprimerUtilisateurFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error
    });
};

const adminUtilisateursSupprimerUtilisateurReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_START : return adminUtilisateursSupprimerUtilisateurStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_SUCCESS : return adminUtilisateursSupprimerUtilisateurSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_FAIL : return adminUtilisateursSupprimerUtilisateurFail(state,action);
        case actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_RESET : return adminUtilisateursSupprimerUtilisateurReset(state,action);
        default : return state;
    }

}

export default reducer;