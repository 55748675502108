import * as actionTypes from "../../../../actions/actionTypes";


export const adminCoursFetchListeCategories = () => {
    return {
        type: actionTypes.ADMIN_COURS_INITIATE_FETCH_LISTE_CATEGORIES,
    }
};

export const adminCoursFetchListeCategoriesStart = () => {
    return {
        type: actionTypes.ADMIN_COURS_FETCH_LISTE_CATEGORIES_START
    }
};

export const adminCoursFetchListeCategoriesSuccess = (listeCategories) => {
    return {
        type: actionTypes.ADMIN_COURS_FETCH_LISTE_CATEGORIES_SUCCESS,
        payload : {
            listeCategories: listeCategories
        }
    }
};

export const adminCoursFetchListeCategoriesFail = (error) => {
    return {
        type: actionTypes.ADMIN_COURS_FETCH_LISTE_CATEGORIES_FAIL,
        payload : {
            error : error
        }
    }
};







export const adminCoursFetchCategorieListeCategories = (categorieId) => {
    return {
        type: actionTypes.ADMIN_COURS_INITIATE_FETCH_CATEGORIE_LISTE_CATEGORIES,
        payload : {
            categorieId : categorieId
        }
    }
};

export const adminCoursFetchCategorieListeCategoriesStart = (categorieId) => {
    return {
        type: actionTypes.ADMIN_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_START,
        payload : {
            categorieId : categorieId
        }
    }
};

export const adminCoursFetchCategorieListeCategoriesSuccess = (categorieId,listeCategories) => {
    return {
        type: actionTypes.ADMIN_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_SUCCESS,
        payload : {
            categorieId : categorieId,
            listeCategories: listeCategories
        }
    }
};

export const adminCoursFetchCategorieListeCategoriesFail = (categorieId,error) => {
    return {
        type: actionTypes.ADMIN_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_FAIL,
        payload : {
            categorieId : categorieId,
            error : error
        }
    }
};






export const adminCoursFetchCategorieListeCours = (categorieId) => {
    return {
        type: actionTypes.ADMIN_COURS_INITIATE_FETCH_CATEGORIE_LISTE_COURS,
        payload : {
            categorieId : categorieId
        }
    }
};

export const adminCoursFetchCategorieListeCoursStart = (categorieId) => {
    return {
        type: actionTypes.ADMIN_COURS_FETCH_CATEGORIE_LISTE_COURS_START,
        payload : {
            categorieId : categorieId
        }
    }
};

export const adminCoursFetchCategorieListeCoursSuccess = (categorieId,listeCours) => {
    return {
        type: actionTypes.ADMIN_COURS_FETCH_CATEGORIE_LISTE_COURS_SUCCESS,
        payload : {
            categorieId : categorieId,
            listeCours: listeCours
        }
    }
};

export const adminCoursFetchCategorieListeCoursFail = (categorieId,error) => {
    return {
        type: actionTypes.ADMIN_COURS_FETCH_CATEGORIE_LISTE_COURS_FAIL,
        payload : {
            categorieId : categorieId,
            error : error
        }
    }
};






