import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminUtilisateursAjouterUtilisateurSaga (action) {

    yield put(actions.adminUtilisateursAjouterUtilisateurStart());

    const utilisateur = yield action.payload.utilisateur;
    const photo = yield action.payload.photo;

    const sendEmail = yield action.payload.sendEmail;
    const generatePassword = yield action.payload.generatePassword;

    const formData = yield new FormData();
    yield formData.append('utilisateur', new Blob([JSON.stringify(utilisateur)], {
                                                type: "application/json"
                                            }));
    yield formData.append("photo",photo);

    try {
        const response = yield axios.post(generateDynamicRoute(api.ajouterUtilisateur,[{toReplace:":sendEmail",value:sendEmail},{toReplace:":generatePassword",value:generatePassword}]),formData);
        yield put(actions.adminUtilisateursAjouterUtilisateurSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursAjouterUtilisateurFail(error.response.data));
        }else {
            yield put(actions.adminUtilisateursAjouterUtilisateurFail("There's an error!"));
        }
    }
}

