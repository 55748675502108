import React, { useEffect } from "react";

import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import CategorieCoursListSkeleton from "../../../UI/LoadingSkeleton/CategorieCoursListSkeleton";
import { Box } from "@material-ui/core";
import { utilisateurCartAddOffre } from "../../../store/actions/utilisateur/cart";
import { etudiantExplorerOffresFetchListeOffres } from "../../../store/actions/etudiant/explorerOffres/listeOffres";
import Offer from "../../../components/Home/OffresPage/Offer";

import cssClasses from "./offerList.module.css";

function Offers(props) {
  useEffect(() => {
    props.fetchListeOffres(props.user.category.id);
  }, []);

  const handleAddToCart = (offer, duree) => {
    const article = offer.fixedPeriod
      ? {
          offer: offer,
          duree: null,
        }
      : {
          offer: offer,
          duree: duree,
        };

    props.addArticle(article, props.user.id);
  };

  const offersList = props.offers.listeOffres
    .filter((offer) => offer.active)
    .map((offer) => {
      const offerSelectable =
        props.cartArticles.find((article) => article.offer.id === offer.id) ==
        undefined;

      return (
        <Offer
          offer={offer}
          offerSelectable={offerSelectable}
          isLoggedIn={true}
          handleAddToCart={handleAddToCart}
        />
      );
    });

  return (
    <div className={cssClasses.container}>
      <div className={"p-3"}>
        <h1 className={"text-center " + cssClasses.title}>
          Explorer nos offres d'inscriptions
        </h1>

        {props.offers.loading && (
          <>
            <Grid className={"my-3"} container={true} spacing={3}>
              <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                <CategorieCoursListSkeleton />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                <CategorieCoursListSkeleton />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                <CategorieCoursListSkeleton />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                <Box
                  display={{ xs: "none", sm: "block", md: "none", lg: "block" }}
                >
                  <CategorieCoursListSkeleton />
                </Box>
              </Grid>
            </Grid>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                <CategorieCoursListSkeleton />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                <CategorieCoursListSkeleton />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                <CategorieCoursListSkeleton />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                <Box
                  display={{ xs: "none", sm: "block", md: "none", lg: "block" }}
                >
                  <CategorieCoursListSkeleton />
                </Box>
              </Grid>
            </Grid>
          </>
        )}

        {!props.offers.loading && (
          <Grid container spacing={3} className={"my-5"}>
            {offersList}
          </Grid>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    offers: { ...state.etudiant.explorerOffres.listeOffres },
    cartArticles: state.utilisateur.cart.articles,
    user: state.authSignIn.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchListeOffres: (categoryId) =>
      dispatch(etudiantExplorerOffresFetchListeOffres(categoryId)),
    addArticle: (article, userId) =>
      dispatch(utilisateurCartAddOffre(article, userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
