import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    utilisateur: null,

    changerMotDePasse : {
        success : false,
        loading : false,
        error: null
    },

    ajouterGroupe : {
        success : false,
        loading : false,
        error: null
    },
};

const adminUtilisateursModifierUtilisateurStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        utilisateur: null
    });
};

const adminUtilisateursModifierUtilisateurSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        utilisateur: action.payload.utilisateur
    });
};

const adminUtilisateursModifierUtilisateurFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        utilisateur: null
    });
};

const adminUtilisateursModifierUtilisateurReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};








const adminUtilisateursModifierUtilisateurChangerMotDePasseStart = (state,action) => {
    return updateObject(state,{
        ...state,

        changerMotDePasse : {
            success : false,
            loading : true,
            error: null
        },

    });
};




const adminUtilisateursModifierUtilisateurChangerMotDePasseSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        changerMotDePasse : {
            success : true,
            loading : false,
            error: null
        },

    });
};

const adminUtilisateursModifierUtilisateurChangerMotDePasseFail = (state,action) => {
    return updateObject(state,{
        ...state,
        changerMotDePasse : {
            success : false,
            loading : false,
            error: action.payload.error
        },
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_START : return adminUtilisateursModifierUtilisateurStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_SUCCESS : return adminUtilisateursModifierUtilisateurSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_FAIL : return adminUtilisateursModifierUtilisateurFail(state,action);
        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_RESET : return adminUtilisateursModifierUtilisateurReset(state,action);


        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_CHANGER_MOT_DE_PASSE_START: return adminUtilisateursModifierUtilisateurChangerMotDePasseStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_CHANGER_MOT_DE_PASSE_SUCCESS : return adminUtilisateursModifierUtilisateurChangerMotDePasseSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_CHANGER_MOT_DE_PASSE_FAIL : return adminUtilisateursModifierUtilisateurChangerMotDePasseFail(state,action);

        default : return state;
    }

}

export default reducer;