import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";

function CoursCategorieTopCardSkeleton(props) {

    return (

        <Card className={"p-3"}>

            <Skeleton className={"w-100 mx-auto"} style={{maxWidth:"550px"}} height={"35px"} />
            <Skeleton className={"my-3 mx-auto"} variant={"rect"} height={"350px"} width={"100%"} style={{maxWidth:"550px"}} />
            <Skeleton />
            <Skeleton  />
            <Skeleton />


        </Card>

    );

}

export default CoursCategorieTopCardSkeleton;