import React, {useEffect} from "react";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import CoursCategorieList from "../../../../components/CoursCategoriePaginatedList";
import {Link, useParams} from "react-router-dom";
import {explorerCoursPage} from "../../../../routes/guestRoutes";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CoursCategorieTopCardSkeleton from "../../../../UI/LoadingSkeleton/CoursCategorieTopCardSkeleton";
import CoursCategorieTopCard from "../../../../components/CoursCategorieTopCard";
import {
    guestExplorerCoursCoursFetchListeRessources, guestExplorerCoursCoursFetchListeRessourcesReset,
    guestExplorerCoursFetchCours,
    guestExplorerCoursFetchCoursReset
} from "../../../../store/actions/guest/explorerCours/cours";
import {connect} from "react-redux";
import {
    guestExplorerCoursFetchCategorie, guestExplorerCoursFetchCategorieReset
} from "../../../../store/actions/guest/explorerCours/categorie";
import {
    guestExplorerCoursFetchCategorieListeCategories,
    guestExplorerCoursFetchCategorieListeCours
} from "../../../../store/actions/guest/explorerCours/listeCategories";


function Categorie(props) {

    let { categorieId } = useParams();



    useEffect(()=> {
        props.fetchCategorie(categorieId);
        props.fetchCategorieListeCategories(categorieId);
        props.fetchCategorieListeCours(categorieId);
        //props.fetchRessources(categorieId);

        return () => {
            props.resetCategorie();
            //props.resetRessources();
        }
    },[categorieId]);

    /*const categoriesCours = [
        {
            id : 1,
            photo : 'https://cdnp1.stackassets.com/dbd8b662370e2dd5283d3f352802c43c79b15f8d/store/opt/596/298/abf733ad50de70c3ad93f8b34f894876f93318a2aff76ccc7504ecce0fc1/product_16032_product_shot_wide_image.jpg',
            titre : "Programmation C",
            descriptionCourte : 'kjerkeznekjnjenkzjnre zjekrkerjre kjerknrkzejnrkje'
        },
        {
            id : 2,
            photo : 'https://s24255.pcdn.co/wp-content/uploads/2014/05/oracle-java.jpg',
            titre : "Programmation JAVA",
            descriptionCourte : 'kjerkeznekjnjenkzjnre zjekrkerjre kjerknrkzejnrkje'
        },
        {
            id : 3,
            photo : 'https://hewittbritton.com/wp-content/uploads/2018/06/PHPSS.png',
            titre : "Programmation PHP",
            descriptionCourte : 'kjerkeznekjnjenkzjnre zjekrkerjre kjerknrkzejnrkje'
        },
        {
            id : 4,
            photo : 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/512px-HTML5_logo_and_wordmark.svg.png',
            titre : "HTML",
            descriptionCourte : 'kjerkeznekjnjenkzjnre zjekrkerjre kjerknrkzejnrkje'
        },
    ];*/

    return(
        <>

            <div className={"p-5"}>
            <Link to={explorerCoursPage.path}>
                <IconButton className={"my-3"}>
                    <ArrowBackIcon />
                </IconButton>
            </Link>

            {props.loading && <CoursCategorieTopCardSkeleton/>}
            {!props.loading && !props.error && <CoursCategorieTopCard item={props.categorie}/>}


            {!props.listeCategories.loading && !props.listeCours.loading &&

                <CoursCategorieList categoriesCours={props.listeCategories.listeCategories.concat(props.listeCours.listeCours)} />
            }
            </div>
        </>
    );

}



const mapStateToProps = (state) => {
    return {
        loading : state.guest.explorerCours.categorie.loading,
        error : state.guest.explorerCours.categorie.error,
        categorie : state.guest.explorerCours.categorie.categorie,

        listeCategories : {
            loading : state.guest.explorerCours.categorie.listeCategories.loading,
            error : state.guest.explorerCours.categorie.listeCategories.error,
            listeCategories : state.guest.explorerCours.categorie.listeCategories.listeCategories
        },

        listeCours : {
            loading : state.guest.explorerCours.categorie.listeCours.loading,
            error : state.guest.explorerCours.categorie.listeCours.error,
            listeCours : state.guest.explorerCours.categorie.listeCours.listeCours
        }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCategorie : (categorieId) => dispatch(guestExplorerCoursFetchCategorie(categorieId)),
        resetCategorie : () => dispatch(guestExplorerCoursFetchCategorieReset()),
        fetchCategorieListeCategories : (categorieId) => dispatch(guestExplorerCoursFetchCategorieListeCategories(categorieId)),
        fetchCategorieListeCours : (categorieId) => dispatch(guestExplorerCoursFetchCategorieListeCours(categorieId)),
        //fetchCategorieListeCours : () => dispatch(guestExplorerCoursCoursFetchListeRessourcesReset())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Categorie);