import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Autocomplete from '@material-ui/lab/Autocomplete';

import Icon from "@material-ui/core/Icon";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InputIcon from '@material-ui/icons/Input';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import userPhotoPlaceholder from "../../../assets/user-photo-placeholder.png";

import {Grid} from "@material-ui/core";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import {connect} from "react-redux";

import Alert from "@material-ui/lab/Alert";


import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import {DropzoneDialogBase} from "material-ui-dropzone";
import {
    adminUtilisateursAjouterUtilisateur,
    adminUtilisateursAjouterUtilisateurReset
} from "../../../store/actions/admin/utilisateurs/ajouterUtilisateurActions";
import {
    adminCoursFetchCategorieListeCategories,
    adminCoursFetchCategorieListeCours,
    adminCoursFetchListeCategories,
    adminCoursFetchListeCours,
    adminCoursSupprimerCategorie,
    adminCoursSupprimerCategorieReset,
    adminCoursSupprimerCours,
    adminCoursSupprimerCoursReset,
    authSignUp,
    enseignantDemandeVideosAjouterDemandeReset,
    enseignantDemandeVideosAjouterDemandeVideo,
    enseignantMesCoursFetchListeCours, enseignantMesCoursFetchListeCoursReset
} from "../../../store/actions";
import {NavLink, Redirect} from "react-router-dom";
import {signInPage} from "../../../routes/authRoutes";

const initialState = {
    cours : {
        value: "",
        error: null
    },
    nom : {
        value: "",
        error: null
    },
    tag : {
        value: "",
        error: null
    },
    description : {
        value: "",
        error: null
    },

    video : null,
};










function DemandeVideo(props) {






    const [open, setOpen] = React.useState(false);
    const [fileObjects, setFileObjects] = React.useState([]);
    const [state,setState] = useState(initialState);


    useEffect(() => {
        props.fetchListeCours(props.utilisateurId);
        return () => {
            props.resetDemandeAjouterVideo();
            props.resetListeCours();
        }
    },[]);


    useEffect(() => {

        setState(initialState);
        setFileObjects([]);

    },[props.success]);


    /*** Form ***/

    const coursOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                cours : {
                    value : value,
                    error: error
                }
            }
        });
    };


    const nomOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                nom : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const tagOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                tag : {
                    value : value,
                    error: error
                }
            }
        });
    };


    const descriptionOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                description : {
                    value : value,
                    error: error
                }
            }
        });
    };



    const formOnSubmitHandler = (event) => {
        event.preventDefault();

        let requirementsError = false;

        const cours = state.cours.value;
        const nom = state.nom.value;
        const tag = state.tag.value;
        const description = state.description.value;



        if(!nom) {
            setState(prevState => {
                return {
                    ...prevState,
                    nom : {
                        ...prevState.nom,
                        error : "Le nom est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!tag) {
            setState(prevState => {
                return {
                    ...prevState,
                    tag : {
                        ...prevState.prenom,
                        error : "Tag est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!requirementsError) {
            const demandeVideo = {
                nom : cours + "----------" + nom + "-------" + tag,
                description : description
            };
            const video = state.video ? state.video.file : null;

            props.demandeAjouterVideo(demandeVideo,video);
        }

    };

    /*** Form ***/

    const inputClasses = ["my-2"];
    return(
        <>
            <div className={"text-center border rounded p-3"}>
                <div>
                    <h2>Demande Ajout Video</h2>
                    {props.success &&
                    <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Votre demande a été bien recu</Alert>
                    }
                    {props.error &&
                    <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.error.message}</Alert>
                    }
                    <form onSubmit={formOnSubmitHandler}>
                        <Grid container spacing={3} className={"my-3"}>

                            <Grid item xs={12} className={"text-left"}>
                                    <InputLabel id="demo-simple-select-label">Matiere :</InputLabel>
                                    <Select
                                        className={"w-100"}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={state.cours.value}
                                        onChange={coursOnChangeHandler}
                                    >
                                        {
                                            props.listeCours.listeCours.map(cours => {
                                                return <MenuItem value={cours.id}>{cours.titre}</MenuItem>
                                            })
                                        }
                                    </Select>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField error={state.nom.error ? true : false} helperText={state.nom.error} className={inputClasses.join(" ")} onChange={nomOnChangeHandler} value={state.nom.value} fullWidth={true} size={"small"} label="Nom" variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField error={state.tag.error ? true : false} helperText={state.tag.error} className={inputClasses.join(" ")} onChange={tagOnChangeHandler} value={state.tag.value} fullWidth={true} size={"small"}  label="Tag" variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField multiline={true} error={state.description.error ? true : false} helperText={state.description.error} className={inputClasses.join(" ")} onChange={descriptionOnChangeHandler} value={state.description.value} fullWidth={true} size={"small"} rows={4}  label="Description" variant="outlined" />
                            </Grid>

                            <Grid item xs={12} className={"text-center"}>

                                <h6>{state.video ? state.video.file.name : ""}</h6>

                                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                                    Choisir Video
                                </Button>
                                <DropzoneDialogBase
                                    showAlerts={false}
                                    acceptedFiles={['video/*']}
                                    filesLimit={1}
                                    maxFileSize={100000000000}
                                    fileObjects={fileObjects}
                                    cancelButtonText={"annuler"}
                                    submitButtonText={"valider"}
                                    previewText={"Prev"}
                                    open={open}
                                    onAdd={newFileObjs => {
                                        setFileObjects([].concat(fileObjects, newFileObjs));
                                    }}
                                    onDelete={deleteFileObj => {
                                        setFileObjects([]);
                                    }}
                                    onClose={() => setOpen(false)}
                                    onSave={() => {
                                        setState(prevState => {
                                            return {
                                                ...prevState,
                                                video : fileObjects[0]
                                            }
                                        });
                                        setOpen(false);
                                    }}
                                    showPreviews={true}
                                    showFileNamesInPreview={true}
                                />

                            </Grid>

                        </Grid>
                        {
                            <div className={"position-relative"}>
                                <Button disabled={props.loading} type={"submit"} className={inputClasses.join(" ")} color={"primary"} variant={"contained"} endIcon={<InputIcon />}>Ajouter</Button>
                                {props.loading && <CircularProgress  size={24}/>}
                            </div>
                        }
                    </form>
                </div>
            </div>



        </>
    );


}

const mapStateToProps = (state) => {
    return {
        utilisateurId : state.authSignIn.utilisateur.id,
        ...state.enseignant.demandeVideos.ajouterDemandeVideo,
        listeCours : { ...state.enseignant.mesCours.listeCours }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

        demandeAjouterVideo : (demnadeVideo,video) => dispatch(enseignantDemandeVideosAjouterDemandeVideo(demnadeVideo,video)),
        resetDemandeAjouterVideo : () => dispatch(enseignantDemandeVideosAjouterDemandeReset()),

        fetchListeCours : (utilisateurId) => dispatch(enseignantMesCoursFetchListeCours(utilisateurId)),
        resetListeCours : () => dispatch(enseignantMesCoursFetchListeCoursReset())


    }

};

export default connect(mapStateToProps,mapDispatchToProps)(DemandeVideo);



