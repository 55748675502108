import * as actionTypes from "../../../actions/actionTypes";
import {updateObject} from "../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null
};

const authSignUpStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null
    });
};

const authSignUpSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null
    });
};

const authSignUpFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error
    });
};

const authSignUpReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.AUTH_SIGNUP_START : return authSignUpStart(state,action);
        case actionTypes.AUTH_SIGNUP_SUCCESS : return authSignUpSuccess(state,action);
        case actionTypes.AUTH_SIGNUP_FAIL : return authSignUpFail(state,action);
        case actionTypes.AUTH_SIGNUP_RESET : return authSignUpReset(state,action);
        default : return state;
    }

}

export default reducer;