import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    assignementUtilisateurCategorieId : null
};

const adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorieStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        assignementUtilisateurCategorieId : null
    });
};

const adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorieSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        assignementUtilisateurCategorieId : action.payload.assignementUtilisateurCategorieId
    });
};

const adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorieFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        assignementUtilisateurCategorieId : null
    });
};

const adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorieReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_START : return adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorieStart(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_SUCCESS : return adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorieSuccess(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_FAIL : return adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorieFail(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_RESET : return adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorieReset(state,action);
        default : return state;
    }

}

export default reducer;