import * as actionTypes from "../../../actionTypes";


export const adminUtilisateursAjouterUtilisateurAuGroupe = (utilisateurId,groupeId) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_AJOUTER_UTILISATEUR_AU_GROUPE,
        payload : {
            utilisateurId : utilisateurId,
            groupeId : groupeId
        }
    }
};

export const adminUtilisateursAjouterUtilisateurAuGroupeStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_START
    }
};
export const adminUtilisateursAjouterUtilisateurAuGroupeReset = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_RESET
    }
};

export const adminUtilisateursAjouterUtilisateurAuGroupeSuccess = (utilisateur,groupe) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_SUCCESS,
        payload : {
            utilisateur : utilisateur,
            groupe : groupe
        }
    }
};

export const adminUtilisateursAjouterUtilisateurAuGroupeFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_FAIL,
        payload : {
            error : error
        }
    }
};
