



/**** ASSIGNEMENTS UTILISATEUR CATEGORIE *****/

export const UTILISATEUR_INITIATE_FETCH_TOP_NOTIFICATIONS = 'UTILISATEUR_INITIATE_FETCH_TOP_NOTIFICATIONS';
export const UTILISATEUR_FETCH_TOP_NOTIFICATIONS_START = 'UTILISATEUR_FETCH_TOP_NOTIFICATIONS_START';
export const UTILISATEUR_FETCH_TOP_NOTIFICATIONS_SUCCESS = 'UTILISATEUR_FETCH_TOP_NOTIFICATIONS_SUCCESS';
export const UTILISATEUR_FETCH_TOP_NOTIFICATIONS_FAIL = 'UTILISATEUR_FETCH_TOP_NOTIFICATIONS_FAIL';
export const UTILISATEUR_FETCH_TOP_NOTIFICATIONS_RESET = 'UTILISATEUR_FETCH_TOP_NOTIFICATIONS_RESET';



export const UTILISATEUR_ADD_TOP_NOTIFICATION = 'UTILISATEUR_ADD_TOP_NOTIFICATION';
