import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import {CircularProgress} from "@material-ui/core";
import {Grid} from "@material-ui/core";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import {connect} from "react-redux";

import Alert from "@material-ui/lab/Alert";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";



import Chip from '@material-ui/core/Chip';
import Box from "@material-ui/core/Box";
import {
    adminUtilisateursAjouterGroupe, adminUtilisateursAjouterGroupeFetchGroupeListeGroupes,
    adminUtilisateursAjouterGroupeFetchListeGroupes, adminUtilisateursAjouterGroupeReset
} from "../../../../store/actions/admin/utilisateurs/ajouterGroupeActions";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {findGroupes} from "../../../../utils/utils";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import TreeListeGroupesSingleAdd from "../../../../components/TreeListeGroupesSingleAdd";

const initialState = {
    nom : {
        value: "",
        error: null
    },
    groupeParent : null
};

function AjouterGroupe(props) {


    const [state,setState] = useState(initialState);

    const [groupesExpanded,setGroupesExpanded] = useState(false);

    const handleGroupesExpanded = (event,isExpanded) => {
        setGroupesExpanded(isExpanded);
    };


    const handleGroupeSelect = (groupe) => {
        setState(prevState => {
            return {
                ...prevState,
                groupeParent: groupe
            };
        });
    };

    const handleGroupeUnSelect = () => {
        setState(prevState => {
            return {
                ...prevState,
                groupeParent: null
            };
        });
    };

    const isGroupeSelected = (groupeToCheck) => {
        return state.groupeParent && state.groupeParent.id === groupeToCheck.id;
    };

    /*** Form ***/

    const nomOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                nom : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const formOnSubmitHandler = (event) => {
        event.preventDefault();
        let requirementsError = false;

        const nom = state.nom.value;

        if(!nom) {
            setState(prevState => {
                return {
                    ...prevState,
                    nom : {
                        ...prevState.nom,
                        error : "Le nom est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!requirementsError) {
            const groupe = {
                nom : state.nom.value,
                groupeParent:  state.groupeParent ? {id : state.groupeParent.id }: null
            };
            props.ajouterGroupe(groupe);
        }

    };

    /*** Form ***/





    const inputClasses = ["my-2"];


    useEffect(() => {
        props.fetchListeGroupes();
        return () => {
            props.ajouterGroupeReset();
        }
    },[]);

    useEffect(() => {
        setState(initialState);
        props.fetchListeGroupes();
    },[props.success]);

    /*useEffect(() => {
        props.fetchGroupes();
    },[props.groupe]);*/

    return(

        <div className={"text-center border rounded p-3"}>

            <h2>Ajouter Groupe</h2>
            {props.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.error}</Alert>
            }
            {props.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Le groupe {props.groupe.nom} ajouté avec succees</Alert>
            }
            <form onSubmit={formOnSubmitHandler}>
                <Grid container spacing={3} className={"my-3"}>
                    <Grid item xs={12}>
                        <TextField error={state.nom.error ? true : false} helperText={state.nom.error} className={inputClasses.join(" ")} onChange={nomOnChangeHandler} value={state.nom.value} fullWidth={true} size={"small"} label="Nom" variant="outlined" />
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel expanded={groupesExpanded} onChange={handleGroupesExpanded}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <h6>Groupe Parent : {!groupesExpanded && state.groupeParent ? <Chip label={state.groupeParent.nom} onDelete={handleGroupeUnSelect} /> : null}</h6>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails >
                                {props.listeGroupes.loading ?
                                    <CircularProgress size={50} thickness={4} />
                                    :
                                    <Grid container spacing={3}>

                                        <Grid item xs={12} md={8} >
                                            <span>Selectionner un groupe</span>
                                            <TreeListeGroupesSingleAdd fetchGroupeListeGroupes={props.fetchGroupeListeGroupes} listeGroupes={props.listeGroupes.listeGroupes} selected={state.groupeParent} handleGroupeUnSelect={handleGroupeUnSelect} handleGroupeSelect={handleGroupeSelect} isGroupeSelected={isGroupeSelected} />
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <span>Groupe Selectionné</span>
                                            <Box>
                                                {state.groupeParent &&
                                                <Chip
                                                    label={state.groupeParent.nom}
                                                    onDelete={handleGroupeUnSelect}
                                                />}
                                            </Box>
                                        </Grid>

                                    </Grid>
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                </Grid>
                <div className={"position-relative"}>
                    <Button disabled={props.loading} type={"submit"} className={inputClasses.join(" ")} color={"primary"} variant={"contained"} endIcon={<PersonAddIcon />}>Ajouter</Button>
                    {props.loading && <CircularProgress  size={24} /*className={spinnerClasses.join(" ")}*/ />}
                </div>
            </form>

        </div>
    );

}

const mapStateToProps = (state) => {
    return {
        ...state.admin.utilisateurs.ajouterGroupe,


        listeGroupes : state.admin.utilisateurs.listeGroupes
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        ajouterGroupe : (groupe) => dispatch(adminUtilisateursAjouterGroupe(groupe)),
        ajouterGroupeReset : () => dispatch(adminUtilisateursAjouterGroupeReset()),
        fetchListeGroupes : () => dispatch(adminUtilisateursFetchListeGroupes()),
        fetchListeUtilisateurs : () => dispatch(adminUtilisateursFetchListeUtilisateurs()),
        fetchGroupeListeGroupes : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeGroupes(groupeId)),
        fetchGroupeListeUtilisateurs : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeUtilisateurs(groupeId)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AjouterGroupe);