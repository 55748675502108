import {put} from "redux-saga/effects";

import axios from "../../../api/axios"

import * as actions from "../../actions";
import * as api from "../../../api";

export function* parametresFetchParametresSaga(action) {


    yield put(actions.parametresFetchParametresStart());

    /*const groupeData = {
        nom : action.payload.nom,
        groupeParent : action.payload.groupeParent ? {
            id : action.payload.groupeParent.id
        } : null
    };
    */
    try {
        const response = yield axios.get(api.parametres);
        yield put(actions.parametresFetchParametresSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.parametresFetchParametresFail(error.response.data.error));
        }else {
            yield put(actions.parametresFetchParametresFail("There's an error!"));
        }
    }
}