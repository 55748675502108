import React, {useEffect} from "react";
import CategorieCoursListSkeleton from "../../../UI/LoadingSkeleton/CategorieCoursListSkeleton";
import HomePageDescriptionSkeleton from "../../../UI/LoadingSkeleton/HomePageDescriptionSkeleton";




import Grid from '@material-ui/core/Grid';

import logoImage from "../../../assets/logo.png";
import onlineVideoImage from "../../../assets/online-learning.png";
import gestures from "../../../assets/gestures.png";
import interfaceImage from "../../../assets/interface.png";
import {Box} from "@material-ui/core";

import cssClasses from "./home.module.css";
import {connect} from "react-redux";
import {guestHomeFetchListeCoursSpecial} from "../../../store/actions/guest/home/listeCoursSpecialsActions";
import CoursCategorieList from "../../../components/CoursCategoriePaginatedList";
import {guestHomeFetchListeCategoriesSpecial} from "../../../store/actions/guest/home/listeCategoriesSpecialsActions";
import OffersCaroussel from "../../../components/OffersCaroussel";
import HomeIntro from "../../../components/HomeIntro";

function Home(props) {

    useEffect(() => {

        if(!props.parametres.loading && props.parametres.parametres && props.parametres.parametres.montrerLesCoursSpeciaux === "true") {

            props.fetchListeCategoriesSpecials();
            props.fetchListeCoursSpecials();

        }
    },[props.parametres.loading,props.parametres.parametres]);


    const topContentClasses = [cssClasses.topContent,"mx-auto","text-center","w-100"];
    const categoriesCoursContainerClasses = ["py-3"];
    const featureImageClasses = [cssClasses.featureImage,"w-75", "mx-auto", "mb-4"];
    const featuresTitleClasses = ["my-5"];

    return(
        <>
            <div>
                <OffersCaroussel/>
            </div>

            <div className={"px-5"}>
            <div>
                <HomeIntro/>
            </div>


            <div className={topContentClasses.join(" ")}>
                <img src={logoImage} />
                {props.parametres.loading &&
                <HomePageDescriptionSkeleton/>}
                {!props.parametres.loading && !props.parametres.error &&
                <h5>{props.parametres.parametres.websiteDescription}</h5>
                }
            </div>
            <div className={"text-center my-5"}>
                <h2 className={featuresTitleClasses.join(" ")}>Pourquoi utiliser eAcadémie?</h2>
                <Grid container={true} spacing={4}>
                    <Grid item={true} xs={12} sm={4}>
                        <img className={featureImageClasses.join(" ")} src={onlineVideoImage} />
                        <h4>Vidéos en ligne</h4>
                        <p>Des cours plus clairs sous forme de vidéos</p>
                    </Grid>
                    <Grid item={true} xs={12} sm={4}>
                        <img className={featureImageClasses.join(" ")} src={interfaceImage} />
                        <h4>Analytiques</h4>
                        <p>Des outils de statistiques pour bien suivre votre progrès</p>
                    </Grid>
                    <Grid item={true} xs={12} sm={4}>
                        <img className={featureImageClasses.join(" ")} src={gestures} />
                        <h4>Collaboration</h4>
                        <p>Plusieurs outils pour collaborer et communiquer en ligne</p>
                    </Grid>
                </Grid>
            </div>
            {(!props.parametres.loading && props.parametres.parametres && props.parametres.parametres.montrerLesCoursSpeciaux === "true" ) ?
            <><h2>Nos cours spéciaux :</h2>
            {
                (props.listeCoursSpecials.loading || props.listeCategoriesSpecials.loading) &&
            <div className={categoriesCoursContainerClasses.join(" ")}>
                <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                        <CategorieCoursListSkeleton/>
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                        <CategorieCoursListSkeleton/>
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                        <CategorieCoursListSkeleton/>
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={4} lg={3} >
                        <Box display={{ xs: 'none', sm:'block',md:'none',lg: 'block' }}>
                            <CategorieCoursListSkeleton/>
                        </Box>
                    </Grid>
                </Grid>
            </div>}

            {!props.listeCategoriesSpecials.loading && !props.listeCoursSpecials.loading  &&
            <CoursCategorieList categoriesCours={props.listeCategoriesSpecials.listeCategoriesSpecials.concat(props.listeCoursSpecials.listeCoursSpecials)} />
            }
            </>
            :null}

            </div>
        </>
    );

}


const mapStateToProps = (state) => {
    return {
        parametres : {
            loading : state.parametres.loading,
            error : state.parametres.loading,
            parametres : state.parametres.parametres,
        },
        listeCoursSpecials : {
            loading : state.guest.home.listeCoursSpecials.loading,
            error : state.guest.home.listeCoursSpecials.error,
            listeCoursSpecials : state.guest.home.listeCoursSpecials.listeCoursSpecials,
        },
        listeCategoriesSpecials : {
            loading : state.guest.home.listeCategoriesSpecials.loading,
            error : state.guest.home.listeCategoriesSpecials.error,
            listeCategoriesSpecials : state.guest.home.listeCategoriesSpecials.listeCategoriesSpecials,
        }

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchListeCoursSpecials : () => dispatch(guestHomeFetchListeCoursSpecial()),
        fetchListeCategoriesSpecials : () => dispatch(guestHomeFetchListeCategoriesSpecial()),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Home);
