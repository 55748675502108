import * as actionTypes from "../../../../actions/actionTypes";


export const adminUtilisateursFetchListeGroupes = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_FETCH_LISTE_GROUPES,
    }
};

export const adminUtilisateursFetchListeGroupesStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_LISTE_GROUPES_START
    }
};

export const adminUtilisateursFetchListeGroupesSuccess = (listeGroupes) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_LISTE_GROUPES_SUCCESS,
        payload : {
            listeGroupes: listeGroupes
        }
    }
};

export const adminUtilisateursFetchListeGroupesFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_LISTE_GROUPES_FAIL,
        payload : {
            error : error
        }
    }
};







export const adminUtilisateursFetchGroupeListeGroupes = (groupeId) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_FETCH_GROUPE_LISTE_GROUPES,
        payload : {
            groupeId : groupeId
        }
    }
};

export const adminUtilisateursFetchGroupeListeGroupesStart = (groupeId) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_GROUPES_START,
        payload : {
            groupeId : groupeId
        }
    }
};

export const adminUtilisateursFetchGroupeListeGroupesSuccess = (groupeId,listeGroupes) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_GROUPES_SUCCESS,
        payload : {
            groupeId : groupeId,
            listeGroupes: listeGroupes
        }
    }
};

export const adminUtilisateursFetchGroupeListeGroupesFail = (groupeId,error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_GROUPES_FAIL,
        payload : {
            groupeId : groupeId,
            error : error
        }
    }
};






export const adminUtilisateursFetchGroupeListeUtilisateurs = (groupeId) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_FETCH_GROUPE_LISTE_UTILISATEURS,
        payload : {
            groupeId : groupeId
        }
    }
};

export const adminUtilisateursFetchGroupeListeUtilisateursStart = (groupeId) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_UTILISATEURS_START,
        payload : {
            groupeId : groupeId
        }
    }
};

export const adminUtilisateursFetchGroupeListeUtilisateursSuccess = (groupeId,listeUtilisateurs) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_UTILISATEURS_SUCCESS,
        payload : {
            groupeId : groupeId,
            listeUtilisateurs: listeUtilisateurs
        }
    }
};

export const adminUtilisateursFetchGroupeListeUtilisateursFail = (groupeId,error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_UTILISATEURS_FAIL,
        payload : {
            groupeId : groupeId,
            error : error
        }
    }
};






