import React from "react";
import {Switch} from "react-router-dom";
import CustomRoute from "../../../hoc/CustomRoute";

function Utilisateurs(props) {



    return (
        <div className={"p-5"}>
        <Switch>
            {props.routes.map(route => {
                return CustomRoute(route);
            })}
        </Switch>
        </div>
    );

}

export default Utilisateurs;