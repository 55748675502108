import * as actionTypes from "../../../../actions/actionTypes";

export const adminUtilisateursSupprimerGroupe = (groupe,supprimerSousGroupes,supprimerUtilisateurs) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_SUPPRIMER_GROUPE,
        payload : {
            groupe : groupe,
            supprimerSousGroupes : supprimerSousGroupes,
            supprimerUtilisateurs : supprimerUtilisateurs
        }
    }
};

export const adminUtilisateursSupprimerGroupeStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_GROUPE_START
    }
};

export const adminUtilisateursSupprimerGroupeSuccess = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_GROUPE_SUCCESS
    }
};

export const adminUtilisateursSupprimerGroupeFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_GROUPE_FAIL,
        payload : {
            error : error
        }
    }
};


export const adminUtilisateursSupprimerGroupeReset = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_GROUPE_RESET
    }
};
