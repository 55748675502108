import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* enseignantDemandeFichiersAjouterDemandeFichierSaga (action) {

    yield put(actions.enseignantDemandeFichiersAjouterDemandeStart());

    const demandeFichier = action.payload.demandeFichier;
    const fichier = action.payload.fichier;


    const formData = yield new FormData();
    yield formData.append('demandeFichier', new Blob([JSON.stringify(demandeFichier)], {
        type: "application/json"
    }));
    yield formData.append("fichier",fichier);


    try {
        const response = yield axios.post(api.enseignantAjouterDemandeFichier,formData);
        yield put(actions.enseignantDemandeFichiersAjouterDemandeSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.enseignantDemandeFichiersAjouterDemandeFail(error.response.data.error));
        }else {
            yield put(actions.enseignantDemandeFichiersAjouterDemandeFail("There's an error!"));
        }
    }
}

