import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

function SupprimerUtilisateurDialog(props) {

    return (<Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title" className={"text-center"}>Voulez vous vraiment supprimer l'utilisateur ?</DialogTitle>
        <DialogActions className={"justify-content-center"}>
            <Button onClick={props.onClose} variant={"contained"} color={"default"}>
                Annuler
            </Button>
            <Button onClick={props.supprimer} variant={"contained"} color={"secondary"}>
                Supprimer
            </Button>
        </DialogActions>
    </Dialog>);

}

export default SupprimerUtilisateurDialog;