import React from "react";


import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

function TelechargerFichier(props) {

    const fichier = props.fichier;

    return (
        <div className={"text-center"}>
            <a href={fichier.lien} download target={"_blank"}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<CloudDownloadIcon />}
                >
                    Télécharger Maintenant
                </Button>
            </a>
        </div>
    );

}

export default TelechargerFichier;