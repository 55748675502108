import {put} from "redux-saga/effects";

import axios from "../../../../../../api/axios"

import * as actions from "../../../../../actions";
import * as api from "../../../../../../api";
import {generateDynamicRoute} from "../../../../../../utils/utils";

export function* guestExplorerCoursCoursFetchListeRessourcesSaga(action) {


    yield put(actions.guestExplorerCoursCoursFetchListeRessourcesStart());
    const coursId = action.payload.coursId;

    try {
        const response = yield axios.get(generateDynamicRoute(api.getListeSections,[{toReplace:":coursId",value:coursId}]));
        const sections = response.data;

        yield put(actions.guestExplorerCoursCoursFetchListeRessourcesSuccess(sections));
    } catch (error) {
        if(error.response) {
            yield put(actions.guestExplorerCoursCoursFetchListeRessourcesFail(error.response.data.error));
        }else {
            yield put(actions.guestExplorerCoursCoursFetchListeRessourcesFail("There's an error!"));
        }
    }
}