import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : true,
    error: null,
    paiement: null
};

const utilisateurCommandePayerCommandeStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        paiement: null
    });
};

const utilisateurCommandePayerCommandeSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        paiement: action.payload.paiement
    });
};

const utilisateurCommandePayerCommandeFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        paiement: null
    });
};

const utilisateurCommandePayerCommandeReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.UTILISATEUR_COMMANDE_PAYER_COMMANDE_START : return utilisateurCommandePayerCommandeStart(state,action);
        case actionTypes.UTILISATEUR_COMMANDE_PAYER_COMMANDE_SUCCESS : return utilisateurCommandePayerCommandeSuccess(state,action);
        case actionTypes.UTILISATEUR_COMMANDE_PAYER_COMMANDE_FAIL : return utilisateurCommandePayerCommandeFail(state,action);
        case actionTypes.UTILISATEUR_COMMANDE_PAYER_COMMANDE_RESET : return utilisateurCommandePayerCommandeReset(state,action);
        default : return state;
    }

}

export default reducer;