import * as actionTypes from "../../../actionTypes";

export const guestExplorerCoursFetchCategorie = (categorieId) => {
    return {
        type : actionTypes.GUEST_EXPLORER_COURS_INITIATE_FETCH_CATEGORIE,
        payload : {
            categorieId : categorieId
        }
    }
};

export const guestExplorerCoursFetchCategorieStart = () => {
    return {
        type : actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_START
    }
};

export const guestExplorerCoursFetchCategorieSuccess = (categorie) => {
    return {
        type : actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_SUCCESS,
        payload : {
            categorie : categorie
        }
    }
};

export const guestExplorerCoursFetchCategorieFail = (error) => {
    return {
        type : actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_FAIL,
        payload : {
            error: error
        }
    }
};

export const guestExplorerCoursFetchCategorieReset = () => {
    return {
        type : actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_RESET
    }
};
