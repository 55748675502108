import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    assignementUtilisateurCours: null
};

const adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        assignementUtilisateurCours: null
    });
};

const adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        assignementUtilisateurCours: action.payload.assignementUtilisateurCours
    });
};

const adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        assignementUtilisateurCours: null
    });
};


const adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};


function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_START : return adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursStart(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_SUCCESS : return adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursSuccess(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_FAIL : return adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursFail(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_RESET : return adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursReset(state,action);
        default : return state;
    }

}

export default reducer;