import * as actionTypes from "../../../actionTypes";

export const enseignantDemandeFichiersAjouterDemandeFichier = (demandeFichier,fichier) => {
    return {
        type : actionTypes.ENSEIGNANT_DEMANDE_FICHIERS_INITIATE_AJOUTER_DEMANDE_FICHIER,
        payload : {
            demandeFichier : demandeFichier,
            fichier : fichier
        }
    }
};

export const enseignantDemandeFichiersAjouterDemandeStart = () => {
    return {
        type : actionTypes.ENSEIGNANT_DEMANDE_FICHIERS_AJOUTER_DEMANDE_FICHIER_START
    }
};

export const enseignantDemandeFichiersAjouterDemandeSuccess = (demandeFichier) => {
    return {
        type : actionTypes.ENSEIGNANT_DEMANDE_FICHIERS_AJOUTER_DEMANDE_FICHIER_SUCCESS,
        payload : {
            demandeFichier : demandeFichier
        }
    }
};

export const enseignantDemandeFichiersAjouterDemandeFail = (error) => {
    return {
        type : actionTypes.ENSEIGNANT_DEMANDE_FICHIERS_AJOUTER_DEMANDE_FICHIER_FAIL,
        payload : {
            error: error
        }
    }
};

export const enseignantDemandeFichiersAjouterDemandeReset = () => {
    return {
        type : actionTypes.ENSEIGNANT_DEMANDE_FICHIERS_AJOUTER_DEMANDE_FICHIER_RESET
    }
};
