import React from "react";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import EnseignantCoursListItem from "../EnseignantCoursListItem";

function EnseignantCoursList(props) {

    const listeCours = props.listeCours;

    const listItems = listeCours.map((item,index) => {
        return (
            <>
                {(index !== 0) && <Divider component="li" />}
                <EnseignantCoursListItem item={item} />
            </>);
    })


    return (
        <List className={"border rounded "}>
            {listItems}
        </List>
    )



}

export default EnseignantCoursList;