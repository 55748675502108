import React from "react";
import Grid from "@material-ui/core/Grid";
import DoneIcon from '@material-ui/icons/Done';

import classes from "./homeintro.module.css";


function HomeIntro(props) {

    return ( <Grid container={true} spacing={2} className={"my-5"}>

            <Grid item={true} md={6}>

                <Grid container={true} spacing={2} direction="column">
                    <Grid item={true}>
                        <div className={classes.box}>
                            <h3 style={{color: "#3f51b5"}} className={"mb-3"}>Tout au long de l’année</h3>
                            <ul>
                                <li><DoneIcon style={{color: "green"}}/><span className={"service"}>Des vidéos</span> de
                                    cours et de méthodologie
                                </li>
                                <li><DoneIcon style={{color: "green"}}/><span className={"service"}>Des fiches</span> de
                                    synthèse à télécharger
                                </li>
                                <li><DoneIcon style={{color: "green"}}/><span className={"service"}>Des QCM</span> pour
                                    valider les acquis
                                </li>
                                <li><DoneIcon style={{color: "green"}}/><span
                                    className={"service"}>Des exercices</span> comme en classe
                                </li>
                                <li><DoneIcon style={{color:"green"}}/><span className={"service"}>Des astuces</span> pour tout comprendre</li>
                                <li><DoneIcon style={{color:"green"}}/><span className={"service"}>Des corrigés</span> en texte et en vidéo</li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item={true}>
                        <div className={classes.box}>
                            <h3 style={{color: "#3f51b5"}} className={"mb-3"}>Pendant les vacances</h3>
                            <ul>
                                <li><DoneIcon style={{color: "green"}}/><span className={"service"}>Des stages</span> en
                                    ligne pendant les vacances
                                </li>
                                <li><DoneIcon style={{color: "green"}}/><span
                                    className={"service"}>Des révisions</span> durant l’été
                                </li>
                            </ul>
                        </div>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item={true} sm={6}>

                <Grid container={true} spacing={2} direction="column">

                    <Grid item={true}>
                        <div className={classes.box}>
                            <h3 style={{color: "#3f51b5"}} className={"mb-3"}>Avant les examens</h3>
                            <ul>
                                <li><DoneIcon style={{color: "green"}}/><span
                                    className={"service"}>Des annales</span> Bac et Brevet corrigées
                                </li>
                                <li><DoneIcon style={{color: "green"}}/><span
                                    className={"service"}>Des exercices</span> de préparation aux épreuves communes de
                                    contrôle continu
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item={true}>
                        <div className={classes.box}>
                            <h3 style={{color : "#3f51b5"}} className={"mb-3"}>Et pour les parents</h3>
                            <ul>
                                <li><DoneIcon style={{color:"green"}}/><span  className={"service"}>Un espace dédié</span> pour suivre votre enfant</li>
                                <li><DoneIcon style={{color:"green"}}/><span  className={"service"}>Des conseils pratiques</span> sur sa scolarité</li>
                            </ul>
                        </div>
                    </Grid>

                </Grid>
            </Grid>



        </Grid>
        );

}

export default HomeIntro;