import React, {useEffect, useState} from "react";
import SideNav from "../../containers/Guest/SideNav";
import {Route, Switch} from 'react-router-dom'
import routes from "../../routes";
import MainContent from "../../containers/Enseignant/MainContent";
import cssClasses from "./layout.module.css"
import SideNavEnseignant from "../../containers/Enseignant/SideNavEnseignant";

function LayoutEnseignant(props) {

    return(
        <>
            <div className={cssClasses.layout}>
                <SideNavEnseignant/>
                <MainContent/>
            </div>
        </>
    );

}

export default LayoutEnseignant;

