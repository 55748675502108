import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    listeUtilisateurs: []
};

const adminUtilisateursFetchListeUtilisateursStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        listeUtilisateurs: []
    });
};

const adminUtilisateursFetchListeUtilisateursSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        listeUtilisateurs: action.payload.listeUtilisateurs
    });
};

const adminUtilisateursFetchListeUtilisateursFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        listeUtilisateurs: []
    });
};

function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_UTILISATEURS_FETCH_LISTE_UTILISATEURS_START : return adminUtilisateursFetchListeUtilisateursStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_FETCH_LISTE_UTILISATEURS_SUCCESS : return adminUtilisateursFetchListeUtilisateursSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_FETCH_LISTE_UTILISATEURS_FAIL : return adminUtilisateursFetchListeUtilisateursFail(state,action);

        default : return state;
    }

}

export default reducer;