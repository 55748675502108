import * as actionTypes from "../actionTypes";

export const parametresFetchParametresStart = () => {
    return {
        type: actionTypes.PARAMETRES_FETCH_PARAMETRES_START
    }
};

export const parametresFetchParametresSuccess = (parametres) => {
    return {
        type: actionTypes.PARAMETRES_FETCH_PARAMETRES_SUCCESS,
        payload : {
            parametres: parametres
        }
    }
};

export const parametresFetchParametresFail = (error) => {
    return {
        type: actionTypes.PARAMETRES_FETCH_PARAMETRES_FAIL,
        payload : {
            error : error
        }
    }
};

export const parametresFetchParametres = () => {
    return {
        type: actionTypes.PARAMETRES_INITIATE_FETCH_PARAMETRES,
    }
};