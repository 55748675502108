import React from "react";

import Skeleton from '@material-ui/lab/Skeleton';


function HomePageDescriptionSkeleton() {

    return (
        <div>
            <Skeleton />
            <Skeleton />
        </div>

    );
}

export default HomePageDescriptionSkeleton;