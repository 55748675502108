import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* utilisateurCommandePayerCommandeSaga (action) {

    yield put(actions.utilisateurCommandePayerCommandeStart());

    const paiement = yield action.payload.paiement;
    const commandeId = yield action.payload.commandeId;

    try {
        const response = yield axios.put(generateDynamicRoute(api.utilisateurCommandePayerCommande,[{toReplace : ":commandeId",value : commandeId}]),paiement);
        yield put(actions.utilisateurCommandePayerCommandeSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.utilisateurCommandePayerCommandeFail(error.response.data.error));
        }else {
            yield put(actions.utilisateurCommandePayerCommandeFail("There's an error!"));
        }
    }
}

