import React, { useEffect } from "react";

import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import miniLogoWhite from "../../../assets/miniLogo.png";

import PersonAddIcon from "@material-ui/icons/PersonAdd";

import cssClasses from "./topheader.module.css";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import {
  homePage,
  lmdPage,
  prepaPage,
  guestExploreOffersPage,
} from "../../../routes/guestRoutes";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

function TopHeader(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar
          className={"d-flex justify-content-between flex-sm-row bg-white"}
        >
          <div className={cssClasses.topHeader}>
            <Link to={homePage.path}>
              <img
                style={{ height: "50px" }}
                className={"mr-5"}
                src={miniLogoWhite}
              />
            </Link>
            <Link to={prepaPage.path}>
              <Typography variant="h5" className={cssClasses.appbarItems}>
                Préparatoire
              </Typography>
            </Link>

            <Link to={lmdPage.path}>
              <Typography variant="h5" className={cssClasses.appbarItems}>
                LMD
              </Typography>
            </Link>
            <Link to={guestExploreOffersPage.path}>
              <Typography variant="h5" className={cssClasses.appbarItems}>
                Nos Offres
              </Typography>
            </Link>
          </div>
          <div className={cssClasses.menu}>
            <IconButton onClick={handleMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              keepMounted
              transformOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={open}
              onClose={handleClose}
              style={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleClose}>
                <Link to={prepaPage.path}>
                  <Typography variant="h5" className={cssClasses.menuItems}>
                    Préparatoire
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link to={prepaPage.path}>
                  <Typography variant="h5" className={cssClasses.menuItems}>
                    Préparatoire Intégrée
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link to={lmdPage.path}>
                  <Typography variant="h5" className={cssClasses.menuItems}>
                    LMD
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link to={guestExploreOffersPage.path}>
                  <Typography variant="h5" className={cssClasses.menuItems}>
                    Nos Offres
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link to={"/auth/signin"}>
                  <Typography variant="h5" className={cssClasses.menuItems}>
                    Se connecter
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link to={"/auth/signup"}>
                  <Typography
                    variant="h5"
                    style={{
                      color: "#3f51b5",
                      marginLeft: "1rem",
                      marginRight: "1rem ",
                      padding: "1rem",
                      height: "100%",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Créer un compte
                  </Typography>
                </Link>
              </MenuItem>
            </Menu>
          </div>

          <div className={"d-flex my-2 my-sm-0 " + cssClasses.authButtons}>
            <>
              <div className={"ml-sm-3"}>
                <NavLink to={"/auth/signin"}>
                  <Button
                    className={cssClasses.authSingleBtn}
                    variant={"contained"}
                    endIcon={
                      <Icon
                        className={"fa fa-unlock-alt " + cssClasses.buttonIcon}
                      />
                    }
                  >
                    Se Connecter
                  </Button>
                </NavLink>
              </div>

              <div className={"ml-3"}>
                <NavLink to={"/auth/signup"}>
                  <Button
                    className={cssClasses.newAccountButton}
                    variant={"contained"}
                    endIcon={
                      <PersonAddIcon className={cssClasses.buttonIcon} />
                    }
                  >
                    Créer un compte
                  </Button>
                </NavLink>
              </div>
            </>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authSignIn.token != null,
    user: state.authSignIn.user,
    token: state.authSignIn.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSideNavOpen: () => dispatch(actions.openSideNav()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
