import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    parametres: null
};

const adminParametresModifierParametresStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        parametres: null
    });
};

const adminParametresModifierParametresSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        parametres: action.payload.parametres
    });
};

const adminParametresModifierParametresFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        parametres: null
    });
};

const adminParametresModifierParametresReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};







function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_PARAMETRES_MODIFIER_PARAMETRES_START : return adminParametresModifierParametresStart(state,action);
        case actionTypes.ADMIN_PARAMETRES_MODIFIER_PARAMETRES_SUCCESS : return adminParametresModifierParametresSuccess(state,action);
        case actionTypes.ADMIN_PARAMETRES_MODIFIER_PARAMETRES_FAIL: return adminParametresModifierParametresFail(state,action);
        case actionTypes.ADMIN_PARAMETRES_MODIFIER_PARAMETRES_RESET : return adminParametresModifierParametresReset(state,action);

        default : return state;
    }

}

export default reducer;