import {combineReducers} from "redux";

import listeCoursReducer from "./listeCours";
import listeCategoriesReducer from "./listeCategories";

import categorieReducer from "./categorie";
import coursReducer from "./cours";


export default combineReducers({
    listeCours : listeCoursReducer,
    listeCategories : listeCategoriesReducer,
    categorie : categorieReducer,
    cours : coursReducer
});