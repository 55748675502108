import * as actionTypes from "../../../../actions/actionTypes";

export const adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCours = (assignementUtilisateurCours) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_INITIATE_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS,
        payload : {
            assignementUtilisateurCours : assignementUtilisateurCours
        }
    }
};

export const adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursStart = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_START
    }
};

export const adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursReset = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_RESET
    }
};

export const adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursSuccess = (assignementUtilisateurCours) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_SUCCESS,
        payload : {
            assignementUtilisateurCours: assignementUtilisateurCours
        }
    }
};

export const adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursFail = (error) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_FAIL,
        payload : {
            error : error
        }
    }
};
