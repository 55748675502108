import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* utilisateurCommandeFetchCommandeSaga (action) {

    yield put(actions.utilisateurCommandeFetchCommandeStart());

    const commandeId = yield action.payload.commandeId;

    try {
        const response = yield axios.get(generateDynamicRoute(api.utilisateurCommandeFetchCommande,[{toReplace : ":commandeId",value : commandeId}]));
        yield put(actions.utilisateurCommandeFetchCommandeSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.utilisateurCommandeFetchCommandeFail(error.response.data.error));
        }else {
            yield put(actions.utilisateurCommandeFetchCommandeFail("There's an error!"));
        }
    }
}

