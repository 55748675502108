import MyCourses from "../containers/Student/MyCourses";
import CoursPage from "../containers/Student/MyCourses/Course";

import ListeCours from "../containers/Student/MyCourses/CoursesList";
import RessourceModal from "../components/RessourceModal";
import Profil from "../containers/Student/Profil";
import Commandes from "../containers/Student/Orders";
import ListeCommandes from "../containers/Student/Orders/OrdersList";
import Commande from "../containers/Student/Orders/Order";
import Offers from "../containers/Student/Offers";
import ShoppingCart from "../containers/Student/ShoppingCart";

export const studentCartPage = {
  type: "route",
  path: "/student/cart",
  component: ShoppingCart,
  icon: "fa fa-id-card",
  notInSideNav: true,
  requireAuthentication: true,
};

export const CoursesListPage = {
  type: "route",
  path: "/student/mycourses",
  label: "Mes Cours",
  component: ListeCours,
  icon: "fa fa-home",
  requireAuthentication: true,
  requiredRole: "ROLE_APPRENANT",
};

export const CoursePage = {
  type: "route",
  path: "/student/myCourses/course/:coursId",
  label: "Mes Cours",
  component: CoursPage,
  icon: "fa fa-home",
  requiredRole: "ROLE_APPRENANT",
  requireAuthentication: true,
};

export const ressourcePage = {
  type: "route",
  path: "/student/myCourses/course/:coursId/section/:sectionId/ressource/:ressourceId",
  label: "Mes Cours",
  component: RessourceModal,
  icon: "fa fa-home",
  requiredRole: "ROLE_APPRENANT",
  requireAuthentication: true,
};

export const StudentMyCoursesPage = {
  type: "route",
  path: "/student/myCourses/",
  label: "Mes Cours",
  component: MyCourses,
  icon: "fa fa-book",
  requireAuthentication: true,
  requiredRole: "ROLE_APPRENANT",
  routes: [CoursePage, CoursesListPage],
};

export const OffersPage = {
  type: "route",
  path: "/student/offers/",
  label: "Nos Offres",
  component: Offers,
  icon: "fas fa-shop",
  requireAuthentication: true,
};

export const profilePage = {
  type: "route",
  path: "/student/profile",
  label: "Profil",
  component: Profil,
  icon: "fa fa-user",
  requiredRole: "ROLE_APPRENANT",
  requireAuthentication: true,
};

export const StudentOrderPage = {
  type: "route",
  path: "/student/orders/:commandeId",
  component: Commande,
  notInSideNav: true,
  requiredRole: "ROLE_APPRENANT",
  requireAuthentication: true,
};

export const EtudiantCommandesPage = {
  type: "route",
  path: "/student/orders",
  label: "Mes Commandes",
  icon: "fa fa-shopping-cart",
  component: Commandes,
  requireAuthentication: true,
  requiredRole: "ROLE_APPRENANT",
  routes: [
    StudentOrderPage,
    {
      type: "route",
      path: "/student/orders",
      component: ListeCommandes,
      requireAuthentication: true,
      requiredRole: "ROLE_APPRENANT",
    },
  ],
};

const routes = [
  StudentMyCoursesPage,
  OffersPage,
  EtudiantCommandesPage,
  profilePage,
  studentCartPage,
];

export default routes;
