import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {

    loading : true,
    error : null,
    tagsList: []

};

const technicianTagsFetchTagsListStart = (state,action) => {
    return updateObject(state,{
        loading:true,
        error:null,
        tagsList: []
    });
};

const technicianTagsFetchTagsListSuccess = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:null,
        tagsList: action.payload.tagsList
    });
};

const technicianTagsFetchTagsListFail = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:action.payload.error,
        tagsList: []
    });
};

const technicianTagsFetchTagsListReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.TECHNICIAN_TAGS_FETCH_TAGS_LIST_START : return technicianTagsFetchTagsListStart(state,action);
        case actionTypes.TECHNICIAN_TAGS_FETCH_TAGS_LIST_SUCCESS : return technicianTagsFetchTagsListSuccess(state,action);
        case actionTypes.TECHNICIAN_TAGS_FETCH_TAGS_LIST_FAIL : return technicianTagsFetchTagsListFail(state,action);
        case actionTypes.TECHNICIAN_TAGS_FETCH_TAGS_LIST_RESET : return technicianTagsFetchTagsListReset(state,action);
        default : return state;
    }
};

export default reducer;