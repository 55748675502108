import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    utilisateur: null
};

const adminUtilisateursFetchUtilisateurStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        utilisateur: null
    });
};

const adminUtilisateursFetchUtilisateurSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        utilisateur: action.payload.utilisateur
    });
};

const adminUtilisateursFetchUtilisateurFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        utilisateur: null
    });
};


const adminUtilisateursFetchUtilisateurReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_UTILISATEURS_FETCH_UTILISATEUR_START : return adminUtilisateursFetchUtilisateurStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_FETCH_UTILISATEUR_SUCCESS : return adminUtilisateursFetchUtilisateurSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_FETCH_UTILISATEUR_FAIL : return adminUtilisateursFetchUtilisateurFail(state,action);
        case actionTypes.ADMIN_UTILISATEURS_FETCH_UTILISATEUR_RESET : return adminUtilisateursFetchUtilisateurReset(state,action);
        default : return state;
    }

}

export default reducer;