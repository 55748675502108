import * as actionTypes from "../../../../actions/actionTypes";
import {findGroupe, findGroupes, updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    listeGroupes: []
};

const adminUtilisateursFetchListeGroupesStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        listeGroupes: []
    });
};

const adminUtilisateursFetchListeGroupesSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        listeGroupes: action.payload.listeGroupes
    });
};

const adminUtilisateursFetchListeGroupesFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        listeGroupes: []
    });
};




const adminUtilisateursFetchGroupeListeGroupesStart =(state,action) => {

    const groupeId = action.payload.groupeId;
    
    let newListeGroupes = [...state.listeGroupes];

    const selectedGroupe = findGroupe(newListeGroupes,groupeId);


    //const selectedGroupes = findGroupes(newListeGroupes,groupeId);


    if(selectedGroupe) {

        selectedGroupe.content = {
                    ...selectedGroupe.content,
                    listeGroupes : {
                    loading : true,
                        error : null,
                        listeGroupes : []
                }
        };

    }

    return updateObject(state, {
        ...state,
        listeGroupes : newListeGroupes
    })

};


const adminUtilisateursFetchGroupeListeGroupesSuccess =(state,action) => {


    const groupeId = action.payload.groupeId;

    let newListeGroupes = [...state.listeGroupes];

  const groupeListeGroupes = action.payload.listeGroupes;

/*
    groupeListeGroupes.forEach(groupe => {

        const foundGroupes = findGroupes(newListeGroupes,groupe.id);
        let loadedGroupe = null;
        foundGroupes.forEach(groupe => {
            if(!groupe.content.listeGroupes.loading && !groupe.content.listeUtilisateurs.loading)
                loadedGroupe = groupe;
        });
        if(loadedGroupe)
            groupe.content = {...loadedGroupe.content}

    });*/


    //const selectedGroupes = findGroupes(newListeGroupes,groupeId);


    const selectedGroupe = findGroupe(newListeGroupes,groupeId);

    if(selectedGroupe) {

        selectedGroupe.content = {
            ...selectedGroupe.content,
            listeGroupes : {
                loading : false,
                error : null,
                listeGroupes : groupeListeGroupes
            }
        };

    }

    return updateObject(state, {
        ...state,
        listeGroupes : newListeGroupes
    });
};

const adminUtilisateursFetchGroupeListeGroupesFail =(state,action) => {

    const groupeId = action.payload.groupeId;


    let newListeGroupes = [...state.listeGroupes];

    const selectedGroupe = findGroupe(newListeGroupes,groupeId);


    if(selectedGroupe) {

        selectedGroupe.content = {
                ...selectedGroupe.content,
                listeGroupes : {
                    loading : false,
                    error : action.payload.error,
                    listeGroupes : []
                }
            };

    }

    return updateObject(state, {
        ...state,
        listeGroupes : newListeGroupes
    });
};






const adminUtilisateursFetchGroupeListeUtilisateursStart =(state,action) => {

    const groupeId = action.payload.groupeId;

    let newListeGroupes = [...state.listeGroupes];

    const selectedGroupe = findGroupe(newListeGroupes,groupeId);

    if(selectedGroupe) {

        selectedGroupe.content= {
                ...selectedGroupe.content,
                listeUtilisateurs : {
                    loading : true,
                    error : null,
                    listeUtilisateurs : []
                }
            };

    }

    return updateObject(state, {
        ...state,
        listeGroupes : newListeGroupes
    })

};


const adminUtilisateursFetchGroupeListeUtilisateursSuccess =(state,action) => {

    const groupeId = action.payload.groupeId;

    let newListeGroupes = [...state.listeGroupes];

    const selectedGroupe = findGroupe(newListeGroupes,groupeId);

    if(selectedGroupe) {

        selectedGroupe.content = {
                ...selectedGroupe.content,
                listeUtilisateurs : {
                    loading : false,
                    error : null,
                    listeUtilisateurs : action.payload.listeUtilisateurs
                }
            };

    }

    return updateObject(state, {
        ...state,
        listeGroupes : newListeGroupes
    });
};

const adminUtilisateursFetchGroupeListeUtilisateursFail =(state,action) => {

    const groupeId = action.payload.groupeId;




    let newListeGroupes = [...state.listeGroupes];


    const selectedGroupe = findGroupe(newListeGroupes,groupeId);

    if(selectedGroupe) {

        selectedGroupe.content = {
                ...selectedGroupe.content,
                listeUtilisateurs : {
                    loading : false,
                    error : action.payload.error,
                    listeUtilisateurs : []
                }
            };

    }

    return updateObject(state, {
        ...state,
        listeGroupes : newListeGroupes
    });
};








function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_UTILISATEURS_FETCH_LISTE_GROUPES_START : return adminUtilisateursFetchListeGroupesStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_FETCH_LISTE_GROUPES_SUCCESS : return adminUtilisateursFetchListeGroupesSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_FETCH_LISTE_GROUPES_FAIL : return adminUtilisateursFetchListeGroupesFail(state,action);


        case actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_GROUPES_START : return adminUtilisateursFetchGroupeListeGroupesStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_GROUPES_SUCCESS : return adminUtilisateursFetchGroupeListeGroupesSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_GROUPES_FAIL : return adminUtilisateursFetchGroupeListeGroupesFail(state,action);

        case actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_UTILISATEURS_START : return adminUtilisateursFetchGroupeListeUtilisateursStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_UTILISATEURS_SUCCESS : return adminUtilisateursFetchGroupeListeUtilisateursSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_UTILISATEURS_FAIL : return adminUtilisateursFetchGroupeListeUtilisateursFail(state,action);

        default : return state;
    }

}

export default reducer;