import * as actionTypes from "../../../actionTypes";

export const etudiantMesCoursFetchCoursRessources = (coursId) => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_INITIATE_FETCH_COURS_RESSOURCES,
        payload : {
            coursId : coursId
        }
    }
};

export const etudiantMesCoursFetchCoursRessourcesStart = () => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_COURS_RESSOURCES_START
    }
};

export const etudiantMesCoursFetchCoursRessourcesSuccess = (listeRessources) => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_COURS_RESSOURCES_SUCCESS,
        payload : {
            listeRessources : listeRessources
        }
    }
};

export const etudiantMesCoursFetchCoursRessourcesFail = (error) => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_COURS_RESSOURCES_FAIL,
        payload : {
            error: error
        }
    }
};

export const etudiantMesCoursFetchCoursRessourcesReset = () => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_COURS_RESSOURCES_RESET
    }
};