import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* etudiantExplorerOffresFetchListeOffresSaga(action) {


    yield put(actions.etudiantExplorerOffresFetchListeOffresStart());

    try {
        const categorieId =  action.payload.categorieId
        const response = yield axios.get(generateDynamicRoute(api.studentOffersList,[{toReplace: ":categorieId",value:categorieId}]));
        const listeOffres = yield response.data;
        yield put(actions.etudiantExplorerOffresFetchListeOffresSuccess(listeOffres));
    } catch (error) {
        if(error.response) {
            yield put(actions.etudiantExplorerOffresFetchListeOffresFail(error.response.data.error));
        }else {
            yield put(actions.etudiantExplorerOffresFetchListeOffresFail("There's an error!"));
        }
    }
}