import React, {useEffect} from "react";
import {
    etudiantMesCoursFetchListeCours,
    etudiantMesCoursFetchListeCoursReset
} from "../../../../store/actions/etudiant/mesCours";
import {connect} from "react-redux";
import CoursListSkeleton from "../../../../UI/LoadingSkeleton/CoursListSkeleton";
import CoursList from "../../../../components/CoursList";
import Grid from "@material-ui/core/Grid";
import TeacherCourseItem from "../../../../components/TeacherCourseItem";

import Alert from '@material-ui/lab/Alert';
import {Link} from "react-router-dom";
import {shoppingCartPage} from "../../../../routes/utilisateurRoutes";
import Button from "@material-ui/core/Button";
import {OffersPage} from "../../../../routes/studentRoutes";


function CoursesListe(props) {

    useEffect(() => {
        props.fetchListeCours(props.userId);
        return () => {
            props.resetListeCours();
        }
    },[]);

    const coursesList =  props.coursesList.map(course  => {
            return <TeacherCourseItem course={course}/>
        }
    );


    return (
        <>
            <h1 className={"text-center my-3"}>Mes Cours</h1>

            {props.loading && <CoursListSkeleton/>}
            {!props.loading && !props.error &&
                <>

                <Grid container spacing={3} className={"my-5"}>

                    {coursesList}

                </Grid>
                </>
            }

        </>
    );
};


const mapStateToProps = (state) => {
    return {
        loading : state.etudiant.mesCours.listeCours.loading,
        error : state.etudiant.mesCours.listeCours.error,
        coursesList : state.etudiant.mesCours.listeCours.listeCours,
        userId : state.authSignIn.user.id
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchListeCours : (userId) => dispatch(etudiantMesCoursFetchListeCours(userId)),
        resetListeCours : () => dispatch(etudiantMesCoursFetchListeCoursReset())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(CoursesListe);