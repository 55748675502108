import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null
};

const adminCoursSupprimerCategorieStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
    });
};

const adminCoursSupprimerCategorieSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null
    });
};

const adminCoursSupprimerCategorieFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error
    });
};

const adminCoursSupprimerCategorieReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_COURS_SUPPRIMER_CATEGORIE_START : return adminCoursSupprimerCategorieStart(state,action);
        case actionTypes.ADMIN_COURS_SUPPRIMER_CATEGORIE_SUCCESS : return adminCoursSupprimerCategorieSuccess(state,action);
        case actionTypes.ADMIN_COURS_SUPPRIMER_CATEGORIE_FAIL : return adminCoursSupprimerCategorieFail(state,action);
        case actionTypes.ADMIN_COURS_SUPPRIMER_CATEGORIE_RESET : return adminCoursSupprimerCategorieReset(state,action);
        default : return state;
    }

}

export default reducer;