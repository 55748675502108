import * as actionTypes from "../../../../actions/actionTypes";

export const adminCoursAjouterCours = (cours,photo) => {
    return {
        type: actionTypes.ADMIN_COURS_INITIATE_AJOUTER_COURS,
        payload : {
            cours : cours,
            photo : photo
        }
    }
};

export const adminCoursAjouterCoursStart = () => {
    return {
        type: actionTypes.ADMIN_COURS_AJOUTER_COURS_START
    }
};

export const adminCoursAjouterCoursSuccess = (cours) => {
    return {
        type: actionTypes.ADMIN_COURS_AJOUTER_COURS_SUCCESS,
        payload : {
            cours: cours
        }
    }
};

export const adminCoursAjouterCoursFail = (error) => {
    return {
        type: actionTypes.ADMIN_COURS_AJOUTER_COURS_FAIL,
        payload : {
            error : error
        }
    }
};
