import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";
import {combineReducers} from "redux";

import listeRessourcesReducer from "./listeRessources";

const initialState = {
    loading : true,
    error: null,
    cours: []
};

const guestExplorerCoursFetchCoursStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        cours: null
    });
};

const guestExplorerCoursFetchCoursSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        cours: action.payload.cours
    });
};

const guestExplorerCoursFetchCoursFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error ?  action.payload.error  : "Error",
        cours: null
    });
};

function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_COURS_START : return guestExplorerCoursFetchCoursStart(state,action);
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_COURS_SUCCESS : return guestExplorerCoursFetchCoursSuccess(state,action);
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_COURS_FAIL : return guestExplorerCoursFetchCoursFail(state,action);

        default : return state;
    }

}



export default combineReducers({
    main : reducer,
    listeRessources : listeRessourcesReducer
});
