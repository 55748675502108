import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null
};

const adminCoursSupprimerCoursStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
    });
};

const adminCoursSupprimerCoursSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null
    });
};

const adminCoursSupprimerCoursFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error
    });
};

const adminCoursSupprimerCoursReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_COURS_SUPPRIMER_COURS_START : return adminCoursSupprimerCoursStart(state,action);
        case actionTypes.ADMIN_COURS_SUPPRIMER_COURS_SUCCESS : return adminCoursSupprimerCoursSuccess(state,action);
        case actionTypes.ADMIN_COURS_SUPPRIMER_COURS_FAIL : return adminCoursSupprimerCoursFail(state,action);
        case actionTypes.ADMIN_COURS_SUPPRIMER_COURS_RESET : return adminCoursSupprimerCoursReset(state,action);
        default : return state;
    }

}

export default reducer;