import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminParametresModifierParametresSaga (action) {

    yield put(actions.adminParametresModifierParametresStart());


    const parametres = action.payload.parametres;

    try {
        const response = yield axios.put(api.adminModifierParametres,parametres);
        yield put(actions.adminParametresModifierParametresSuccess(response.data));

        yield put(actions.parametresFetchParametresSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminParametresModifierParametresFail(error.response.data));
        }else {
            yield put(actions.adminParametresModifierParametresFail("There's an error!"));
        }
    }
}
