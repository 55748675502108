import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    order: null
};

const utilisateurCommandeCreerCommandeStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        order: null
    });
};

const utilisateurCommandeCreerCommandeSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        order: action.payload.order
    });
};

const utilisateurCommandeCreerCommandeFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        order: null
    });
};

const utilisateurCommandeCreerCommandeReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.UTILISATEUR_COMMANDE_CREER_COMMANDE_START : return utilisateurCommandeCreerCommandeStart(state,action);
        case actionTypes.UTILISATEUR_COMMANDE_CREER_COMMANDE_SUCCESS : return utilisateurCommandeCreerCommandeSuccess(state,action);
        case actionTypes.UTILISATEUR_COMMANDE_CREER_COMMANDE_FAIL : return utilisateurCommandeCreerCommandeFail(state,action);
        case actionTypes.UTILISATEUR_COMMANDE_CREER_COMMANDE_RESET : return utilisateurCommandeCreerCommandeReset(state,action);
        default : return state;
    }

}

export default reducer;