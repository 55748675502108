import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* utilisateurCommandeCreerCommandeSaga (action) {

    yield put(actions.utilisateurCommandeCreerCommandeStart());

    const orderArticles = yield action.payload.orderArticles;

    try {
        const response = yield axios.post(api.studentCreateOrder,orderArticles);
        yield put(actions.utilisateurCommandeCreerCommandeSuccess(response.data));
        yield put(actions.utilisateurCartInitiateResetCart());
    } catch (error) {
        if(error.response) {
            yield put(actions.utilisateurCommandeCreerCommandeFail(error.response.data.error));
        }else {
            yield put(actions.utilisateurCommandeCreerCommandeFail("There's an error!"));
        }
    }
}

