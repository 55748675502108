

/**** CREER COMMANDE *****/

export const ETUDIANT_COMMANDE_INITIATE_CREER_COMMANDE = 'ETUDIANT_COMMANDE_INITIATE_CREER_COMMANDE';
export const ETUDIANT_COMMANDE_CREER_COMMANDE_START = 'ETUDIANT_COMMANDE_CREER_COMMANDE_START';
export const ETUDIANT_COMMANDE_CREER_COMMANDE_SUCCESS = 'ETUDIANT_COMMANDE_CREER_COMMANDE_SUCCESS';
export const ETUDIANT_COMMANDE_CREER_COMMANDE_FAIL = 'ETUDIANT_COMMANDE_CREER_COMMANDE_FAIL';
export const ETUDIANT_COMMANDE_CREER_COMMANDE_RESET = 'ETUDIANT_COMMANDE_CREER_COMMANDE_RESET';


/**** FETCH COMMANDE *****/

export const ETUDIANT_COMMANDE_INITIATE_FETCH_COMMANDE = 'ETUDIANT_COMMANDE_INITIATE_FETCH_COMMANDE';
export const ETUDIANT_COMMANDE_FETCH_COMMANDE_START = 'ETUDIANT_COMMANDE_FETCH_COMMANDE_START';
export const ETUDIANT_COMMANDE_FETCH_COMMANDE_SUCCESS = 'ETUDIANT_COMMANDE_FETCH_COMMANDE_SUCCESS';
export const ETUDIANT_COMMANDE_FETCH_COMMANDE_FAIL = 'ETUDIANT_COMMANDE_FETCH_COMMANDE_FAIL';
export const ETUDIANT_COMMANDE_FETCH_COMMANDE_RESET = 'ETUDIANT_COMMANDE_FETCH_COMMANDE_RESET';


/**** PAYER COMMANDE *****/

export const ETUDIANT_COMMANDE_INITIATE_PAYER_COMMANDE = 'ETUDIANT_COMMANDE_INITIATE_PAYER_COMMANDE';
export const ETUDIANT_COMMANDE_PAYER_COMMANDE_START = 'ETUDIANT_COMMANDE_PAYER_COMMANDE_START';
export const ETUDIANT_COMMANDE_PAYER_COMMANDE_SUCCESS = 'ETUDIANT_COMMANDE_PAYER_COMMANDE_SUCCESS';
export const ETUDIANT_COMMANDE_PAYER_COMMANDE_FAIL = 'ETUDIANT_COMMANDE_PAYER_COMMANDE_FAIL';
export const ETUDIANT_COMMANDE_PAYER_COMMANDE_RESET = 'ETUDIANT_COMMANDE_PAYER_COMMANDE_RESET';
