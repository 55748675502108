import React from "react";
import CustomRoute from "../../../hoc/CustomRoute";
import {Switch} from "react-router-dom";

function MesCours(props) {

    return (
        <div className={"p-5"}>
        <Switch>
            {props.routes.map((route, index) => {
                return CustomRoute(route,index);
            })}
        </Switch>
        </div>
    );

}

export default MesCours;