import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";

import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* guestExplorerCoursFetchCoursSaga(action) {


    yield put(actions.guestExplorerCoursFetchCoursStart());

    const coursId = action.payload.coursId;

    try {
        const response = yield axios.get(generateDynamicRoute(api.getCourse,[{toReplace: ":coursId",value: coursId}]));
        yield put(actions.guestExplorerCoursFetchCoursSuccess(response.data));
    } catch (error) {
        if(error.response) {

            if(error.response.status === 404)
                yield put(actions.errorsThrowError404());
            yield put(actions.guestExplorerCoursFetchCoursFail(error.response.data.error));
        }else {
            yield put(actions.guestExplorerCoursFetchCoursFail("There's an error!"));
        }
    }
}

export * from "./listeRessources";