import React from "react";
import CoursListItem from "../../../components/CoursListItem";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import CoursListItemSkeleton from "../CoursListItemSkeleton";


function CoursListSkeleton() {

    return (
        <List className={"border rounded "}>
            <CoursListItemSkeleton />
            <Divider component="li" />
            <CoursListItemSkeleton />
            <Divider component="li" />
            <CoursListItemSkeleton />
            <Divider component="li" />
            <CoursListItemSkeleton />
        </List>

    );
}

export default CoursListSkeleton;