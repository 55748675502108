import * as actionTypes from "../../../../actions/actionTypes2";


export const adminCommandesFetchListeCommandes = () => {
    return {
        type: actionTypes.ADMIN_COMMANDES_INITIATE_FETCH_LISTE_COMMANDES,
    }
};

export const adminCommandesFetchListeCommandesStart = () => {
    return {
        type: actionTypes.ADMIN_COMMANDES_FETCH_LISTE_COMMANDES_START
    }
};

export const adminCommandesFetchListeCommandesSuccess = (listeCommandes) => {
    return {
        type: actionTypes.ADMIN_COMMANDES_FETCH_LISTE_COMMANDES_SUCCESS,
        payload : {
            listeCommandes: listeCommandes
        }
    }
};

export const adminCommandesFetchListeCommandesFail = (error) => {
    return {
        type: actionTypes.ADMIN_COMMANDES_FETCH_LISTE_COMMANDES_FAIL,
        payload : {
            error : error
        }
    }
};


export const adminCommandesFetchListeCommandesReset = () => {
    return {
        type: actionTypes.ADMIN_COMMANDES_FETCH_LISTE_COMMANDES_RESET
    }
};


