import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {

    loading : true,
    error : null,
    listeRessources: []

};

const enseignantMesCoursFetchCoursRessourcesStart = (state,action) => {
    return updateObject(state,{
        loading:true,
        error:null,
        listeRessources: []
    });
};

const enseignantMesCoursFetchCoursRessourcesSuccess = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:null,
        listeRessources: action.payload.listeRessources
    });
};

const enseignantMesCoursFetchCoursRessourcesFail = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:action.payload.error,
        listeRessources: []
    });
};

const enseignantMesCoursFetchCoursRessourcesReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_RESSOURCES_START : return enseignantMesCoursFetchCoursRessourcesStart(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_RESSOURCES_SUCCESS : return enseignantMesCoursFetchCoursRessourcesSuccess(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_RESSOURCES_FAIL : return enseignantMesCoursFetchCoursRessourcesFail(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_RESSOURCES_RESET : return enseignantMesCoursFetchCoursRessourcesReset(state,action);
        default : return state;
    }
};

export default reducer;