import React, {useState} from "react";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";


import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Alert from '@material-ui/lab/Alert';
import {CircularProgress} from "@material-ui/core";

import cssClasses from "./request-new-password.module.css"

import {validations} from "../../../utils/utils";

import logoImage from "../../../assets/fullLogo.png";

const initialState = {
    email: {
        value: "",
        error: ""
    }
};


function RequestNewPasswordPage(props) {

    const [state, setState] = useState(initialState);


    const emailOnChangeHandler = (event) => {
        const value = event.target.value;
        const error = value ? validations.validateEmail(value) ? null : "Email non valid" : null;
        setState(prevState => {
            return {
                ...prevState,
                email: {
                    value: value,
                    error: error
                }
            }
        })
    };


    const formOnSubmitHandler = (event) => {
        event.preventDefault();

        let requirementsError = false;

        const email = state.email.value;

        if (!email) {
            setState(prevState => {
                return {
                    ...prevState,
                    email: {
                        ...prevState.email,
                        error: "L'Email est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if (!requirementsError && !state.email.error && !state.email.error)
            props.requestNewPassword(state.email.value);

    };


    /*** css ***/

    const formClasses = [cssClasses.signInForm, "text-center", "border", "rounded", "p-3", "mx-auto"];
    const inputClasses = ["my-2"];
    const topContentClasses = ["text-center"];

    /*** end css ***/


    return (
        <>

            <div className={topContentClasses.join(" ")}>
                <img height={200} src={logoImage}/>
            </div>

            <form className={formClasses.join(" ")} onSubmit={formOnSubmitHandler}>


                {
                    (props.requestNewPasswordState.success) && (
                        <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Nous vous avons envoyé des
                            instructions à {state.email.value} pour réinitialiser votre mot de passe. Si vous n'avez pas
                            reçu d'e-mail de notre part d'ici quelques minutes, veuillez vérifier votre dossier de
                            spams.</Alert>
                    )
                }
                {
                    (!props.requestNewPasswordState.success) && (
                        <>
                            <h6>Pour réinitialiser votre mot de passe, indiquez l’adresse courriel utilisée lors de la
                                création de votre compte Skrill. Nous vous enverrons un lien pour vous permettre
                                d’accéder à nouveau à votre compte.</h6>

                            <TextField id={"email"} error={!!state.email.error} helperText={state.email.error}
                                       className={inputClasses.join(" ")} onChange={emailOnChangeHandler}
                                       value={state.email.value}
                                       fullWidth={true} size={"small"} label="Email" variant="outlined"
                                       autocomplete="email"/>
                            <div className={"position-relative"}>
                                <Button disabled={props.requestNewPasswordState.loading} type={"submit"}
                                        className={inputClasses.join(" ")}
                                        color={"primary"} variant={"contained"}
                                        endIcon={<Icon className={"fa fa-unlock-alt"}/>}>Réinitialiser votre mot de
                                    passe</Button>
                                {props.requestNewPasswordState.loading &&
                                <CircularProgress size={24} className={"spinner"}/>}
                            </div>
                        </>
                    )
                }

            </form>


        </>

    );


}

const mapStateToProps = (state) => {
    return {
        requestNewPasswordState: {...state.requestNewPassword},
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        requestNewPassword: (email) => {
            dispatch(actions.authRequestNewPassword(email))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestNewPasswordPage);