import Home from "../containers/Guest/Home2";
//import Home from "../containers/Guest/Home";
import PublicCoursList from "../containers/PublicCoursList";
import Cours from "../containers/Guest/ExplorerCours/Cours";
import Categorie from "../containers/Guest/ExplorerCours/Categorie";
import ExplorerOffres from "../containers/Guest/ExplorerOffres";
import ExplorerCours from "../containers/Guest/ExplorerCours";
import Primaire from "../containers/Guest/Primaire";
import Prepa from "../containers/Guest/Prepa/PrepaPage";
import LMD from "../containers/Guest/LMD";
import Secondaire from "../containers/Guest/Secondaire";

export const homePage = {
  type: "route",
  path: "/home",
  label: "Accueil",
  component: Home,
  requireUnAuthentication: true,
  icon: "fa fa-home",
};

export const primairePage = {
  type: "route",
  path: "/primaire",
  label: "Primaire",
  component: Primaire,
  requireUnAuthentication: true,
  icon: "fa fa-home",
};

export const secondairePage = {
  type: "route",
  path: "/secondaire",
  label: "Primaire",
  component: Secondaire,
  requireUnAuthentication: true,
  icon: "fa fa-home",
};

export const prepaPage = {
  type: "route",
  path: "/preparatoire",
  label: "Primaire",
  component: Prepa,
  requireUnAuthentication: true,
  icon: "fa fa-home",
};

export const lmdPage = {
  type: "route",
  path: "/lmd",
  label: "Primaire",
  component: LMD,
  requireUnAuthentication: true,
  icon: "fa fa-home",
};

export const guestCoursPage = {
  type: "route",
  path: "/nosCours/cours/:coursId",
  label: "List Cours",
  component: Cours,
  requireUnAuthentication: true,
  icon: "fa fa-book",
};

export const guestCategoriePage = {
  type: "route",
  path: "/nosCours/categorie/:categorieId",
  label: "List Cours",
  component: Categorie,
  requireUnAuthentication: true,
  icon: "fa fa-book",
};

export const explorerCoursPage = {
  type: "route",
  path: "/nosCours/",
  component: ExplorerCours,
  requireUnAuthentication: true,
  icon: "fa fa-book",
};

export const publicCoursListPage = {
  type: "route",
  path: "/nosCours",
  label: "Nos Cours",
  component: PublicCoursList,
  icon: "fa fa-book",
  routes: [guestCategoriePage, guestCoursPage, explorerCoursPage],
};
export const guestExploreOffersPage = {
  type: "route",
  path: "/Offers",
  label: "Explore Offers",
  component: ExplorerOffres,
  icon: "fa fa-book",
};

const routes = [
  primairePage,
  secondairePage,
  prepaPage,
  lmdPage,
  homePage,
  publicCoursListPage,
  guestExploreOffersPage,
];

export default routes;
