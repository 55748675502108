import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {

    loading : false,
    error : null,
    success : false,
    videoRequest: null

};

const technicianVideoRequestsTerminateVideoRequestStart = (state,action) => {
    return updateObject(state,{
        loading:true,
        error:null,
        success : false,
        videoRequest: null
    });
};

const technicianVideoRequestsTerminateVideoRequestSuccess = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:null,
        success : true,
        videoRequest: action.payload.videoRequest
    });
};

const technicianVideoRequestsTerminateVideoRequestFail = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:action.payload.error,
        success : false,
        videoRequest: null
    });
};

const technicianVideoRequestsTerminateVideoRequestReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.TECHNICIAN_VIDEO_REQUESTS_TERMINATE_VIDEO_REQUEST_START : return technicianVideoRequestsTerminateVideoRequestStart(state,action);
        case actionTypes.TECHNICIAN_VIDEO_REQUESTS_TERMINATE_VIDEO_REQUEST_SUCCESS: return technicianVideoRequestsTerminateVideoRequestSuccess(state,action);
        case actionTypes.TECHNICIAN_VIDEO_REQUESTS_TERMINATE_VIDEO_REQUEST_FAIL : return technicianVideoRequestsTerminateVideoRequestFail(state,action);
        case actionTypes.TECHNICIAN_VIDEO_REQUESTS_TERMINATE_VIDEO_REQUEST_RESET : return technicianVideoRequestsTerminateVideoRequestReset(state,action);
        default : return state;
    }
};

export default reducer;
