import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    utilisateur : null,
    groupe : null
};



const adminUtilisateursAjouterUtilisateurAuGroupeStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        utilisateur: null,
        groupe: null
    });
};

const adminUtilisateursAjouterUtilisateurAuGroupeSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
            success: true,
            loading: false,
            error: null,
            utilisateur: action.payload.utilisateur,
            groupe: action.payload.groupe
    });
};

const adminUtilisateursAjouterUtilisateurAuGroupeFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success: false,
        loading: false,
        error: action.payload.error,
        utilisateur: null,
        groupe: null
    });
};

const adminUtilisateursAjouterUtilisateurAuGroupeReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};



function reducer(state = initialState,action) {

    switch (action.type) {

        case actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_START : return adminUtilisateursAjouterUtilisateurAuGroupeStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_SUCCESS : return adminUtilisateursAjouterUtilisateurAuGroupeSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_FAIL : return adminUtilisateursAjouterUtilisateurAuGroupeFail(state,action);
        case actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_RESET : return adminUtilisateursAjouterUtilisateurAuGroupeReset(state,action);

        default : return state;
    }

}

export default reducer;