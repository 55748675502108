import Auth from "../containers/Auth";
import SignIn from "../containers/Auth/SignIn";
import SignUp from "../containers/Auth/SignUp";
import RequestNewPasswordPage from "../containers/Auth/RequestNewPassword";
import NewPasswordPage from "../containers/Auth/NewPassword";


export const signInPage = {
    type: "route",
    path: "/auth/signin",
    component: SignIn,
    requireUnAuthentication: true
};

export const requestNewPasswordPage = {
    type: "route",
    path: "/auth/request-new-password",
    component: RequestNewPasswordPage,
    requireUnAuthentication: true
};

export const newPasswordPage = {
    type: "route",
    path: "/auth/new-password",
    component: NewPasswordPage,
    requireUnAuthentication: true
};

export const signUpPage = {
    type: "route",
    path: "/auth/signup",
    component: SignUp,
    requireUnAuthentication: true
};

export const AuthPage = {
    type: "route",
    path: "/auth/",
    component:Auth,
    requireUnAuthentication: true,
    routes : [
        signInPage,
        signUpPage,
        requestNewPasswordPage,
        newPasswordPage,
    ]
};

const routes = [
    AuthPage
];

export default routes;