import * as actionTypes from "../../../../actions/actionTypes";

export const adminUtilisateursSupprimerUtilisateur = (utilisateur) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_SUPPRIMER_UTILISATEUR,
        payload : {
            utilisateur : utilisateur
        }
    }
};

export const adminUtilisateursSupprimerUtilisateurStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_START
    }
};

export const adminUtilisateursSupprimerUtilisateurSuccess = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_SUCCESS
    }
};

export const adminUtilisateursSupprimerUtilisateurFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_FAIL,
        payload : {
            error : error
        }
    }
};

export const adminUtilisateursSupprimerUtilisateurReset = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_RESET
    }
};
