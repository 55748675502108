import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";
const initialState = {

    loading : true,
    error : null,
    ressource: null

};

const enseignantMesCoursFetchRessourceStart = (state,action) => {
    return updateObject(state,{
        loading:true,
        error:null,
        ressource: null
    });
};

const enseignantMesCoursFetchRessourceSuccess = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:null,
        ressource: action.payload.ressource
    });
};

const enseignantMesCoursFetchRessourceFail = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:action.payload.error,
        ressource: null
    });
};

const enseignantMesCoursFetchRessourceReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_RESSOURCE_START : return enseignantMesCoursFetchRessourceStart(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_RESSOURCE_SUCCESS : return enseignantMesCoursFetchRessourceSuccess(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_RESSOURCE_FAIL : return enseignantMesCoursFetchRessourceFail(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_RESSOURCE_RESET : return enseignantMesCoursFetchRessourceReset(state,action);
        default : return state;
    }
};

export default reducer;
