import React from "react";
import cssClasses from "./page1.module.css";
import { Box } from "@material-ui/core";
import homeBG from "../../../assets/homebg-transparent.png";
import fullLogo from "../../../assets/fullLogo.png";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const PageOne = () => {
  return (
    <Box textAlign={"center"} className={cssClasses.page1}>
      <img className={cssClasses.homeImg} src={homeBG} />
      <div className={cssClasses.overlay}>
        <img src={fullLogo} alt={"logo"} height={250} />
        <h5>
          Le soutien scolaire qui révèle le <span>potentiel</span> des élèves
        </h5>
        <NavLink to={"/auth/signup"}>
          <Button className={cssClasses.newAccountButton} variant={"contained"}>
            NOUS REJOINDRE
          </Button>
        </NavLink>
      </div>
    </Box>
  );
};

export default PageOne;
