import React, {useEffect, useRef, useState} from "react";


import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";

import Alert from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import CC1 from "../../../../assets/CC1.png";
import Button from "@material-ui/core/Button";
import PaymentIcon from "@material-ui/icons/Payment";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import {
    utilisateurCommandeFetchCommande,
    utilisateurCommandeFetchCommandeReset
} from "../../../../store/actions/utilisateur/commande/fetchCommande";


import {dateFormat, dateFormatWithHoursAndMinutes} from "../../../../utils/utils";
import {
    utilisateurCommandePayerCommande,
    utilisateurCommandePayerCommandeReset
} from "../../../../store/actions/etudiant/commandes/commandeActions/payerCommande";
import FormControl from "@material-ui/core/FormControl";


const initialState = {
    commande: null,
    numeroTransactionD17: {
        value: "",
        error: null
    },
    numeroTransactionBNK: {
        value: "",
        error: null
    }
};

function Order(props) {

    console.log(props);

    const [state, setState] = useState(initialState);

    const gpgForm = useRef();


    const numeroTransactionD17OnChangeHandler = (event) => {
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                numeroTransactionD17: {
                    value: value,
                    error: error
                }
            }
        });
    };

    const numeroTransactionBNKOnChangeHandler = (event) => {
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                numeroTransactionBNK: {
                    value: value,
                    error: error
                }
            }
        });
    };

    let {commandeId} = useParams();

    useEffect(() => {
        props.fetchCommande(commandeId);

        return () => {
            props.resetFetchCommande();
        }
    }, []);


    useEffect(() => {

        if (props.orderState.success)
            setState(prevState => {
                return {
                    ...prevState,
                    commande: props.orderState.commande
                }
            })

    }, [props.orderState.success]);


    useEffect(() => {

        if (props.payerCommandeState.success)
            setState(prevState => {
                return {
                    ...prevState,
                    commande: {...props.orderState.commande, payment: props.payerCommandeState.paiement}
                }
            })

    }, [props.payerCommandeState.success]);


    const calculTotal = () => {
        let total = 0;
        props.orderState.commande.orderArticles.forEach((article) => {
            total += prixArticle(article);
        });
        return total;
    };

    const prixArticle = (article) => {
        if (article.offer.fixedPeriod)
            return article.offer.price;
        switch (article.duration) {
            case 1 :
                return article.offer.price1;
            case 3 :
                return article.offer.price3;
            case 6 :
                return article.offer.price6;
            case 12 :
                return article.offer.price12;

        }
    };

    const dureeArticle = (article) => {
        if (article.offer.fixedPeriod)
            return "De " + dateFormat(article.offer.startDate) + " à " + dateFormat(article.offer.endDate);
        else {
            return article.duration + " mois";
        }
    };

    const listeOffres = (props.orderState.success) ?

        props.orderState.commande.orderArticles.map((article => {

            return (


                <TableRow>
                    <TableCell align={"left"}>{article.offer.title}</TableCell>
                    <TableCell align={"center"}>{dureeArticle(article)}</TableCell>
                    <TableCell align={"right"}>{prixArticle(article)} DT</TableCell>
                </TableRow>

            )

        }))

        : [];

    const offerDetails = (props.orderState.success) ?
        props.orderState.commande.orderArticles.map(article => {
            return `Offre : ${article.offer.title} (${dureeArticle(article)})`
        }).join(', ')
        :
        "";


    const commandeStatut = (order) => {

        if (order.onlinePayment) {

            switch (order.onlinePayment.status) {
                case "Payé" :
                    return <Alert icon={<CheckCircleOutlineIcon fontSize="inherit"/>} className={"ml-3 px-4"}
                                  variant="filled" severity="success">
                        Payé
                    </Alert>;
                case "Refusé" :
                    return <Alert icon={<CancelOutlinedIcon fontSize="inherit"/>} className={"ml-3 px-4"}
                                  variant="filled" severity="error">
                        Refusé
                    </Alert>;
                case "En attente de paiement" :
                    return <Alert icon={<GavelOutlinedIcon fontSize="inherit"/>} className={"ml-3 px-4"}
                                  variant="filled" severity="warning">
                        En attente de paiement
                    </Alert>;
            }
        }
    };

    const handlePayerCommande = () => {
        let payment;
        if (props.parametres.parametres[0].active && props.parametres.parametres[1].active) {

            if (paymentMethod === 'D17') {
                payment = {
                    transactionNumber: state.numeroTransactionD17.value,
                    setting: {
                        id: "D17"
                    }
                };
            } else {
                payment = {
                    transactionNumber: state.numeroTransactionBNK.value,
                    setting: {
                        id: "BANK"
                    }
                };
            }
        } else if (props.parametres.parametres[0].active) {
            payment = {
                transactionNumber: state.numeroTransactionBNK.value,
                setting: {
                    id: "BANK"
                }
            };
        } else if (props.parametres.parametres[1].active) {
            payment = {
                transactionNumber: state.numeroTransactionD17.value,
                setting: {
                    id: "D17"
                }
            };
        }

        if (payment && payment.transactionNumber) {
            props.payerCommande(props.orderState.commande.id, payment);
        } else {
            alert("Veuillez indiquer le numero de transaction");
        }

    };


    const handleGPGPay = () => {

        gpgForm.current.submit();

    };


    const [paymentMethod, setPaymentMethod] = React.useState('CREDIT_CARD');

    const handleChangePaymentMethod = (event) => {
        setPaymentMethod(event.target.value);
    };


    return (


        state.commande ?
            <div className={"p-5"}>
                <Paper elevation={3}>

                    <div className={"p-3"}>
                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} sm={6}>
                                <h2>Commnade N:{state.commande.id}</h2>
                            </Grid>
                            <Grid item={true} className={"d-flex justify-content-end"} xs={12} sm={6}>
                                <h2>Statut</h2>
                                {commandeStatut(state.commande)}
                            </Grid>

                            <Grid item={true} xs={12} sm={6}>
                                <h6>Date :<b>{dateFormatWithHoursAndMinutes(state.commande.date)}</b></h6>
                            </Grid>
                        </Grid>

                        <Table className={"my-5"}>
                            <TableHead>
                                <TableRow className={"bg-dark"}>
                                    <TableCell align={"left"}><h2 className={" text-white"}>Offres :</h2></TableCell>
                                    <TableCell colSpan={2}/>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={"left"}>Offre</TableCell>
                                    <TableCell align={"center"}>Durée</TableCell>
                                    <TableCell align={"right"}>Prix</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listeOffres}
                                <TableRow>
                                    <TableCell colSpan={2}/>
                                    <TableCell align={"right"}>Totale : {calculTotal()} DT</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        {props.payerCommandeState.error &&
                            <Alert className={"mt-2 mb-3"} variant="filled"
                                   severity="error">{props.payerCommandeState.error}</Alert>
                        }
                        {props.payerCommandeState.success &&
                            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Nous avons bien reçu
                                votre
                                demande de paiement, veuillez patienter jusqu'à ce que nous la traitions.</Alert>
                        }


                        {
                            (state.commande.onlinePayment && state.commande.onlinePayment.status === "En attente de paiement")

                                ?
                                <>
                                    <h4>Paiement en ligne :</h4>


                                    <div className={"d-md-flex align-items-center "}>

                                        <div className={"text-center my-3"}>
                                            <img style={{width: 100}} className={"mx-3"}
                                                 src={CC1}/>
                                        </div>

                                        <FormControl component="fieldset">
                                            <RadioGroup aria-label="gender" name="gender1"
                                                        value={paymentMethod}
                                                        onChange={handleChangePaymentMethod}>
                                                <div>
                                                    <FormControlLabel value="CREDIT_CARD" control={<Radio/>}
                                                                      label="Paiement en ligne"/>
                                                </div>
                                            </RadioGroup>
                                        </FormControl>

                                    </div>

                                    <div className={"text-center"}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={"my-3"}
                                            endIcon={<PaymentIcon/>}
                                            onClick={handleGPGPay}
                                        >
                                            Payer maintenant en ligne
                                        </Button>
                                    </div>


                                    <div>
                                        <form ref={gpgForm} className={"d-none"} name="paiment" id="gpgForm"
                                              method="POST"
                                              action="https://www.gpgcheckout.com/Paiement/Validation_paiement.php">
                                            <input type="hidden" name="NumSite"
                                                   value={state.commande.onlinePayment.numSite}/>
                                            <input type="hidden" name="Password"
                                                   value={state.commande.onlinePayment.password}/>
                                            <input type="hidden" name="orderID" value={state.commande.id}/>
                                            <input type="hidden" name="Amount"
                                                   value={state.commande.onlinePayment.totalPriceMill}/>
                                            <input type="hidden" name="Currency" value="TND"/>
                                            <input type="hidden" name="Language" value="fr"/>
                                            <input type="hidden" name="EMAIL" value={props.user.email}/>
                                            <input type="hidden" name="CustFirstName" value={props.user.firstName}/>
                                            <input type="hidden" name="CustLastName" value={props.user.lastName}/>
                                            <input type="hidden" name="CustAddress" value={props.user.address}/>
                                            <input type="hidden" name="CustZIP" value={props.user.zipCode}/>
                                            <input type="hidden" name="CustCity" value={props.user.city}/>
                                            <input type="hidden" name="CustCountry" value={props.user.country}/>
                                            <input type="hidden" name="CustTel" value={props.user.phoneNumber}/>
                                            <input type="hidden" name="orderProducts" value={offerDetails}/>
                                            <input type="hidden" name="PayementType" value="1"/>
                                            <input type="hidden" name="signature"
                                                   value={state.commande.onlinePayment.signature}/>
                                            <input type="hidden" name="vad" value={state.commande.onlinePayment.vad}/>
                                            <input type="hidden" name="Terminal" value="001"/>
                                        </form>
                                    </div>

                                </>
                                :
                                <></>
                        }


                    </div>


                </Paper>
            </div>

            :
            <div className={"p-5 "}>
                <div className={"mx-auto loadingContainer"}>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );


}


const mapStateToProps = (state) => {
    return {
        user: state.authSignIn.user,
        orderState: state.utilisateur.commande.fetchCommande,
        payerCommandeState: state.etudiant.commandes.commande.payerCommande,
        parametres: state.parametres
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCommande: (commandeId) => dispatch(utilisateurCommandeFetchCommande(commandeId)),
        resetFetchCommande: () => dispatch(utilisateurCommandeFetchCommandeReset()),
        payerCommande: (commandeId, paiement) => dispatch(utilisateurCommandePayerCommande(commandeId, paiement)),
        resetPayerCommande: () => dispatch(utilisateurCommandePayerCommandeReset()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
