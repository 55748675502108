import React from "react";

import Skeleton from '@material-ui/lab/Skeleton';


function RessourceListItemSkeleton() {

    return (
        <li className={"p-3 d-flex justify-content-between align-items-center"}>
            <div className={"mr-3"}>
                <Skeleton variant={"rect"} height={"30px"} width={"30px"} />
            </div>
            <div className={"w-75"}>
                <Skeleton />
            </div>
            <div className={"w-25 d-flex justify-content-end"}>
                <Skeleton variant={"circle"} height={"30px"} width={"30px"} />
            </div>
        </li>

    );
}

export default RessourceListItemSkeleton;