import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import cssClasses from "./feedbackCard.module.css";
const FeedbackCard = (props) => {
  return (
    <Box className={cssClasses.cardContainer}>
      <Card
        className={cssClasses.card}
        style={{
          borderRadius: "16px",
          boxShadow: "#3f51b5 30px 16px",
        }}
      >
        <CardContent>
          <Typography variant="h5" component="h2">
            {props.name}
          </Typography>
          <Typography
            color="textSecondary"
            style={{
              fontSize: "12px",
            }}
          >
            Recommande ⭐ Prépaprofs
          </Typography>
          <Typography
            style={{
              color: "#ee6c4d",
              fontWeight: "bold",
            }}
          >
            {props.rank}
          </Typography>
          <Box
            style={{
              height: "180px",
              overflow: "auto",
              marginTop: "10px",
            }}
          >
            <p className={cssClasses.feedback}>{props.feedback}</p>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FeedbackCard;
