import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminUtilisateursSupprimerGroupeSaga (action) {

    yield put(actions.adminUtilisateursSupprimerGroupeStart());

    const groupe = action.payload.groupe;
    const supprimerSousGroupes = action.payload.supprimerSousGroupes;
    const supprimerUtilisateurs = action.payload.supprimerUtilisateurs;
    try {
        const response = yield axios.delete(generateDynamicRoute(api.supprimerGroupe,[
                            {toReplace:":groupeId",value:groupe},
                            {toReplace:":deleteSousGroupes",value:supprimerSousGroupes},
                            {toReplace:":deleteUtilisateurs",value:supprimerUtilisateurs},

                            ]));
        yield put(actions.adminUtilisateursSupprimerGroupeSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursSupprimerGroupeFail(error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursSupprimerGroupeFail("There's an error!"));
        }
    }
}

