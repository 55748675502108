import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminAssignementsGroupeCoursAjouterAssignementGroupeCoursSaga (action) {

    yield put(actions.adminAssignementsGroupeCoursAjouterAssignementGroupeCoursStart());

    const assignementGroupeCours = action.payload.assignementGroupeCours;

    try {
        const response = yield axios.post(api.ajouterAssignementGroupeCours,assignementGroupeCours);
        yield put(actions.adminAssignementsGroupeCoursAjouterAssignementGroupeCoursSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminAssignementsGroupeCoursAjouterAssignementGroupeCoursFail(error.response.data.error));
        }else {
            yield put(actions.adminAssignementsGroupeCoursAjouterAssignementGroupeCoursFail("There's an error!"));
        }
    }
}

