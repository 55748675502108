import * as actionTypes from "../../../actionTypes";

export const guestHomeFetchListeCoursSpecialStart = () => {
    return {
        type: actionTypes.GUEST_HOME_FETCH_LISTE_COURS_SPECIALS_START
    }
};

export const guestHomeFetchListeCoursSpecialSuccess = (listeCoursSpecials) => {
    return {
        type: actionTypes.GUEST_HOME_FETCH_LISTE_COURS_SPECIALS_SUCCESS,
        payload : {
            listeCoursSpecials: listeCoursSpecials
        }
    }
};

export const guestHomeFetchListeCoursSpecialFail = (error) => {
    return {
        type: actionTypes.GUEST_HOME_FETCH_LISTE_COURS_SPECIALS_FAIL,
        payload : {
            error : error
        }
    }
};

export const guestHomeFetchListeCoursSpecial = () => {
    return {
        type: actionTypes.GUEST_HOME_INITIATE_FETCH_LISTE_COURS_SPECIALS,
    }
};