import {combineReducers} from "redux";

import mesCoursReducer from "./mesCours";
import demandeVideosReducer from "./demandeVideos";
import demandeFichiersReducer from "./demandeFichiers";


export default combineReducers({
    mesCours : mesCoursReducer,
    demandeVideos : demandeVideosReducer,
    demandeFichiers : demandeFichiersReducer
});