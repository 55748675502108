import { put } from "redux-saga/effects";

import axios from "../../../../../api/axios";

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import { generateDynamicRoute } from "../../../../../utils/utils";

export function* etudiantMesCoursFetchListeCoursSaga(action) {
  yield put(actions.etudiantMesCoursFetchListeCoursStart());

  const utilisateurId = action.payload.utilisateurId;

  try {
    const response = yield axios.get(
      generateDynamicRoute(api.studentMyCourses, [
        { toReplace: ":utilisateurId", value: utilisateurId },
      ])
    );
    yield put(actions.etudiantMesCoursFetchListeCoursSuccess(response.data));
  } catch (error) {
    if (error.response) {
      yield put(
        actions.etudiantMesCoursFetchListeCoursFail(error.response.data.error)
      );
    } else {
      yield put(
        actions.etudiantMesCoursFetchListeCoursFail("There's an error!")
      );
    }
  }
}
