import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {CoursePage} from "../../../../../routes/studentRoutes";
import Modal from "@material-ui/core/Modal";
import ReactPlayer from "react-player";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import TelechargerFichier from "../../../../../components/TelechargerFichier";
import VideoPlayer from "../../../../../components/VideoPlayer";

import NoteIcon from '@material-ui/icons/Note';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import {
    etudiantMesCoursFetchCours,
    etudiantMesCoursFetchCoursReset
} from "../../../../../store/actions/etudiant/mesCours/cours";
import {
    etudiantMesCoursFetchCoursRessources,
    etudiantMesCoursFetchCoursRessourcesReset
} from "../../../../../store/actions/etudiant/mesCours/listeRessources";
import {connect} from "react-redux";
import {
    etudiantMesCoursFetchRessource,
    etudiantMesCoursFetchRessourceReset
} from "../../../../../store/actions/etudiant/mesCours/ressourceActions";

import {
    useParams
} from "react-router-dom";
import {generateDynamicRoute} from "../../../../../utils/utils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ChatIcon from '@material-ui/icons/Chat';
import AddIcon from "@material-ui/icons/Add";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Button from "@material-ui/core/Button";
import AjouterUtilisateur from "../../../../Admin/Utilisateurs/ModifierGroupe/AjouterUtilisateureModal";
import AjouterNote from "./AjouterNoteModal";
import {etudiantFetchNotes, etudiantSupprimerNote} from "../../../../../store/actions/etudiant/mesCours/notesActions";
import Alert from "@material-ui/lab/Alert";

import CircularProgress from '@material-ui/core/CircularProgress';
const initialState = {
    ajouterNoteModalOpen : false,
    notes : []
};

function RessourcePage(props) {

    const [state,setState] = useState(initialState);


    useEffect(() => {
        if(props.notes.notes.length > 0)
            setState(prevState => {
                return {
                    ...prevState,
                    notes : props.notes.notes
                }
            });
    },[props.notes.notes]);




    const handleOpenAjouterNoteModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                ajouterNoteModalOpen : true
            }
        })
    };

    const handleCloseAjouterNoteModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                ajouterNoteModalOpen : false
            }
        })
    };


    const history = useHistory();

    let { coursId } = useParams();
    let { ressourceId } = useParams();


    const handleClose = () => {
        history.push(generateDynamicRoute(CoursePage.path,[{toReplace:":coursId",value:coursId}]));
    };


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const generateRessource = (ressource) => {
      switch (ressource.type) {
          case "Fichier" : return <TelechargerFichier fichier={ressource}/>;
          case "Video" : return <VideoPlayer video={ressource}/>;
          default : return null;
      }
    };

    useEffect(()=> {
        if(ressourceId) {
            props.fetchRessource(ressourceId);
            props.fetchNotes(props.utilisateur.id,ressourceId);
            return () => {
                props.resetRessource()
            }
        }
    },[ressourceId]);




    useEffect(() => {
        if(props.ajouterNoteState.success && props.ajouterNoteState.note) {
            setState(prevState => {
                const newState = {
                    ...prevState,
                    notes : [...prevState.notes,props.ajouterNoteState.note]
                };
                return newState;
            })
        }
    },[props.ajouterNoteState.success]);


    useEffect(() => {
        if(props.supprimerNoteState.success && props.supprimerNoteState.noteId) {
            setState(prevState => {
                const newState = {
                    ...prevState,
                    notes :  prevState.notes.filter((note) => note.id != props.supprimerNoteState.noteId)
                };
                return newState;
            })
        }
    },[props.supprimerNoteState.success]);





    return (
        <>
            <Modal open={true} onClose={handleClose} >

                <div style={{height:"100%",width:"100%",overflowY: "auto"}} className={"bg-white"}>

                    <IconButton onClick={handleClose} className={"my-3"}>
                        <ArrowBackIcon />
                    </IconButton>


                    <div className={"container my-5"}>

                        {!props.loading && !props.error && (
                            <>



                                <h2 className={"text-center mb-5"}>{props.ressource.nom}</h2>
                                {
                                    generateRessource(props.ressource)
                                }

                                    <p className={"my-5"}>
                                        {props.ressource.description}
                                        L'héritage est le troisième des paradigmes de la programmation orientée objet (le 1 èr étant l'encapsulation, le 2ème la structure de classe).
                                    </p>

                            </>
                            )}

                        <div className={"my-4"}>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="scrollable force tabs example"
                                    centered
                                >
                                    <Tab label="Notes" icon={<NoteIcon />} id='scrollable-force-tab-0' aria-controls='scrollable-force-tabpanel-0' />
                                    <Tab label="Sujets de discussions" icon={<ChatIcon />} id='scrollable-force-tab-1' aria-controls='scrollable-force-tabpanel-1' />

                                </Tabs>
                            </AppBar>
                            <div className={"py-3 border"}>
                                <div
                                    role="tabpanel"
                                    hidden={value !== 0}
                                    className={"mx-2"}

                                >
                                    {props.supprimerNoteState.success&& <Alert className={"mt-2 mb-3"} variant="filled" severity="success">La note supprimée avec succees</Alert>
                                    }
                                    <List>
                                        {props.notes.loading && <div className={"text-center"}><CircularProgress size={200}/></div> }
                                        {!props.notes.loading && state.notes.map(note => {return (<ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <NoteIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={note.contenu}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="delete" onClick={()=>{props.supprimerNote(note.id)}}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>);})}
                                    </List>
                                    <div className={"text-center"}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            startIcon={<><AddIcon /><NoteIcon /></>}
                                            onClick={handleOpenAjouterNoteModal}
                                        >
                                            Ajouter une note
                                        </Button>
                                    </div>
                                </div>

                                <div
                                    role="tabpanel"
                                    hidden={value !== 1}
                                    className={"mx-2"}

                                >
                                    Sujets de discussions
                                </div>

                            </div>
                        </div>

                    </div>
                </div>


            </Modal>
        </>

    );
}

const mapStateToProps = (state) => {
    return {
        loading : state.etudiant.mesCours.ressource.loading,
        error : state.etudiant.mesCours.ressource.error,
        ressource : state.etudiant.mesCours.ressource.ressource,
        utilisateur : state.authSignIn.utilisateur,
        notes : state.etudiant.mesCours.notes,
        ajouterNoteState : state.etudiant.mesCours.notes.ajouterNote,
        supprimerNoteState : state.etudiant.mesCours.notes.supprimerNote,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchRessource : (ressourceId) => dispatch(etudiantMesCoursFetchRessource(ressourceId)),
        supprimerNote : (noteId) => dispatch(etudiantSupprimerNote(noteId)),

        fetchNotes : (utilisateurId,ressourceId) => dispatch(etudiantFetchNotes(utilisateurId,ressourceId)),
        resetRessource: () => dispatch(etudiantMesCoursFetchRessourceReset())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(RessourcePage);
