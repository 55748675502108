import * as actionTypes from "../../../../actionTypes";

export const guestExplorerCoursCoursFetchListeRessources = (coursId) => {
    return {
        type: actionTypes.GUEST_EXPLORER_COURS_COURS_INITIATE_FETCH_LISTE_RESSOURCES,
        payload : {
            coursId : coursId
        }
    }
};

export const guestExplorerCoursCoursFetchListeRessourcesStart = () => {
    return {
        type : actionTypes.GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_START
    }
};

export const guestExplorerCoursCoursFetchListeRessourcesSuccess = (listeRessources) => {
    return {
        type : actionTypes.GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_SUCCESS,
        payload : {
            listeRessources : listeRessources
        }
    }
};

export const guestExplorerCoursCoursFetchListeRessourcesFail = (error) => {
    return {
        type : actionTypes.GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_FAIL,
        payload : {
            error: error
        }
    }
};

export const guestExplorerCoursCoursFetchListeRessourcesReset = () => {
    return {
        type : actionTypes.GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_RESET
    }
};
