import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    demandeVideo: null
};

const enseignantDemandeVideosAjouterDemandeVideoStart = (state,action) => {
    return updateObject(state,{
        success : false,
        loading : true,
        error: null,
        demandeVideo: null
    });
};

const enseignantDemandeVideosAjouterDemandeVideoSuccess = (state,action) => {
    return updateObject(state,{
        success : true,
        loading : false,
        error: null,
        demandeVideo: action.payload.demandeVideo
    });
};

const enseignantDemandeVideosAjouterDemandeVideoFail = (state,action) => {
    return updateObject(state,{
        success : false,
        loading : false,
        error:action.payload.error,
        demandeVideo: null
    });
};

const enseignantDemandeVideosAjouterDemandeVideoReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.ENSEIGNANT_DEMANDE_VIDEOS_AJOUTER_DEMANDE_VIDEO_START : return enseignantDemandeVideosAjouterDemandeVideoStart(state,action);
        case actionTypes.ENSEIGNANT_DEMANDE_VIDEOS_AJOUTER_DEMANDE_VIDEO_SUCCESS : return enseignantDemandeVideosAjouterDemandeVideoSuccess(state,action);
        case actionTypes.ENSEIGNANT_DEMANDE_VIDEOS_AJOUTER_DEMANDE_VIDEO_FAIL : return enseignantDemandeVideosAjouterDemandeVideoFail(state,action);
        case actionTypes.ENSEIGNANT_DEMANDE_VIDEOS_AJOUTER_DEMANDE_VIDEO_RESET : return enseignantDemandeVideosAjouterDemandeVideoReset(state,action);
        default : return state;
    }
};

export default reducer;
