import {combineReducers} from "redux";

import listeCoursReducer from "./listeCours";
import coursReducer from "./cours";

import listeRessourcesReducer from "./listeRessources";
import ressourceReducer from "./ressource";
import ajouterSectionReducer from "./ajouterSection";
import ajouterFichierReducer from "./ajouterFichier";
import ajouterVideoReducer from "./ajouterVideo";


export default combineReducers({
    listeCours : listeCoursReducer,
    cours : coursReducer,
    listeRessources : listeRessourcesReducer,
    ressource: ressourceReducer,
    ajouterSection: ajouterSectionReducer,
    ajouterFichier: ajouterFichierReducer,
    ajouterVideo: ajouterVideoReducer,
});