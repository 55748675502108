import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    listeCategoriesSpecials: []
};

const guestHomeFetchListeCategoriesSpecialsStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        listeCategoriesSpecials: []
    });
};

const guestHomeFetchListeCategoriesSpecialsSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        listeCategoriesSpecials: action.payload.listeCategoriesSpecials
    });
};

const guestHomeFetchListeCategoriesSpecialsFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        listeCategoriesSpecials: []
    });
};

function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.GUEST_HOME_FETCH_LISTE_CATEGORIES_SPECIALS_START : return guestHomeFetchListeCategoriesSpecialsStart(state,action);
        case actionTypes.GUEST_HOME_FETCH_LISTE_CATEGORIES_SPECIALS_SUCCESS : return guestHomeFetchListeCategoriesSpecialsSuccess(state,action);
        case actionTypes.GUEST_HOME_FETCH_LISTE_CATEGORIES_SPECIALS_FAIL : return guestHomeFetchListeCategoriesSpecialsFail(state,action);

        default : return state;
    }

}

export default reducer;