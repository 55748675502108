import * as actionTypes from "../../../actionTypes";

export const etudiantExplorerOffresFetchListeOffres = (categorieId) => {
    return {
        type: actionTypes.ETUDIANT_EXPLORER_OFFRES_INITIATE_FETCH_LISTE_OFFRES,
        payload: {
            categorieId : categorieId
        }
    }
};

export const etudiantExplorerOffresFetchListeOffresStart = () => {
    return {
        type: actionTypes.ETUDIANT_EXPLORER_OFFRES_FETCH_LISTE_OFFRES_START
    }
};

export const etudiantExplorerOffresFetchListeOffresSuccess = (listeOffres) => {
    return {
        type: actionTypes.ETUDIANT_EXPLORER_OFFRES_FETCH_LISTE_OFFRES_SUCCESS,
        payload : {
            listeOffres: listeOffres
        }
    }
};

export const etudiantExplorerOffresFetchListeOffresFail = (error) => {
    return {
        type: actionTypes.ETUDIANT_EXPLORER_OFFRES_FETCH_LISTE_OFFRES_FAIL,
        payload : {
            error : error
        }
    }
};

export const etudiantExplorerOffresFetchListeOffresReset = () => {
    return {
        type: actionTypes.ETUDIANT_EXPLORER_OFFRES_FETCH_LISTE_OFFRES_RESET
    }
};