import * as actionTypes from "../../../../actions/actionTypes";


export const adminUtilisateursModifierUtilisateur = (utilisateurId,utilisateur,photo) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_MODIFIER_UTILISATEUR,
        payload : {
            utilisateur : utilisateur,
            utilisateurId : utilisateurId,
            photo : photo
        }
    }
};

export const adminUtilisateursModifierUtilisateurStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_START
    }
};

export const adminUtilisateursModifierUtilisateurSuccess = (utilisateur) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_SUCCESS,
        payload : {
            utilisateur: utilisateur
        }
    }
};

export const adminUtilisateursModifierUtilisateurFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_FAIL,
        payload : {
            error : error
        }
    }
};


export const adminUtilisateursModifierUtilisateurReset = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_RESET
    }
};









export const adminUtilisateursModifierUtilisateurChangerMotDePasse = (utilisateurId,utilisateur,sendEmail,generatePassword) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_MODIFIER_UTILISATEUR_CHANGER_MOT_DE_PASSE,
        payload : {
            utilisateurId : utilisateurId,
            utilisateur : utilisateur,
            sendEmail : sendEmail,
            generatePassword : generatePassword,
        }
    }
};

export const adminUtilisateursModifierUtilisateurChangerMotDePasseStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_CHANGER_MOT_DE_PASSE_START
    }
};

export const adminUtilisateursModifierUtilisateurChangerMotDePasseSuccess = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_CHANGER_MOT_DE_PASSE_SUCCESS

    }
};

export const adminUtilisateursModifierUtilisateurChangerMotDePasseFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_CHANGER_MOT_DE_PASSE_FAIL,
        payload : {
            error : error
        }
    }
};


export const adminUtilisateursModifierUtilisateurChangerMotDePasseReset = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_UTILISATEUR_CHANGER_MOT_DE_PASSE_RESET
    }
};
