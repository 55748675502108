import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios";
import * as api from "../../../../../api";

import * as actions from "../../../../actions";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminUtilisateursFetchListeGroupesSaga(action) {


    yield put(actions.adminUtilisateursFetchListeGroupesStart());

    try {
        const response = yield axios.get(api.getListeGroupesRoot);
        const listeGroupes = response.data;
        yield listeGroupes.forEach(groupe => {
            groupe["type"] = "Groupe";
            groupe["content"] = {
                listeGroupes : {
                    loading: true,
                    error: null,
                    listeGroupes : []
                },
                listeUtilisateurs : {
                    loading: true,
                    error: null,
                    listeUtilisateurs : []
                }
            };
        });
        yield put(actions.adminUtilisateursFetchListeGroupesSuccess(listeGroupes));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursFetchListeGroupesFail(error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursFetchListeGroupesFail("There's an error!"));
        }
    }
}


export function* adminUtilisateursFetchGroupeListeGroupesSaga(action) {

    const groupeId = action.payload.groupeId;

    yield put(actions.adminUtilisateursFetchGroupeListeGroupesStart(groupeId));

    try {
        const response = yield axios.get(generateDynamicRoute(api.getGroupeSousGroupes,[{toReplace : ":groupeId",value:groupeId}]));
        const listeGroupes = response.data;
        yield listeGroupes.forEach(groupe => {
            groupe["type"] = "Groupe";
            groupe["content"] = {
                listeGroupes : {
                    loading: true,
                    error: null,
                    listeGroupes : []
                },
                listeUtilisateurs : {
                    loading: true,
                    error: null,
                    listeUtilisateurs : []
                }
            };
        });
        yield put(actions.adminUtilisateursFetchGroupeListeGroupesSuccess(groupeId,listeGroupes));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursFetchGroupeListeGroupesFail(groupeId,error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursFetchGroupeListeGroupesFail(groupeId,"There's an error!"));
        }
    }
}

export function* adminUtilisateursFetchGroupeListeUtilisateursSaga(action) {

    const groupeId = action.payload.groupeId;

    yield put(actions.adminUtilisateursFetchGroupeListeUtilisateursStart(groupeId));

    try {
        const response = yield axios.get(generateDynamicRoute(api.getGroupeUtilisateurs,[{toReplace : ":groupeId",value:groupeId}]));
        const listeUtilisateurs = response.data;
        yield listeUtilisateurs.forEach(utilisateur => {
            utilisateur["type"] = "Utilisateur";
        });
        yield put(actions.adminUtilisateursFetchGroupeListeUtilisateursSuccess(groupeId,listeUtilisateurs));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursFetchGroupeListeUtilisateursFail(groupeId,error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursFetchGroupeListeUtilisateursFail(groupeId,"There's an error!"));
        }
    }
}
