import * as actionTypes from "../../../actionTypes";

export const enseignantMesCoursFetchCours = (coursId) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_INITIATE_FETCH_COURS,
        payload : {
            coursId : coursId
        }
    }
};

export const enseignantMesCoursFetchCoursStart = () => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_START
    }
};

export const enseignantMesCoursFetchCoursSuccess = (cours) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_SUCCESS,
        payload : {
            cours : cours
        }
    }
};

export const enseignantMesCoursFetchCoursFail = (error) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_FAIL,
        payload : {
            error: error
        }
    }
};

export const enseignantMesCoursFetchCoursReset = () => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_RESET
    }
};
