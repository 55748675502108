import * as actionTypes from "../../../actionTypes";

export const enseignantMesCoursFetchCoursRessources = (coursId) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_INITIATE_FETCH_COURS_RESSOURCES,
        payload : {
            coursId : coursId
        }
    }
};

export const enseignantMesCoursFetchCoursRessourcesStart = () => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_RESSOURCES_START
    }
};

export const enseignantMesCoursFetchCoursRessourcesSuccess = (listeRessources) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_RESSOURCES_SUCCESS,
        payload : {
            listeRessources : listeRessources
        }
    }
};

export const enseignantMesCoursFetchCoursRessourcesFail = (error) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_RESSOURCES_FAIL,
        payload : {
            error: error
        }
    }
};

export const enseignantMesCoursFetchCoursRessourcesReset = () => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_RESSOURCES_RESET
    }
};