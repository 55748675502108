import {combineReducers} from "redux";

import videosReducer from "./videos";
import coursesReducer from "./courses";
import videoRequestsReducer from "./videoRequests";
import tagsReducer from "./tags";


export default combineReducers({



    courses: coursesReducer,
    videos: videosReducer,
    videoRequests: videoRequestsReducer,
    tags: tagsReducer



});