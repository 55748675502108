import React from "react";


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';



function AssignementsTable(props) {

    const showSupprimerButton = props.showSupprimerButton;

    let TableHeadCells = null;

    switch (props.type) {

        case "GroupeCours" :
            TableHeadCells = (
                <>

                    <TableCell variant={"head"} align="left"><b>Groupe</b></TableCell>
                    <TableCell variant={"head"} align="left"><b>Cours</b></TableCell>

                </>);
            break;
        case "GroupeCategorie" :
            TableHeadCells = (
                <>

                    <TableCell variant={"head"} align="left"><b>Groupe</b></TableCell>
                    <TableCell variant={"head"} align="left"><b>Categorie</b></TableCell>

                </>);
            break;
        case "UtilisateurCours" :
            TableHeadCells = (
                <>

                    <TableCell variant={"head"} align="left"><b>Utilisateur</b></TableCell>
                    <TableCell variant={"head"} align="left"><b>Cours</b></TableCell>

                </>);
            break;
        case "UtilisateurCategorie" :
            TableHeadCells = (
                <>

                    <TableCell variant={"head"} align="left"><b>Utilisateur</b></TableCell>
                    <TableCell variant={"head"} align="left"><b>Categorie</b></TableCell>

                </>);
            break;


    }

    const TableBodyCells = (assignement) => {

        switch (props.type) {

            case "GroupeCours" :

                return(<>
                    <TableCell align="left">{assignement.groupe.nom}</TableCell>
                    <TableCell align="left">{assignement.cours.titre}</TableCell>
                    </>);
            case "GroupeCategorie" :

                return(<>
                    <TableCell align="left">{assignement.groupe.nom}</TableCell>
                    <TableCell align="left">{assignement.categorie.titre}</TableCell>
                </>);
            case "UtilisateurCours" :

                return(<>
                    <TableCell align="left">{assignement.utilisateur.nom} {assignement.utilisateur.prenom}</TableCell>
                    <TableCell align="left">{assignement.cours.titre}</TableCell>
                </>);
            case "UtilisateurCategorie" :

                return(<>
                    <TableCell align="left">{assignement.utilisateur.nom} {assignement.utilisateur.prenom}</TableCell>
                    <TableCell align="left">{assignement.categorie.titre}</TableCell>
                </>);

        }


    };



    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {showSupprimerButton && <TableCell variant={"head"} align="left"><b>Supprimer</b></TableCell>}
                        {TableHeadCells}
                        <TableCell variant={"head"} align="left"><b>Role</b></TableCell>
                        <TableCell variant={"head"} align="left"><b>Date Debut</b></TableCell>
                        <TableCell variant={"head"} align="left"><b>Date Fin</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.assignements.map((assignement) => (
                        <TableRow key={assignement.id}>
                            {showSupprimerButton && <TableCell align="left"><IconButton onClick={() => props.supprimer(assignement)} ><DeleteForeverIcon /></IconButton></TableCell>}
                            {TableBodyCells(assignement)}
                            <TableCell align="left">{assignement.role}</TableCell>
                            <TableCell align="left">{assignement.dateDebut}</TableCell>
                            <TableCell align="left">{assignement.dateFin}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );


}


export default AssignementsTable;