import * as actionTypes from "../../../actionTypes";

export const technicianVideoRequestsFetchVideoRequestsList = () => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_INITIATE_FETCH_VIDEO_REQUESTS_LIST
    }
};

export const technicianVideoRequestsFetchVideoRequestsListStart = () => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUESTS_LIST_START
    }
};

export const technicianVideoRequestsFetchVideoRequestsListSuccess = (videoRequestsList) => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUESTS_LIST_SUCCESS,
        payload : {
            videoRequestsList : videoRequestsList
        }
    }
};

export const technicianVideoRequestsFetchVideoRequestsListFail = (error) => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUESTS_LIST_FAIL,
        payload : {
            error: error
        }
    }
};

export const technicianVideoRequestsFetchVideoRequestsListReset = () => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUESTS_LIST_RESET
    }
};

