import * as actionTypes from "../../../../actions/actionTypes";


export const adminUtilisateursFetchListeUtilisateurs = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_FETCH_LISTE_UTILISATEURS,
    }
};

export const adminUtilisateursFetchListeUtilisateursStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_LISTE_UTILISATEURS_START
    }
};

export const adminUtilisateursFetchListeUtilisateursSuccess = (listeUtilisateurs) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_LISTE_UTILISATEURS_SUCCESS,
        payload : {
            listeUtilisateurs: listeUtilisateurs
        }
    }
};

export const adminUtilisateursFetchListeUtilisateursFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_LISTE_UTILISATEURS_FAIL,
        payload : {
            error : error
        }
    }
};
