
/**** ASSIGNEMENTS UTILISATEUR CATEGORIE *****/

export const UTILISATEUR_CART_INITIATE_ADD_OFFRE = 'UTILISATEUR_CART_INITIATE_ADD_OFFRE';
export const UTILISATEUR_CART_ADD_OFFRE_SUCCESS = 'UTILISATEUR_CART_ADD_OFFRE_SUCCESS';
export const UTILISATEUR_CART_INITIATE_REMOVE_OFFRE = 'UTILISATEUR_CART_INITIATE_REMOVE_OFFRE';
export const UTILISATEUR_CART_REMOVE_OFFRE_SUCCESS = 'UTILISATEUR_CART_REMOVE_OFFRE_SUCCESS';
export const UTILISATEUR_CART_INITIATE_LOAD_CART = 'UTILISATEUR_CART_INITIATE_LOAD_CART';
export const UTILISATEUR_CART_LOAD_CART = 'UTILISATEUR_CART_LOAD_CART';
export const UTILISATEUR_CART_INITIATE_RESET_CART = 'UTILISATEUR_CART_INITIATE_RESET_CART';
export const UTILISATEUR_CART_RESET_CART_SUCCESS = 'UTILISATEUR_CART_RESET_CART_SUCCESS';