import * as actionTypes from "../../actions/actionTypes"
import {updateObject} from "../../../utils/utils";

const initialState = {
    open:false
};

const openSideNav = (state,action) => {
    return updateObject(state, {open: true});
};

const closeSideNav = (state,action) => {
    return updateObject(state, {open: false});
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.OPEN_SIDENAV: return openSideNav(state,action);
        case actionTypes.CLOSE_SIDENAV: return closeSideNav(state,action);
        default : return state;
    }
};

export default reducer;