import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    groupe: null
};

const adminUtilisateursAjouterGroupeStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        groupe: null
    });
};

const adminUtilisateursAjouterGroupeSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        groupe: action.payload.groupe
    });
};

const adminUtilisateursAjouterGroupeFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        groupe: null
    });
};

const adminUtilisateursAjouterGroupeReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_UTILISATEURS_AJOUTER_GROUPE_START : return adminUtilisateursAjouterGroupeStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_AJOUTER_GROUPE_SUCCESS : return adminUtilisateursAjouterGroupeSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_AJOUTER_GROUPE_FAIL : return adminUtilisateursAjouterGroupeFail(state,action);
        case actionTypes.ADMIN_UTILISATEURS_AJOUTER_GROUPE_RESET : return adminUtilisateursAjouterGroupeReset(state,action);
        default : return state;
    }

}

export default reducer;