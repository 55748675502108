import * as actionTypes from "../../../actionTypes";

export const technicianVideosAddVideo = (video,files) => {
    return {
        type : actionTypes.TECHNICIAN_VIDEOS_INITIATE_ADD_VIDEO,
        payload : {
            video : video,
            files : files
        }
    }
};

export const technicianVideosAddVideoStart = () => {
    return {
        type : actionTypes.TECHNICIAN_VIDEOS_ADD_VIDEO_START
    }
};

export const technicianVideosAddVideoSuccess = (video) => {
    return {
        type : actionTypes.TECHNICIAN_VIDEOS_ADD_VIDEO_SUCCESS,
        payload : {
            video : video
        }
    }
};

export const technicianVideosAddVideoFail = (error) => {
    return {
        type : actionTypes.TECHNICIAN_VIDEOS_ADD_VIDEO_FAIL,
        payload : {
            error: error
        }
    }
};

export const technicianVideosAddVideoReset = () => {
    return {
        type : actionTypes.TECHNICIAN_VIDEOS_ADD_VIDEO_RESET
    }
};
