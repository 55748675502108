import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* enseignantMesCoursFetchCoursSaga(action) {

    yield put(actions.enseignantMesCoursFetchCoursStart());

    const coursId = action.payload.coursId;


    try {
        const response = yield axios.get(generateDynamicRoute(api.enseignantFetchCours,[{toReplace:":coursId",value:coursId}]));
        console.log(response.data);
        yield put(actions.enseignantMesCoursFetchCoursSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.enseignantMesCoursFetchCoursFail(error.response.data.error));
        }else {
            yield put(actions.enseignantMesCoursFetchCoursFail("There's an error!"));
        }
    }

}