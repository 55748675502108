import React from "react";


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';



function GroupesTable(props) {

    const showSupprimerButton = props.showSupprimerButton;

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {showSupprimerButton && <TableCell variant={"head"} align="left"><b>Supprimer</b></TableCell>}
                        <TableCell variant={"head"} align="left"><b>Nom</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.groupes.map((groupe) => (
                        <TableRow key={groupe.id}>
                            {showSupprimerButton && <TableCell align="left"><IconButton onClick={() => props.supprimer(groupe)} ><DeleteForeverIcon /></IconButton></TableCell>}
                            <TableCell align="left">{groupe.nom}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );


}


export default GroupesTable;