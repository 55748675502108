import Grid from "@material-ui/core/Grid";
import CoursCategorieListItem from "../CoursCategoriePaginatedListItem";
import Pagination from "@material-ui/lab/Pagination";
import React from "react";


function CoursCategorieList(props) {

    const categoriesCours = props.categoriesCours.map(categorieCours => {
        return (
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <CoursCategorieListItem categorieCours={categorieCours}/>
            </Grid>
        );
    });

    return (
    <Grid container spacing={3} className={"my-5"}>

        {categoriesCours}

    </Grid>
        );

}
export default CoursCategorieList;