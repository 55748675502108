import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* etudiantFetchNotesSaga (action) {

    yield put(actions.etudiantFetchNotesStart());

    const ressourceId = action.payload.ressourceId;
    const utilisateurId = action.payload.utilisateurId;

    try {
        const response = yield axios.get(generateDynamicRoute(api.etudiantFetchNotes,[{toReplace:":utilisateurId",value:utilisateurId},{toReplace:":ressourceId",value:ressourceId}]));
        yield put(actions.etudiantFetchNotesSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.etudiantFetchNotesFail(error.response.data.error));
        }else {
            yield put(actions.etudiantFetchNotesFail("There's an error!"));
        }
    }
}




export function* etudiantAjouterNoteSaga (action) {

    yield put(actions.etudiantAjouterNoteStart());

    const note = action.payload.note;

    try {
        const response = yield axios.post(api.etudiantAjouterNote,note);
        yield put(actions.etudiantAjouterNoteSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.etudiantAjouterNoteFail(error.response.data.error));
        }else {
            yield put(actions.etudiantAjouterNoteFail("There's an error!"));
        }
    }
}


export function* etudiantSupprimerNoteSaga (action) {

    yield put(actions.etudiantSupprimerNoteStart());

    const noteId = action.payload.noteId;

    try {
        const response = yield axios.delete(generateDynamicRoute(api.etudiantSupprimerNote,[{toReplace:":noteId",value:noteId}]));
        yield put(actions.etudiantSupprimerNoteSuccess(noteId));
    } catch (error) {
        if(error.response) {
            yield put(actions.etudiantSupprimerNoteFail(error.response.data.error));
        }else {
            yield put(actions.etudiantSupprimerNoteFail("There's an error!"));
        }
    }
}

