import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeView from "@material-ui/lab/TreeView";
import React, {useEffect, useState} from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import GroupIcon from "@material-ui/icons/Group";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import MenuBookIcon from '@material-ui/icons/MenuBook';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PersonIcon from "@material-ui/icons/Person";
const initialState = {
    expanded : [],
    selected : null,
}

function TreeListe(props) {


    const [state,setState] = useState(initialState);

    const handleSelect = (event, item) => {
        if(props.dispatchSelected)
            props.dispatchSelected(item);
        setState(prevState => {
            return{
                ...prevState,
                selected : item
            };
        });
    };

    const handleExpand = (event, nodeIds) => {
        setState(prevState => {
            return{
                ...prevState,
                expanded : nodeIds
            };
        });
    };




    useEffect(() => {

        const selected = state.selected;

        if(selected != null) {
            if(selected.startsWith("Groupe")) {
                const groupeId = selected.replace("Groupe","");

                if(props.onlyGroupes)
                    props.fetchGroupeListeGroupes(groupeId);
                else {
                    props.fetchGroupeListeGroupes(groupeId);
                    props.fetchGroupeListeUtilisateurs(groupeId);
                }
            } else if(selected.startsWith("Categorie")) {
                const categorieId = selected.replace("Categorie","");

                if(props.onlyCategories)
                    props.fetchCategorieListeCategories(categorieId);
                else {
                    props.fetchCategorieListeCategories(categorieId);
                    props.fetchCategorieListeCours(categorieId);
                }
            }
        }

    },[state.selected]);

    const groupeTreeItemTemplate = (item) => {

        switch (item.type) {
            case "Groupe" :
                return (<TreeItem nodeId={"Groupe"+item.id}
                          label={
                              <div>
                                  <GroupIcon/> {item.nom}
                                  {
                                      (props.selectable=="Groupe") ?
                                          (props.isGroupeSelected(item)) ?
                                          <HighlightOffIcon color={"error"} onClick={(event)=> {event.preventDefault();props.handleUnSelect(item)}} />
                                          :
                                          <AddCircleOutlineIcon color={"primary"} onClick={(event)=> {event.preventDefault();props.handleSelect(item)}} />


                                          : null


                                      }
                              </div>
                          }>
                    {
                        (props.onlyGroupes) ?
                            (item.content.listeGroupes.loading) ?
                                "loading"
                                : item.content.listeGroupes.listeGroupes.map(item => groupeTreeItemTemplate(item))
                        :
                            (item.content.listeGroupes.loading || item.content.listeUtilisateurs.loading) ?
                                "loading"
                                : item.content.listeGroupes.listeGroupes.concat(item.content.listeUtilisateurs.listeUtilisateurs).map(item => groupeTreeItemTemplate(item))
                    }

                </TreeItem>);

            case "Categorie" :
                return (<TreeItem nodeId={"Categorie"+item.id}
                                  label={
                                      <div>
                                          <LibraryBooksIcon/> {item.titre}
                                          {
                                              (props.selectable=="Categorie") ?
                                                  (props.isCategorieSelected(item)) ?
                                                      <HighlightOffIcon color={"error"} onClick={(event)=> {event.preventDefault();props.handleUnSelect(item)}} />
                                                      :
                                                      <AddCircleOutlineIcon color={"primary"} onClick={(event)=> {event.preventDefault();props.handleSelect(item)}} />


                                                  : null


                                          }
                                      </div>
                                  }>
                    {
                        (item.content.listeCategories.loading) ?
                            "loading"
                            : item.content.listeCategories.listeCategories.map(item => groupeTreeItemTemplate(item))
                    }

                </TreeItem>);

            case "Cours" :
                return (
                    <TreeItem nodeId={"Cours"+item.id}
                              label={
                                  <div>
                                      <MenuBookIcon/> {item.titre}

                                      {(props.selectable=="Cours") ?  ( props.isCoursSelected(item)) ?
                                          <HighlightOffIcon color={"error"} onClick={(event)=> {event.preventDefault();props.handleUnSelect(item)}} />
                                          :
                                          <AddCircleOutlineIcon color={"primary"} onClick={(event)=> {event.preventDefault();props.handleSelect(item)}} />
                                        : null
                                      }
                                  </div>
                              }>

                    </TreeItem>
                );

            case "Utilisateur" :
                return (
                    <TreeItem nodeId={"Utilisateur"+item.id}
                              label={
                                  <div>
                                      <PersonIcon/>  {item.nom} {item.prenom}

                                      {(props.selectable=="Utilisateur") ?  ( props.isUtilisateurSelected(item)) ?
                                          <HighlightOffIcon color={"error"} onClick={(event)=> {event.preventDefault();props.handleUnSelect(item)}} />
                                          :
                                          <AddCircleOutlineIcon color={"primary"} onClick={(event)=> {event.preventDefault();props.handleSelect(item)}} />
                                          : null
                                      }
                                  </div>
                              }>

                    </TreeItem>
                );

            case "Section" :
                return (<TreeItem nodeId={"Section"+item.id}
                                  label={
                                      <div>
                                          <LibraryBooksIcon/> {item.nom}
                                          {
                                              (props.selectable=="Section") ?
                                                  (props.isSectionSelected(item)) ?
                                                      <HighlightOffIcon color={"error"} onClick={(event)=> {event.preventDefault();props.handleUnSelect(item)}} />
                                                      :
                                                      <AddCircleOutlineIcon color={"primary"} onClick={(event)=> {event.preventDefault();props.handleSelect(item)}} />


                                                  : null


                                          }
                                      </div>
                                  }>
                    {
                        item.sousSections.map(item => groupeTreeItemTemplate(item))
                    }

                </TreeItem>);
        }
    };

    const treeItems = props.liste.map((groupe,index) => {
        return groupeTreeItemTemplate(groupe);
    });

    return(

        <TreeView
            className={"text-left p-3"}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={state.expanded}
            selected={state.selected}
            onNodeToggle={handleExpand}
            onNodeSelect={handleSelect}
            disableSelection={false}
        >
            {
                treeItems
            }
        </TreeView>

    );
}

export default TreeListe;