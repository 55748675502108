import React, {useEffect} from "react";

import Modal from '@material-ui/core/Modal';

import FormHelperText from '@material-ui/core/FormHelperText';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import {CircularProgress, Grid} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";
import {CoursePage} from "../../../../../routes/studentRoutes";
import {generateDynamicRoute} from "../../../../../utils/utils";
import {AdminUtilisateursModifierGroupePage} from "../../../../../routes/administratorRoutes";
import {adminUtilisateursFetchGroupe} from "../../../../../store/actions/admin/utilisateurs/groupeActions";
import {adminUtilisateursAjouterGroupe} from "../../../../../store/actions/admin/utilisateurs/ajouterGroupeActions";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import {connect} from "react-redux";
import Chip from "@material-ui/core/Chip";


import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';



import DateFnsUtils from '@date-io/date-fns';

import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {adminAssignementsGroupeCoursAjouterAssignementGroupeCours} from "../../../../../store/actions/admin/assignementsGroupeCours/ajouterAssignementGroupeCoursActions";
import Alert from "@material-ui/lab/Alert";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TreeListeGroupesSingleAdd from "../../../../../components/TreeListeGroupesSingleAdd";
import Box from "@material-ui/core/Box";
import TreeListeGroupesMultiAdd from "../../../../../components/TreeListeGroupesMultiAdd";
import {adminCoursFetchListeCours} from "../../../../../store/actions/admin/cours/listeCoursActions";
import TreeListe from "../../../../../components/TreeListe";
import {
    adminCoursFetchCategorieListeCategories, adminCoursFetchCategorieListeCours,
    adminCoursFetchListeCategories
} from "../../../../../store/actions/admin/cours/listeCategoriesActions";
import {adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorie} from "../../../../../store/actions/admin/assignementsGroupeCategorie/ajouterAssignementGroupeCategorieActions";
import {adminUtilisateursModifierGroupeAjouterSousGroupe} from "../../../../../store/actions/admin/utilisateurs/modifierGroupeActions";

const initialState = {
    sousGroupe: {value : null, error : null},
    groupesExpanded : false,
};

function AjouterSousGroupeModal(props) {


    const history = useHistory();

    const handleGroupesExpanded = (event,isExpanded) => {
        setState(prevState => {
            return {
                ...prevState,
                groupesExpanded : isExpanded
            }
        });
    };



    useEffect(() => {
        props.fetchListeGroupes();
    },[]);

    const [state, setState] = React.useState(initialState);

    const formOnSubmitHandler = (event) => {

        event.preventDefault();

        let error = false;

        const sousGroupe = state.sousGroupe.value;

        if(!sousGroupe) {
            error = true;
            setState(prevState => {
                return {
                    ...prevState,
                    groupe: {
                        value: null,
                        error : "Le sous groupe est obligatoire"
                    }
                }
            })
        }


        if(!error) {

        }

    };

    const handleGroupeSelect = (groupe) => {
        setState(prevState => {
            return {
                ...prevState,
                sousGroupe: {value : groupe, error : null }
            };
        });
    };

    const handleGroupeUnSelect = () => {
        setState(prevState => {
            return {
                ...prevState,
                sousGroupe: {value : null, error : null }
            };
        });
    };


    const isGroupeSelected = (groupeToCheck) => {
        return state.sousGroupe.value && state.sousGroupe.value.id === groupeToCheck.id;
    };


    return(
        <Modal
            open={props.open}
            onClose={props.handleClose}
            style={{overflowY:"auto"}}
        >
            <div style={{maxWidth:"700px", width:"100%"}} className={"bg-white text-center border rounded mx-auto my-5"}>

                <h2>Ajouter un sous groupe</h2>

                {props.ajouterSousGroupeState.error &&
                <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.assignementGroupeCategorie.error}</Alert>
                }
                {props.ajouterSousGroupeState.success&&
                <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'assignement ajouté avec succees</Alert>
                }


                <form className={"text-left p-4"} onSubmit={formOnSubmitHandler}>


                        <ExpansionPanel expanded={state.groupesExpanded} onChange={handleGroupesExpanded}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <FormControl error={state.sousGroupe.error ? true : false}>
                                <h6>Groupe : {!state.groupesExpanded && state.sousGroupe.value ? <Chip label={state.sousGroupe.value.nom} onDelete={handleGroupeUnSelect} /> : null}</h6>
                                    {state.sousGroupe.error && <FormHelperText>{state.sousGroupe.error}</FormHelperText>}
                                </FormControl>

                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails >
                                {props.listeGroupes.loading ?
                                    <CircularProgress size={50} thickness={4} />
                                    :
                                    <Grid container spacing={3}>

                                        <Grid item xs={12} md={8} >
                                            <span>Selectionner un groupe</span>

                                            <TreeListe fetchGroupeListeGroupes={props.fetchGroupeListeGroupes} selectable={"Groupe"} liste={props.listeGroupes.listeGroupes} onlyGroupes={true} handleSelect={handleGroupeSelect} handleUnSelect={handleGroupeUnSelect} isGroupeSelected={isGroupeSelected}/>

                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <span>Groupe Selectionné</span>
                                            <Box>
                                                {state.sousGroupe.value &&
                                                <Chip
                                                    label={state.sousGroupe.value.nom}
                                                    onDelete={handleGroupeUnSelect}
                                                />}
                                            </Box>
                                        </Grid>

                                    </Grid>
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>



                                    <div>
                                        <Button disabled={props.ajouterSousGroupeState.loading} type={"submit"} className={"my-2"}
                                                color={"primary"} variant={"contained"} endIcon={<PersonAddIcon/>}>Ajouter</Button>
                                        {props.ajouterSousGroupeState.loading && <CircularProgress size={24} /*className={spinnerClasses.join(" ")}*/ />}
                                    </div>

                </form>


            </div>
        </Modal>
    );




}

const mapStateToProps = (state) => {
    return {
        ajouterSousGroupeState : state.admin.utilisateurs.modifierGroupe.ajouterSousGroupe,
        listeGroupes : state.admin.utilisateurs.listeGroupes,
        listeCategories : state.admin.cours.listeCategories
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        ajouterSousGroupe : (groupeId,sousGroupeId) => dispatch(adminUtilisateursModifierGroupeAjouterSousGroupe(groupeId,sousGroupeId)),

        fetchListeGroupes : () => dispatch(adminUtilisateursFetchListeGroupes()),
        fetchGroupeListeGroupes : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeGroupes(groupeId)),

    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AjouterSousGroupeModal);