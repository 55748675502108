import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    utilisateur: null,

    changerMotDePasse : {
        success : false,
        loading : false,
        error: null
    },
};

const utilisateurModifierProfilStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        utilisateur: null
    });
};

const utilisateurModifierProfilSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        utilisateur: action.payload.utilisateur
    });
};

const utilisateurModifierProfilFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        utilisateur: null
    });
};

const utilisateurModifierProfilReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};








const utilisateurModifierProfilChangerMotDePasseStart = (state,action) => {
    return updateObject(state,{
        ...state,

        changerMotDePasse : {
            success : false,
            loading : true,
            error: null
        },

    });
};




const utilisateurModifierProfilChangerMotDePasseSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        changerMotDePasse : {
            success : true,
            loading : false,
            error: null
        },

    });
};

const utilisateurModifierProfilChangerMotDePasseFail = (state,action) => {
    return updateObject(state,{
        ...state,
        changerMotDePasse : {
            success : false,
            loading : false,
            error: action.payload.error
        },
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.UTILISATEUR_MODIFIER_PROFIL_START : return utilisateurModifierProfilStart(state,action);
        case actionTypes.UTILISATEUR_MODIFIER_PROFIL_SUCCESS : return utilisateurModifierProfilSuccess(state,action);
        case actionTypes.UTILISATEUR_MODIFIER_PROFIL_FAIL : return utilisateurModifierProfilFail(state,action);
        case actionTypes.UTILISATEUR_MODIFIER_PROFIL_RESET : return utilisateurModifierProfilReset(state,action);


        case actionTypes.UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_START: return utilisateurModifierProfilChangerMotDePasseStart(state,action);
        case actionTypes.UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_SUCCESS : return utilisateurModifierProfilChangerMotDePasseSuccess(state,action);
        case actionTypes.UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_FAIL : return utilisateurModifierProfilChangerMotDePasseFail(state,action);

        default : return state;
    }

}

export default reducer;