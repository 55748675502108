import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminUtilisateursAjouterGroupeSaga (action) {

    yield put(actions.adminUtilisateursAjouterGroupeStart());

    const groupe = action.payload.groupe;

    try {
        const response = yield axios.post(api.ajouterGroupe,groupe);
        yield put(actions.adminUtilisateursAjouterGroupeSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursAjouterGroupeFail(error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursAjouterGroupeFail("There's an error!"));
        }
    }
}

