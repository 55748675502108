import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* enseignantMesCoursFetchListeCoursSaga(action) {

    yield put(actions.enseignantMesCoursFetchListeCoursStart());

    const utilisateurId = action.payload.utilisateurId;

    try {
        const response = yield axios.get(generateDynamicRoute(api.enseignantMesCours,[{toReplace:":utilisateurId",value:utilisateurId}]));
        console.log(response.data);
        yield put(actions.enseignantMesCoursFetchListeCoursSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.enseignantMesCoursFetchListeCoursFail(error.response.data.error));
        }else {
            yield put(actions.enseignantMesCoursFetchListeCoursFail("There's an error!"));
        }
    }

}