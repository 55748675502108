import * as actionTypes from "../../../../actions/actionTypes";

export const adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorie = (assignementGroupeCategorie) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_INITIATE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE,
        payload : {
            assignementGroupeCategorie : assignementGroupeCategorie
        }
    }
};

export const adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieStart = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_START
    }
};

export const adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieReset = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_RESET
    }
};

export const adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieSuccess = (assignementGroupeCategorie) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_SUCCESS,
        payload : {
            assignementGroupeCategorie: assignementGroupeCategorie
        }
    }
};

export const adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieFail = (error) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_FAIL,
        payload : {
            error : error
        }
    }
};
