import React, {useEffect, useState} from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

import {Box} from "@material-ui/core";


import Button from '@material-ui/core/Button';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckBoxIcon from '@material-ui/icons/CheckBox';


import {Link, useHistory} from "react-router-dom";
import {
    AdminCoursAjouterCoursPage,
} from "../../../../routes/administratorRoutes";import BlockIcon from '@material-ui/icons/Block';



import {connect} from "react-redux";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import Backdrop from "@material-ui/core/Backdrop";
import {
    adminUtilisateursSupprimerGroupe,
    adminUtilisateursSupprimerGroupeReset
} from "../../../../store/actions/admin/utilisateurs/supprimerGroupe";
import Alert from "@material-ui/lab/Alert";
import {
    adminUtilisateursSupprimerUtilisateur,
    adminUtilisateursSupprimerUtilisateurReset
} from "../../../../store/actions/admin/utilisateurs/supprimerUtilisateur";
import {dateFormat, dateFormatWithHoursAndMinutes, generateDynamicRoute} from "../../../../utils/utils";
import SupprimerUtilisateurDialog from "../../../../components/SupprimerUtilisateurDialog";
import SupprimerGroupeDialog from "../../../../components/SupprimerGroupeDialog";
import TreeListe from "../../../../components/TreeListe";
import {
    adminCoursFetchCategorieListeCategories, adminCoursFetchCategorieListeCours,
    adminCoursFetchListeCategories
} from "../../../../store/actions/admin/cours/listeCategoriesActions";
import {adminCoursFetchListeCours} from "../../../../store/actions/admin/cours/listeCoursActions";
import SupprimerCategorieDialog from "../../../../components/SupprimerCategorieDialog";
import {
    adminCoursSupprimerCategorie,
    adminCoursSupprimerCategorieReset
} from "../../../../store/actions/admin/cours/supprimerCategorieActions";
import DeleteWithNoOption from "../../../../components/DeleteWithNoOption";
import {
    adminCommandesFetchListeCommandes,
    adminCommandesFetchListeCommandesReset
} from "../../../../store/actions/admin/commandes/listeCommandesActions";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import GavelOutlinedIcon from "@material-ui/icons/GavelOutlined";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {
    etudiantCommandesFetchListeCommandes,
    etudiantCommandesFetchListeCommandesReset
} from "../../../../store/actions/etudiant/commandes/listeCommandesActions";
import {etudiantCommandePage, StudentOrderPage} from "../../../../routes/studentRoutes";
import {
    technicianVideoRequestsFetchVideoRequestsList,
    technicianVideoRequestsFetchVideoRequestsListReset
} from "../../../../store/actions/technician/videoRequests/videoRequestsList";
import {technicianVideoRequestPage} from "../../../../routes/technicianRoutes";
import {technicianVideoRequestsTerminateVideoRequest} from "../../../../store/actions/technician/videoRequests/terminateVideoRequest";

function VideoRequestList(props) {



    useEffect(() => {
        props.fetchVideoRequests();
        return () => {
            props.resetFetchVideoRequests();
            props.resetTerminateVideoRequests();
        }
    },[]);


    useEffect(() => {
        if(props.terminateVideoRequestState.success)
            props.fetchVideoRequests();

    },[props.terminateVideoRequestState.success]);

    /*
        const handleVoirDetails = () => {
            if(selected.startsWith("Categorie")) {
                const categorieId = selected.replace("Categorie","");
                history.push(generateDynamicRoute(AdminCoursDetailsCategoriePage.path,[{toReplace:":categorieId",value: categorieId}]));
            } else if(selected.startsWith("Cours")) {
                const coursId = selected.replace("Cours","");
                history.push(generateDynamicRoute(AdminCoursDetailsCoursPage.path,[{toReplace:":coursId",value: coursId}]))
            }
        };

        const handleModifier = () => {
            if(selected.startsWith("Categorie")) {
                const categorieId = selected.replace("Categorie","");
                history.push(generateDynamicRoute(AdminCoursModifierCategoriePage.path,[{toReplace:":categorieId",value: categorieId}]));
            } else if(selected.startsWith("Cours")) {
                const coursId = selected.replace("Cours","");
                history.push(generateDynamicRoute(AdminCoursModifierCoursPage.path,[{toReplace:":coursId",value: coursId}]));
            }

        };

    */



    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const handleTerminateVideoRequest = (videoRequestId) => {
        props.terminateVideoRequest(videoRequestId);
    };




    return (
        <>
            <div className={"p-5"}>
                <Backdrop style={{zIndex : 999}} open={false}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <h1 className={"text-center mt-3 mb-4"}>Les Demandes Video</h1>

                {props.terminateVideoRequestState.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.supprimerCategorie.error}</Alert>
            }
            {props.terminateVideoRequestState.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Le demande a été terminée avec succees</Alert>
            }

            {/*props.supprimerCours.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.supprimerCours.error}</Alert>
            }
            {props.supprimerCours.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'Cours supprimé avec succees</Alert>
            */}


                <Box className={"border rounded"}>

                    {!props.videoRequestsList.loading &&
                    <div>


                        <Paper >
                            <TableContainer>
                                <Table aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Par</TableCell>
                                            <TableCell align="center">Chapitre</TableCell>
                                            <TableCell align="center">Video</TableCell>
                                            <TableCell align="center">Voir Détails</TableCell>
                                            <TableCell align="center">Terminer</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.videoRequestsList.videoRequestsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((videoRequest) => {
                                            return (
                                                <TableRow key={videoRequest.id}>
                                                    <TableCell align={"center"}>
                                                        {videoRequest.user.firstName}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {videoRequest.section}
                                                    </TableCell>

                                                    <TableCell align="center">{videoRequest.name}</TableCell>

                                                    <TableCell align={"center"}>
                                                        <Link to={generateDynamicRoute(technicianVideoRequestPage.path,[{toReplace:":videoRequestId",value:videoRequest.id}])}>
                                                            <Button
                                                                variant="contained"
                                                                color="default"
                                                                size="large"
                                                                startIcon={<VisibilityIcon />}
                                                            >
                                                                Voir
                                                            </Button>
                                                        </Link>
                                                    </TableCell>

                                                    <TableCell align="center">

                                                       <Button
                                                            onClick={() => handleTerminateVideoRequest(videoRequest.id)}
                                                            variant="contained"
                                                            color="primary"
                                                            size="large"
                                                            disabled={props.terminateVideoRequestState.loading}
                                                            startIcon={<CheckBoxIcon />}
                                                        >
                                                            Terminer
                                                        </Button>

                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={props.videoRequestsList.videoRequestsList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>

                    </div>
                    }
                </Box>
            </div>
        </>

    );


}

const mapStateToProps = (state) => {
    return {
        videoRequestsList  : state.technician.videoRequests.videoRequestsList,
        terminateVideoRequestState  : state.technician.videoRequests.terminateVideoRequest,
        userId : state.authSignIn.user.id
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVideoRequests : () => dispatch(technicianVideoRequestsFetchVideoRequestsList()),
        resetFetchVideoRequests : () => dispatch(technicianVideoRequestsFetchVideoRequestsListReset()),


        terminateVideoRequest : (videoRequestId) => dispatch(technicianVideoRequestsTerminateVideoRequest(videoRequestId)),
        resetTerminateVideoRequests : () => dispatch(technicianVideoRequestsFetchVideoRequestsListReset()),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(VideoRequestList);

