import GererUtilisateurs from "../containers/Admin/Utilisateurs/GererUtilisateurs";
import Utilisateurs from "../containers/Admin/Utilisateurs";
import AjouterGroupe from "../containers/Admin/Utilisateurs/AjouterGroupe";
import AjouterUtilisateur from "../containers/Admin/Utilisateurs/AjouterUtilisateur";
import DetailsGroupe from "../containers/Admin/Utilisateurs/DetailsGroupe";
import ModifierGroupe from "../containers/Admin/Utilisateurs/ModifierGroupe";
import AjouterAssignementGroupeCoursModal
    from "../containers/Admin/AjouterAssignementGroupeCoursModal";
import AjouterAssignementGroupeCategorieModal from "../containers/Admin/AjouterAssignementGroupeCategorieModal";
import DetailsUtilisateur from "../containers/Admin/Utilisateurs/DetailsUtilisateur";
import ModifierUtilisateur from "../containers/Admin/Utilisateurs/ModifierUtilisateur";
import AjouterAssignementUtilisateurCoursModal from "../containers/Admin/AjouterAssignementUtilisateurCoursModal";
import AjouterAssignementUtilisateurCategorieModal
    from "../containers/Admin/AjouterAssignementUtilisateurCategorieModal";
import GererCours from "../containers/Admin/Cours/GererCours";
import Cours from "../containers/Admin/Cours";
import AjouterCours from "../containers/Admin/Cours/AjouterCours";
import AjouterCategorie from "../containers/Admin/Cours/AjouterCategorie";
import Parametres from "../containers/Admin/Parametres";
import Offres from "../containers/Admin/Offres";
import GererOffres from "../containers/Admin/Offres/GererOffres";
import Commandes from "../containers/Admin/Commandes";
import GererCommandes from "../containers/Admin/Commandes/GererCommandes";


export const AdminUtilisateursAjouterGroupePage = {
    type: "route",
    path: "/utilisateurs/ajouterGroupe",
    component:AjouterGroupe,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};

export const AdminUtilisateursAjouterUtilisateurPage = {
    type: "route",
    path: "/utilisateurs/ajouterUtilisateur",
    label: "utilisateurs",
    icon:"fa fa-users",
    component:AjouterUtilisateur,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};


export const AdminUtilisateursDetailsGroupePage = {
    type: "route",
    path: "/utilisateurs/groupe/:groupeId",
    label: "utilisateurs",
    icon:"fa fa-users",
    component:DetailsGroupe,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};


export const AdminUtilisateursDetailsUtilisateurPage = {
    type: "route",
    path: "/utilisateurs/utilisateur/:utilisateurId",
    label: "utilisateurs",
    icon:"fa fa-users",
    component:DetailsUtilisateur,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};

export const AdminUtilisateursModifierGroupeAjouterAssignementGroupeCoursPage = {
    type: "route",
    path: "/utilisateurs/groupe/:groupeId/modifier/ajouterAssCours",
    component:AjouterAssignementGroupeCoursModal,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};

export const AdminUtilisateursModifierGroupeAjouterAssignementGroupeCategoriePage = {
    type: "route",
    path: "/utilisateurs/groupe/:groupeId/modifier/ajouterAssCategorie",
    label: "utilisateurs",
    icon:"fa fa-users",
    component:AjouterAssignementGroupeCategorieModal,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};


export const AdminUtilisateursModifierUtilisateurAjouterAssignementUtilisateurCoursPage = {
    type: "route",
    path: "/utilisateurs/utilisateur/:utilisateurId/modifier/ajouterAssCours",
    component:AjouterAssignementUtilisateurCoursModal,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};

export const AdminUtilisateursModifierUtilisateurAjouterAssignementUtilisateurCategoriePage = {
    type: "route",
    path: "/utilisateurs/utilisateur/:utilisateurId/modifier/ajouterAssCategorie",
    label: "utilisateurs",
    icon:"fa fa-users",
    component:AjouterAssignementUtilisateurCategorieModal,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};

export const AdminUtilisateursModifierGroupePage = {
    type: "route",
    path: "/utilisateurs/groupe/:groupeId/modifier",
    label: "utilisateurs",
    icon:"fa fa-users",
    component:ModifierGroupe,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
    routes : [
        AdminUtilisateursModifierGroupeAjouterAssignementGroupeCategoriePage,
        AdminUtilisateursModifierGroupeAjouterAssignementGroupeCoursPage
    ]
};

export const AdminUtilisateursModifierUtilisateurPage = {
    type: "route",
    path: "/utilisateurs/utilisateur/:utilisateurId/modifier",
    label: "utilisateurs",
    icon:"fa fa-users",
    component:ModifierUtilisateur,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
    routes : [
        AdminUtilisateursModifierUtilisateurAjouterAssignementUtilisateurCoursPage,
        AdminUtilisateursModifierUtilisateurAjouterAssignementUtilisateurCategoriePage
    ]
};









export const AdminCoursAjouterCoursPage = {
    type: "route",
    path: "/admin/cours/ajouterCours",
    component:AjouterCours,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};

export const AdminCoursAjouterCategoriePage = {
    type: "route",
    path: "/admin/cours/ajouterCategorie",
    component:AjouterCategorie,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};
export const AdminCoursDetailsCategoriePage = {
    type: "route",
    path: "/admin/cours/categorie/:categorieId",
    component:DetailsGroupe,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};


export const AdminCoursDetailsCoursPage = {
    type: "route",
    path: "/admin/cours/cours/:coursId",
    component:DetailsUtilisateur,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};

export const AdminCoursModifierCategorieAjouterAssignementUtilisateurCategoriePage= {
    type: "route",
    path: "/admin/cours/categorie/:categorieId/modifier/ajouterAssUtilisateur",
    component:AjouterAssignementGroupeCoursModal,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};

export const AdminCoursModifierCategorieAjouterAssignementGroupeCategoriePage = {
    type: "route",
    path: "/admin/cours/categorie/:categorieId/modifier/ajouterAssGroupe",
    label: "utilisateurs",
    icon:"fa fa-users",
    component:AjouterAssignementGroupeCategorieModal,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};


export const AdminCoursModifierCoursAjouterAssignementGroupeCoursPage= {
    type: "route",
    path: "/admin/cours/cours/:coursId/modifier/ajouterAssGroupe",
    component:AjouterAssignementUtilisateurCoursModal,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};

export const AdminCoursModifierCoursAjouterAssignementUtilisateurCoursPage = {
    type: "route",
    path: "/admin/cours/cours/:coursId/modifier/ajouterAssUtilisateur",
    component:AjouterAssignementUtilisateurCategorieModal,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
};



export const AdminCoursModifierCategoriePage = {
    type: "route",
    path: "/admin/cours/categorie/:categorieId/modifier",
    component:ModifierGroupe,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
    routes : [
        AdminCoursModifierCategorieAjouterAssignementUtilisateurCategoriePage,
        AdminCoursModifierCategorieAjouterAssignementGroupeCategoriePage
    ]
};



export const AdminCoursModifierCoursPage = {
    type: "route",
    path: "/admin/cours/cours/:coursId/modifier",
    component:ModifierUtilisateur,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
    routes : [
        AdminCoursModifierCoursAjouterAssignementUtilisateurCoursPage,
        AdminCoursModifierCoursAjouterAssignementGroupeCoursPage
    ]
};


export const AdminOffresPage = {
    type: "route",
    path: "/admin/offres",
    label: "Offres",
    icon: "fa fa-handshake",
    component:Offres,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
    routes : [
        AdminCoursAjouterCoursPage,
        AdminCoursAjouterCategoriePage,
        AdminCoursModifierCategoriePage,
        AdminCoursModifierCoursPage,
        AdminCoursDetailsCategoriePage,
        AdminCoursDetailsCoursPage,
        {
            type: "route",
            path: "/admin/offres",
            component:GererOffres,
            requireAuthentication:true,
            requiredRole:'ROLE_ADMINISTRATEUR',
        }
    ]
};


export const AdminCommandesPage = {
    type: "route",
    path: "/admin/commandes",
    label: "Commandes",
    icon: "fa fa-handshake",
    component:Commandes,
    requireAuthentication:true,
    requiredRole:'ROLE_ADMINISTRATEUR',
    routes : [
        AdminCoursAjouterCoursPage,
        AdminCoursAjouterCategoriePage,
        AdminCoursModifierCategoriePage,
        AdminCoursModifierCoursPage,
        AdminCoursDetailsCategoriePage,
        AdminCoursDetailsCoursPage,
        {
            type: "route",
            path: "/admin/commandes",
            component:GererCommandes,
            requireAuthentication:true,
            requiredRole:'ROLE_ADMINISTRATEUR',
        }
    ]
};




const routes = [
    {
        type:"divider",
        label: "Admin",
        requireAuthentication:true,
        requiredRole:'ROLE_ADMINISTRATEUR'
    },
    {
        type: "route",
        path: "/utilisateurs",
        label: "Utilisateurs",
        icon:"fa fa-users",
        component:Utilisateurs,
        requireAuthentication:true,
        requiredRole:'ROLE_ADMINISTRATEUR',
        routes : [
            AdminUtilisateursAjouterUtilisateurPage
            ,
            AdminUtilisateursAjouterGroupePage
            ,
            AdminUtilisateursModifierGroupePage,
            AdminUtilisateursModifierUtilisateurPage,

            AdminUtilisateursDetailsGroupePage,


            AdminUtilisateursDetailsUtilisateurPage,


            {
                type: "route",
                path: "/utilisateurs",
                label: "Users",
                icon:"fa fa-users",
                component:GererUtilisateurs,
                requireAuthentication:true,
                requiredRole:'ROLE_ADMINISTRATEUR',
            }
        ]
    },
    {
        type: "route",
        path: "/admin/cours",
        label: "Cours",
        icon: "fa fa-book",
        component:Cours,
        requireAuthentication:true,
        requiredRole:'ROLE_ADMINISTRATEUR',
        routes : [
            AdminCoursAjouterCoursPage,
            AdminCoursAjouterCategoriePage,
            AdminCoursModifierCategoriePage,
            AdminCoursModifierCoursPage,
            AdminCoursDetailsCategoriePage,
            AdminCoursDetailsCoursPage,
            {
                type: "route",
                path: "/admin/cours",
                component:GererCours,
                requireAuthentication:true,
                requiredRole:'ROLE_ADMINISTRATEUR',
            }
        ]
    },


    AdminOffresPage,
    AdminCommandesPage,



    {
        type: "route",
        path: "/teacher/courses",
        label: "Statistics",
        icon: "fa fa-chart-bar",
        requireAuthentication:true,
        requiredRole:'ROLE_ADMINISTRATEUR'
    },
    {
        type: "route",
        path: "/admin/parametres",
        label: "Parametres",
        component:Parametres,
        icon: "fa fa-cogs",
        requireAuthentication:true,
        requiredRole:'ROLE_ADMINISTRATEUR'
    }
];

export default routes;