import React, { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import fullLogo from "../../../assets/fullLogo.png";
import { connect } from "react-redux";
import { guestHomeFetchListeCoursSpecial } from "../../../store/actions/guest/home/listeCoursSpecialsActions";
import { guestHomeFetchListeCategoriesSpecial } from "../../../store/actions/guest/home/listeCategoriesSpecialsActions";
import HomeIntro from "../../../components/HomeIntro";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";

import CardCarousel from "../../../components/Home/CardCarousel";

import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import VideocamIcon from "@material-ui/icons/Videocam";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import CheckIcon from "@material-ui/icons/Check";
import cssclass from "./home.module.css";
import PageOne from "../../../components/Home/page1";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";

import number1 from "../../../assets/Students-bro.png";
import number2 from "../../../assets/Online learning-amico (1).png";
import number3 from "../../../assets/Learning-bro (1).png";
import number4 from "../../../assets/Webinar-pana.png";
import introVid from "../../../assets/video.mp4";
import feature1 from "../../../assets/feature1.png";
import feature2 from "../../../assets/Exams-rafiki.png";
import feature3 from "../../../assets/Graduation-pana.png";
import feature4 from "../../../assets/Exams-bro.png";
import feature5 from "../../../assets/Webinar-bro.png";
import feature6 from "../../../assets/Webinar-amico.png";

import { Icon } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

const tiers = [
  // {
  //     title: 'أساسي',
  //     price: '0',
  //     description: ['10 users included', '2 GB of storage', 'Help center access', 'Email support'],
  //     buttonText: 'Plus de details',
  //     buttonVariant: 'contained',
  //     classes: ['7eme', '8eme', '9eme'],
  //     timeline: [{icon : <VideoLibraryIcon/>,color:"primary",title:"Videos",subtitle:"Des videos enregistré"},
  //         {icon : <VideocamIcon/>,color:"secondary",title:"Séance directe",subtitle:"Des séance directe ",},
  //         {icon : <AssignmentIcon color={"primary"}/>,color:"primary",title:"Exercices",subtitle:"Des exercices corrigés" ,variant:"outlined"},
  //         {icon : <PictureAsPdfIcon/>,color:"secondary",title:"PDF",subtitle:"Des résumé sous forme PDF"},
  //         {icon : <CheckIcon/>,color:"primary",title:"Astuces",subtitle:"Des astuces"},
  //     ]
  // },
  // {
  //     title: 'Secondaire',
  //     price: '15',
  //     description: [
  //         '20 users included',
  //         '10 GB of storage',
  //         'Help center access',
  //         'Priority email support',
  //     ],
  //     buttonText: 'Plus de details',
  //     buttonVariant: 'contained',
  //     classes: ['1ere', '2eme', '3eme','Bac'],
  //     timeline: [{icon : <VideoLibraryIcon/>,color:"primary",title:"Videos",subtitle:"Des videos enregistré"},
  //         {icon : <VideocamIcon/>,color:"secondary",title:"Séance directe",subtitle:"Des séance directe ",},
  //         {icon : <AssignmentIcon color={"primary"}/>,color:"primary",title:"Exercices",subtitle:"Des exercices corrigés" ,variant:"outlined"},
  //         {icon : <PictureAsPdfIcon/>,color:"secondary",title:"PDF",subtitle:"Des résumé sous forme PDF"},
  //         {icon : <CheckIcon/>,color:"primary",title:"Astuces",subtitle:"Des astuces"},
  //     ]
  // },
  {
    title: "Universitaire",
    price: "30",
    description: [
      "50 users included",
      "30 GB of storage",
      "Help center access",
      "Phone & email support",
    ],
    buttonText: "Plus de details",
    buttonVariant: "contained",
    classes: ["LMD", "Prepa", "Ing", "..."],
    timeline: [
      {
        icon: <VideoLibraryIcon />,
        color: "primary",
        title: "Videos",
        subtitle: "Des videos enregistré",
      },
      {
        icon: <VideocamIcon />,
        color: "secondary",
        title: "Séance directe",
        subtitle: "Des séance directe ",
      },
      {
        icon: <AssignmentIcon color={"primary"} />,
        color: "primary",
        title: "Exercices",
        subtitle: "Des exercices corrigés",
        variant: "outlined",
      },
      {
        icon: <PictureAsPdfIcon />,
        color: "secondary",
        title: "PDF",
        subtitle: "Des résumé sous forme PDF",
      },
      {
        icon: <CheckIcon />,
        color: "primary",
        title: "Astuces",
        subtitle: "Des astuces",
      },
    ],
  },
];

const cards = [
  {
    name: "Mohamed Eladab",
    rank: "Rang: 8 MP",
    feedback:
      "السلام عليكم، أنا محمد الأدب رتبة 8 على المسوى الوطني شعبة رياضيات-فيزياء (mp) حبيت نقدم شكر خاص لprepaprofs ، الحق المنصة هذي كان عندها دور كبير في النجاح هذا و بتوفيق من الله و عاونتني بش نتحصل على الأعداد 15.33 في analyse و17.63 في algèbre, المنصة قدمتلي دعم كبير برشا في الرياضيات و سهلت عليا مهمة المراجعة برشا و مكنتني من فهم المواد بالباهي. الكور فاها ياسر مفصل و مقدم بطريقة تسهل الفهم و الTD و الexs classiques الي يمسو جميع نواحي الدرس، شكرا لprepaprofs❤️❤️",
  },
  {
    name: "Wajdi Kalthoum",
    rank: "Rang: 3 PT",
    feedback:
      "السلام عليكم3aslema nes lkol 👋🏾 ana Wajdi Kalthoum rang 3 techno avec 16.15 en Mathématiques , 9rit 3am keml fy plateforme PrepaProfs w la79i9a a7sen 7aja 3maltha sne ,soum fel motnewl w qualité mta3 prof mfmesh kifha netsawer w les sujets sont parfaitement proposé , bel7a9 t7eb t'excelli fel math 3lik w 3la Prepaprofs w inshallah benje7 nes lkol 🥰👋🏾",
  },
  {
    name: "Chaima Achour",
    rank: "Rang: 83 PC",
    feedback:
      "Bonjour Eni 3adyt concours 2024 section pc et j'ai eu note 16.06 math 7abyt nochkerkom 3l plateforme blha9 min a7sen el hajat Eli ynajem yaamlhom étudiant 2 eme prépa Yasser mnadhema cours bien organisés et bien détaillés même si la correction de TD est bien organisée",
  },
  {
    name: "Anas Aouini",
    rank: "Rang: 86 MP",
    feedback:
      "Salem Ena 3am sne 9rit bel platform te3kom, L7a9 el mawjoud fel proba 3aweni barsha ferevision te3i yaatikom saha",
  },
];
function Home(props) {
  const classes = useStyles();

  return (
    <div className={cssclass.body}>
      <Container component="main" className={"p-3 my-3 "}>
        <PageOne />

        <Box textAlign={"center"} className={cssclass.page2}>
          <h3>
            Travailler vite et bien avec les cours en ligne des{" "}
            <span>Prépaprofs</span>
          </h3>
          <div className={cssclass.video}>
            <video src={introVid} autoPlay loop muted />
          </div>

          <p>
            Prépa Profs est la première plateforme éducative en ligne{" "}
            <span>pour les étudiants universitaires</span> en Tunisie. Nous
            visons à offrir des ressources pédagogiques(Cours, TD, Examens... )
            sous forme de vidéos enregistrées et des cours interactifs en direct{" "}
          </p>
          <NavLink to={"/auth/signup"}>
            <Button className={cssclass.newAccountButton} variant={"contained"}>
              NOUS REJOINDRE
            </Button>
          </NavLink>
        </Box>

        <Grid
          container
          spacing={8}
          justify={"center"}
          style={{ marginBottom: "16px", marginTop: "16px" }}
        >
          <Grid
            item
            xs={12}
            md={12}
            justify={"center"}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Box
              textAlign={"center"}
              component={"h2"}
              className={cssclass.title}
            >
              La meilleure plateforme de soutien scolaire pour avoir{" "}
              <span> les meilleurs rangs</span>
            </Box>
          </Grid>
          <Grid
            textAlign={"center"}
            justify="center"
            item
            xs={12}
            md={6}
            rowSpacing={1}
          >
            <Box className={cssclass.numbersContainer}>
              <div className={cssclass.numbersImg}>
                <img src={number2} alt={"logo"} />
              </div>
              <div className={cssclass.numbers}>
                <h3>Chaque semaine</h3>
                <h4>Une seance en direct</h4>
              </div>
            </Box>
          </Grid>
          <Grid textAlign={"center"} justify="center" item xs={12} md={6}>
            <Box className={cssclass.numbersContainer}>
              <div className={cssclass.numbersImg}>
                <img src={number3} alt={"logo"} />
              </div>
              <div className={cssclass.numbers}>
                <h3>+1800</h3>
                <h4>Cours compatible avec les programmes officiels</h4>
              </div>
            </Box>
          </Grid>
          <Grid textAlign={"center"} justify="center" item xs={12} md={6}>
            <Box className={cssclass.numbersContainer}>
              <div className={cssclass.numbersImg}>
                <img src={number4} alt={"logo"} />
              </div>
              <div className={cssclass.numbers}>
                <h3>+500</h3>
                <h4>Heures de contenu pédagogique</h4>
              </div>
            </Box>
          </Grid>
          <Grid textAlign={"center"} justify="center" item xs={12} md={6}>
            <Box className={cssclass.numbersContainer}>
              <div className={cssclass.numbersImg}>
                <img src={number1} alt={"logo"} />
              </div>
              <div className={cssclass.numbers}>
                <h3>+2250</h3>
                <h4>Étudiants inscrits sur le site</h4>
              </div>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={8}
          style={{ marginTop: "16px", marginBottom: "16px" }}
        >
          <Grid
            item
            xs={12}
            md={12}
            justify={"center"}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Box
              textAlign={"center"}
              component={"h3"}
              className={cssclass.title}
            >
              Suivant une méthodologie <span>bien étudiée</span>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={cssclass.feature}>
              <div className={cssclass.featureImg}>
                <img src={feature1} alt={"logo"} />
              </div>
              <h4 className={cssclass.specialh4}>
                Vidéos de cours et de méthodologie
              </h4>
              <p>pour tout comprendre</p>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={cssclass.feature}>
              <div>
                <div className={cssclass.featureImg}>
                  <img src={feature4} alt={"logo"} />
                </div>
              </div>
              <h4>Exercices corrigés</h4>
              <p>pour préparer ses controles</p>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={cssclass.feature}>
              <div>
                <div className={cssclass.featureImg}>
                  <img src={feature3} alt={"logo"} />
                </div>
              </div>
              <h4>Des DS et Examens</h4>
              <p>pour réussir le concours</p>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={cssclass.feature}>
              <div>
                <div className={cssclass.featureImg}>
                  <img src={feature2} alt={"logo"} />
                </div>
              </div>
              <h4>Quiz et des fiches récap</h4>
              <p>pour s'auto évaluer</p>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={cssclass.feature}>
              <div>
                <div className={cssclass.featureImg}>
                  <img src={feature5} alt={"logo"} />
                </div>
              </div>
              <h4>Stages de vavances</h4>
              <p>pour se remettre en niveau</p>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={cssclass.feature}>
              <div>
                <div className={cssclass.featureImg}>
                  <img src={feature6} alt={"logo"} />
                </div>
              </div>
              <h4>Aide aux devoirs en visio</h4>
              <p>avec des professeurs excellents</p>
            </div>
          </Grid>
        </Grid>

        <div>
          <Box textAlign={"center"} component={"h3"} className={cssclass.title}>
            Avec le témoignage de <span>nos chères élèves</span>
          </Box>

          <div
            style={{
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <CardCarousel cards={cards} />
          </div>
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    parametres: {
      loading: state.parametres.loading,
      error: state.parametres.loading,
      parametres: state.parametres.parametres,
    },
    listeCoursSpecials: {
      loading: state.guest.home.listeCoursSpecials.loading,
      error: state.guest.home.listeCoursSpecials.error,
      listeCoursSpecials:
        state.guest.home.listeCoursSpecials.listeCoursSpecials,
    },
    listeCategoriesSpecials: {
      loading: state.guest.home.listeCategoriesSpecials.loading,
      error: state.guest.home.listeCategoriesSpecials.error,
      listeCategoriesSpecials:
        state.guest.home.listeCategoriesSpecials.listeCategoriesSpecials,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchListeCoursSpecials: () => dispatch(guestHomeFetchListeCoursSpecial()),
    fetchListeCategoriesSpecials: () =>
      dispatch(guestHomeFetchListeCategoriesSpecial()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
