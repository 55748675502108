import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    listeCours: []
};

const guestExplorerCoursFetchListeCoursStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        listeCours: []
    });
};

const guestExplorerCoursFetchListeCoursSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        listeCours: action.payload.listeCours
    });
};

const guestExplorerCoursFetchListeCoursFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        listeCours: []
    });
};

function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_LISTE_COURS_START : return guestExplorerCoursFetchListeCoursStart(state,action);
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_LISTE_COURS_SUCCESS : return guestExplorerCoursFetchListeCoursSuccess(state,action);
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_LISTE_COURS_FAIL : return guestExplorerCoursFetchListeCoursFail(state,action);

        default : return state;
    }

}

export default reducer;