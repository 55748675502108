



/**** ASSIGNEMENTS UTILISATEUR CATEGORIE *****/

export const ETUDIANT_INITIATE_FETCH_NOTES = 'ETUDIANT_INITIATE_FETCH_NOTES';
export const ETUDIANT_FETCH_NOTES_START = 'ETUDIANT_FETCH_NOTES_START';
export const ETUDIANT_FETCH_NOTES_SUCCESS = 'ETUDIANT_FETCH_NOTES_SUCCESS';
export const ETUDIANT_FETCH_NOTES_FAIL = 'ETUDIANT_FETCH_NOTES_FAIL';
export const ETUDIANT_FETCH_NOTES_RESET = 'ETUDIANT_FETCH_NOTES_RESET';




export const ETUDIANT_INITIATE_AJOUTER_NOTE = 'ETUDIANT_INITIATE_AJOUTER_NOTE';
export const ETUDIANT_AJOUTER_NOTE_START = 'ETUDIANT_AJOUTER_NOTE_START';
export const ETUDIANT_AJOUTER_NOTE_SUCCESS = 'ETUDIANT_AJOUTER_NOTE_SUCCESS';
export const ETUDIANT_AJOUTER_NOTE_FAIL = 'ETUDIANT_AJOUTER_NOTE_FAIL';
export const ETUDIANT_AJOUTER_NOTE_RESET = 'ETUDIANT_AJOUTER_NOTE_RESET';


export const ETUDIANT_INITIATE_SUPPRIMER_NOTE = 'ETUDIANT_INITIATE_SUPPRIMER_NOTE';
export const ETUDIANT_SUPPRIMER_NOTE_START = 'ETUDIANT_SUPPRIMER_NOTE_START';
export const ETUDIANT_SUPPRIMER_NOTE_SUCCESS = 'ETUDIANT_SUPPRIMER_NOTE_SUCCESS';
export const ETUDIANT_SUPPRIMER_NOTE_FAIL = 'ETUDIANT_SUPPRIMER_NOTE_FAIL';
export const ETUDIANT_SUPPRIMER_NOTE_RESET = 'ETUDIANT_SUPPRIMER_NOTE_RESET';




