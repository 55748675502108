import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Autocomplete from '@material-ui/lab/Autocomplete';

import Icon from "@material-ui/core/Icon";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InputIcon from '@material-ui/icons/Input';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


import DeleteIcon from '@material-ui/icons/Delete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import userPhotoPlaceholder from "../../../assets/user-photo-placeholder.png";

import {Grid} from "@material-ui/core";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import {connect} from "react-redux";

import Alert from "@material-ui/lab/Alert";


import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import {DropzoneDialogBase} from "material-ui-dropzone";
import {
    adminUtilisateursAjouterUtilisateur,
    adminUtilisateursAjouterUtilisateurReset
} from "../../../store/actions/admin/utilisateurs/ajouterUtilisateurActions";
import {
    adminCoursFetchCategorieListeCategories,
    adminCoursFetchCategorieListeCours,
    adminCoursFetchListeCategories,
    adminCoursFetchListeCours,
    adminCoursSupprimerCategorie,
    adminCoursSupprimerCategorieReset,
    adminCoursSupprimerCours,
    adminCoursSupprimerCoursReset,
    authSignUp,
    enseignantDemandeVideosAjouterDemandeReset,
    enseignantDemandeVideosAjouterDemandeVideo,
    enseignantMesCoursFetchListeCours, enseignantMesCoursFetchListeCoursReset
} from "../../../store/actions";
import {NavLink, Redirect} from "react-router-dom";
import {signInPage} from "../../../routes/authRoutes";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

const initialState = {
    course : {
        value: "",
        error: null
    },
    section : {
        value: "",
        error: null
    },
    subSection : {
        value: "",
        error: null
    },
    name : {
        value: "",
        error: null
    },
    tag : {
        value: "",
        error: null
    },
    description : {
        value: "",
        error: null
    },
    note : {
        value: "",
        error: null
    },
    video : null,


    fileName : {
        value: "",
        error: null
    },
    fileDescription : {
        value: "",
        error: null
    },

    file : null,

    videoFileRequests : [],
};










function VideoRequest(props) {


    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [fileObjects, setFileObjects] = React.useState([]);
    const [fileObjects2, setFileObjects2] = React.useState([]);
    const [state,setState] = useState(initialState);


    useEffect(() => {
        props.fetchListeCours(props.userId);


        return () => {
            props.resetDemandeAjouterVideo();
            props.resetListeCours();
        }

    },[]);


    useEffect(() => {

        setState(initialState);
        setFileObjects([]);

    },[props.success]);

    const handleAddFile = () => {
        const name = state.fileName.value;
        const description = state.fileDescription.value;
        const file = state.file;

        let requirementsError = false;
        if(!name) {
            setState(prevState => {
                return {
                    ...prevState,
                    fileName : {
                        ...prevState.nom,
                        error : "Le nom est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!file) {
            setState(prevState => {
                return {
                    ...prevState,
                    fileError : "Le fichier est obligatoire"
                }
            });
            requirementsError = true;
        }

        if (!requirementsError) {
            setState(prevState => {
                return {
                    ...prevState,
                    videoFileRequests: [...prevState.videoFileRequests,
                        {
                            name : name,
                            description : description,
                            file : file
                        }
                    ],
                    fileName: initialState.fileName,
                    fileDescription: initialState.fileDescription,
                    file: initialState.file,
                }
            });
            setFileObjects2([])
        }

    };


    /*** Form ***/

    const courseOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                course : {
                    value : value,
                    error: error
                }
            }
        });
    };


    const nameOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                name : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const descriptionOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                description : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const tagOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                tag : {
                    value : value,
                    error: error
                }
            }
        });
    };


    const sectionOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                section : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const subSectionOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                subSection : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const noteOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                note : {
                    value : value,
                    error: error
                }
            }
        });
    };



    const fileNameOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                fileName : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const fileDescriptionOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                fileDescription : {
                    value : value,
                    error: error
                }
            }
        });
    };



    const formOnSubmitHandler = (event) => {
        event.preventDefault();

        let requirementsError = false;

        const course = state.course.value;
        const section = state.section.value;
        const subSection = state.subSection.value;
        const name = state.name.value;
        const description = state.name.value;
        const tag = state.tag.value;
        const note = state.note.value;



        if(!name) {
            setState(prevState => {
                return {
                    ...prevState,
                    name : {
                        ...prevState.nom,
                        error : "Le nom est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!tag) {
            setState(prevState => {
                return {
                    ...prevState,
                    tag : {
                        ...prevState.prenom,
                        error : "Tag est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!requirementsError) {
            const videoRequest = {
                user : {
                    id : props.userId
                },
                course : {
                    id : course
                },
                name : name,
                description:  description,
                section: section,
                subSection: subSection,
                tag: tag,
                note: note
            };
            const video = state.video ? state.video.file : null;

            const videoFileRequests = [];
            const files = [];

            state.videoFileRequests.map(request => {
                files.push(request.file.file);
                let request2 = {...request};
                delete request2.file;
                videoFileRequests.push(request2);
            });

            props.demandeAjouterVideo(videoRequest,video,videoFileRequests,files);
        }

    };

    const removeVideoFileRequest = (key) => {
        setState(prevState => {
            return {
                ...prevState,
                videoFileRequests: prevState.videoFileRequests.filter((_,i) => key !== i)
            }
        });
    };

    /*** Form ***/

    const inputClasses = ["my-2"];
    return(
        <>
            <div className={"text-center border rounded p-3"}>
                <div>
                    <h2>Demande Ajout Video</h2>
                    {props.success &&
                    <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Votre demande a été bien recu</Alert>
                    }
                    {props.error &&
                    <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.error.message}</Alert>
                    }
                    <form onSubmit={formOnSubmitHandler}>
                        <Grid container spacing={3} className={"my-3"}>

                            <Grid item xs={12} className={"text-left"}>
                                    <InputLabel id="demo-simple-select-label">Matiere :</InputLabel>
                                    <Select
                                        className={"w-100"}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={state.course.value}
                                        onChange={courseOnChangeHandler}
                                    >
                                        {
                                            props.listeCours.listeCours.map(cours => {
                                                return <MenuItem value={cours.id}>{cours.fullTitle}</MenuItem>
                                            })
                                        }
                                    </Select>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField error={state.section.error ? true : false} helperText={state.section.error} className={inputClasses.join(" ")} onChange={sectionOnChangeHandler} value={state.section.value} fullWidth={true} size={"small"} label="Chapitre" variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField error={state.subSection.error ? true : false} helperText={state.subSection.error} className={inputClasses.join(" ")} onChange={subSectionOnChangeHandler} value={state.subSection.value} fullWidth={true} size={"small"} label="Sous Chapitre" variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField error={state.name.error ? true : false} helperText={state.name.error} className={inputClasses.join(" ")} onChange={nameOnChangeHandler} value={state.name.value} fullWidth={true} size={"small"} label="Nom" variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField error={state.tag.error ? true : false} helperText={state.tag.error} className={inputClasses.join(" ")} onChange={tagOnChangeHandler} value={state.tag.value} fullWidth={true} size={"small"}  label="Tag" variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField multiline={true} error={state.description.error ? true : false} helperText={state.description.error} className={inputClasses.join(" ")} onChange={descriptionOnChangeHandler} value={state.description.value} fullWidth={true} size={"small"} rows={4}  label="Description" variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField multiline={true} error={state.note.error ? true : false} helperText={state.note.error} className={inputClasses.join(" ")} onChange={noteOnChangeHandler} value={state.note.value} fullWidth={true} size={"small"} rows={4}  label="Remarque pour l'administration" variant="outlined" />
                            </Grid>

                            <Grid item xs={12} >

                                <div className={"d-flex align-items-center"}>

                                    <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                                        Choisir Video
                                    </Button><h6 className={"ml-3"}>{state.video ? state.video.file.name : ""}</h6>

                                </div>

                                <DropzoneDialogBase
                                    showAlerts={false}
                                    acceptedFiles={['video/*']}
                                    filesLimit={1}
                                    maxFileSize={100000000000}
                                    fileObjects={fileObjects}
                                    cancelButtonText={"annuler"}
                                    submitButtonText={"valider"}
                                    previewText={"Prev"}
                                    open={open}
                                    onAdd={newFileObjs => {
                                        setFileObjects([].concat(fileObjects, newFileObjs));
                                    }}
                                    onDelete={deleteFileObj => {
                                        setFileObjects([]);
                                    }}
                                    onClose={() => setOpen(false)}
                                    onSave={() => {
                                        setState(prevState => {
                                            return {
                                                ...prevState,
                                                video : fileObjects[0]
                                            }
                                        });
                                        setOpen(false);
                                    }}
                                    showPreviews={true}
                                    showFileNamesInPreview={true}
                                />

                            </Grid>

                        </Grid>

                        <Divider/>

                        <div className={"my-4 d-flex"}>
                            {

                                state.videoFileRequests.map((videoFileRequest,key) => {
                                        return (

                                            <Paper elevation={3} className={"p-4 text-left mx-4"}>
                                                <div className={"text-right"}>
                                                    <DeleteIcon color={"secondary"} onClick={() =>{removeVideoFileRequest(key)}} />
                                                </div>
                                                <div><b>Nom :</b> {videoFileRequest.name}</div>
                                                <div><b>Description :</b> {videoFileRequest.description.substr(0,20)}</div>
                                                <div><b>Nom original du fichier :</b>{videoFileRequest.file.file.name}</div>
                                            </Paper>)
                                    }

                                )

                            }
                        </div>



                        <Divider/>

                        <h4 className={"text-left my-4"}>Ajouter Fichier</h4>

                        <Grid item xs={12}>
                            <TextField error={state.fileName.error ? true : false} helperText={state.fileName.error} className={inputClasses.join(" ")} onChange={fileNameOnChangeHandler} value={state.fileName.value} fullWidth={true} size={"small"} label="Nom fichier" variant="outlined" />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField multiline={true} error={state.fileDescription.error ? true : false} helperText={state.fileDescription.error} className={inputClasses.join(" ")} onChange={fileDescriptionOnChangeHandler} value={state.fileDescription.value} fullWidth={true} size={"small"} rows={4}  label="Description du fichier" variant="outlined" />
                        </Grid>

                        <DropzoneDialogBase

                            showAlerts={false}
                            filesLimit={1}
                            fileObjects={fileObjects2}
                            maxFileSize={100000000000}
                            cancelButtonText={"annuler"}
                            submitButtonText={"valider"}
                            previewText={"Prev"}
                            open={open2}
                            onAdd={newFileObjs => {
                                setFileObjects2([].concat(fileObjects2, newFileObjs));
                            }}
                            onDelete={deleteFileObj => {
                                setFileObjects2([]);
                            }}
                            onClose={() => setOpen2(false)}
                            onSave={() => {
                                console.log(fileObjects2[0]);
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        file : fileObjects2[0]
                                    }
                                });
                                setOpen2(false);
                            }}
                            showPreviews={true}
                            showFileNamesInPreview={true}
                        />

                        <div className={"d-flex align-items-center"}>

                            <Button variant="contained" color="primary" onClick={() => setOpen2(true)}>
                                Choisir Fichier
                            </Button>
                            <h6 className={"ml-3"}>{state.file ? state.file.file.name : ""}</h6>

                        </div>
                        <div className={"text-left my-5"}>
                            <Button variant="contained" className={"text-white text-left"} style={{backgroundColor:"green"}} onClick={handleAddFile}>
                                Confirmer
                            </Button>
                        </div>

                        <Divider/>

                        <div className={"position-relative"}>
                            <Button disabled={props.loading} type={"submit"} className={inputClasses.join(" ")} color={"primary"} variant={"contained"} endIcon={<InputIcon />}>Envoyer la demande</Button>
                            {props.loading && <CircularProgress  size={24}/>}
                        </div>

                    </form>
                </div>
            </div>



        </>
    );


}

const mapStateToProps = (state) => {
    return {
        userId : state.authSignIn.user.id,
        ...state.enseignant.demandeVideos.ajouterDemandeVideo,
        listeCours : { ...state.enseignant.mesCours.listeCours }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

        demandeAjouterVideo : (videoRequest,video,videoFileRequests,files) => dispatch(enseignantDemandeVideosAjouterDemandeVideo(videoRequest,video,videoFileRequests,files)),
        resetDemandeAjouterVideo : () => dispatch(enseignantDemandeVideosAjouterDemandeReset()),

        fetchListeCours : (utilisateurId) => dispatch(enseignantMesCoursFetchListeCours(utilisateurId)),
        resetListeCours : () => dispatch(enseignantMesCoursFetchListeCoursReset())


    }

};

export default connect(mapStateToProps,mapDispatchToProps)(VideoRequest);



