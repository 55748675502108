import React from "react";
import { cssClasses } from "./exploreCourseContent.module.css";

function ExploreCourseContent(props) {
  const sectionsList = props.sections.map((section, index) => {
    return <li key={index}>{section.name}</li>;
  });
  return (
    <div>
      <ul>{sectionsList}</ul>
    </div>
  );
}

export default ExploreCourseContent;
