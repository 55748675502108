import ListeCours from "../containers/Enseignant/MesCours/ListeCours";
import MesCours from "../containers/Enseignant/MesCours";
import CoursPage from "../containers/Enseignant/MesCours/Cours";
import Commande from "../containers/Student/Orders/Order";
import Home from "../containers/Guest/Home2";
import DemandeVideo from "../containers/Enseignant/DemandeVideo";
import DemandeFichier from "../containers/Enseignant/DemandeFichier";
import AddVideo from "../containers/Technician/AddVideo";
import VideoRequestList from "../containers/Technician/VideoRequests/VideoRequestList";
import VideoRequests from "../containers/Technician/VideoRequests";
import VideoRequest from "../containers/Technician/VideoRequests/VideoRequest";



export const technicianAddVideoPage = {
    type: "route",
    path: "/technician/addVideo/",
    icon:"fa fa-book",
    label:"Ajouter Video",
    component:AddVideo,
    requiredRole:'ROLE_TECHNICIAN',
    requireAuthentication:true
};


export const technicianVideoRequestsListPage = {
    type: "route",
    path: "/technician/videoRequests/",
    icon:"fa fa-list",
    label:"Les demandes videos",
    component:VideoRequestList,
    requiredRole:'ROLE_TECHNICIAN',
    requireAuthentication:true
};



export const technicianVideoRequestPage = {
    type: "route",
    path: "/technician/videoRequests/:videoRequestId",
    icon:"fa fa-list",
    label:"Les demandes videos",
    component:VideoRequest,
    requiredRole:'ROLE_TECHNICIAN',
    requireAuthentication:true
};



export const technicianVideoRequestsPage = {
    type: "route",
    path: "/technician/videoRequests/",
    icon:"fa fa-list",
    label:"Les demandes videos",
    component:VideoRequests,
    requiredRole:'ROLE_TECHNICIAN',
    requireAuthentication:true,
    routes : [
        technicianVideoRequestPage,
        technicianVideoRequestsListPage,
    ]
};




const routes = [
    technicianVideoRequestsPage,
    technicianAddVideoPage,
];

export default routes;