import {put} from "redux-saga/effects";

import axios from "../../api/axios"

import * as actions from "../actions";
import * as api from "../../api";

export function* signInSaga(action) {

    yield put(actions.authSignInStart());
    const authData = {
        email : action.payload.email,
        password : action.payload.password
    };

    try {
        const response = yield axios.post(api.authSignIn,authData);

        console.log(response);

        const expirationDate = yield new Date(
            new Date().getTime() + response.data.expiresIn * 1000 + ( 60 * 15 )
        );
        yield localStorage.setItem("token", response.data.token);
        yield localStorage.setItem("expirationDate", expirationDate);
        yield localStorage.setItem("user", JSON.stringify(response.data.user));
        yield put(actions.authSignInSuccess(response.data.token,response.data.user));
        //yield put(actions.utilisateurFetchTopNotifcations(response.data.utilisateur.id));
    } catch (error) {
        if (error.response) {
            yield put(actions.authSignInFail(error.response.data));
        } else {
            yield put(actions.authSignInFail("There's an error!"));
        }
    }
}

export function* requestNewPasswordSaga(action) {

    yield put(actions.authRequestNewPasswordStart());
    const request = {
        email: action.payload.email,
    };

    try {
        const response = yield axios.post(api.authRequestPassword, request);


        const expirationDate = yield new Date(
            new Date().getTime() + response.data.expiresIn * 1000 + (60 * 15)
        );
        yield put(actions.authRequestNewPasswordSuccess());
    } catch (error) {
        if (error.response) {
            yield put(actions.authRequestNewPasswordFail(error.response.data));
        } else {
            yield put(actions.authRequestNewPasswordFail("There's an error!"));
        }
    }
}

export function* newPasswordSaga(action) {

    yield put(actions.authNewPasswordStart());
    const request = {
        email: action.payload.email,
        token: action.payload.token,
        newPassword: action.payload.newPassword,
    };

    try {
        const response = yield axios.post(api.authNewPassword, request);

        console.log(response);

        const expirationDate = yield new Date(
            new Date().getTime() + response.data.expiresIn * 1000 + (60 * 15)
        );
        yield localStorage.setItem("token", response.data.token);
        yield localStorage.setItem("expirationDate", expirationDate);
        yield localStorage.setItem("user", JSON.stringify(response.data.user));
        yield put(actions.authSignInSuccess(response.data.token, response.data.user));
        //yield put(actions.utilisateurFetchTopNotifcations(response.data.utilisateur.id));
    } catch (error) {
        if (error.response) {
            yield put(actions.authNewPasswordFail(error.response.data));
        } else {
            yield put(actions.authNewPasswordFail("There's an error!"));
        }
    }
}


export function* authSignUpSaga (action) {

    yield put(actions.authSignUpStart());

    const user = yield action.payload.user;

    const formData = yield new FormData();
    yield formData.append('user', new Blob([JSON.stringify(user)], {
        type: "application/json"
    }));

    try {
        yield axios.post(api.authSignUp,formData);
        yield put(actions.authSignUpSuccess());
    } catch (error) {
        if(error.response) {
            yield put(actions.authSignUpFail(error.response.data));
        }else {
            yield put(actions.authSignUpFail("There's an error!"));
        }
    }
}










export function* logoutSaga(action) {
    yield localStorage.removeItem("token");
    yield localStorage.removeItem("expirationDate");
    yield localStorage.removeItem("utilisateur");
    yield localStorage.removeItem("roles");
    yield put(actions.logoutSuccess());
}

export function* tryAutoSignInSaga(action) {
    const token = yield localStorage.getItem("token");
    if(!token) {
        yield put(actions.logout());
    } else {
        const expirationDate = yield new Date(
            localStorage.getItem("expirationDate")
        );
        if(expirationDate <= new Date()) {
            yield put(actions.logout());
        } else {
            const user = yield JSON.parse(localStorage.getItem("user"));
            yield put(actions.authSignInSuccess(token,user));
            //yield put(actions.utilisateurFetchTopNotifcations(utilisateur.id));
        }
    }
    yield put(actions.appLoadingAutoLoginComplete());
}
