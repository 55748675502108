import * as actionTypes from "../../../actionTypes";


export const adminUtilisateursSupprimerUtilisateurAuGroupe = (utilisateurId,groupeId) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_SUPPRIMER_UTILISATEUR_AU_GROUPE,
        payload : {
            utilisateurId : utilisateurId,
            groupeId : groupeId
        }
    }
};

export const adminUtilisateursSupprimerUtilisateurAuGroupeStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_START
    }
};

export const adminUtilisateursSupprimerUtilisateurAuGroupeSuccess = (utilisateurId,groupeId) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_SUCCESS,
        payload : {
            utilisateurId : utilisateurId,
            groupeId : groupeId
        }
    }
};

export const adminUtilisateursSupprimerUtilisateurAuGroupeFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_FAIL,
        payload : {
            error : error
        }
    }
};

export const adminUtilisateursSupprimerUtilisateurAuGroupeReset = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_RESET
    }
};
