import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieSaga (action) {

    yield put(actions.adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieStart());

    const assignementUtilisateurCategorie = action.payload.assignementUtilisateurCategorie;

    try {
        const response = yield axios.post(api.ajouterAssignementUtilisateurCategorie,assignementUtilisateurCategorie);
        yield put(actions.adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieFail(error.response.data.error));
        }else {
            yield put(actions.adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieFail("There's an error!"));
        }
    }
}

