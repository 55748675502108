import React, {useState} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";


import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const initialState = {
    supprimerSousGroupes : false,
    supprimerUtilisateurs : false,
}

function SupprimerGroupeDialog(props) {

    const [state,setState] = useState(initialState);

    const  handleChangeSousGroupes = (event) => {
        const checked = event.target.checked;
        setState(prevState => {
            return {
                ...prevState,
                supprimerSousGroupes: checked
            }
        })
    };

    const  handleChangeUtilisateurs = (event) => {
        const checked = event.target.checked;
        setState(prevState => {
            return {
                ...prevState,
                supprimerUtilisateurs: checked
            }
        })
    };

    return (<Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title" className={"text-center"}>Voulez vous vraiment supprimer le groupe ?</DialogTitle>
        <DialogContent>

            <div>
                <FormControlLabel
                    control={<Switch color={"primary"} checked={state.supprimerSousGroupes} onChange={handleChangeSousGroupes}/>}
                    label="supprimer les sous groupes!"
                />
            </div>

            <div>
                <FormControlLabel
                    control={<Switch color={"primary"} checked={state.supprimerUtilisateurs} onChange={handleChangeUtilisateurs}/>}
                    label="supprimer les utilisateurs!"
                />
            </div>


        </DialogContent>
        <DialogActions className={"justify-content-center"}>
            <Button onClick={props.onClose} variant={"contained"} color={"default"}>
                Annuler
            </Button>
            <Button onClick={() => props.supprimer(state.supprimerSousGroupes,state.supprimerUtilisateurs)} variant={"contained"} color={"secondary"}>
                Supprimer
            </Button>
        </DialogActions>
    </Dialog>);

}

export default SupprimerGroupeDialog;