import * as actionTypes from "./actionTypes";

export const openSideNav = () => {
    return {
        type: actionTypes.OPEN_SIDENAV
    }
};

export const closeSideNav = () => {
    return {
        type: actionTypes.CLOSE_SIDENAV
    }
};