import * as actionTypes from "./actionTypes";


export const authSignIn = (email, password) => {
    return {
        type: actionTypes.AUTH_INITIATE_SIGNIN,
        payload: {
            email: email,
            password: password
        }
    }
};

export const authRequestNewPassword = (email) => {
    return {
        type: actionTypes.AUTH_INITIATE_REQUEST_NEW_PASSWORD,
        payload: {
            email: email,
        }
    }
};

export const authRequestNewPasswordStart = () => {
    return {
        type: actionTypes.AUTH_REQUEST_NEW_PASSWORD_START,
    }
};

export const authRequestNewPasswordSuccess = () => {
    return {
        type: actionTypes.AUTH_REQUEST_NEW_PASSWORD_SUCCESS,
    }
};

export const authRequestNewPasswordFail = (error) => {
    return {
        type: actionTypes.AUTH_REQUEST_NEW_PASSWORD_FAIL,
        payload: {
            error: error
        }
    }
};


export const authNewPassword = (email, token, newPassword) => {
    return {
        type: actionTypes.AUTH_INITIATE_NEW_PASSWORD,
        payload: {
            email: email,
            token: token,
            newPassword: newPassword
        }
    }
};

export const authNewPasswordStart = () => {
    return {
        type: actionTypes.AUTH_NEW_PASSWORD_START,
    }
};

export const authNewPasswordFail = (error) => {
    return {
        type: actionTypes.AUTH_NEW_PASSWORD_FAIL,
        payload: {
            error: error
        }
    }
};


export const authSignInStart = () => {
    return {
        type: actionTypes.AUTH_SIGNIN_START,
    }
};

export const authSignInSuccess = (token, user) => {
    return {
        type: actionTypes.AUTH_SIGNIN_SUCCESS,
        payload: {
            token:token,
            user:user
        }
    }
};

export const authSignInFail = (error) => {
    return {
        type : actionTypes.AUTH_SIGNIN_FAIL,
        payload : {
            error : error
        }
    }
};






export const authSignUp = (user) => {
    return {
        type: actionTypes.AUTH_INITIATE_SIGNUP,
        payload : {
            user : user
        }
    }
};

export const authSignUpStart = () => {
    return {
        type : actionTypes.AUTH_SIGNUP_START,
    }
};

export const authSignUpSuccess = () => {
    return {
        type : actionTypes.AUTH_SIGNUP_SUCCESS
    }
};

export const authSignUpFail = (error) => {
    return {
        type : actionTypes.AUTH_SIGNUP_FAIL,
        payload : {
            error : error
        }
    }
};

export const authSignUpReset = (error) => {
    return {
        type : actionTypes.AUTH_SIGNUP_RESET
    }
};












export const logout = () => {
    return {
        type: actionTypes.AUTH_INITIATE_LOGOUT
    }
};

export const logoutSuccess = () => {
    return {
        type: actionTypes.AUTH_LOGOUT_SUCCESS
    }
};

export const tryAutoSignIn = () => {
    return {
        type: actionTypes.AUTH_TRY_AUTO_SIGNIN
    }
};

//TODO

export const authUpdateUtilisateur = (utilisateur) => {
    return {
        type : actionTypes.AUTH_UPDATE_UTILISATEUR,
        payload: {
            utilisateur : utilisateur
        }
    }
};