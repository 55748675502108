import * as actionTypes from "../../../../actions/actionTypes";

export const adminCoursAjouterCategorie = (categorie,photo) => {
    return {
        type: actionTypes.ADMIN_COURS_INITIATE_AJOUTER_CATEGORIE,
        payload : {
            categorie : categorie,
            photo : photo
        }
    }
};

export const adminCoursAjouterCategorieStart = () => {
    return {
        type: actionTypes.ADMIN_COURS_AJOUTER_CATEGORIE_START
    }
};

export const adminCoursAjouterCategorieSuccess = (categorie) => {
    return {
        type: actionTypes.ADMIN_COURS_AJOUTER_CATEGORIE_SUCCESS,
        payload : {
            categorie: categorie
        }
    }
};

export const adminCoursAjouterCategorieFail = (error) => {
    return {
        type: actionTypes.ADMIN_COURS_AJOUTER_CATEGORIE_FAIL,
        payload : {
            error : error
        }
    }
};
