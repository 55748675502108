import React from "react";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import {Link} from "react-router-dom";
import {dateFormat, generateDynamicRoute} from "../../utils/utils";
import {guestCoursPage} from "../../routes/guestRoutes";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";


import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {shoppingCartPage} from "../../routes/utilisateurRoutes";
import {CoursePage} from "../../routes/teacherRoutes";

function TeacherCourseItem(props) {


    const course = props.course;

    return (



        <Grid item xs={12} sm={6} md={4} lg={3}>


        <Card className={"position-relative"}>
            <CardMedia
                style={{
                    height:"140px",
                    backgroundSize: "contain"
                }}
                image={course.photo}
            />
            <CardContent className={"text-center"}>
                <h5>
                    {course.mediumTitle}
                </h5>
                <p>
                    {course.smallDescription}
                </p>
                <div className={"text-center"} >
                    <Link to={generateDynamicRoute(CoursePage.path,[{toReplace: ':coursId',value:course.id}])}>
                        <Button variant={"contained"} color="primary">
                            Suivre
                        </Button>
                    </Link>
                </div>
            </CardContent>
        </Card>


        </Grid>
    );

}

export default TeacherCourseItem;