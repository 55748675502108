import * as actionTypes from "../../../actionTypes";

export const guestExplorerOffresFetchListeOffres = () => {
    return {
        type: actionTypes.GUEST_EXPLORER_OFFRES_INITIATE_FETCH_LISTE_OFFRES,
    }
};

export const guestExplorerOffresFetchListeOffresStart = () => {
    return {
        type: actionTypes.GUEST_EXPLORER_OFFRES_FETCH_LISTE_OFFRES_START
    }
};

export const guestExplorerOffresFetchListeOffresSuccess = (listeOffres) => {
    return {
        type: actionTypes.GUEST_EXPLORER_OFFRES_FETCH_LISTE_OFFRES_SUCCESS,
        payload : {
            listeOffres: listeOffres
        }
    }
};

export const guestExplorerOffresFetchListeOffresFail = (error) => {
    return {
        type: actionTypes.GUEST_EXPLORER_OFFRES_FETCH_LISTE_OFFRES_FAIL,
        payload : {
            error : error
        }
    }
};