import * as actionTypes from "../actionTypes";

export const errorsThrowError404 = () => {
    return {
        type: actionTypes.ERRORS_THROW_ERROR_404
    }
};

export const errorsRemoveError404 = () => {
    return {
        type: actionTypes.ERRORS_REMOVE_ERROR_404
    }
};

export const errorsThrowError500 = () => {
    return {
        type: actionTypes.ERRORS_THROW_ERROR_500
    }
};

export const errorsRemoveError500 = () => {
    return {
        type: actionTypes.ERRORS_REMOVE_ERROR_500
    }
};