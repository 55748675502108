import * as actionTypes from "../../../../actions/actionTypes";

export const adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorie = (assignementGroupeCategorieId) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_INITIATE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE,
        payload : {
            assignementGroupeCategorieId : assignementGroupeCategorieId
        }
    }
};

export const adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieStart = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_START
    }
};

export const adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieReset = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_RESET
    }
};

export const adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieSuccess = (assignementGroupeCategorieId) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_SUCCESS,
        payload : {
            assignementGroupeCategorieId: assignementGroupeCategorieId
        }
    }
};

export const adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieFail = (error) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_FAIL,
        payload : {
            error : error
        }
    }
};
