import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./index.css";

import { BrowserRouter as Router } from "react-router-dom";

import { LastLocationProvider } from "react-router-last-location";

import { Provider } from "react-redux";

import { applyMiddleware, compose, createStore } from "redux";
import reducer from "./store/reducers";
import createSagaMiddleware from "redux-saga";
import watch from "./store/sagas";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(watch);

const app = (
  <React.StrictMode>
    <Provider store={store}>
      <Router basename={process.env.PUBLIC_URL}>
        <LastLocationProvider>
          <App />
        </LastLocationProvider>
      </Router>
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(
  app,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
