import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

import AddIcon from '@material-ui/icons/Add';

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import {CircularProgress} from "@material-ui/core";
import {Grid} from "@material-ui/core";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import {connect} from "react-redux";

import Alert from "@material-ui/lab/Alert";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";



import Chip from '@material-ui/core/Chip';
import Box from "@material-ui/core/Box";
import {
    adminUtilisateursAjouterGroupe, adminUtilisateursAjouterGroupeFetchGroupeListeGroupes,
    adminUtilisateursAjouterGroupeFetchListeGroupes
} from "../../../../store/actions/admin/utilisateurs/ajouterGroupeActions";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {findGroupes, generateDynamicRoute} from "../../../../utils/utils";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import {Link, Switch, useHistory, useParams} from "react-router-dom";
import {adminUtilisateursFetchGroupe} from "../../../../store/actions/admin/utilisateurs/groupeActions";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import AssignementsTable from "../../../../components/AssignementsTable";
import AjouterAssignementGroupeCoursModal from "../../AjouterAssignementGroupeCoursModal";
import CustomRoute from "../../../../hoc/CustomRoute";
import {
    AdminUtilisateursModifierGroupeAjouterAssignementGroupeCategoriePage,
    AdminUtilisateursModifierGroupeAjouterAssignementGroupeCoursPage
} from "../../../../routes/administratorRoutes";
import {ajouterAssignementGroupeCours} from "../../../../api";
import {adminAssignementsGroupeCoursSupprimerAssignementGroupeCours} from "../../../../store/actions/admin/assignementsGroupeCours/supprimerAssignementGroupeCoursActions";
import {adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorie} from "../../../../store/actions/admin/assignementsGroupeCategorie/supprimerAssignementGroupeCategorieActions";
import {
    adminUtilisateursModifierGroupe,
    adminUtilisateursModifierGroupeSupprimerSousGroupe
} from "../../../../store/actions/admin/utilisateurs/modifierGroupeActions";
import GroupesTable from "../../../../components/GroupesTable";
import UtilisateursTable from "../../../../components/UtilisateursTable";
import AjouterSousGroupeModal from "./AjouterSousGroupeModal";
import AjouterUtilisateur from "./AjouterUtilisateureModal";
import {adminUtilisateursSupprimerUtilisateurAuGroupe} from "../../../../store/actions/admin/utilisateurs/supprimerUtilisateurAuGroupeActions";
import TreeListe from "../../../../components/TreeListe";

const initialState = {
    nom : {
        value: "",
        error: null
    },
    groupeParent : null,


    utilisateurs : [],
    sousGroupes : [],
    assignementGroupeCours : [],
    assignementGroupeCategorie : [],


    expanded : [],
    ajouterSousGroupeModalOpen : false,
    ajouterUtilisateurModalOpen : false,
};

function ModifierGroupe(props) {


    const [state,setState] = useState(initialState);


    let { groupeId } = useParams();


    useEffect(() => {
        props.fetchGroupe(groupeId);
    },[groupeId]);


    const handleOpenAjouterSousGroupeModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                ajouterSousGroupeModalOpen : true
            }
        })
    };

    const handleCloseAjouterSousGroupeModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                ajouterSousGroupeModalOpen : false
            }
        })
    };

    const handleOpenAjouterUtilisateurModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                ajouterUtilisateurModalOpen : true
            }
        })
    };

    const handleCloseAjouterUtilisateurModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                ajouterUtilisateurModalOpen : false
            }
        })
    };



    const [groupesExpanded,setGroupesExpanded] = useState(false);

    const handleGroupesExpanded = (event,isExpanded) => {
        setGroupesExpanded(isExpanded);
    };

    const handleGroupeSelect = (groupe) => {
        setState(prevState => {
            return {
                ...prevState,
                groupeParent: groupe
            };
        });
    };

    const handleGroupeUnSelect = () => {
        setState(prevState => {
            return {
                ...prevState,
                groupeParent: null
            };
        });
    };

    const isGroupeSelected = (groupeToCheck) => {
        return state.groupeParent && state.groupeParent.id === groupeToCheck.id;
    };

    /*** Form ***/

    const nomOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                nom : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const formOnSubmitHandler = (event) => {
        event.preventDefault();
        let requirementsError = false;

        const nom = state.nom.value;

        if(!nom) {
            setState(prevState => {
                return {
                    ...prevState,
                    nom : {
                        ...prevState.nom,
                        error : "Le nom est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!requirementsError) {
            const groupe = {
                nom : state.nom.value,
                groupeParent:  state.groupeParent ? { id : state.groupeParent.id } : null
            };
            props.modifierGroupe(groupeId,groupe);
        }

    };

    /*** Form ***/



    const inputClasses = ["my-2"];



    useEffect(() => {
        props.fetchGroupe(groupeId);
        props.fetchListeGroupes();
        props.fetchListeUtilisateurs();
    },[]);

    useEffect(() => {
        if(props.groupe.groupe)
            setState(prevState => {
                return {
                    ...prevState,
                    nom : {
                        value: props.groupe.groupe.nom,
                        error: null
                    },
                    groupeParent : props.groupe.groupe.groupeParent,
                    utilisateurs : props.groupe.groupe.utilisateurs,
                    sousGroupes : props.groupe.groupe.sousGroupes,
                    assignementGroupeCategorie : props.groupe.groupe.assignementGroupeCategorie,
                    assignementGroupeCours : props.groupe.groupe.assignementGroupeCours

                }
            });
    },[props.groupe.groupe]);

    useEffect(() => {
        props.fetchListeGroupes();
    },[props.modifierGroupeState.success]);



    useEffect(() => {
        if(props.ajouterUtilisateurAuGroupeState.success && props.ajouterUtilisateurAuGroupeState.utilisateur) {
            setState(prevState => {
                const newState = {
                    ...prevState,
                    utilisateurs : [...prevState.utilisateurs,props.ajouterUtilisateurAuGroupeState.utilisateur]
                };
                return newState;
            })
        }
    },[props.ajouterUtilisateurAuGroupeState.success]);


    useEffect(() => {
        if(props.supprimerUtilisateurAuGroupeState.success && props.supprimerUtilisateurAuGroupeState.utilisateurId) {
            setState(prevState => {
                const newState = {
                    ...prevState,
                    utilisateurs :  prevState.utilisateurs.filter((utilisateur) => utilisateur.id != props.supprimerUtilisateurAuGroupeState.utilisateurId)
                };
                return newState;
            })
        }
    },[props.supprimerUtilisateurAuGroupeState.success]);



    useEffect(() => {
        if(props.ajouterSousGroupeState.success && props.ajouterSousGroupeState.sousGroupe) {
            props.fetchListeGroupes();
            setState(prevState => {
                const newState = {
                    ...prevState,
                    sousGroupes : [...prevState.sousGroupes,props.ajouterSousGroupeState.sousGroupe]
                };
                return newState;
            })
        }
    },[props.ajouterSousGroupeState.success]);


    useEffect(() => {
        if(props.supprimerSousGroupeState.success && props.supprimerSousGroupeState.sousGroupeId) {
            props.fetchListeGroupes();
            setState(prevState => {
                const newState = {
                    ...prevState,
                    sousGroupes :  prevState.sousGroupes.filter((utilisateur) => utilisateur.id != props.supprimerSousGroupeState.sousGroupeId)
                };
                return newState;
            })
        }
    },[props.supprimerSousGroupeState.success]);






    useEffect(() => {
        if(props.supprimerAssignementGroupeCategorieState.success && props.supprimerAssignementGroupeCategorieState.assignementGroupeCategorieId) {
            setState(prevState => {
                const newState = {
                    ...prevState,
                    assignementGroupeCategorie :  prevState.assignementGroupeCategorie.filter((assignementGroupeCategorie) => assignementGroupeCategorie.id != props.supprimerAssignementGroupeCategorieState.assignementGroupeCategorieId)
                };
                return newState;
            })
        }
    },[props.supprimerAssignementGroupeCategorieState.success]);


    useEffect(() => {
        if(props.supprimerAssignementGroupeCoursState.success && props.supprimerAssignementGroupeCoursState.assignementGroupeCoursId) {
            setState(prevState => {
                const newState = {
                    ...prevState,
                    assignementGroupeCours :  prevState.assignementGroupeCours.filter((assignementGroupeCours) => assignementGroupeCours.id != props.supprimerAssignementGroupeCoursState.assignementGroupeCoursId)
                };
                return newState;
            })
        }
    },[props.supprimerAssignementGroupeCoursState.success]);


    useEffect(() => {
        if(props.ajouterAssignementGroupeCategorieState.success && props.ajouterAssignementGroupeCategorieState.assignementGroupeCategorie) {
            setState(prevState => {
                const newState = {
                    ...prevState,
                    assignementGroupeCategorie : [...prevState.assignementGroupeCategorie,props.ajouterAssignementGroupeCategorieState.assignementGroupeCategorie]
                };
                return newState;
            })
        }
    },[props.ajouterAssignementGroupeCategorieState.success]);

    useEffect(() => {
        if(props.ajouterAssignementGroupeCoursState.success && props.ajouterAssignementGroupeCoursState.assignementGroupeCours) {
            setState(prevState => {
                const newState = {
                    ...prevState,
                    assignementGroupeCours : [...prevState.assignementGroupeCategorie,props.ajouterAssignementGroupeCoursState.assignementGroupeCours]
                };
                return newState;
            })
        }
    },[props.ajouterAssignementGroupeCoursState.success]);


    const handleSupprimerSousGroupe = (sousGroupe) => {

        props.supprimerSousGroupe(props.groupe.groupe.id,sousGroupe.id);

    };
    const handleSupprimerUtilisateurAuGroupe = (utilisateur) => {

        props.supprimerUtilisateurAuGroupe(utilisateur.id,props.groupe.groupe.id);

    };

    const handleSupprimerAssignementGroupeCours = (assignementGroupeCours) => {

        props.supprimerAssignementGroupeCours(assignementGroupeCours.id);

    };

    const handleSupprimerAssignementGroupeCategorie = (assignementGroupeCategorie) => {

        props.supprimerAssignementGroupeCategorie(assignementGroupeCategorie.id);

    };

    return(
<>
        <div className={"text-center border rounded p-3"}>

            <h2>Modifier Groupe</h2>
            {props.modifierGroupeState.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.error}</Alert>
            }
            {props.modifierGroupeState.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Le groupe {props.modifierGroupeState.groupe.nom} ajouté avec succees</Alert>
            }

            {props.supprimerUtilisateurAuGroupeState.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'utilisateur supprimé avec succees</Alert>
            }

            {props.supprimerAssignementGroupeCoursState.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'assignement supprimé avec succees</Alert>
            }

            {!props.groupe.loading && !props.groupe.error &&
            <form onSubmit={formOnSubmitHandler}>
                <Grid container spacing={3} className={"my-3 text-left"}>
                    <Grid item xs={12}>
                        <TextField error={state.nom.error ? true : false} helperText={state.nom.error}
                                   className={inputClasses.join(" ")} onChange={nomOnChangeHandler}
                                   value={state.nom.value} fullWidth={true} size={"small"} label="Nom"
                                   variant="outlined"/>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel
                            className={"text-center"} expanded={groupesExpanded} onChange={handleGroupesExpanded}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon/>}
                            >
                                <h6>Groupe Parent : {!groupesExpanded && state.groupeParent ?
                                    <Chip label={state.groupeParent.nom} onDelete={handleGroupeUnSelect}/> : null}</h6>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {props.listeGroupes.loading ?
                                    <CircularProgress size={50} thickness={4}/>
                                    :
                                    <Grid container spacing={3}>

                                        <Grid item xs={12} md={8}>
                                            <span>Selectionner un groupe</span>


                                            <TreeListe fetchGroupeListeGroupes={props.fetchGroupeListeGroupes} selectable={"Groupe"} liste={props.listeGroupes.listeGroupes} onlyGroupes={true} handleSelect={handleGroupeSelect} handleUnSelect={handleGroupeUnSelect} isGroupeSelected={isGroupeSelected}/>

                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <span>Groupe Selectionné</span>
                                            <Box>
                                                {state.groupeParent &&
                                                <Chip
                                                    label={state.groupeParent.nom}
                                                    onDelete={handleGroupeUnSelect}
                                                />}
                                            </Box>
                                        </Grid>

                                    </Grid>
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>



                </Grid>
                <div className={"position-relative"}>
                    <Button disabled={props.modifierGroupeState.loading} type={"submit"} className={inputClasses.join(" ")}
                            color={"primary"} variant={"contained"} endIcon={<PersonAddIcon/>}>Modifier</Button>
                    {props.modifierGroupeState.loading && <CircularProgress size={24} /*className={spinnerClasses.join(" ")}*/ />}
                </div>
            </form>
            }

        </div>



    {!props.groupe.loading && !props.groupe.error &&

        <>
        <div className={"text-center border rounded p-3 my-3"}>

            <Grid container>

                <Grid item xs={12} className={"my3"}>

                    <h4 className={"text-left"}><b>Sous Groupes : </b><IconButton>
                        <AddIcon onClick={handleOpenAjouterSousGroupeModal} color={"primary"} />
                    </IconButton></h4>
                    <GroupesTable groupes={state.sousGroupes} showSupprimerButton={true} supprimer={handleSupprimerSousGroupe} />

                </Grid>

                <Grid item xs={12} className={"my3"}>

                    <h4  className={"text-left"}><b>Utilisateurs : </b><IconButton onClick={handleOpenAjouterUtilisateurModal}>
                        <AddIcon color={"primary"} />
                    </IconButton></h4>
                    <UtilisateursTable utilisateurs={state.utilisateurs} showSupprimerButton={true} supprimer={handleSupprimerUtilisateurAuGroupe} />

                </Grid>

                <Grid item xs={12} className={"my3"}>

                    <h4 className={"text-left"}><b>Assignements Groupe Categories : </b><Link to={generateDynamicRoute(AdminUtilisateursModifierGroupeAjouterAssignementGroupeCategoriePage.path,[{toReplace:":groupeId",value:props.groupe.groupe.id}])} ><IconButton>
                        <AddIcon color={"primary"} />
                    </IconButton></Link></h4>
                    <AssignementsTable type={"GroupeCategorie"} supprimer={handleSupprimerAssignementGroupeCategorie} showSupprimerButton={true} assignements={state.assignementGroupeCategorie}/>

                </Grid>


                <Grid item xs={12} className={"my3"}>

                    <h4 className={"text-left"}><b>Assignements Groupe Cours : </b><Link to={generateDynamicRoute(AdminUtilisateursModifierGroupeAjouterAssignementGroupeCoursPage.path,[{toReplace:":groupeId",value:props.groupe.groupe.id}])} ><IconButton >
                        <AddIcon color={"primary"} />
                    </IconButton></Link></h4>
                    <AssignementsTable type={"GroupeCours"} supprimer={handleSupprimerAssignementGroupeCours} showSupprimerButton={true} assignements={state.assignementGroupeCours}/>

                </Grid>


            </Grid>
        </div>




        <AjouterSousGroupeModal groupe={props.groupe.groupe} open={state.ajouterSousGroupeModalOpen} handleClose={handleCloseAjouterSousGroupeModal} />

        <AjouterUtilisateur groupe={props.groupe.groupe} open={state.ajouterUtilisateurModalOpen} handleClose={handleCloseAjouterUtilisateurModal} />


    </>

    }





        <Switch>
            {props.routes.map(route => {
                return CustomRoute(route);
            })}
        </Switch>

    </>
    );

}

const mapStateToProps = (state) => {
    return {

        ajouterSousGroupeState : state.admin.utilisateurs.modifierGroupe.ajouterSousGroupe,
        supprimerSousGroupeState : state.admin.utilisateurs.modifierGroupe.supprimerSousGroupe,


        ajouterUtilisateurAuGroupeState : state.admin.utilisateurs.ajouterUtilisateurAuGroupe,
        supprimerUtilisateurAuGroupeState : state.admin.utilisateurs.supprimerUtilisateurAuGroupe,

        ajouterAssignementGroupeCoursState : state.admin.assignementsGroupeCours.ajouterAssignementGroupeCours,
        supprimerAssignementGroupeCoursState : state.admin.assignementsGroupeCours.supprimerAssignementGroupeCours,
        ajouterAssignementGroupeCategorieState : state.admin.assignementsGroupeCategorie.ajouterAssignementGroupeCategorie,
        supprimerAssignementGroupeCategorieState : state.admin.assignementsGroupeCategorie.supprimerAssignementGroupeCategorie,
        modifierGroupeState : state.admin.utilisateurs.modifierGroupe,
        groupe : state.admin.utilisateurs.groupe,
        listeGroupes : state.admin.utilisateurs.listeGroupes
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchGroupe : (groupeId) => dispatch(adminUtilisateursFetchGroupe(groupeId)),
        modifierGroupe : (groupeId,groupe) => dispatch(adminUtilisateursModifierGroupe(groupeId,groupe)),
        fetchListeGroupes : () => dispatch(adminUtilisateursFetchListeGroupes()),
        fetchListeUtilisateurs : () => dispatch(adminUtilisateursFetchListeUtilisateurs()),
        fetchGroupeListeGroupes : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeGroupes(groupeId)),
        fetchGroupeListeUtilisateurs : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeUtilisateurs(groupeId)),


        supprimerSousGroupe : (groupeId,sousGroupeId) => dispatch(adminUtilisateursModifierGroupeSupprimerSousGroupe(groupeId,sousGroupeId)),

        supprimerUtilisateurAuGroupe : (utilisateurId,groupeId) => dispatch(adminUtilisateursSupprimerUtilisateurAuGroupe(utilisateurId,groupeId)),

        supprimerAssignementGroupeCours : (assignementGroupeCoursId) => dispatch(adminAssignementsGroupeCoursSupprimerAssignementGroupeCours(assignementGroupeCoursId)),
        supprimerAssignementGroupeCategorie : (assignementGroupeCategorieId) => dispatch(adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorie(assignementGroupeCategorieId))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(ModifierGroupe);