import React, { useEffect } from "react";
import {
  etudiantMesCoursFetchListeCours,
  etudiantMesCoursFetchListeCoursReset,
} from "../../../../store/actions/etudiant/mesCours";
import { connect } from "react-redux";
import CoursListSkeleton from "../../../../UI/LoadingSkeleton/CoursListSkeleton";
import Grid from "@material-ui/core/Grid";
import StudentCourseItem from "../../../../components/StudentCourseItem";

import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { OffersPage } from "../../../../routes/studentRoutes";

function CoursesListe(props) {
  useEffect(() => {
    props.fetchListeCours(props.userId);
    return () => {
      props.resetListeCours();
    };
  }, []);

  const coursesList = props.coursesList.map((course) => {
    return <StudentCourseItem course={course} />;
  });

  const enrolledAlert = () => {
    var i = 0;
    while (i < props.coursesList.length) {
      if (props.coursesList[i].enrolled) return null;
      i++;
    }

    return (
      <>
        <Alert
          variant="filled"
          severity="error"
          action={
            <Link to={OffersPage.path}>
              <Button size="small" color="inherit">
                Consulter nos offres
              </Button>
            </Link>
          }
        >
          <div>
            <h6>Vous n'avez aucune inscription active, inscrivez vous!</h6>
          </div>
        </Alert>
      </>
    );
  };

  return (
    <div className={"pt-5 px-4"}>
      <h1 className={"text-center my-3"}>Mes Cours</h1>

      {props.loading && <CoursListSkeleton />}
      {!props.loading && !props.error && (
        <>
          {enrolledAlert()}

          <Grid container spacing={3} className={"my-5"}>
            {coursesList}
          </Grid>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.etudiant.mesCours.listeCours.loading,
    error: state.etudiant.mesCours.listeCours.error,
    coursesList: state.etudiant.mesCours.listeCours.listeCours,
    userId: state.authSignIn.user.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchListeCours: (userId) =>
      dispatch(etudiantMesCoursFetchListeCours(userId)),
    resetListeCours: () => dispatch(etudiantMesCoursFetchListeCoursReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoursesListe);
