import React from "react";
import {Redirect, Route, useHistory, useLocation} from "react-router-dom";
import {homePage} from "../../routes/guestRoutes";
import {signInPage, signUpPage} from "../../routes/authRoutes";
import {store} from "../../index";
import {useLastLocation} from "react-router-last-location";
import {StudentMyCoursesPage} from "../../routes/studentRoutes";

function CustomRoute(route) {

    const location = useLocation();
    const history = useHistory();
    //const { from } = location.state || { from: { pathname: homePage.path } };


    const lastLocation = useLastLocation();


    const state = store.getState();

    const isAuthenticated = state.authSignIn.token != null;
    const roles = ["ROLE_ENSEIGNANT"];
    const utilisateur = state.authSignIn.user;
    const error404 = state.errors.error404;

    if (route.type === "route") {
        if (route.requireAuthentication) {
            if (isAuthenticated) {
                return <Route path={route.path} render={props => (
                    // pass the sub-routes down to keep nesting
                    <route.component {...props} routes={route.routes}/>
                )}/>
            } else {
                return <Redirect push from={route.path} to={signInPage.path}/>
            }

        } else if (route.requireUnAuthentication) {

            if (!isAuthenticated) {
                return <Route path={route.path} render={props => (
                    // pass the sub-routes down to keep nesting
                    <route.component {...props} routes={route.routes}/>
                )}/>
            } else {

                return <Redirect push from={route.path} to={StudentMyCoursesPage.path}/>

                if(lastLocation) {
                    if(lastLocation.pathname == signInPage.path || lastLocation.pathname == signUpPage.path) {
                        return <Redirect push from={route.path} to={homePage.path}/>
                    } else {
                        return <Redirect push from={route.path} to={lastLocation.pathname}/>
                    }
                } else {
                    return <Redirect push from={route.path} to={homePage.path}/>
                }

            }

        } else {
            return <Route path={route.path} render={props => (
                // pass the sub-routes down to keep nesting
                <route.component {...props} routes={route.routes}/>
            )}/>
        }

    } else
        return null;
}


export default CustomRoute;