import {combineReducers} from "redux";

import utilisateursReducer from "./utilisateurs";
import coursReducer from "./cours";
import offresReducer from "./offres";
import commandesReducer from "./commandes";
import assignementsGroupeCoursReducer from "./assignementsGroupeCours";
import assignementsGroupeCategorieReducer from "./assignementsGroupeCategorie";
import assignementsUtilisateurCoursReducer from "./assignementsUtilisateurCours";
import assignementsUtilisateurCategorieReducer from "./assignementsUtilisateurCategorie";
import parametresReducer from "./parametres";


export default combineReducers({
    utilisateurs : utilisateursReducer,
    cours : coursReducer,
    offres : offresReducer,
    commandes : commandesReducer,
    parametres : parametresReducer,
    assignementsGroupeCours : assignementsGroupeCoursReducer,
    assignementsGroupeCategorie : assignementsGroupeCategorieReducer,
    assignementsUtilisateurCours : assignementsUtilisateurCoursReducer,
    assignementsUtilisateurCategorie : assignementsUtilisateurCategorieReducer,
});