import * as actionTypes from "../../../actionTypes";

export const guestExplorerCoursFetchListeCoursStart = () => {
  return {
    type: actionTypes.GUEST_EXPLORER_COURS_FETCH_LISTE_COURS_START,
  };
};

export const guestExplorerCoursFetchListeCoursSuccess = (listeCours) => {
  return {
    type: actionTypes.GUEST_EXPLORER_COURS_FETCH_LISTE_COURS_SUCCESS,
    payload: {
      listeCours: listeCours,
    },
  };
};

export const guestExplorerCoursFetchListeCoursFail = (error) => {
  return {
    type: actionTypes.GUEST_EXPLORER_COURS_FETCH_LISTE_COURS_FAIL,
    payload: {
      error: error,
    },
  };
};

export const guestExplorerCoursFetchListeCours = (category) => {
  return {
    type: actionTypes.GUEST_EXPLORER_COURS_INITIATE_FETCH_LISTE_COURS,
    payload: {
      category: category,
    },
  };
};
