import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"
import * as api from "../../../../../api";

import * as actions from "../../../../actions";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminCoursFetchListeCategoriesSaga(action) {


    yield put(actions.adminCoursFetchListeCategoriesStart());

    try {
        //const response = yield axios.get(api.getListeCategoriesRoot);
        const response = yield axios.get(api.guestGetCategories);

        const listeCategories = response.data;
        yield listeCategories.forEach(groupe => {
            groupe["type"] = "Categorie";
            groupe["content"] = {
                listeCategories : {
                    loading: true,
                    error: null,
                    listeCategories : []
                },
                listeCours : {
                    loading: true,
                    error: null,
                    listeCours : []
                }
            };
        });
        yield put(actions.adminCoursFetchListeCategoriesSuccess(listeCategories));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminCoursFetchListeCategoriesFail(error.response.data.error));
        }else {
            yield put(actions.adminCoursFetchListeCategoriesFail("There's an error!"));
        }
    }
}


export function* adminCoursFetchCategorieListeCategoriesSaga(action) {

    const categorieId = action.payload.categorieId;

    yield put(actions.adminCoursFetchCategorieListeCategoriesStart(categorieId));

    try {
        const response = yield axios.get(generateDynamicRoute(api.getCategorieSousCategories,[{toReplace : ":categorieId",value:categorieId}]));
        const listeCategories = response.data;
        yield listeCategories.forEach(groupe => {
            groupe["type"] = "Categorie";
            groupe["content"] = {
                listeCategories : {
                    loading: true,
                    error: null,
                    listeCategories : []
                },
                listeCours : {
                    loading: true,
                    error: null,
                    listeCours : []
                }
            };
        });
        yield put(actions.adminCoursFetchCategorieListeCategoriesSuccess(categorieId,listeCategories));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminCoursFetchCategorieListeCategoriesFail(categorieId,error.response.data.error));
        }else {
            yield put(actions.adminCoursFetchCategorieListeCategoriesFail(categorieId,"There's an error!"));
        }
    }
}

export function* adminCoursFetchCategorieListeCoursSaga(action) {

    const categorieId = action.payload.categorieId;

    yield put(actions.adminCoursFetchCategorieListeCoursStart(categorieId));

    try {
        const response = yield axios.get(generateDynamicRoute(api.getCategorieCours,[{toReplace : ":categorieId",value:categorieId}]));
        const listeCours = response.data;
        yield listeCours.forEach(utilisateur => {
            utilisateur["type"] = "Cours";
        });
        yield put(actions.adminCoursFetchCategorieListeCoursSuccess(categorieId,listeCours));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminCoursFetchCategorieListeCoursFail(categorieId,error.response.data.error));
        }else {
            yield put(actions.adminCoursFetchCategorieListeCoursFail(categorieId,"There's an error!"));
        }
    }
}
