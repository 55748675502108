import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminUtilisateursModifierGroupeSaga (action) {

    yield put(actions.adminUtilisateursModifierGroupeStart());

    const groupe = action.payload.groupe;
    const groupeId = action.payload.groupeId;

    try {
        const response = yield axios.put(generateDynamicRoute(api.modifierGroupe,[{toReplace:":groupeId",value:groupeId}]),groupe);
        yield put(actions.adminUtilisateursModifierGroupeSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursModifierGroupeFail(error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursModifierGroupeFail("There's an error!"));
        }
    }
}




export function* adminUtilisateursModifierGroupeAjouterSousGroupeSaga (action) {

    yield put(actions.adminUtilisateursModifierGroupeAjouterSousGroupeStart());

    const groupeId = action.payload.groupeId;
    const sousGroupeId = action.payload.sousGroupeId;

    try {
        const response = yield axios.put(generateDynamicRoute(api.ajouterSousGroupe,[{toReplace:":groupeId",value:groupeId},{toReplace:":sousGroupeId",value:sousGroupeId}]));
        yield put(actions.adminUtilisateursModifierGroupeAjouterSousGroupeSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursModifierGroupeAjouterSousGroupeFail(error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursModifierGroupeAjouterSousGroupeFail("There's an error!"));
        }
    }
}


export function* adminUtilisateursModifierGroupeSupprimerSousGroupeSaga (action) {

    yield put(actions.adminUtilisateursModifierGroupeSupprimerSousGroupeStart());

    const groupeId = action.payload.groupeId;
    const sousGroupeId = action.payload.sousGroupeId;

    try {
        const response = yield axios.delete(generateDynamicRoute(api.supprimerSousGroupe,[{toReplace:":groupeId",value:groupeId},{toReplace:":sousGroupeId",value:sousGroupeId}]));
        yield put(actions.adminUtilisateursModifierGroupeSupprimerSousGroupeSuccess(sousGroupeId));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursModifierGroupeSupprimerSousGroupeFail(error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursModifierGroupeSupprimerSousGroupeFail("There's an error!"));
        }
    }
}

