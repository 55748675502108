import * as actionTypes from "../../../actionTypes";

export const guestExplorerCoursFetchCours = (coursId) => {
    return {
        type : actionTypes.GUEST_EXPLORER_COURS_INITIATE_FETCH_COURS,
        payload : {
            coursId : coursId
        }
    }
};

export const guestExplorerCoursFetchCoursStart = () => {
    return {
        type : actionTypes.GUEST_EXPLORER_COURS_FETCH_COURS_START
    }
};

export const guestExplorerCoursFetchCoursSuccess = (cours) => {
    return {
        type : actionTypes.GUEST_EXPLORER_COURS_FETCH_COURS_SUCCESS,
        payload : {
            cours : cours
        }
    }
};

export const guestExplorerCoursFetchCoursFail = (error) => {
    return {
        type : actionTypes.GUEST_EXPLORER_COURS_FETCH_COURS_FAIL,
        payload : {
            error: error
        }
    }
};

export const guestExplorerCoursFetchCoursReset = () => {
    return {
        type : actionTypes.GUEST_EXPLORER_COURS_FETCH_COURS_RESET
    }
};

export * from "./listeRessources";