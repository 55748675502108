import {put} from "redux-saga/effects";
import axios from "../../../../../api/axios";
import * as api from "../../../../../api";

import * as actions from "../../../../actions";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminUtilisateursFetchUtilisateurSaga(action) {

    const utilisateurId = action.payload.utilisateurId;

    yield put(actions.adminUtilisateursFetchUtilisateurStart());

    try {
        const response = yield axios.get(generateDynamicRoute(api.getUtilisateur,[{toReplace: ":utilisateurId",value:utilisateurId}]));
        yield put(actions.adminUtilisateursFetchUtilisateurSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursFetchUtilisateurFail(error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursFetchUtilisateurFail("There's an error!"));
        }
    }
}