import React, {useEffect} from "react";
import ReactPlayer from "react-player";


function VideoPlayer(props) {

    const video = props.video;

    useEffect(() => {

        // eslint-disable-next-line no-undef
        new VdoPlayer({
            otp: video.otp,
            playbackInfo: video.playbackInfo,
            theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
            // the container can be any DOM element on website
            container: document.querySelector( "#vdoCipherPlayer" ),
        });

    },[]);


    return (
        <div>
            <div id={"vdoCipherPlayer"}>
            </div>
        </div>
    );

}

export default VideoPlayer;