import React from "react";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import PermMediaIcon from "@material-ui/icons/PermMedia";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import EventIcon from '@material-ui/icons/Event';


import IconButton from "@material-ui/core/IconButton";
import Badge from '@material-ui/core/Badge';
import {Link} from "react-router-dom";
import {coursPage} from "../../routes/teacherRoutes";
import {generateDynamicRoute} from "../../utils/utils";

import EditIcon from '@material-ui/icons/Edit';

function EnseignantCoursListItem(props) {

    const item = props.item;


    return (
        <ListItem className={"text-center text-md-left flex-column flex-md-row justify-content-between"}>
        <div className={"d-flex flex-column flex-md-row align-items-center"}>
            <img style={{height:"150px",width:"150px"}} src={item.photo} />
            <div className={"pl-3 py-3"}>
                <h2>{item.titre}</h2>
                <p>{item.descriptionCourte}</p>
            </div>
        </div>
        <div className={"d-flex"} style={{width:"150px"}}>
            <IconButton>
                <PermMediaIcon />
            </IconButton>
            <Link to={generateDynamicRoute(coursPage.path,[{toReplace: ':coursId',value:item.id}])}>
            <IconButton>
                <EditIcon />
            </IconButton>
            </Link>
        </div>

    </ListItem>);
}

export default EnseignantCoursListItem;