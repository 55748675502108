import * as actionTypes from "../../../../actions/actionTypes";

export const utilisateurCommandeCreerCommande= (orderArticles) => {
    return {
        type: actionTypes.UTILISATEUR_COMMANDE_INITIATE_CREER_COMMANDE,
        payload : {
            orderArticles : orderArticles,
        }
    }
};

export const utilisateurCommandeCreerCommandeStart = () => {
    return {
        type: actionTypes.UTILISATEUR_COMMANDE_CREER_COMMANDE_START
    }
};

export const utilisateurCommandeCreerCommandeSuccess = (order) => {
    return {
        type: actionTypes.UTILISATEUR_COMMANDE_CREER_COMMANDE_SUCCESS,
        payload : {
            order: order
        }
    }
};

export const utilisateurCommandeCreerCommandeFail = (error) => {
    return {
        type: actionTypes.UTILISATEUR_COMMANDE_CREER_COMMANDE_FAIL,
        payload : {
            error : error
        }
    }
};

export const utilisateurCommandeCreerCommandeReset = () => {
    return {
        type: actionTypes.UTILISATEUR_COMMANDE_CREER_COMMANDE_RESET
    }
};
