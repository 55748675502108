import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminCoursAjouterCoursSaga (action) {

    yield put(actions.adminCoursAjouterCoursStart());

    const cours = yield action.payload.cours;
    const photo = yield action.payload.photo;

    const formData = yield new FormData();
    yield formData.append('cours', new Blob([JSON.stringify(cours)], {
                                                type: "application/json"
                                            }));
    yield formData.append("photo",photo);

    try {
        const response = yield axios.post(api.ajouterCours,formData);
        yield put(actions.adminCoursAjouterCoursSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminCoursAjouterCoursFail(error.response.data.error));
        }else {
            yield put(actions.adminCoursAjouterCoursFail("There's an error!"));
        }
    }
}

