import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
        success : false,
        loading : false,
        error: null,
    utilisateurId : null,
    groupeId : null

};




const adminUtilisateursSupprimerUtilisateurAuGroupeStart = (state,action) => {
    return updateObject(state,{
        ...state,

            success : false,
            loading : true,
            error: null,

        utilisateurId : null,
        groupeId : null
    });
};

const adminUtilisateursSupprimerUtilisateurAuGroupeSuccess = (state,action) => {
    return updateObject(state,{
        ...state,

            success: true,
            loading: false,
            error: null,
        utilisateurId : action.payload.utilisateurId,
        groupeId : action.payload.groupeId

    });
};

const adminUtilisateursSupprimerUtilisateurAuGroupeFail = (state,action) => {
    return updateObject(state,{
        ...state,
            success: false,
            loading: false,
            error: action.payload.error,

        utilisateurId : null,
        groupeId : null

    });
};

const adminUtilisateursSupprimerUtilisateurAuGroupeReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {

        case actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_START : return adminUtilisateursSupprimerUtilisateurAuGroupeStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_SUCCESS : return adminUtilisateursSupprimerUtilisateurAuGroupeSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_FAIL : return adminUtilisateursSupprimerUtilisateurAuGroupeFail(state,action);
        case actionTypes.ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_RESET : return adminUtilisateursSupprimerUtilisateurAuGroupeReset(state,action);
        default : return state;
    }

}

export default reducer;