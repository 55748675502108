import * as actionTypes from "../../../../actions/actionTypes";

export const adminCoursSupprimerCategorie = (categorie,supprimerSousCategories,supprimerCours) => {
    return {
        type: actionTypes.ADMIN_COURS_INITIATE_SUPPRIMER_CATEGORIE,
        payload : {
            categorie : categorie,
            supprimerSousCategories : supprimerSousCategories,
            supprimerCours : supprimerCours
        }
    }
};

export const adminCoursSupprimerCategorieStart = () => {
    return {
        type: actionTypes.ADMIN_COURS_SUPPRIMER_CATEGORIE_START
    }
};

export const adminCoursSupprimerCategorieSuccess = () => {
    return {
        type: actionTypes.ADMIN_COURS_SUPPRIMER_CATEGORIE_SUCCESS
    }
};

export const adminCoursSupprimerCategorieFail = (error) => {
    return {
        type: actionTypes.ADMIN_COURS_SUPPRIMER_CATEGORIE_FAIL,
        payload : {
            error : error
        }
    }
};


export const adminCoursSupprimerCategorieReset = () => {
    return {
        type: actionTypes.ADMIN_COURS_SUPPRIMER_CATEGORIE_RESET
    }
};
