import * as actionTypes from "../../../../../actions/actionTypes";
import {updateObject} from "../../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    paiement: null
};

const etudiantCommandePayerCommandeStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        paiement: null
    });
};

const etudiantCommandePayerCommandeSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        paiement: action.payload.paiement
    });
};

const etudiantCommandePayerCommandeFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        paiement: null
    });
};

const etudiantCommandePayerCommandeReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ETUDIANT_COMMANDE_PAYER_COMMANDE_START : return etudiantCommandePayerCommandeStart(state,action);
        case actionTypes.ETUDIANT_COMMANDE_PAYER_COMMANDE_SUCCESS : return etudiantCommandePayerCommandeSuccess(state,action);
        case actionTypes.ETUDIANT_COMMANDE_PAYER_COMMANDE_FAIL : return etudiantCommandePayerCommandeFail(state,action);
        case actionTypes.ETUDIANT_COMMANDE_PAYER_COMMANDE_RESET : return etudiantCommandePayerCommandeReset(state,action);
        default : return state;
    }

}

export default reducer;