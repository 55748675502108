import React, {useEffect, useState} from "react";

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from "@material-ui/core/IconButton";


import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Typography from '@material-ui/core/Typography';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import DeleteIcon from '@material-ui/icons/Delete';
import Label from '@material-ui/icons/Label';
import {Box} from "@material-ui/core";


import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';


import {Link, useHistory} from "react-router-dom";
import UserList from "../../../../components/UsersList";
import {
    AdminUtilisateursAjouterGroupePage,
    AdminUtilisateursAjouterUtilisateurPage,
    AdminUtilisateursDetailsGroupePage,
    AdminUtilisateursDetailsUtilisateurPage,
    AdminUtilisateursModifierGroupePage, AdminUtilisateursModifierUtilisateurPage
} from "../../../../routes/administratorRoutes";


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



import {connect} from "react-redux";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import Backdrop from "@material-ui/core/Backdrop";
import {
    adminUtilisateursSupprimerGroupe,
    adminUtilisateursSupprimerGroupeReset
} from "../../../../store/actions/admin/utilisateurs/supprimerGroupe";
import Alert from "@material-ui/lab/Alert";
import {
    adminUtilisateursSupprimerUtilisateur,
    adminUtilisateursSupprimerUtilisateurReset
} from "../../../../store/actions/admin/utilisateurs/supprimerUtilisateur";
import {generateDynamicRoute} from "../../../../utils/utils";
import SupprimerUtilisateurDialog from "../../../../components/SupprimerUtilisateurDialog";
import SupprimerGroupeDialog from "../../../../components/SupprimerGroupeDialog";
import TreeListe from "../../../../components/TreeListe";

function GererUtilisateurs(props) {

    const [selected, setSelected] = React.useState();
    const [expanded,setExpanded] = React.useState([]);

    const [supprimerUtilisateurDialogOpen,setSupprimerUtilisateurDialogOpen] = useState(false);
    const [supprimerGroupeDialogOpen,setSupprimerGroupeDialogOpen] = useState(false);

    const handleOpenSupprimerUtilisateurDialog = () => {
        setSupprimerUtilisateurDialogOpen(true);
    };

    const handleCloseSupprimerUtilisateurDialog = () => {
        setSupprimerUtilisateurDialogOpen(false);
    };

    const handleOpenSupprimerGroupeDialog = () => {
        setSupprimerGroupeDialogOpen(true);
    };

    const handleCloseSupprimerGroupeDialog = () => {
        setSupprimerGroupeDialogOpen(false);
    };

    const handleOpenSupprimerDialog = () => {
        if(selected.startsWith("Utilisateur"))
            handleOpenSupprimerUtilisateurDialog();
        else if(selected.startsWith("Groupe"))
            handleOpenSupprimerGroupeDialog();
    };

    const handleCloseSupprimerDialog = () => {
        handleCloseSupprimerUtilisateurDialog();
        handleCloseSupprimerGroupeDialog();
    }


    const history = useHistory();



    const [activeButtons, setActiveButtons] = React.useState({
        addUtilisateurButtonActive :true,
        addGroupeButtonActive : true,
        editButtonActive : false,
        deleteButtonActive : false,
        viewButtonActive : false
    });


    useEffect(() => {

        if(props.supprimerGroupe.success || props.supprimerUtilisateur.success) {
            props.fetchListeGroupes();
            props.fetchListeUtilisateurs();
        }

    },[props.supprimerGroupe.success,props.supprimerUtilisateur.success])

    useEffect(() => {
        if(selected != null) {
            setActiveButtons(prevState => {
                return{
                    ...prevState,
                    editButtonActive : true,
                    deleteButtonActive : true,
                    viewButtonActive : true
                };
            });
        }
    },[selected]);

    const dispatchSelected = (selected) => {
        setSelected(selected);
    };


    useEffect(() => {
        props.fetchListeGroupes();
        props.fetchListeUtilisateurs();

        return () => {
            props.supprimerGroupeReset();
            props.supprimerUtilisateurReset();
        }

    },[]);

    const handleSupprimerUtilisateur = () => {

        props.supprimerGroupeReset();
        props.supprimerUtilisateurReset();

        if(selected.startsWith("Utilisateur")) {
            const utilisateurId = selected.replace("Utilisateur","");
            props.onSupprimerUtilisateur(utilisateurId);
        }

        handleCloseSupprimerDialog();
    };

    const handleSupprimerGroupe = (supprimerSousGroupes,supprimerUtilisateurs) => {

        props.supprimerGroupeReset();
        props.supprimerUtilisateurReset();

        if(selected.startsWith("Groupe")) {
            const groupeId = selected.replace("Groupe","");
            props.onSupprimerGroupe(groupeId,supprimerSousGroupes,supprimerUtilisateurs);
        }

        handleCloseSupprimerDialog();
    };


    const handleVoirDetails = () => {
        if(selected.startsWith("Groupe")) {
            const groupeId = selected.replace("Groupe","");
            history.push(generateDynamicRoute(AdminUtilisateursDetailsGroupePage.path,[{toReplace:":groupeId",value: groupeId}]));
        } else if(selected.startsWith("Utilisateur")) {
            const utilisateurId = selected.replace("Utilisateur","");
            history.push(generateDynamicRoute(AdminUtilisateursDetailsUtilisateurPage.path,[{toReplace:":utilisateurId",value: utilisateurId}]))
        }
    };

    const handleModifier = () => {
        if(selected.startsWith("Groupe")) {
            const groupeId = selected.replace("Groupe","");
            history.push(generateDynamicRoute(AdminUtilisateursModifierGroupePage.path,[{toReplace:":groupeId",value: groupeId}]));
        } else if(selected.startsWith("Utilisateur")) {
            const utilisateurId = selected.replace("Utilisateur","");
            history.push(generateDynamicRoute(AdminUtilisateursModifierUtilisateurPage.path,[{toReplace:":utilisateurId",value: utilisateurId}]));
        }

    };

    return (
        <>
            <Backdrop style={{zIndex : 999}} open={props.supprimerGroupe.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        <h1 className={"text-center"}>Gérer les utilisateurs</h1>

            {props.supprimerGroupe.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.supprimerGroupe.error}</Alert>
            }
            {props.supprimerGroupe.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Le groupe supprimé avec succees</Alert>
            }

            {props.supprimerUtilisateur.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.supprimerUtilisateur.error}</Alert>
            }
            {props.supprimerUtilisateur.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'Utilisateur supprimé avec succees</Alert>
            }


        <Box className={"border rounded"}>
            <Grid container className={"p-3"} alignItems="center" justify={"center"} spacing={3}>
                <Grid item >
                    <Link to={AdminUtilisateursAjouterUtilisateurPage.path} >
                        <IconButton disabled={!activeButtons.addUtilisateurButtonActive}>
                            <PersonAddIcon color={activeButtons.addUtilisateurButtonActive ? "primary" : "disabled"} />
                        </IconButton>
                    </Link>
                </Grid>
                <Grid item >
                    <Link to={AdminUtilisateursAjouterGroupePage.path}>
                        <IconButton disabled={!activeButtons.addGroupeButtonActive}>
                            <GroupAddIcon color={activeButtons.addGroupeButtonActive ? "primary" : "disabled"} />
                        </IconButton>
                    </Link>
                </Grid>
                <Grid item >
                    <IconButton onClick={handleModifier} disabled={!activeButtons.editButtonActive}>
                        <EditIcon style={{color :activeButtons.editButtonActive? "#ff9800" : null}} color={!activeButtons.editButtonActive ?"disabled" : ""} />
                    </IconButton>
                </Grid>
                <Grid item >
                    <IconButton onClick={handleVoirDetails} disabled={!activeButtons.viewButtonActive}>
                        <VisibilityIcon style={{color : activeButtons.viewButtonActive? "#4caf50" : null}} color={!activeButtons.viewButtonActive ?"disabled" : ""} />
                    </IconButton>
                </Grid>
                <Grid item >
                    <IconButton disabled={!activeButtons.deleteButtonActive} onClick={handleOpenSupprimerDialog}>
                        <DeleteForeverIcon color={activeButtons.deleteButtonActive ? "error" : "disabled"} />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />

            {!props.listeGroupes.loading && !props.listeUtilisateurs.loading &&
<div>
            <TreeListe dispatchSelected={dispatchSelected} fetchGroupeListeUtilisateurs={props.fetchGroupeListeUtilisateurs} fetchGroupeListeGroupes={props.fetchGroupeListeGroupes} liste={props.listeGroupes.listeGroupes.concat(props.listeUtilisateurs.listeUtilisateurs)} />


           </div>
            }

            <SupprimerUtilisateurDialog open={supprimerUtilisateurDialogOpen} onClose={handleCloseSupprimerUtilisateurDialog} supprimer={handleSupprimerUtilisateur}/>
            <SupprimerGroupeDialog open={supprimerGroupeDialogOpen} onClose={handleCloseSupprimerGroupeDialog} supprimer={handleSupprimerGroupe}/>


            {/*
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={"text-center"}>Voulez vous vraiment supprimer ?</DialogTitle>
                <DialogActions className={"justify-content-center"}>
                    <Button onClick={handleCloseDeleteDialog} variant={"contained"} color={"default"}>
                        Annuler
                    </Button>
                    <Button onClick={handleSupprimer} variant={"contained"} color={"secondary"}>
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>*/}


        </Box>
</>
    );


}

const mapStateToProps = (state) => {
    return {
        supprimerGroupe : {
            loading : state.admin.utilisateurs.supprimerGroupe.loading,
            error : state.admin.utilisateurs.supprimerGroupe.error,
            success : state.admin.utilisateurs.supprimerGroupe.success
        },
        supprimerUtilisateur : {
            loading : state.admin.utilisateurs.supprimerUtilisateur.loading,
            error : state.admin.utilisateurs.supprimerUtilisateur.error,
            success : state.admin.utilisateurs.supprimerUtilisateur.success
        },
        listeGroupes : {
            loading : state.admin.utilisateurs.listeGroupes.loading,
            error : state.admin.utilisateurs.listeGroupes.error,
            listeGroupes : state.admin.utilisateurs.listeGroupes.listeGroupes,
        },
        listeUtilisateurs : {
            loading : state.admin.utilisateurs.listeUtilisateurs.loading,
            error : state.admin.utilisateurs.listeUtilisateurs.error,
            listeUtilisateurs : state.admin.utilisateurs.listeUtilisateurs.listeUtilisateurs,
        }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        //addGroupe : (nom,groupeParent) => dispatch(adminUsersAddGroupe(nom,groupeParent)),
        fetchListeGroupes : () => dispatch(adminUtilisateursFetchListeGroupes()),
        fetchListeUtilisateurs : () => dispatch(adminUtilisateursFetchListeUtilisateurs()),
        fetchGroupeListeGroupes : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeGroupes(groupeId)),
        fetchGroupeListeUtilisateurs : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeUtilisateurs(groupeId)),

        onSupprimerGroupe : (groupeId,supprimerSousGroupes,supprimerUtilisateurs) => dispatch(adminUtilisateursSupprimerGroupe(groupeId,supprimerSousGroupes,supprimerUtilisateurs)),
        supprimerGroupeReset : () => dispatch(adminUtilisateursSupprimerGroupeReset()),
        onSupprimerUtilisateur : (utilisateurId) => dispatch(adminUtilisateursSupprimerUtilisateur(utilisateurId)),
        supprimerUtilisateurReset : () => dispatch(adminUtilisateursSupprimerUtilisateurReset())
        //resetListeGroupes : () => dispatch(etudiantMesCoursFetchListeCoursReset())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(GererUtilisateurs);

