import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Box, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import { guestExplorerOffresFetchListeOffres } from "../../../store/actions/guest/explorerOffres/listeOffres";
import Offer from "../../../components/Home/OffresPage/Offer";
import cssClasses from "./exploreOffers.module.css";
import Grid from "@material-ui/core/Grid";

function ExplorerOffres(props) {
  useEffect(() => {
    props.fetchListeOffres();
  }, []);
  const [category, setCategory] = React.useState("MP1");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (category) => {
    setAnchorEl(null);
  };
  const menuWidth = anchorEl ? anchorEl.offsetWidth : "auto";

  return (
    <div className={cssClasses.container}>
      <h1>Explorer les offres</h1>
      <p>
        Bénéficiez du meilleur contenu pédagogique et d’un accompagnement
        continu tout au long de l’année scolaire grâce à nos différentes offres.
      </p>
      <Box>
        <ul className={cssClasses.menu}>
          <li>
            <h5
              onClick={handleMenu}
              className={`${cssClasses.menuItem} ${
                category !== "1ere année" && category !== "1ere année MPI"
                  ? cssClasses.selected
                  : ""
              }`}
            >
              Préparatoire
            </h5>

            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              getContentAnchorEl={null}
              style={{ width: menuWidth }}
            >
              <MenuItem
                onClick={() => {
                  setCategory("MP1");
                  handleClose();
                }}
              >
                <h5 className={cssClasses.listItem}>MP1</h5>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setCategory("MP2");
                  handleClose();
                }}
              >
                <h5 className={cssClasses.listItem}>MP2</h5>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setCategory("PC1");
                  handleClose();
                }}
              >
                <h5 className={cssClasses.listItem}>PC1</h5>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setCategory("PC2");
                  handleClose();
                }}
              >
                <h5 className={cssClasses.listItem}>PC2</h5>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setCategory("PT1");
                  handleClose();
                }}
              >
                <h5 className={cssClasses.listItem}>PT1</h5>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setCategory("PT2");
                  handleClose();
                }}
              >
                <h5 className={cssClasses.listItem}>PT2</h5>
              </MenuItem>
            </Menu>
          </li>
          <li>
            <h5
              className={`${cssClasses.menuItem} ${
                category === "1ere année MPI" ? cssClasses.selected : ""
              }`}
              onClick={() => {
                setCategory("1ere année MPI");
              }}
            >
              Préparatoire Intégré
            </h5>
          </li>
          <li>
            <h5
              className={`${cssClasses.menuItem} ${
                category === "1ere année" ? cssClasses.selected : ""
              }`}
              onClick={() => {
                setCategory("1ere année");
              }}
            >
              LMD
            </h5>
          </li>
        </ul>
      </Box>
      <Grid container spacing={3} className={"my-5"} justifyContent="center">
        {OffersList(props, category)}
      </Grid>
    </div>
  );
}

function OffersList(props, category) {
  const listeOffres = props.listeOffres.listeOffres.map((offer) => {
    if (offer.active && offer.category === category) {
      return <Offer offer={offer} isloggedIn={false} />;
    }
  });
  return listeOffres;
}

const mapStateToProps = (state) => {
  return {
    listeOffres: { ...state.guest.explorerOffres.listeOffres },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchListeOffres: () => dispatch(guestExplorerOffresFetchListeOffres()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExplorerOffres);
