import React, { useEffect } from "react";

import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Badge from "@material-ui/core/Badge";
import Grow from "@material-ui/core/Grow";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { Link } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import Popper from "@material-ui/core/Popper";

import IconButton from "@material-ui/core/IconButton";
//import {adminUtilisateursAjouterUtilisateur} from "../../../store/actions/admin/utilisateurs/ajouterUtilisateurActions";
//import {adminUtilisateursFetchListeUtilisateurs} from "../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import {
  OffersPage,
  profilePage,
  studentCartPage,
} from "../../../routes/studentRoutes";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import MenuIcon from "@material-ui/icons/Menu";

import cssClasses from "./topheader.module.css";

function TopHeader(props) {
  const { handleDrawerToggle } = props;

  const handleToggle = () => {
    setOpen4((prevOpen) => !prevOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [open4, setOpen4] = React.useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const anchorRef = React.useRef(null);

  const handleClose4 = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen4(false);
  };

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open4 === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open4;
  }, [open4]);

  return (
    props.isAuthenticated && (
      <div>
        <AppBar position="fixed" color="white">
          <Toolbar className={"d-flex justify-content-between"}>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={cssClasses.menuButton}
            >
              <MenuIcon />
            </IconButton>

            <div className={"d-flex position-absolute"} style={{ right: 10 }}>
              <>
                {props.cartArticles.length > 0 ? (
                  <Link to={studentCartPage.path}>
                    <IconButton>
                      <Badge
                        badgeContent={props.cartArticles.length}
                        color="secondary"
                      >
                        <ShoppingCartOutlinedIcon color="primary" />
                      </Badge>
                    </IconButton>
                  </Link>
                ) : (
                  <>
                    <IconButton
                      ref={anchorRef}
                      aria-controls={open4 ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                    >
                      <ShoppingCartOutlinedIcon color="primary" />
                    </IconButton>

                    <Popper
                      open={open4}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose4}>
                              <MenuList
                                autoFocusItem={open4}
                                className={"text-center"}
                                id="menu-list-grow"
                              >
                                <MenuItem
                                  className={"d-block text-center"}
                                  disabled={true}
                                >
                                  Votre carte est vide
                                </MenuItem>
                                <MenuItem className={"text-center"}>
                                  <Link to={OffersPage.path}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size={"small"}
                                    >
                                      Explorer nos offres
                                    </Button>
                                  </Link>
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                )}

                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <Icon className={"fa fa-user-circle"} color="primary" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <h5 className={"my-3"}>
                      <b>
                        {props.user.firstName} {props.user.lastName}
                      </b>
                    </h5>
                  </MenuItem>
                  <Link to={profilePage.path}>
                    <MenuItem onClick={handleClose}>Profil</MenuItem>
                  </Link>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.onLogout();
                    }}
                  >
                    Se déconnecter
                  </MenuItem>
                </Menu>
              </>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    )
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authSignIn.token != null,
    user: state.authSignIn.user,
    token: state.authSignIn.token,
    cartArticles: state.utilisateur.cart.articles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSideNavOpen: () => dispatch(actions.openSideNav()),

    onLogout: () => dispatch(actions.logout()),
    addNotification: (notification) =>
      dispatch(actions.UtilisateurAddTopNotificaiton(notification)),

    //addRole : (role) => dispatch(authActions.addRole(role))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
