import * as actionTypes from "../../../../actions/actionTypes";

export const adminParametresModifierParametres = (parametres) => {
    return {
        type: actionTypes.ADMIN_PARAMETRES_INITIATE_MODIFIER_PARAMETRES,
        payload : {
            parametres : parametres
        }
    }
};

export const adminParametresModifierParametresStart = () => {
    return {
        type: actionTypes.ADMIN_PARAMETRES_MODIFIER_PARAMETRES_START
    }
};

export const adminParametresModifierParametresSuccess = (parametres) => {
    return {
        type: actionTypes.ADMIN_PARAMETRES_MODIFIER_PARAMETRES_SUCCESS,
        payload : {
            parametres: parametres
        }
    }
};

export const adminParametresModifierParametresFail = (error) => {
    return {
        type: actionTypes.ADMIN_PARAMETRES_MODIFIER_PARAMETRES_FAIL,
        payload : {
            error : error
        }
    }
};


export const adminParametresModifierParametresReset = () => {
    return {
        type: actionTypes.ADMIN_PARAMETRES_MODIFIER_PARAMETRES_RESET
    }
};


