import React, {useEffect} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import {
    adminUtilisateursSupprimerGroupe,
    adminUtilisateursSupprimerGroupeReset
} from "../../../../store/actions/admin/utilisateurs/supprimerGroupe";
import {
    adminUtilisateursSupprimerUtilisateur,
    adminUtilisateursSupprimerUtilisateurReset
} from "../../../../store/actions/admin/utilisateurs/supprimerUtilisateur";
import {connect} from "react-redux";
import {adminUtilisateursFetchGroupe} from "../../../../store/actions/admin/utilisateurs/groupeActions";
import {Grid} from "@material-ui/core";


import BlockIcon from '@material-ui/icons/Block';

import MaterialTable from 'material-table';

import MUIDataTable from "mui-datatables";



import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';



import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import UserList from "../../../../components/UsersList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import GroupIcon from "@material-ui/icons/Group";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PersonIcon from "@material-ui/icons/Person";
import AssignementsTable from "../../../../components/AssignementsTable";
import UtilisateursTable from "../../../../components/UtilisateursTable";
import GroupesTable from "../../../../components/GroupesTable";
import {generateDynamicRoute} from "../../../../utils/utils";
import {
    AdminUtilisateursModifierGroupeAjouterAssignementGroupeCoursPage,
    AdminUtilisateursModifierGroupePage, AdminUtilisateursModifierUtilisateurPage
} from "../../../../routes/administratorRoutes";
import AddIcon from "@material-ui/icons/Add";
import {adminUtilisateursFetchUtilisateur} from "../../../../store/actions/admin/utilisateurs/utilisateurActions";

function DetailsUtilisateur(props) {

    let { utilisateurId } = useParams();


    useEffect(() => {
        props.fetchUtilisateur(utilisateurId);
    },[utilisateurId]);


    return(

        <div className={"text-center border rounded p-3"}>

        <h2>Details Utilisateur
            <Link to={generateDynamicRoute(AdminUtilisateursModifierUtilisateurPage.path,[{toReplace:":utilisateurId",value:utilisateurId}])}>
            <IconButton >
                <EditIcon style={{color :"#ff9800"}} />
            </IconButton>
            </Link>
        </h2>

        {!props.loading ?
                props.error === null && props.utilisateur != null ?
            <div>
        <Grid container spacing={3} className={"my-3"}>
            <Grid item xs={12}>
                <TextField className={"my-2"} value={props.utilisateur.nom} disabled={true} fullWidth={true} size={"small"} label="Nom" variant="outlined" />
            </Grid>
        </Grid>

                <div className={"text-left"}>
                    <div >
                        <h5 className={"my-4"} ><b>Groupes : </b></h5>
                        <GroupesTable groupes={props.utilisateur.groupes} />
                    </div>

                    <div >
                        <h5 className={"my-4"}><b>Assignements  Cours : </b></h5>
                        <AssignementsTable type={"UtilisateurCours"}  assignements={props.utilisateur.assignementUtilisateurCours}/>

                    </div>

                    <div>
                        <h5 className={"my-4"}><b>Assignements Utilisateur Cours : </b></h5>
                        <AssignementsTable type={"UtilisateurCours"} assignements={props.utilisateur.assignementUtilisateurCours}/>
                    </div>

                </div>

            </div> : null : null}


    </div>);


}


const mapStateToProps = (state) => {
    return {
        loading : state.admin.utilisateurs.utilisateur.loading,
        error : state.admin.utilisateurs.utilisateur.error,
        utilisateur : state.admin.utilisateurs.utilisateur.utilisateur,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUtilisateur : (utilisateurId) => dispatch(adminUtilisateursFetchUtilisateur(utilisateurId))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(DetailsUtilisateur);