import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {

    loading : true,
    error : null,
    videoRequest: null

};

const technicianVideoRequestsFetchVideoRequestStart = (state,action) => {
    return updateObject(state,{
        loading:true,
        error:null,
        videoRequest: null
    });
};

const technicianVideoRequestsFetchVideoRequestSuccess = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:null,
        videoRequest: action.payload.videoRequest
    });
};

const technicianVideoRequestsFetchVideoRequestFail = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:action.payload.error,
        videoRequest: null
    });
};

const technicianVideoRequestsFetchVideoRequestReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUEST_START : return technicianVideoRequestsFetchVideoRequestStart(state,action);
        case actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUEST_SUCCESS: return technicianVideoRequestsFetchVideoRequestSuccess(state,action);
        case actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUEST_FAIL : return technicianVideoRequestsFetchVideoRequestFail(state,action);
        case actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUEST_RESET : return technicianVideoRequestsFetchVideoRequestReset(state,action);
        default : return state;
    }
};

export default reducer;
