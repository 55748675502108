import React, {useEffect, useState} from "react";


import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";

import Alert from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import TextField from '@material-ui/core/TextField';

import Button from "@material-ui/core/Button";
import PaymentIcon from "@material-ui/icons/Payment";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import {
    technicianVideoRequestsFetchVideoRequest,
    technicianVideoRequestsFetchVideoRequestReset
} from "../../../../store/actions/technician/videoRequests/videoRequest";




function VideoRequest(props) {

    let { videoRequestId } = useParams();

    useEffect(()=> {
        props.fetchVideoRequest(videoRequestId);

        return () => {
            props.resetFetchVideoRequest();
        }
    },[]);



    return (
    !props.videoRequest.loading &&
        <div className={"p-5"}>
    <Paper elevation={3}>

        <div className={"p-3"}>
            <h2>
                Video :
            </h2>

            <div>
                <Grid item xs={12} className={"my-3"}>
                    <h6>Par : <b>{props.videoRequest.videoRequest.user.firstName} {props.videoRequest.videoRequest.user.lastName}</b></h6>
                </Grid>
                <Grid item xs={12}>
                    <TextField className={"my-2"} value={props.videoRequest.videoRequest.courseResource.fullTitle} fullWidth={true} size={"small"} label="Matiere" variant="outlined" />
                </Grid>

                <Grid item xs={12}>
                    <TextField className={"my-2"} value={props.videoRequest.videoRequest.section} fullWidth={true} size={"small"} label="Chapitre" variant="outlined" />
                </Grid>

                <Grid item xs={12}>
                    <TextField  className={"my-2"}  value={props.videoRequest.videoRequest.tag} fullWidth={true} size={"small"} label="Tag" variant="outlined" />
                </Grid>

                <Grid item xs={12}>
                    <TextField  className={"my-2"}  value={props.videoRequest.videoRequest.name} fullWidth={true} size={"small"}  label="Nom" variant="outlined" />
                </Grid>


                <Grid item xs={12}>
                    <TextField multiline={true} value={props.videoRequest.videoRequest.description} className={"my-2"}  fullWidth={true} size={"small"} rows={4}  label="Description" variant="outlined" />
                </Grid>

                <Grid item xs={12}>
                    <TextField multiline={true} value={props.videoRequest.videoRequest.note} className={"my-2"}  fullWidth={true} size={"small"} rows={4}  label="Note" variant="outlined" />
                </Grid>

                <Grid item xs={12}>
                    <TextField  className={"my-2"}  value={props.videoRequest.videoRequest.link} fullWidth={true} size={"small"}  label="Lien" variant="outlined" />
                </Grid>

            </div>



            <hr/>

            <h2>Fichiers :</h2>

            {
                props.videoRequest.videoRequest.videoFileRequests.map(file => {
                    return (
                        <Paper elevation={3} className={"p-3 my-3"} >


                            <Grid item xs={12}>
                                <TextField  className={"my-2"}  value={file.name} fullWidth={true} size={"small"}  label="Nom" variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField  className={"my-2"}  value={file.link} fullWidth={true} size={"small"}  label="Lien" variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField multiline={true} value={file.description} className={"my-2"}  fullWidth={true} size={"small"} rows={4}  label="Description" variant="outlined" />
                            </Grid>

                        </Paper>
                    );
                })
            }





        </div>


    </Paper>
        </div>
    );


}


const mapStateToProps = (state) => {
    return {
        videoRequest : state.technician.videoRequests.videoRequest
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVideoRequest : (videoRequestId) => dispatch(technicianVideoRequestsFetchVideoRequest(videoRequestId)),
        resetFetchVideoRequest: () => dispatch(technicianVideoRequestsFetchVideoRequestReset())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(VideoRequest);
