import React, {useEffect} from "react";
import RessourceModal from "../../../../components/RessourceModal";
import {Route, Link, useLocation, useHistory, useParams} from "react-router-dom";
import {explorerCoursPage, homePage} from "../../../../routes/guestRoutes";
import {useLastLocation} from "react-router-last-location";
import CoursCategorieTopCard from "../../../../components/CoursCategorieTopCard";
import RessourceList from "../../../../components/RessourceList";
import {CoursePage, StudentMyCoursesPage} from "../../../../routes/studentRoutes";

import {connect} from "react-redux";
import CoursCategorieTopCardSkeleton from "../../../../UI/LoadingSkeleton/CoursCategorieTopCardSkeleton";
import RessourceListSkeleton from "../../../../UI/LoadingSkeleton/RessourceListSkeleton";
import {
    guestExplorerCoursCoursFetchListeRessources,
    guestExplorerCoursFetchCours,
    guestExplorerCoursFetchCoursReset,
    guestExplorerCoursCoursFetchListeRessourcesReset
} from "../../../../store/actions/guest/explorerCours/cours";

import IconButton from "@material-ui/core/IconButton";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {etudiantMesCoursFetchRessource} from "../../../../store/actions/etudiant/mesCours/ressourceActions";
import {etudiantMesCoursFetchCoursRessources} from "../../../../store/actions/etudiant/mesCours/listeRessources";

function CoursPage(props) {


    let { coursId } = useParams();

    useEffect(()=> {
        props.fetchCours(coursId);
        props.fetchRessources(coursId);

        return () => {
            props.resetCours();
            props.resetRessources();
        }
    },[]);

    return (

        <>

            <div className={"p-5"}>
            <Link to={explorerCoursPage.path}>
                <IconButton className={"my-3"}>
                    <ArrowBackIcon />
                </IconButton>
            </Link>

            {props.main.loading && <CoursCategorieTopCardSkeleton/>}
            {!props.main.loading ?

                !props.main.error ? <CoursCategorieTopCard item={props.main.cours}/> : null : null}


            {props.listeRessources.loading  && <RessourceListSkeleton/>}
            {!props.listeRessources.loading && !props.listeRessources.error  && <RessourceList sectionsRessources={props.listeRessources.listeRessources}/>}

            </div>
        </>


    );

}

const mapStateToProps = (state) => {
    return {
        main : {
            loading : state.guest.explorerCours.cours.main.loading,
            error : state.guest.explorerCours.cours.main.error,
            cours : state.guest.explorerCours.cours.main.cours
        },
        listeRessources : {
            loading : state.guest.explorerCours.cours.listeRessources.loading,
            error : state.guest.explorerCours.cours.listeRessources.error,
            listeRessources : state.guest.explorerCours.cours.listeRessources.listeRessources
        }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCours : (coursId) => dispatch(guestExplorerCoursFetchCours(coursId)),
        resetCours : () => dispatch(guestExplorerCoursFetchCoursReset()),
        fetchRessources : (coursId) => dispatch(guestExplorerCoursCoursFetchListeRessources(coursId)),
        resetRessources : () => dispatch(guestExplorerCoursCoursFetchListeRessourcesReset())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(CoursPage);