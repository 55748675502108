import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    groupe: null
};

const adminUtilisateursFetchGroupeStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        groupe: null
    });
};

const adminUtilisateursFetchGroupeSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        groupe: action.payload.groupe
    });
};

const adminUtilisateursFetchGroupeFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        groupe: null
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_START : return adminUtilisateursFetchGroupeStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_SUCCESS : return adminUtilisateursFetchGroupeSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_FAIL : return adminUtilisateursFetchGroupeFail(state,action);
        default : return state;
    }

}

export default reducer;