import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    fichier: null
};

const enseignantMesCoursAjouterFichierStart = (state,action) => {
    return updateObject(state,{
        success : false,
        loading : true,
        error: null,
        fichier: null
    });
};

const enseignantMesCoursAjouterFichierSuccess = (state,action) => {
    return updateObject(state,{
        success : true,
        loading : false,
        error: null,
        fichier: action.payload.fichier
    });
};

const enseignantMesCoursAjouterFichierFail = (state,action) => {
    return updateObject(state,{
        success : false,
        loading : false,
        error:action.payload.error,
        fichier: null
    });
};

const enseignantMesCoursAjouterFichierReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_FICHIER_START : return enseignantMesCoursAjouterFichierStart(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_FICHIER_SUCCESS : return enseignantMesCoursAjouterFichierSuccess(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_FICHIER_FAIL : return enseignantMesCoursAjouterFichierFail(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_FICHIER_RESET : return enseignantMesCoursAjouterFichierReset(state,action);
        default : return state;
    }
};

export default reducer;
