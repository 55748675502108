import React from "react";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";

import IconButton from '@material-ui/core/IconButton';
import Icon from "@material-ui/core/Icon";

import cssClasses from "../../Guest/SideNav/sidenav.module.css";
import SideNavItems from "../../../components/SideNavItems";


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Divider from "@material-ui/core/Divider";

import miniLogo from "../../../assets/miniLogo.png";

import Drawer from '@material-ui/core/Drawer';

import routes from "../../../routes/teacherRoutes";

function SideNavEnseignant(props) {

    return(
            <Drawer variant="permanent"
                    anchor="left" >
                <div className={"text-center p-2"} style={{height:64}}>

                    <img height={50} src={miniLogo}/>

                </div>
                <Divider/>
                <List style={{width:175}}>
                    <SideNavItems routes={routes}/>
                </List>
            </Drawer>

    );

}


export default SideNavEnseignant;