import * as actionTypes from "../../../../../actions/actionTypes";
import {updateObject} from "../../../../../../utils/utils";

const initialState = {
    success : false,
    loading : true,
    error: null,
    commande: null
};

const etudiantCommandeFetchCommandeStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        commande: null
    });
};

const etudiantCommandeFetchCommandeSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        commande: action.payload.commande
    });
};

const etudiantCommandeFetchCommandeFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        commande: null
    });
};

const etudiantCommandeFetchCommandeReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ETUDIANT_COMMANDE_FETCH_COMMANDE_START : return etudiantCommandeFetchCommandeStart(state,action);
        case actionTypes.ETUDIANT_COMMANDE_FETCH_COMMANDE_SUCCESS : return etudiantCommandeFetchCommandeSuccess(state,action);
        case actionTypes.ETUDIANT_COMMANDE_FETCH_COMMANDE_FAIL : return etudiantCommandeFetchCommandeFail(state,action);
        case actionTypes.ETUDIANT_COMMANDE_FETCH_COMMANDE_RESET : return etudiantCommandeFetchCommandeReset(state,action);
        default : return state;
    }

}

export default reducer;