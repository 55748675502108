import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    utilisateur: null
};

const adminUtilisateursAjouterUtilisateurStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        utilisateur: null
    });
};

const adminUtilisateursAjouterUtilisateurSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        utilisateur: action.payload.utilisateur
    });
};

const adminUtilisateursAjouterUtilisateurFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        utilisateur: null
    });
};

const adminUtilisateursAjouterUtilisateurReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_START : return adminUtilisateursAjouterUtilisateurStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_SUCCESS : return adminUtilisateursAjouterUtilisateurSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_FAIL : return adminUtilisateursAjouterUtilisateurFail(state,action);
        case actionTypes.ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_RESET : return adminUtilisateursAjouterUtilisateurReset(state,action);
        default : return state;
    }

}

export default reducer;