import {combineReducers} from "redux";

import videoRequestsListReducer from "./videoRequestsList";
import videoRequestReducer from "./videoRequest";
import terminateVideoRequestReducer from "./terminateVideoRequest";


export default combineReducers({
    videoRequestsList : videoRequestsListReducer,
    videoRequest : videoRequestReducer,
    terminateVideoRequest : terminateVideoRequestReducer,
});