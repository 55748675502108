import React from 'react';
import Carousel from 'react-material-ui-carousel'
import {Paper} from '@material-ui/core'
import Button from '@material-ui/core/Button';

function OffersCaroussel(props)
{
    var items = [
        {
            name: "Nouvelle offre 1",
            description: "xxxxxxxxx"
        },
        {
            name: "Nouvelle offre 2",
            description: "xxxxxxxxx"
        },
        {
            name: "Séance directe de la semaine derniere",
            description: "xxxxxxxxx"
        }
    ];

    return (
        <Carousel autoPlay={true}>
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>
    )
}

function Item(props)
{
    return (
            <div className={"w-100 text-center text-white"} style={{minHeight:"500px",backgroundSize : "cover",backgroundImage:"url('https://images.unsplash.com/photo-1501290741922-b56c0d0884af?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjF9')", backgroundPosition:"center", backgroundRepeat:"no-repeat"} }>

                <div className={"pt-5"}>
                <h1>{props.item.name}</h1>
                <p>{props.item.description}</p>
                </div>

                <Button color={"primary"} size={"large"} variant={"contained"} className="CheckButton position-absolute" style={{bottom:100+"px",left:"50%",transform:"translateX(-50%)"}}>
                    Consulter l'Offre
                </Button>

            </div>
    )
}

export default OffersCaroussel;