import React from "react";

import Skeleton from '@material-ui/lab/Skeleton';


function CategorieCoursListSkeleton() {

    return (
        <div>
            <Skeleton variant="rect"  height={140} />
            <div className={"pt-2"}>
                <Skeleton />
                <Skeleton />
                <Skeleton width="60%" />
            </div>
        </div>

    );
}

export default CategorieCoursListSkeleton;