import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {

    loading : true,
    error : null,
    videoRequestsList: []

};

const technicianVideoRequestsFetchVideoRequestsListStart = (state,action) => {
    return updateObject(state,{
        loading:true,
        error:null,
        videoRequestsList: []
    });
};

const technicianVideoRequestsFetchVideoRequestsListSuccess = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:null,
        videoRequestsList: action.payload.videoRequestsList
    });
};

const technicianVideoRequestsFetchVideoRequestsListFail = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:action.payload.error,
        videoRequestsList: []
    });
};

const technicianVideoRequestsFetchVideoRequestsListReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUESTS_LIST_START : return technicianVideoRequestsFetchVideoRequestsListStart(state,action);
        case actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUESTS_LIST_SUCCESS : return technicianVideoRequestsFetchVideoRequestsListSuccess(state,action);
        case actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUESTS_LIST_FAIL : return technicianVideoRequestsFetchVideoRequestsListFail(state,action);
        case actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUESTS_LIST_RESET : return technicianVideoRequestsFetchVideoRequestsListReset(state,action);
        default : return state;
    }
};

export default reducer;