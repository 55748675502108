


import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"
import * as api from "../../../../../api";

import * as actions from "../../../../actions";

export function* adminCommandesFetchListeCommandesSaga(action) {


    yield put(actions.adminCommandesFetchListeCommandesStart());

    try {
        const response = yield axios.get(api.adminCommandesFetchListeCommandes);
        const listeCommandes = response.data;
        yield put(actions.adminCommandesFetchListeCommandesSuccess(listeCommandes));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminCommandesFetchListeCommandesFail(error.response.data.error));
        }else {
            yield put(actions.adminCommandesFetchListeCommandesFail("There's an error!"));
        }
    }
}