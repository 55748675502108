import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* etudiantMesCoursFetchRessourceSaga(action) {

    yield put(actions.etudiantMesCoursFetchRessourceStart());

    const ressourceId = action.payload.ressourceId;

    try {
        //const response = yield axios.get("http://www.mocky.io/v2/5ed6ebe03200003500274403");
        const response = yield axios.get(generateDynamicRoute(api.studentFetchVideo,[{toReplace:":ressourceId",value:ressourceId}]));//"http://www.mocky.io/v2/5ed6ebb93200005600274402");
        console.log(response.data);
        //response.data.type = 'Fichier';
        yield put(actions.etudiantMesCoursFetchRessourceSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.etudiantMesCoursFetchRessourceFail(error.response.data.error));
        }else {
            yield put(actions.etudiantMesCoursFetchRessourceFail("There's an error!"));
        }
    }

}