import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : true,
    error: null,
    notes: [],
    ajouterNote : {
        success : false,
        loading : false,
        error: null,
        note : null,
    },
    supprimerNote : {
        success : false,
        loading : false,
        error: null,
        noteId : null
    }
};

const etudiantFetchNotesStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        notes: []
    });
};

const etudiantFetchNotesSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        notes: action.payload.notes
    });
};

const etudiantFetchNotesFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        notes: []
    });
};





const etudiantAjouterNoteStart = (state,action) => {
    return updateObject(state,{
        ...state,

        ajouterNote : {
            success : false,
            loading : true,
            error: null,
            note : null
        }
    });
};

const etudiantAjouterNoteSuccess = (state,action) => {
    return updateObject(state,{
        ...state,

        ajouterNote : {
            success: true,
            loading: false,
            error: null,
            note : action.payload.note
        }
    });
};

const etudiantAjouterNoteFail = (state,action) => {
    return updateObject(state,{
        ...state,
        ajouterNote : {
            success: false,
            loading: false,
            error: action.payload.error,
            note : null
        }
    });
};




const etudiantSupprimerNoteStart = (state,action) => {
    return updateObject(state,{
        ...state,

        supprimerNote : {
            success : false,
            loading : true,
            error: null,
            noteId : null
        }
    });
};

const etudiantSupprimerNoteSuccess = (state,action) => {
    return updateObject(state,{
        ...state,

        supprimerNote : {
            success: true,
            loading: false,
            error: null,
            noteId : action.payload.noteId
        }
    });
};

const etudiantSupprimerNoteFail = (state,action) => {
    return updateObject(state,{
        ...state,
        supprimerNote : {
            success: false,
            loading: false,
            error: action.payload.error,
            noteId : null
        }
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ETUDIANT_FETCH_NOTES_START : return etudiantFetchNotesStart(state,action);
        case actionTypes.ETUDIANT_FETCH_NOTES_SUCCESS : return etudiantFetchNotesSuccess(state,action);
        case actionTypes.ETUDIANT_FETCH_NOTES_FAIL : return etudiantFetchNotesFail(state,action);

        case actionTypes.ETUDIANT_AJOUTER_NOTE_START : return etudiantAjouterNoteStart(state,action);
        case actionTypes.ETUDIANT_AJOUTER_NOTE_SUCCESS : return etudiantAjouterNoteSuccess(state,action);
        case actionTypes.ETUDIANT_AJOUTER_NOTE_FAIL : return etudiantAjouterNoteFail(state,action);

        case actionTypes.ETUDIANT_SUPPRIMER_NOTE_START : return etudiantSupprimerNoteStart(state,action);
        case actionTypes.ETUDIANT_SUPPRIMER_NOTE_SUCCESS : return etudiantSupprimerNoteSuccess(state,action);
        case actionTypes.ETUDIANT_SUPPRIMER_NOTE_FAIL : return etudiantSupprimerNoteFail(state,action);

        default : return state;
    }

}

export default reducer;