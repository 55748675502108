import * as actionTypes from "../../../actionTypes";

export const technicianVideoRequestsFetchVideoRequest = (videoRequestId) => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_INITIATE_FETCH_VIDEO_REQUEST,
        payload : {
            videoRequestId : videoRequestId
        }
    }
};

export const technicianVideoRequestsFetchVideoRequestStart = () => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUEST_START
    }
};

export const technicianVideoRequestsFetchVideoRequestSuccess = (videoRequest) => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUEST_SUCCESS,
        payload : {
            videoRequest : videoRequest
        }
    }
};

export const technicianVideoRequestsFetchVideoRequestFail = (error) => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUEST_FAIL,
        payload : {
            error: error
        }
    }
};

export const technicianVideoRequestsFetchVideoRequestReset = () => {
    return {
        type : actionTypes.TECHNICIAN_VIDEO_REQUESTS_FETCH_VIDEO_REQUEST_RESET
    }
};
