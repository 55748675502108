import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";

import * as api from "../../../../../api";

export function* guestHomeFetchListeCategoriesSpecialsSaga(action) {


    yield put(actions.guestHomeFetchListeCategoriesSpecialStart());

    try {
        const response = yield axios.get(api.guestFetchListeCategoriesSpeciales);
        const listeCategories = response.data;
        yield listeCategories.forEach(categorie => {categorie["type"] = "Categorie"})
        yield put(actions.guestHomeFetchListeCategoriesSpecialSuccess(listeCategories));
    } catch (error) {
        if(error.response) {
            yield put(actions.guestHomeFetchListeCategoriesSpecialFail(error.response.data.error));
        }else {
            yield put(actions.guestHomeFetchListeCategoriesSpecialFail("There's an error!"));
        }
    }
}