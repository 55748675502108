import React from "react";
import { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import cssClasses from "./sidenav.module.css";
import routes from "../../../routes/studentRoutes";
import { CssBaseline, Hidden, List, Tooltip } from "@material-ui/core";
import SideNav, {
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SideNavItems from "../../../components/SideNavItems";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function SideNavEtudiant(props) {
  const { mobileOpen, handleDrawerToggle } = props;
  const [expanded, setExpanded] = useState(false);
  const toggle = () => {
    setExpanded(!expanded);
  };
  const isMobile = useMediaQuery("(max-width:600px)");

  const history = useHistory(); // useNavigate hook to navigate programmatically
  const drawer = routes.map((route) => {
    return !route.notInSideNav ? (
      <NavItem eventKey={route.path}>
        <NavIcon>
          <Tooltip title={route.label} placement="right">
            <i
              className={"fa fa-fw " + route.icon}
              style={{ fontSize: "1.75em" }}
            />
          </Tooltip>
        </NavIcon>
        <NavText>
          <span className={cssClasses.navItemText}>{route.label}</span>
        </NavText>
      </NavItem>
    ) : null;
  });
  const DrawerItems = () => {
    return (
      <List style={{ width: 175 }}>
        <SideNavItems routes={routes} />
      </List>
    );
  };
  return (
    <>
      <CssBaseline />
      <Hidden xsDown implementation="css">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          classes={{ paper: cssClasses.sideNav }}
        >
          <DrawerItems />
        </Drawer>
      </Hidden>
      {isMobile ? null : (
        <SideNav
          expanded={expanded}
          onToggle={toggle}
          onSelect={(selected) => {
            history.push(selected);
            if (expanded) toggle();
          }}
          className={cssClasses.sideNav}
        >
          <SideNav.Toggle />
          <SideNav.Nav defaultSelected="Mes Cours">{drawer}</SideNav.Nav>
        </SideNav>
      )}
    </>
  );
}

export default SideNavEtudiant;
