import React from "react";
import {connect} from "react-redux";
import {Route, Switch, Redirect,
    useLocation} from "react-router-dom";
import CustomRoute from "../../../hoc/CustomRoute";
import routes from "../../../routes";
import cssClasses from "./maincontent.module.css"
import * as actions from "../../../store/actions";
import TopHeader from "../TopHeader";
import Footer from "../../../components/Footer";

import {homePage} from "../../../routes/guestRoutes"
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer - 3,
        color: '#fff',
    },
}));

function MainContent(props) {

    let location = useLocation();

    const pages = routes.map((route) => {
        return route.type === "route" ?
                CustomRoute(route)
            : null;
    });


    const mainContentClasses = [cssClasses.mainContent];
    const pagesClasses = [cssClasses.page];

    const classes = useStyles();


    return (
        <>
        <div style={{marginLeft:175}} className={mainContentClasses.join(" ")}>
            <TopHeader />
            <div className={pagesClasses.join(" ")}>

                <Switch location={location}>
                    {pages}
                    <Redirect to={homePage.path} />
                </Switch>


            </div>
            <Footer/>
        </div>
            </>
    );

}



const mapStateToProps = (state) => {
    return {
        open : state.sideNav.open,
        isAuthenticated: state.authSignIn.token != null,
        errors : { ...state.errors}
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onReturnToMainContent : () => dispatch(actions.closeSideNav()),
        onLogout : () => dispatch(actions.logout()),
        onSideNavClose:  () => dispatch(actions.closeSideNav())
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(MainContent);