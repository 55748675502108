import * as actionTypes from "../../../actionTypes";

export const guestHomeFetchListeCategoriesSpecialStart = () => {
    return {
        type: actionTypes.GUEST_HOME_FETCH_LISTE_CATEGORIES_SPECIALS_START
    }
};

export const guestHomeFetchListeCategoriesSpecialSuccess = (listeCategoriesSpecials) => {
    return {
        type: actionTypes.GUEST_HOME_FETCH_LISTE_CATEGORIES_SPECIALS_SUCCESS,
        payload : {
            listeCategoriesSpecials: listeCategoriesSpecials
        }
    }
};

export const guestHomeFetchListeCategoriesSpecialFail = (error) => {
    return {
        type: actionTypes.GUEST_HOME_FETCH_LISTE_CATEGORIES_SPECIALS_FAIL,
        payload : {
            error : error
        }
    }
};

export const guestHomeFetchListeCategoriesSpecial = () => {
    return {
        type: actionTypes.GUEST_HOME_INITIATE_FETCH_LISTE_CATEGORIES_SPECIALS,
    }
};