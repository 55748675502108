import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputIcon from '@material-ui/icons/Input';
import CircularProgress from '@material-ui/core/CircularProgress';


import DeleteIcon from '@material-ui/icons/Delete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


import {Grid} from "@material-ui/core";
import {connect} from "react-redux";

import Alert from "@material-ui/lab/Alert";

import {
    enseignantDemandeVideosAjouterDemandeReset,
    enseignantDemandeVideosAjouterDemandeVideo,
    enseignantMesCoursFetchListeCours,
    enseignantMesCoursFetchListeCoursReset,
    technicianCoursesFetchCoursesList,
    technicianCoursesFetchCoursesListReset,
    technicianTagsFetchTagsList,
    technicianTagsFetchTagsListReset,
    technicianVideosAddVideo, technicianVideosAddVideoReset
} from "../../../store/actions";
import {NavLink, Redirect} from "react-router-dom";
import {signInPage} from "../../../routes/authRoutes";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

const initialState = {
    course : {
        value: "",
        error: null
    },
    section : {
        value: "",
        error: null
    },
    tag : {
        value: "",
        error: null
    },
    name : {
        value: "",
        error: null
    },
    link : {
        value: "",
        error: null
    },
    description : {
        value: "",
        error: null
    },
    fileName : {
        value: "",
        error: null
    },
    fileLink : {
        value: "",
        error: null
    },
    fileDescription : {
        value: "",
        error: null
    },
    videoFiles : [],
};










function AddVideo(props) {

    const [state,setState] = useState(initialState);

    useEffect(() => {
        props.fetchCoursesList();
        props.fetchTagsList();

        return () => {
            props.resetAddVideo();
            props.resetFetchCoursesList();
            props.resetFetchTagsList();
        }

    },[]);


    const handleAddFile = () => {
        const name = state.fileName.value;
        const description = state.fileDescription.value;
        const link = state.fileLink.value;

        let requirementsError = false;
        if(!name) {
            setState(prevState => {
                return {
                    ...prevState,
                    fileName : {
                        ...prevState.name,
                        error : "Le nom est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }
        if(!link) {
            setState(prevState => {
                return {
                    ...prevState,
                    fileLink : {
                        ...prevState.fileLink,
                        error : "Le lien est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if (!requirementsError) {
            setState(prevState => {
                return {
                    ...prevState,
                    videoFiles: [...prevState.videoFiles,
                        {
                            name : name,
                            description : description,
                            link : link
                        }
                    ],
                    fileName: initialState.fileName,
                    fileDescription: initialState.fileDescription,
                    fileLink: initialState.fileLink,
                }
            });
        }

    };


    /*** Form ***/

    const courseOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                course : {
                    value : value,
                    error: error
                },
                section : {
                    value : null,
                    error: error
                }
            }
        });
    };
    const sectionOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                section : {
                    value : value,
                    error: error
                }
            }
        });
    };
    const tagOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                tag : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const getSelectedCourse = () => {
        return props.coursesList.coursesList.filter((course) => course.id == state.course.value)[0]
    };


    const nameOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                name : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const linkOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                link : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const descriptionOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                description : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const fileNameOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                fileName : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const fileDescriptionOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                fileDescription : {
                    value : value,
                    error: error
                }
            }
        });
    };


    const fileLinkOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                fileLink : {
                    value : value,
                    error: error
                }
            }
        });
    };



    const formOnSubmitHandler = (event) => {
        event.preventDefault();

        let requirementsError = false;

        const course = state.course.value;
        const section = state.section.value;
        const tag = state.tag.value;
        const name = state.name.value;
        const link = state.link.value;
        const description = state.name.value;


        if(!course) {
            setState(prevState => {
                return {
                    ...prevState,
                    course : {
                        ...prevState.course,
                        error : "Le cours est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!section) {
            setState(prevState => {
                return {
                    ...prevState,
                    section : {
                        ...prevState.section,
                        error : "Le chapitre est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!tag) {
            setState(prevState => {
                return {
                    ...prevState,
                    tag : {
                        ...prevState.tag,
                        error : "Le tag est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!name) {
            setState(prevState => {
                return {
                    ...prevState,
                    name : {
                        ...prevState.name,
                        error : "Le nom est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!link) {
            setState(prevState => {
                return {
                    ...prevState,
                    link : {
                        ...prevState.link,
                        error : "Le lien est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!requirementsError) {
            const video = {
                section: {
                    id : section
                },
                tag: {id : tag},
                name : name,
                description:  description,
                link : link
            };

            const files = [];

            state.videoFiles.map(file => {
                const file2 = {
                    name : file.name,
                    link : file.link,
                    description: file.description
                }

                files.push(file2);
            });

            props.addVideo(video,files);
        }

    };

    const removeVideoFile = (key) => {
        setState(prevState => {
            return {
                ...prevState,
                videoFiles: prevState.videoFiles.filter((_,i) => key !== i)
            }
        });
    };

    /*** Form ***/

    const inputClasses = ["my-2"];
    return(
        <>
            <div className={"text-center border rounded p-3"}>
                <div>
                    <h2>Ajouter Video</h2>
                    {props.success &&
                    <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Le vidéo a été bien ajouté</Alert>
                    }
                    {props.error &&
                    <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.error.message}</Alert>
                    }
                    <form onSubmit={formOnSubmitHandler}>
                        <Grid container spacing={3} className={"my-3"}>

                            <Grid item xs={12} className={"text-left"}>
                                <InputLabel id="demo-simple-select-label">Matiere :</InputLabel>
                                <Select
                                    className={"w-100"}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.course.value}
                                    onChange={courseOnChangeHandler}
                                >
                                    {
                                        props.coursesList.coursesList.map(course => {
                                            return <MenuItem value={course.id}>{course.fullTitle}</MenuItem>
                                        })
                                    }
                                </Select>
                            </Grid>

                            <Grid item xs={12} className={"text-left"}>
                                <InputLabel id="demo-simple-select-label">Chapitre :</InputLabel>
                                <Select
                                    className={"w-100"}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.section.value}
                                    onChange={sectionOnChangeHandler}
                                >
                                    {
                                        state.course.value && getSelectedCourse().sections.map(section => {
                                            return <MenuItem value={section.id}>{section.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </Grid>


                            <Grid item xs={12} className={"text-left"}>
                                <InputLabel id="demo-simple-select-label">Tag :</InputLabel>
                                <Select
                                    className={"w-100"}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.tag.value}
                                    onChange={tagOnChangeHandler}
                                >
                                    {
                                        props.tagsList.tagsList.map(tag => {
                                            return <MenuItem value={tag.id}>{tag.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </Grid>



                            <Grid item xs={12}>
                                <TextField error={state.name.error ? true : false} helperText={state.name.error} className={inputClasses.join(" ")} onChange={nameOnChangeHandler} value={state.name.value} fullWidth={true} size={"small"} label="Nom" variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField error={state.link.error ? true : false} helperText={state.link.error} className={inputClasses.join(" ")} onChange={linkOnChangeHandler} value={state.link.value} fullWidth={true} size={"small"} label="Lien" variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField multiline={true} error={state.description.error ? true : false} helperText={state.description.error} className={inputClasses.join(" ")} onChange={descriptionOnChangeHandler} value={state.description.value} fullWidth={true} size={"small"} rows={4}  label="Description" variant="outlined" />
                            </Grid>

                        </Grid>

                        <Divider/>

                        <div className={"my-4 d-flex"}>
                            {

                                state.videoFiles.map((videoFile,key) => {
                                        return (

                                            <Paper elevation={3} className={"p-4 text-left mx-4"}>
                                                <div className={"text-right"}>
                                                    <DeleteIcon color={"secondary"} onClick={() =>{removeVideoFile(key)}} />
                                                </div>
                                                <div><b>Nom :</b> {videoFile.name}</div>
                                                <div><b>Description :</b> {videoFile.description.substr(0,20)}</div>
                                                <div><b>Lien :</b>{videoFile.link}</div>
                                            </Paper>)
                                    }

                                )

                            }
                        </div>



                        <Divider/>

                        <h4 className={"text-left my-4"}>Ajouter Fichier</h4>

                        <Grid item xs={12}>
                            <TextField error={state.fileName.error ? true : false} helperText={state.fileName.error} className={inputClasses.join(" ")} onChange={fileNameOnChangeHandler} value={state.fileName.value} fullWidth={true} size={"small"} label="Nom fichier" variant="outlined" />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField error={state.fileLink.error ? true : false} helperText={state.fileLink.error} className={inputClasses.join(" ")} onChange={fileLinkOnChangeHandler} value={state.fileLink.value} fullWidth={true} size={"small"} rows={4}  label="Lien du fichier" variant="outlined" />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField multiline={true} error={state.fileDescription.error ? true : false} helperText={state.fileDescription.error} className={inputClasses.join(" ")} onChange={fileDescriptionOnChangeHandler} value={state.fileDescription.value} fullWidth={true} size={"small"} rows={4}  label="Description du fichier" variant="outlined" />
                        </Grid>
                        <div className={"text-left my-5"}>
                            <Button variant="contained" className={"text-white text-left"} style={{backgroundColor:"green"}} onClick={handleAddFile}>
                                Confirmer
                            </Button>
                        </div>

                        <Divider/>

                        <div className={"position-relative"}>
                            <Button disabled={props.loading} type={"submit"} className={inputClasses.join(" ")} color={"primary"} variant={"contained"} endIcon={<InputIcon />}>Ajouter le vidéo</Button>
                            {props.loading && <CircularProgress  size={24}/>}
                        </div>

                    </form>
                </div>
            </div>



        </>
    );


}

const mapStateToProps = (state) => {
    return {
        ...state.technician.videos.addVideo,
        coursesList : { ...state.technician.courses.coursesList },
        tagsList : { ...state.technician.tags.tagsList }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

        addVideo : (video,files) => dispatch(technicianVideosAddVideo(video,files)),
        resetAddVideo : () => dispatch(technicianVideosAddVideoReset()),


        fetchCoursesList : () => dispatch(technicianCoursesFetchCoursesList()),
        resetFetchCoursesList : () => dispatch(technicianCoursesFetchCoursesListReset()),

        fetchTagsList : () => dispatch(technicianTagsFetchTagsList()),
        resetFetchTagsList : () => dispatch(technicianTagsFetchTagsListReset()),

    }

};

export default connect(mapStateToProps,mapDispatchToProps)(AddVideo);



