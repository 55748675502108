import * as actionTypes from "../../../actions/actionTypes";
import {updateObject} from "../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    notifications: []
};

const utilisateurFetchTopNotificationsStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        notifications: []
    });
};

const utilisateurFetchTopNotificationsSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        notifications: action.payload.notifications
    });
};

const utilisateurFetchTopNotificationsFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        notifications: []
    });
};


const utilisateurAddTopNotificaiton= (state,action) => {
    return updateObject(state,{
        ...state,
        notifications: [...state.notifications,action.payload.notification]
    });
};

function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.UTILISATEUR_FETCH_TOP_NOTIFICATIONS_START : return utilisateurFetchTopNotificationsStart(state,action);
        case actionTypes.UTILISATEUR_FETCH_TOP_NOTIFICATIONS_SUCCESS : return utilisateurFetchTopNotificationsSuccess(state,action);
        case actionTypes.UTILISATEUR_FETCH_TOP_NOTIFICATIONS_FAIL : return utilisateurFetchTopNotificationsFail(state,action);
        case actionTypes.UTILISATEUR_ADD_TOP_NOTIFICATION : return utilisateurAddTopNotificaiton(state,action);

        default : return state;
    }

}

export default reducer;