import React, {useEffect} from "react";

import Modal from '@material-ui/core/Modal';

import FormHelperText from '@material-ui/core/FormHelperText';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Button from "@material-ui/core/Button";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import {CircularProgress, Grid} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";
import {generateDynamicRoute} from "../../../utils/utils";
import {
    AdminUtilisateursModifierUtilisateurPage
} from "../../../routes/administratorRoutes";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import {connect} from "react-redux";
import Chip from "@material-ui/core/Chip";


import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';



import DateFnsUtils from '@date-io/date-fns';

import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import Alert from "@material-ui/lab/Alert";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Box from "@material-ui/core/Box";
import {adminCoursFetchListeCours} from "../../../store/actions/admin/cours/listeCoursActions";
import TreeListe from "../../../components/TreeListe";
import {
    adminCoursFetchCategorieListeCategories, adminCoursFetchCategorieListeCours,
    adminCoursFetchListeCategories
} from "../../../store/actions/admin/cours/listeCategoriesActions";
import {
    adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCours,
    adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursReset
} from "../../../store/actions/admin/assignementsUtilisateurCours/ajouterAssignementUtilisateurCoursActions";

const initialState = {
    utilisateur: {value : null, error : null},
    cours: {value : null, error : null},
    role: {value : null, error : null} ,
    dateDebutEnabled : false,
    dateDebut : new Date(),
    dateFinEnabled : false,
    dateFin : new Date(),
    utilisateursExpanded : false,
    coursExpanded : false
};

function AjouterAssignementUtilisateurCoursModal(props) {


    const history = useHistory();

    let { utilisateurId } = useParams();

    const handleUtilisateursExpanded = (event,isExpanded) => {
        setState(prevState => {
            return {
                ...prevState,
                utilisateursExpanded : isExpanded
            }
        });
    };

    const handleCoursExpanded = (event,isExpanded) => {
        setState(prevState => {
            return {
                ...prevState,
                coursExpanded : isExpanded
            }
        });
    };

    useEffect(() => {

        if(props.utilisateur.utilisateur) {
            setState(prevState => {
                return {
                    ...prevState,
                    utilisateur:{ value : props.utilisateur.utilisateur, error : null}
                }
            })
        }

    },[props.utilisateur.utilisateur]);


    useEffect(() => {
        props.fetchListeGroupes();
        props.fetchListeUtilisateurs();
        props.fetchListeCours();
        props.fetchListeCategories();

        return () => {
            props.ajouterAssignementUtilisateurCoursReset();
        }

    },[]);

    const [state, setState] = React.useState(initialState);

    const handleDateDebutChange = (date) => {
        setState(prevState => {
            return {
                ...prevState,
                dateDebut : date
            }
        })
    };
    const handleDateFinChange = (date) => {
        setState(prevState => {
            return {
                ...prevState,
                dateFin: date
            }
        })
    };

    const handleChangeRole = (event) => {
        const value = event.target.value;
        setState(prevState => {
            return {
                ...prevState,
                role : {value: value, error:null}
            }
        })
    };

    const handleChangeDateDebutEnabled = (event) => {
        const checked = event.target.checked;
        setState(prevState => {
            return {
                ...prevState,
                dateDebutEnabled: checked
            }
        });
    };

    const handleChangeDateFinEnabled = (event) => {
        const checked = event.target.checked;
        setState(prevState => {
            return {
                ...prevState,
                dateFinEnabled: checked
            }
        });
    };



    const handleClose = () => {

        history.push(generateDynamicRoute(AdminUtilisateursModifierUtilisateurPage.path,[{toReplace:":utilisateurId",value:utilisateurId}]));

    };

    const formOnSubmitHandler = (event) => {

        event.preventDefault();

        let error = false;

        const utilisateur = state.utilisateur.value;
        const cours = state.cours.value;
        const role = state.role.value;

        if(!utilisateur) {
            error = true;
            setState(prevState => {
                return {
                    ...prevState,
                    utilisateur: {
                        value: null,
                        error : "L'utilisateur est obligatoire"
                    }
                }
            })
        }

        if(!cours) {
            error = true;
            setState(prevState => {
                return {
                    ...prevState,
                    cours: {
                        value: null,
                        error : "Le cours est obligatoire"
                    }
                }
            })
        }

        if(!role) {
            error = true;
            setState(prevState => {
                return {
                    ...prevState,
                    role: {
                        value: null,
                        error : "Le role est obligatoire"
                    }
                }
            })
        }

        if(!error) {

            const assignementUtilisateurCours = {
                utilisateur : {
                    id : utilisateur.id
                },
                cours : {
                    id : cours.id
                },
                role : role,
                dateDebut : state.dateDebutEnabled ? state.dateDebut : null,
                dateFin : state.dateFinEnabled ? state.dateFin : null
            };


            props.ajouterAssignementUtilisateurCours(assignementUtilisateurCours);
        }

    };

    const handleUtilisateurSelect = (utilisateur) => {
        setState(prevState => {
            return {
                ...prevState,
                utilisateur: {value : utilisateur, error : null }
            };
        });
    };

    const handleUtilisateurUnSelect = () => {
        setState(prevState => {
            return {
                ...prevState,
                utilisateur: {value : null, error : null }
            };
        });
    };



    const handleCoursSelect = (cours) => {
        setState(prevState => {
            return {
                ...prevState,
                cours:{value : cours, error : null}
            };
        });
    };

    const handleCoursUnSelect = () => {
        setState(prevState => {
            return {
                ...prevState,
                cours: {value: null, error :null}
            };
        });
    };

    const isCoursSelected = (coursToCheck) => {
        return state.cours.value && state.cours.value.id === coursToCheck.id;
    };


    const isUtilisateurSelected = (utilisateurToCheck) => {
        return state.utilisateur.value && state.utilisateur.value.id === utilisateurToCheck.id;
    };


    return(
        <Modal
            open={true}
            onClose={handleClose}
            style={{overflowY:"auto"}}
        >
            <div style={{maxWidth:"700px", width:"100%"}} className={"bg-white text-center border rounded mx-auto my-5"}>

                <h2>Assigner un utilisateur à un cours</h2>

                {props.assignementUtilisateurCours.error &&
                <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.assignementUtilisateurCours.error}</Alert>
                }
                {props.assignementUtilisateurCours.success&&
                <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'assignement ajouté avec succees</Alert>
                }


                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <form className={"text-left p-4"} onSubmit={formOnSubmitHandler}>


                        <ExpansionPanel expanded={state.utilisateursExpanded} onChange={handleUtilisateursExpanded}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <FormControl error={state.utilisateur.error ? true : false}>
                                <h6>Utilisateur : {!state.utilisateursExpanded && state.utilisateur.value ? <Chip label={state.utilisateur.value.nom} onDelete={handleUtilisateurUnSelect} /> : null}</h6>
                                    {state.utilisateur.error && <FormHelperText>{state.utilisateur.error}</FormHelperText>}
                                </FormControl>

                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails >
                                {props.listeGroupes.loading || props.listeUtilisateurs.loading ?
                                    <CircularProgress size={50} thickness={4} />
                                    :
                                    <Grid container spacing={3}>

                                        <Grid item xs={12} md={8} >
                                            <span>Selectionner un utilisateur</span>

                                            <TreeListe fetchGroupeListeGroupes={props.fetchUtilisateurListeGroupes} fetchGroupeListeUtilisateurs={props.fetchGroupeListeUtilisateurs} selectable={"Utilisateur"} liste={props.listeGroupes.listeGroupes}  handleSelect={handleUtilisateurSelect} handleUnSelect={handleUtilisateurUnSelect} isUtilisateurSelected={isUtilisateurSelected}/>

                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <span>Utilisateur Selectionné</span>
                                            <Box>
                                                {state.utilisateur.value &&
                                                <Chip
                                                    label={state.utilisateur.value.nom}
                                                    onDelete={handleUtilisateurUnSelect}
                                                />}
                                            </Box>
                                        </Grid>

                                    </Grid>
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>




                    <ExpansionPanel expanded={state.coursExpanded} onChange={handleCoursExpanded}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <FormControl error={state.cours.error ? true : false}>
                                <h6>Cours : {!state.coursExpanded && state.cours.value ? <Chip label={state.cours.value.titre} onDelete={handleCoursUnSelect} /> : null}</h6>
                                {state.cours.error && <FormHelperText>{state.cours.error}</FormHelperText>}
                            </FormControl>
                            </ExpansionPanelSummary>
                        <ExpansionPanelDetails >
                            {props.listeCours.loading ?
                                <CircularProgress size={50} thickness={4} />
                                :
                                <Grid container spacing={3}>

                                    <Grid item xs={12} md={8} >
                                        <span>Selectionner un cours</span>
                                        <TreeListe fetchCategorieListeCategories={props.fetchCategorieListeCategories} fetchCategorieListeCours={props.fetchCategorieListeCours} selectable={"Cours"} liste={props.listeCategories.listeCategories.concat(props.listeCours.listeCours)} handleSelect={handleCoursSelect} handleUnSelect={handleCoursUnSelect} isCoursSelected={isCoursSelected}/>
                                    </Grid>
                                    <Grid item xs={12} md={4} >
                                        <span>Cours Selectionné</span>
                                        <Box>
                                            {state.cours.value &&
                                            <Chip
                                                label={state.cours.value.titre}
                                                onDelete={handleCoursUnSelect}
                                            />}
                                        </Box>
                                    </Grid>

                                </Grid>
                            }
                        </ExpansionPanelDetails>
                    </ExpansionPanel>



                    <FormControl error={state.role.error ? true : false} fullWidth={true} className={"my-3"}>
                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.role.value}
                            onChange={handleChangeRole}
                        >
                            <MenuItem value={'ROLE_APPRENANT'}>Apprenant</MenuItem>
                            <MenuItem value={'ROLE_ENSEIGNANT'}>Enseignant</MenuItem>
                        </Select>
                        {state.role.error && <FormHelperText>{state.role.error}</FormHelperText>}
                    </FormControl>


                    <FormControlLabel
                        control={<Switch checked={state.dateDebutEnabled} onChange={handleChangeDateDebutEnabled} />}
                        label="Avoir un date debut"
                    />

                    <br />
                    <DateTimePicker value={state.dateDebut}
                                    fullWidth={true}
                                    label="Date Debut"
                                    disabled={!state.dateDebutEnabled}
                                    disablePast
                                    ampm={false}
                                    format="yyyy/MM/dd HH:mm" inputVariant={"outlined"} onChange={handleDateDebutChange} />

                    <FormControlLabel
                        control={<Switch checked={state.dateFinEnabled} onChange={handleChangeDateFinEnabled} />}
                        label="Avoir un date fin"
                    />

                    <DateTimePicker value={state.dateDebut}
                                    fullWidth={true}
                                    label="Date Debut"
                                    disabled={!state.dateFinEnabled}
                                    disablePast
                                    ampm={false}
                                    format="yyyy/MM/dd HH:mm" inputVariant={"outlined"} onChange={handleDateFinChange} />

                                    <div>
                                        <Button disabled={props.assignementUtilisateurCours.loading} type={"submit"} className={"my-2"}
                                                color={"primary"} variant={"contained"} endIcon={<PersonAddIcon/>}>Ajouter</Button>
                                        {props.assignementUtilisateurCours.loading && <CircularProgress size={24} /*className={spinnerClasses.join(" ")}*/ />}
                                    </div>

                </form>
                </MuiPickersUtilsProvider>


            </div>
        </Modal>
    );




}

const mapStateToProps = (state) => {
    return {
        assignementUtilisateurCours : state.admin.assignementsUtilisateurCours.ajouterAssignementUtilisateurCours,
        utilisateur : state.admin.utilisateurs.utilisateur,
        listeGroupes : state.admin.utilisateurs.listeGroupes,
        listeUtilisateurs : state.admin.utilisateurs.listeUtilisateurs,
        listeCours : state.admin.cours.listeCours,
        listeCategories : state.admin.cours.listeCategories
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        ajouterAssignementUtilisateurCours : (assignementUtilisateurCours) => dispatch(adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCours(assignementUtilisateurCours)),
        ajouterAssignementUtilisateurCoursReset : () => dispatch(adminAssignementsUtilisateurCoursAjouterAssignementUtilisateurCoursReset()),

        fetchListeGroupes : () => dispatch(adminUtilisateursFetchListeGroupes()),
        fetchListeUtilisateurs : () => dispatch(adminUtilisateursFetchListeUtilisateurs()),
        fetchGroupeListeGroupes : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeGroupes(groupeId)),
        fetchGroupeListeUtilisateurs : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeUtilisateurs(groupeId)),

        fetchListeCategories : () => dispatch(adminCoursFetchListeCategories()),
        fetchCategorieListeCategories : (categorieId) => dispatch(adminCoursFetchCategorieListeCategories(categorieId)),
        fetchCategorieListeCours : (categorieId) => dispatch(adminCoursFetchCategorieListeCours(categorieId)),

        fetchListeCours : () => dispatch(adminCoursFetchListeCours()),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AjouterAssignementUtilisateurCoursModal);