import * as actionTypes from "../../../../actions/actionTypes";

export const utilisateurCommandeFetchCommande= (commandeId) => {
    return {
        type: actionTypes.UTILISATEUR_COMMANDE_INITIATE_FETCH_COMMANDE,
        payload : {
            commandeId : commandeId,
        }
    }
};

export const utilisateurCommandeFetchCommandeStart = () => {
    return {
        type: actionTypes.UTILISATEUR_COMMANDE_FETCH_COMMANDE_START
    }
};

export const utilisateurCommandeFetchCommandeSuccess = (commande) => {
    return {
        type: actionTypes.UTILISATEUR_COMMANDE_FETCH_COMMANDE_SUCCESS,
        payload : {
            commande: commande
        }
    }
};

export const utilisateurCommandeFetchCommandeFail = (error) => {
    return {
        type: actionTypes.UTILISATEUR_COMMANDE_FETCH_COMMANDE_FAIL,
        payload : {
            error : error
        }
    }
};

export const utilisateurCommandeFetchCommandeReset = () => {
    return {
        type: actionTypes.UTILISATEUR_COMMANDE_FETCH_COMMANDE_RESET
    }
};
