import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    groupe: null,
    ajouterSousGroupe : {
        success : false,
        loading : false,
        error: null,
        sousGroupe : null,
    },
    supprimerSousGroupe : {
        success : false,
        loading : false,
        error: null,
        sousGroupeId : null
    }
};

const adminUtilisateursModifierGroupeStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        groupe: null
    });
};

const adminUtilisateursModifierGroupeSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        groupe: action.payload.groupe
    });
};

const adminUtilisateursModifierGroupeFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        groupe: null
    });
};

const adminUtilisateursModifierGroupeReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





const adminUtilisateursModifierGroupeAjouterSousGroupeStart = (state,action) => {
    return updateObject(state,{
        ...state,

        ajouterSousGroupe : {
            success : false,
            loading : true,
            error: null,
            sousGroupe : null
        }
    });
};

const adminUtilisateursModifierGroupeAjouterSousGroupeSuccess = (state,action) => {
    return updateObject(state,{
        ...state,

        ajouterSousGroupe : {
            success: true,
            loading: false,
            error: null,
            sousGroupe : action.payload.sousGroupe
        }
    });
};

const adminUtilisateursModifierGroupeAjouterSousGroupeFail = (state,action) => {
    return updateObject(state,{
        ...state,
        ajouterSousGroupe : {
            success: false,
            loading: false,
            error: action.payload.error,
            sousGroupe : null
        }
    });
};




const adminUtilisateursModifierGroupeSupprimerSousGroupeStart = (state,action) => {
    return updateObject(state,{
        ...state,

        supprimerSousGroupe : {
            success : false,
            loading : true,
            error: null,
            sousGroupeId : null
        }
    });
};

const adminUtilisateursModifierGroupeSupprimerSousGroupeSuccess = (state,action) => {
    return updateObject(state,{
        ...state,

        supprimerSousGroupe : {
            success: true,
            loading: false,
            error: null,
            sousGroupeId : action.payload.sousGroupeId
        }
    });
};

const adminUtilisateursModifierGroupeSupprimerSousGroupeFail = (state,action) => {
    return updateObject(state,{
        ...state,
        supprimerSousGroupe : {
            success: false,
            loading: false,
            error: action.payload.error,
            sousGroupeId : null
        }
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_START : return adminUtilisateursModifierGroupeStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUCCESS : return adminUtilisateursModifierGroupeSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_FAIL : return adminUtilisateursModifierGroupeFail(state,action);
        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_RESET : return adminUtilisateursModifierGroupeReset(state,action);

        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_AJOUTER_SOUS_GROUPE_START : return adminUtilisateursModifierGroupeAjouterSousGroupeStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_AJOUTER_SOUS_GROUPE_SUCCESS : return adminUtilisateursModifierGroupeAjouterSousGroupeSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_AJOUTER_SOUS_GROUPE_FAIL : return adminUtilisateursModifierGroupeAjouterSousGroupeFail(state,action);

        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUPPRIMER_SOUS_GROUPE_START : return adminUtilisateursModifierGroupeSupprimerSousGroupeStart(state,action);
        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUPPRIMER_SOUS_GROUPE_SUCCESS : return adminUtilisateursModifierGroupeSupprimerSousGroupeSuccess(state,action);
        case actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUPPRIMER_SOUS_GROUPE_FAIL : return adminUtilisateursModifierGroupeSupprimerSousGroupeFail(state,action);
        default : return state;
    }

}

export default reducer;