import React from "react";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";

import cssClasses from "./sidenav.module.css";
import SideNavItems from "../../../components/SideNavItems";


import List from '@material-ui/core/List';

import {sideNavRoutes} from "../../../routes";

import Drawer from '@material-ui/core/Drawer';


function SideNav(props) {

    const navClasses = [cssClasses.sideNav,"py-2"];
    if (props.open) {
        navClasses.push(cssClasses.open)
    }

    return(
        <>
            <Drawer variant="persistent"
                    anchor="left" open={props.open} >
                <List>
                    <SideNavItems routes={sideNavRoutes} onClick={props.open ? props.onSideNavClose : null} role={props.role} isAuthenticated={props.isAuthenticated} withLabel={props.open} />
                </List>
            </Drawer>


        </>
    );

}

const mapStateToProps = (state) => {
    return {
        open : state.sideNav.open,
        isAuthenticated : state.authSignIn.token != null,
        role: state.authSignIn.user ?state.authSignIn.user.role : null
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSideNavOpen:  () => dispatch(actions.openSideNav()),
        onSideNavClose:  () => dispatch(actions.closeSideNav())
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(SideNav);