import React from "react";

import Skeleton from '@material-ui/lab/Skeleton';


function CoursListItemSkeleton() {

    return (
        <li className={"p-3 d-flex justify-content-between align-items-center flex-column flex-md-row"}>
            <div className={"d-flex align-items-center flex-column flex-md-row w-75"}>
                <Skeleton variant="rect"  height={140} width={140} />
                <div className={"w-75 p-3"}>
                    <Skeleton />
                    <Skeleton width="60%" />
                </div>
            </div>
            <div className={"d-flex justify-content-end"}>
                <Skeleton variant="circle" className={"mx-2"}  height={40} width={40} />
                <Skeleton variant="circle" className={"mx-2"}  height={40} width={40} />
            </div>

        </li>

    );
}

export default CoursListItemSkeleton;