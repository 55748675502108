import React, {useEffect} from "react";
import {
    etudiantMesCoursFetchListeCours,
    etudiantMesCoursFetchListeCoursReset
} from "../../../../store/actions/etudiant/mesCours";
import {connect} from "react-redux";
import CoursListSkeleton from "../../../../UI/LoadingSkeleton/CoursListSkeleton";
import CoursList from "../../../../components/CoursList";
import {
    enseignantMesCoursFetchListeCours,
    enseignantMesCoursFetchListeCoursReset
} from "../../../../store/actions/enseignant/mesCours";
import EnseignantCoursList from "../../../../components/EnseignantCoursList";


function ListeCours(props) {

    useEffect(() => {
        props.fetchListeCours(props.utilisateurId);
        return () => {
            props.resetListeCours();
        }
    },[]);


    return (
        <>
            <h1 className={"text-center my-3"}>Mes Cours</h1>

            {props.loading && <CoursListSkeleton/>}
            {!props.loading && !props.error && <EnseignantCoursList listeCours={props.listeCours} />}

        </>
    );
};


const mapStateToProps = (state) => {
    return {
        loading : state.enseignant.mesCours.listeCours.loading,
        error : state.enseignant.mesCours.listeCours.error,
        listeCours : state.enseignant.mesCours.listeCours.listeCours,
        utilisateurId : state.authSignIn.utilisateur.id
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        //addGroupe : (nom,groupeParent) => dispatch(adminUsersAddGroupe(nom,groupeParent)),
        fetchListeCours : (utilisateurId) => dispatch(enseignantMesCoursFetchListeCours(utilisateurId)),
        resetListeCours : () => dispatch(enseignantMesCoursFetchListeCoursReset())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(ListeCours);