export const OPEN_SIDENAV = 'OPEN_SIDENAV';
export const CLOSE_SIDENAV = 'CLOSE_SIDENAV';

export const AUTH_TRY_AUTO_SIGNIN = 'AUTH_TRY_AUTO_SIGNIN';

export const AUTH_INITIATE_SIGNIN = 'AUTH_INITIATE_SIGNIN';
export const AUTH_SIGNIN_START = 'AUTH_SIGNIN_START';
export const AUTH_SIGNIN_SUCCESS = 'AUTH_SIGNIN_SUCCESS';
export const AUTH_SIGNIN_FAIL = 'AUTH_SIGNIN_FAIL';

export const AUTH_INITIATE_REQUEST_NEW_PASSWORD = 'AUTH_INITIATE_REQUEST_NEW_PASSWORD';
export const AUTH_REQUEST_NEW_PASSWORD_START = 'AUTH_REQUEST_NEW_PASSWORD_START';
export const AUTH_REQUEST_NEW_PASSWORD_SUCCESS = 'AUTH_REQUEST_NEW_PASSWORD_SUCCESS';
export const AUTH_REQUEST_NEW_PASSWORD_FAIL = 'AUTH_REQUEST_NEW_PASSWORD_FAIL';

export const AUTH_INITIATE_NEW_PASSWORD = 'AUTH_INITIATE_NEW_PASSWORD';
export const AUTH_NEW_PASSWORD_START = 'AUTH_NEW_PASSWORD_START';
export const AUTH_NEW_PASSWORD_FAIL = 'AUTH_NEW_PASSWORD_FAIL';


export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_UPDATE_UTILISATEUR = 'AUTH_UPDATE_UTILISATEUR';
export const AUTH_ADD_ROLE = 'AUTH_ADD_ROLE';


export const ADMIN_USERS_INITIATE_ADD_USER = 'ADMIN_USERS_INITIATE_ADD_USER';
export const ADMIN_USERS_ADD_USER_START = 'ADMIN_USERS_ADD_START';
export const ADMIN_USERS_ADD_USER_SUCCESS = 'ADMIN_USERS_ADD_USER_SUCCESS';
export const ADMIN_USERS_ADD_USER_FAIL = 'ADMIN_USERS_ADD_USER_FAIL';

export const ADMIN_USERS_ADD_USER_INITIATE_FETCH_GROUPES = 'ADMIN_USERS_ADD_USER_INITIATE_FETCH_GROUPES';
export const ADMIN_USERS_ADD_USER_FETCH_GROUPES_START = 'ADMIN_USERS_ADD_USER_FETCH_GROUPES_START';
export const ADMIN_USERS_ADD_USER_FETCH_GROUPES_SUCCESS = 'ADMIN_USERS_ADD_USER_FETCH_GROUPES_SUCCESS';
export const ADMIN_USERS_ADD_USER_FETCH_GROUPES_FAIL = 'ADMIN_USERS_ADD_USER_FETCH_GROUPES_FAIL';
export const ADMIN_USERS_ADD_USER_FETCH_GROUPES_RESET = 'ADMIN_USERS_ADD_USER_FETCH_GROUPES_RESET';


export const ADMIN_USERS_INITIATE_ADD_GROUPE = 'ADMIN_USERS_INITIATE_ADD_GROUPE';
export const ADMIN_USERS_ADD_GROUPE_START = 'ADMIN_USERS_ADD_GROUPE_START';
export const ADMIN_USERS_ADD_GROUPE_SUCCESS = 'ADMIN_USERS_ADD_GROUPE_SUCCESS';
export const ADMIN_USERS_ADD_GROUPE_FAIL = 'ADMIN_USERS_ADD_GROUPE_FAIL';

export const ADMIN_USERS_ADD_GROUPE_INITIATE_FETCH_GROUPES = 'ADMIN_USERS_ADD_GROUPE_INITIATE_FETCH_GROUPES';
export const ADMIN_USERS_ADD_GROUPE_FETCH_GROUPES_START = 'ADMIN_USERS_ADD_GROUPE_FETCH_GROUPES_START';
export const ADMIN_USERS_ADD_GROUPE_FETCH_GROUPES_SUCCESS = 'ADMIN_USERS_ADD_GROUPE_FETCH_GROUPES_SUCCESS';
export const ADMIN_USERS_ADD_GROUPE_FETCH_GROUPES_FAIL = 'ADMIN_USERS_ADD_GROUPE_FETCH_GROUPES_FAIL';
export const ADMIN_USERS_ADD_GROUPE_FETCH_GROUPES_RESET = 'ADMIN_USERS_ADD_GROUPE_FETCH_GROUPES_RESET';


export const GUEST_COURS_CATEGORIES_INITIATE_FETCH_COURS_CATEGORIES = 'GUEST_COURS_CATEGORIES_INITIATE_FETCH_COURS_CATEGORIES';
export const GUEST_COURS_CATEGORIES_FETCH_COURS_CATEGORIES_START = 'GUEST_COURS_CATEGORIES_FETCH_COURS_CATEGORIES_START';
export const GUEST_COURS_CATEGORIES_FETCH_COURS_CATEGORIES_SUCCESS = 'GUEST_COURS_CATEGORIES_FETCH_COURS_CATEGORIES_SUCCESS';
export const GUEST_COURS_CATEGORIES_FETCH_COURS_CATEGORIES_FAIL = 'GUEST_COURS_CATEGORIES_FETCH_COURS_CATEGORIES_FAIL';
export const GUEST_COURS_CATEGORIES_FETCH_COURS_CATEGORIES_RESET = 'GUEST_COURS_CATEGORIES_FETCH_COURS_CATEGORIES_RESET';
/*
export const GUEST_CATEGORIE_INITIATE_FETCH_CATEGORIE = 'GUEST_CATEGORIE_INITIATE_FETCH_CATEGORIE';
export const GUEST_CATEGORIE_FETCH_ROOT_START = 'GUEST_CATEGORIE_FETCH_ROOT_START';
export const GUEST_CATEGORIE_FETCH_ROOT_SUCCESS = 'GUEST_CATEGORIE_FETCH_ROOT_SUCCESS';
export const GUEST_CATEGORIE_FETCH_ROOT_FAIL = 'GUEST_CATEGORIE_FETCH_ROOT_FAIL';
export const GUEST_CATEGORIE_FETCH_ROOT_RESET = 'GUEST_CATEGORIE_FETCH_ROOT_RESET';*/




/**** ETUDIANT ****/


export const ETUDIANT_MES_COURS_INITIATE_FETCH_LISTE_COURS = 'ETUDIANT_MES_COURS_INITIATE_FETCH_LISTE_COURS';
export const ETUDIANT_MES_COURS_FETCH_LISTE_COURS_START = 'ETUDIANT_MES_COURS_FETCH_LISTE_COURS_START';
export const ETUDIANT_MES_COURS_FETCH_LISTE_COURS_SUCCESS = 'ETUDIANT_MES_COURS_FETCH_LISTE_COURS_SUCCESS';
export const ETUDIANT_MES_COURS_FETCH_LISTE_COURS_FAIL = 'ETUDIANT_MES_COURS_FETCH_LISTE_COURS_FAIL';
export const ETUDIANT_MES_COURS_FETCH_LISTE_COURS_RESET = 'ETUDIANT_MES_COURS_FETCH_LISTE_COURS_RESET';



export const ETUDIANT_MES_COURS_INITIATE_FETCH_COURS = 'ETUDIANT_MES_COURS_INITIATE_FETCH_COURS';
export const ETUDIANT_MES_COURS_FETCH_COURS_START = 'ETUDIANT_MES_COURS_FETCH_COURS_START';
export const ETUDIANT_MES_COURS_FETCH_COURS_SUCCESS = 'ETUDIANT_MES_COURS_FETCH_COURS_SUCCESS';
export const ETUDIANT_MES_COURS_FETCH_COURS_FAIL = 'ETUDIANT_MES_COURS_FETCH_COURS_FAIL';
export const ETUDIANT_MES_COURS_FETCH_COURS_RESET = 'ETUDIANT_MES_COURS_FETCH_COURS_RESET';

export const ETUDIANT_MES_COURS_INITIATE_FETCH_COURS_RESSOURCES = 'ETUDIANT_MES_COURS_INITIATE_FETCH_COURS_RESSOURCES';
export const ETUDIANT_MES_COURS_FETCH_COURS_RESSOURCES_START = 'ETUDIANT_MES_COURS_FETCH_COURS_RESSOURCES_START';
export const ETUDIANT_MES_COURS_FETCH_COURS_RESSOURCES_SUCCESS = 'ETUDIANT_MES_COURS_FETCH_COURS_RESSOURCES_SUCCESS';
export const ETUDIANT_MES_COURS_FETCH_COURS_RESSOURCES_FAIL = 'ETUDIANT_MES_COURS_FETCH_COURS_RESSOURCES_FAIL';
export const ETUDIANT_MES_COURS_FETCH_COURS_RESSOURCES_RESET = 'ETUDIANT_MES_COURS_FETCH_COURS_RESSOURCES_RESET';

export const ETUDIANT_MES_COURS_INITIATE_FETCH_RESSOURCE = 'ETUDIANT_MES_COURS_INITIATE_FETCH_RESSOURCE';
export const ETUDIANT_MES_COURS_FETCH_RESSOURCE_START = 'ETUDIANT_MES_COURS_FETCH_RESSOURCE_START';
export const ETUDIANT_MES_COURS_FETCH_RESSOURCE_SUCCESS = 'ETUDIANT_MES_COURS_FETCH_RESSOURCE_SUCCESS';
export const ETUDIANT_MES_COURS_FETCH_RESSOURCE_FAIL = 'ETUDIANT_MES_COURS_FETCH_RESSOURCE_FAIL';
export const ETUDIANT_MES_COURS_FETCH_RESSOURCE_RESET = 'ETUDIANT_MES_COURS_FETCH_RESSOURCE_RESET';




/**** GUEST ****/


export const GUEST_HOME_INITIATE_FETCH_LISTE_COURS_SPECIALS = 'GUEST_HOME_INITIATE_FETCH_LISTE_COURS_SPECIALS';
export const GUEST_HOME_FETCH_LISTE_COURS_SPECIALS_START = 'GUEST_HOME_FETCH_LISTE_COURS_SPECIALS_START';
export const GUEST_HOME_FETCH_LISTE_COURS_SPECIALS_SUCCESS = 'GUEST_HOME_FETCH_LISTE_COURS_SPECIALS_SUCCESS';
export const GUEST_HOME_FETCH_LISTE_COURS_SPECIALS_FAIL = 'GUEST_HOME_FETCH_LISTE_COURS_PECIALS_FAIL';
export const GUEST_HOME_FETCH_LISTE_COURS_SPECIALS_RESET = 'GUEST_HOME_FETCH_LISTE_COURS_SPECIALS_RESET';


export const GUEST_HOME_INITIATE_FETCH_LISTE_CATEGORIES_SPECIALS = 'GUEST_HOME_INITIATE_FETCH_LISTE_CATEGORIES_SPECIALS';
export const GUEST_HOME_FETCH_LISTE_CATEGORIES_SPECIALS_START = 'GUEST_HOME_FETCH_LISTE_CATEGORIES_SPECIALS_START';
export const GUEST_HOME_FETCH_LISTE_CATEGORIES_SPECIALS_SUCCESS = 'GUEST_HOME_FETCH_LISTE_CATEGORIES_SPECIALS_SUCCESS';
export const GUEST_HOME_FETCH_LISTE_CATEGORIES_SPECIALS_FAIL = 'GUEST_HOME_FETCH_LISTE_CATEGORIES_SPECIALS_FAIL';
export const GUEST_HOME_FETCH_LISTE_CATEGORIES_SPECIALS_RESET = 'GUEST_HOME_FETCH_LISTE_CATEGORIES_SPECIALS_RESET';


export const GUEST_EXPLORER_COURS_INITIATE_FETCH_LISTE_COURS = 'GUEST_EXPLORER_COURS_INITIATE_FETCH_LISTE_COURS';
export const GUEST_EXPLORER_COURS_FETCH_LISTE_COURS_START = 'GUEST_EXPLORER_COURS_FETCH_LISTE_COURS_START';
export const GUEST_EXPLORER_COURS_FETCH_LISTE_COURS_SUCCESS = 'GUEST_EXPLORER_COURS_FETCH_LISTE_COURS_SUCCESS';
export const GUEST_EXPLORER_COURS_FETCH_LISTE_COURS_FAIL = 'GUEST_EXPLORER_COURS_FETCH_LISTE_COURS_FAIL';
export const GUEST_EXPLORER_COURS_FETCH_LISTE_COURS_RESET = 'GUEST_EXPLORER_COURS_FETCH_LISTE_COURS_RESET';

export const GUEST_EXPLORER_COURS_INITIATE_FETCH_LISTE_CATEGORIES = 'GUEST_EXPLORER_COURS_INITIATE_FETCH_LISTE_CATEGORIES';
export const GUEST_EXPLORER_COURS_FETCH_LISTE_CATEGORIES_START = 'GUEST_EXPLORER_COURS_FETCH_LISTE_CATEGORIES_START';
export const GUEST_EXPLORER_COURS_FETCH_LISTE_CATEGORIES_SUCCESS = 'GUEST_EXPLORER_COURS_FETCH_LISTE_CATEGORIES_SUCCESS';
export const GUEST_EXPLORER_COURS_FETCH_LISTE_CATEGORIES_FAIL = 'GUEST_EXPLORER_COURS_FETCH_LISTE_CATEGORIES_FAIL';
export const GUEST_EXPLORER_COURS_FETCH_LISTE_CATEGORIES_RESET = 'GUEST_EXPLORER_COURS_FETCH_LISTE_CATEGORIES_RESET';




export const GUEST_EXPLORER_COURS_INITIATE_FETCH_CATEGORIE_LISTE_CATEGORIES = 'GUEST_EXPLORER_COURS_INITIATE_FETCH_CATEGORIE_LISTE_CATEGORIES';
export const GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_START = 'GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_START';
export const GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_SUCCESS = 'GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_SUCCESS';
export const GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_FAIL = 'GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_FAIL';
export const GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_RESET = 'GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_RESET';



export const GUEST_EXPLORER_COURS_INITIATE_FETCH_CATEGORIE_LISTE_COURS = 'GUEST_EXPLORER_COURS_INITIATE_FETCH_CATEGORIE_LISTE_COURS';
export const GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_COURS_START = 'GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_COURS_START';
export const GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_COURS_SUCCESS = 'GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_COURS_SUCCESS';
export const GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_COURS_FAIL = 'GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_COURS_FAIL';
export const GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_COURS_RESET = 'GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_COURS_RESET';



export const GUEST_EXPLORER_COURS_INITIATE_FETCH_COURS = 'GUEST_EXPLORER_COURS_INITIATE_FETCH_COURS';
export const GUEST_EXPLORER_COURS_FETCH_COURS_START = 'GUEST_EXPLORER_COURS_FETCH_COURS_START';
export const GUEST_EXPLORER_COURS_FETCH_COURS_SUCCESS = 'GUEST_EXPLORER_COURS_FETCH_COURS_SUCCESS';
export const GUEST_EXPLORER_COURS_FETCH_COURS_FAIL = 'GUEST_EXPLORER_COURS_FETCH_COURS_FAIL';
export const GUEST_EXPLORER_COURS_FETCH_COURS_RESET = 'GUEST_EXPLORER_COURS_FETCH_COURS_RESET';

export const GUEST_EXPLORER_COURS_INITIATE_FETCH_CATEGORIE = 'GUEST_EXPLORER_COURS_INITIATE_FETCH_CATEGORIE';
export const GUEST_EXPLORER_COURS_FETCH_CATEGORIE_START = 'GUEST_EXPLORER_COURS_FETCH_CATEGORIE_START';
export const GUEST_EXPLORER_COURS_FETCH_CATEGORIE_SUCCESS = 'GUEST_EXPLORER_COURS_FETCH_CATEGORIE_SUCCESS';
export const GUEST_EXPLORER_COURS_FETCH_CATEGORIE_FAIL = 'GUEST_EXPLORER_COURS_FETCH_CATEGORIE_FAIL';
export const GUEST_EXPLORER_COURS_FETCH_CATEGORIE_RESET = 'GUEST_EXPLORER_COURS_FETCH_CATEGORIE_RESET';

export const GUEST_EXPLORER_COURS_COURS_INITIATE_FETCH_LISTE_RESSOURCES = 'GUEST_EXPLORER_COURS_COURS_INITIATE_FETCH_LISTE_RESSOURCES';
export const GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_START = 'GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_START';
export const GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_SUCCESS = 'GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_SUCCESS';
export const GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_FAIL = 'GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_FAIL';
export const GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_RESET = 'GUEST_EXPLORER_COURS_COURS_FETCH_LISTE_RESSOURCES_RESET';


/***** PARAMETRES *****/

export const PARAMETRES_INITIATE_FETCH_PARAMETRES = 'PARAMETRES_INITIATE_FETCH_PARAMETRES';
export const PARAMETRES_FETCH_PARAMETRES_START = 'PARAMETRES_FETCH_PARAMETRES_START';
export const PARAMETRES_FETCH_PARAMETRES_SUCCESS = 'PARAMETRES_FETCH_PARAMETRES_SUCCESS';
export const PARAMETRES_FETCH_PARAMETRES_FAIL = 'PARAMETRES_FETCH_PARAMETRES_FAIL';
export const PARAMETRES_FETCH_PARAMETRES_RESET = 'PARAMETRES_FETCH_PARAMETRES_RESET';


/**** ADMIN ****/

export const ADMIN_UTILISATEURS_INITIATE_FETCH_LISTE_GROUPES = 'ADMIN_UTILISATEURS_INITIATE_FETCH_LISTE_GROUPES';
export const ADMIN_UTILISATEURS_FETCH_LISTE_GROUPES_START = 'ADMIN_UTILISATEURS_FETCH_LISTE_GROUPES_START';
export const ADMIN_UTILISATEURS_FETCH_LISTE_GROUPES_SUCCESS = 'ADMIN_UTILISATEURS_FETCH_LISTE_GROUPES_SUCCESS';
export const ADMIN_UTILISATEURS_FETCH_LISTE_GROUPES_FAIL = 'ADMIN_UTILISATEURS_FETCH_LISTE_GROUPES_FAIL';
export const ADMIN_UTILISATEURS_FETCH_LISTE_GROUPES_RESET = 'ADMIN_UTILISATEURS_FETCH_LISTE_GROUPES_RESET';

export const ADMIN_UTILISATEURS_INITIATE_FETCH_LISTE_UTILISATEURS = 'ADMIN_UTILISATEURS_INITIATE_FETCH_LISTE_UTILISATEURS';
export const ADMIN_UTILISATEURS_FETCH_LISTE_UTILISATEURS_START = 'ADMIN_UTILISATEURS_FETCH_LISTE_UTILISATEURS_START';
export const ADMIN_UTILISATEURS_FETCH_LISTE_UTILISATEURS_SUCCESS = 'ADMIN_UTILISATEURS_FETCH_LISTE_UTILISATEURS_SUCCESS';
export const ADMIN_UTILISATEURS_FETCH_LISTE_UTILISATEURS_FAIL = 'ADMIN_UTILISATEURS_FETCH_LISTE_UTILISATEURS_FAIL';
export const ADMIN_UTILISATEURS_FETCH_LISTE_UTILISATEURS_RESET = 'ADMIN_UTILISATEURS_FETCH_LISTE_UTILISATEURS_RESET';

export const ADMIN_UTILISATEURS_INITIATE_FETCH_GROUPE_LISTE_GROUPES = 'ADMIN_UTILISATEURS_INITIATE_FETCH_GROUPE_LISTE_GROUPES';
export const ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_GROUPES_START = 'ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_GROUPES_START';
export const ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_GROUPES_SUCCESS = 'ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_GROUPES_SUCCESS';
export const ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_GROUPES_FAIL = 'ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_GROUPES_FAIL';
export const ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_GROUPES_RESET = 'ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_GROUPES_RESET';

export const ADMIN_UTILISATEURS_INITIATE_FETCH_GROUPE_LISTE_UTILISATEURS = 'ADMIN_UTILISATEURS_INITIATE_FETCH_GROUPE_LISTE_UTILISATEURS';
export const ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_UTILISATEURS_START = 'ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_UTILISATEURS_START';
export const ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_UTILISATEURS_SUCCESS = 'ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_UTILISATEURS_SUCCESS';
export const ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_UTILISATEURS_FAIL = 'ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_UTILISATEURS_FAIL';
export const ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_UTILISATEURS_RESET = 'ADMIN_UTILISATEURS_FETCH_GROUPE_LISTE_UTILISATEURS_RESET';

/**** COURS CATEGORIES *****/


export const ADMIN_COURS_INITIATE_FETCH_LISTE_CATEGORIES = 'ADMIN_COURS_INITIATE_FETCH_LISTE_CATEGORIES';
export const ADMIN_COURS_FETCH_LISTE_CATEGORIES_START = 'ADMIN_COURS_FETCH_LISTE_CATEGORIES_START';
export const ADMIN_COURS_FETCH_LISTE_CATEGORIES_SUCCESS = 'ADMIN_COURS_FETCH_LISTE_CATEGORIES_SUCCESS';
export const ADMIN_COURS_FETCH_LISTE_CATEGORIES_FAIL = 'ADMIN_COURS_FETCH_LISTE_CATEGORIES_FAIL';
export const ADMIN_COURS_FETCH_LISTE_CATEGORIES_RESET = 'ADMIN_COURS_FETCH_LISTE_CATEGORIES_RESET';

export const ADMIN_COURS_INITIATE_FETCH_LISTE_COURS = 'ADMIN_COURS_INITIATE_FETCH_LISTE_COURS';
export const ADMIN_COURS_FETCH_LISTE_COURS_START = 'ADMIN_COURS_FETCH_LISTE_COURS_START';
export const ADMIN_COURS_FETCH_LISTE_COURS_SUCCESS = 'ADMIN_COURS_FETCH_LISTE_COURS_SUCCESS';
export const ADMIN_COURS_FETCH_LISTE_COURS_FAIL = 'ADMIN_COURS_FETCH_LISTE_COURS_FAIL';
export const ADMIN_COURS_FETCH_LISTE_COURS_RESET = 'ADMIN_COURS_FETCH_LISTE_COURS_RESET';

export const ADMIN_COURS_INITIATE_FETCH_CATEGORIE_LISTE_CATEGORIES = 'ADMIN_COURS_INITIATE_FETCH_CATEGORIE_LISTE_CATEGORIES';
export const ADMIN_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_START = 'ADMIN_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_START';
export const ADMIN_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_SUCCESS = 'ADMIN_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_SUCCESS';
export const ADMIN_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_FAIL = 'ADMIN_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_FAIL';
export const ADMIN_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_RESET = 'ADMIN_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_RESET';

export const ADMIN_COURS_INITIATE_FETCH_CATEGORIE_LISTE_COURS = 'ADMIN_COURS_INITIATE_FETCH_CATEGORIE_LISTE_COURS';
export const ADMIN_COURS_FETCH_CATEGORIE_LISTE_COURS_START = 'ADMIN_COURS_FETCH_CATEGORIE_LISTE_COURS_START';
export const ADMIN_COURS_FETCH_CATEGORIE_LISTE_COURS_SUCCESS = 'ADMIN_COURS_FETCH_CATEGORIE_LISTE_COURS_SUCCESS';
export const ADMIN_COURS_FETCH_CATEGORIE_LISTE_COURS_FAIL = 'ADMIN_COURS_FETCH_CATEGORIE_LISTE_COURS_FAIL';
export const ADMIN_COURS_FETCH_CATEGORIE_LISTE_COURS_RESET = 'ADMIN_COURS_FETCH_CATEGORIE_LISTE_COURS_RESET';




/**** AJOUTER GROUPE ****/


export const ADMIN_UTILISATEURS_INITIATE_AJOUTER_GROUPE = 'ADMIN_UTILISATEURS_INITIATE_AJOUTER_GROUPE';
export const ADMIN_UTILISATEURS_AJOUTER_GROUPE_START = 'ADMIN_UTILISATEURS_AJOUTER_GROUPE_START';
export const ADMIN_UTILISATEURS_AJOUTER_GROUPE_SUCCESS = 'ADMIN_UTILISATEURS_AJOUTER_GROUPE_SUCCESS';
export const ADMIN_UTILISATEURS_AJOUTER_GROUPE_FAIL = 'ADMIN_UTILISATEURS_AJOUTER_GROUPE_FAIL';
export const ADMIN_UTILISATEURS_AJOUTER_GROUPE_RESET = 'ADMIN_UTILISATEURS_AJOUTER_GROUPE_RESET';

/**** MODIFIER GROUPE ****/


export const ADMIN_UTILISATEURS_INITIATE_MODIFIER_GROUPE = 'ADMIN_UTILISATEURS_INITIATE_MODIFIER_GROUPE';
export const ADMIN_UTILISATEURS_MODIFIER_GROUPE_START = 'ADMIN_UTILISATEURS_MODIFIER_GROUPE_START';
export const ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUCCESS = 'ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUCCESS';
export const ADMIN_UTILISATEURS_MODIFIER_GROUPE_FAIL = 'ADMIN_UTILISATEURS_MODIFIER_GROUPE_FAIL';
export const ADMIN_UTILISATEURS_MODIFIER_GROUPE_RESET = 'ADMIN_UTILISATEURS_MODIFIER_GROUPE_RESET';

/**** AJOUTER UTILISATEUR ****/

export const ADMIN_UTILISATEURS_INITIATE_AJOUTER_UTILISATEUR = 'ADMIN_UTILISATEURS_INITIATE_AJOUTER_UTILISATEUR';
export const ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_START = 'ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_START';
export const ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_SUCCESS = 'ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_SUCCESS';
export const ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_FAIL = 'ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_FAIL';
export const ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_RESET = 'ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_RESET';

/**** SUPPRIMER GROUPE ****/

export const ADMIN_UTILISATEURS_INITIATE_SUPPRIMER_GROUPE = 'ADMIN_UTILISATEURS_INITIATE_SUPPRIMER_GROUPE';
export const ADMIN_UTILISATEURS_SUPPRIMER_GROUPE_START = 'ADMIN_UTILISATEURS_SUPPRIMER_GROUPE_START';
export const ADMIN_UTILISATEURS_SUPPRIMER_GROUPE_SUCCESS = 'ADMIN_UTILISATEURS_SUPPRIMER_GROUPE_SUCCESS';
export const ADMIN_UTILISATEURS_SUPPRIMER_GROUPE_FAIL = 'ADMIN_UTILISATEURS_SUPPRIMER_GROUPE_FAIL';
export const ADMIN_UTILISATEURS_SUPPRIMER_GROUPE_RESET = 'ADMIN_UTILISATEURS_SUPPRIMER_GROUPE_RESET';

export const ADMIN_UTILISATEURS_INITIATE_SUPPRIMER_UTILISATEUR = 'ADMIN_UTILISATEURS_INITIATE_SUPPRIMER_UTILISATEUR';
export const ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_START = 'ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_START';
export const ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_SUCCESS = 'ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_SUCCESS';
export const ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_FAIL = 'ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_FAIL';
export const ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_RESET = 'ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_RESET';



/**** GROUPE *****/

export const ADMIN_UTILISATEURS_INITIATE_FETCH_GROUPE = 'ADMIN_UTILISATEURS_INITIATE_FETCH_GROUPE';
export const ADMIN_UTILISATEURS_FETCH_GROUPE_START = 'ADMIN_UTILISATEURS_FETCH_GROUPE_START';
export const ADMIN_UTILISATEURS_FETCH_GROUPE_SUCCESS = 'ADMIN_UTILISATEURS_FETCH_GROUPE_SUCCESS';
export const ADMIN_UTILISATEURS_FETCH_GROUPE_FAIL = 'ADMIN_UTILISATEURS_FETCH_GROUPE_FAIL';
export const ADMIN_UTILISATEURS_FETCH_GROUPE_RESET = 'ADMIN_UTILISATEURS_FETCH_GROUPE_RESET';


/**** GROUPE && SOUS GROUPE *****/

export const ADMIN_UTILISATEURS_MODIFIER_GROUPE_INITIATE_AJOUTER_SOUS_GROUPE = 'ADMIN_UTILISATEURS_MODIFIER_GROUPE_INITIATE_AJOUTER_SOUS_GROUPE';
export const ADMIN_UTILISATEURS_MODIFIER_GROUPE_AJOUTER_SOUS_GROUPE_START = 'ADMIN_UTILISATEURS_MODIFIER_GROUPE_AJOUTER_SOUS_GROUPE_START';
export const ADMIN_UTILISATEURS_MODIFIER_GROUPE_AJOUTER_SOUS_GROUPE_SUCCESS = 'ADMIN_UTILISATEURS_MODIFIER_GROUPE_AJOUTER_SOUS_GROUPE_SUCCESS';
export const ADMIN_UTILISATEURS_MODIFIER_GROUPE_AJOUTER_SOUS_GROUPE_FAIL = 'ADMIN_UTILISATEURS_MODIFIER_GROUPE_AJOUTER_SOUS_GROUPE_FAIL';
export const ADMIN_UTILISATEURS_MODIFIER_GROUPE_AJOUTER_SOUS_GROUPE_RESET = 'ADMIN_UTILISATEURS_MODIFIER_GROUPE_AJOUTER_SOUS_GROUPE_RESET';

export const ADMIN_UTILISATEURS_MODIFIER_GROUPE_INITIATE_SUPPRIMER_SOUS_GROUPE = 'ADMIN_UTILISATEURS_MODIFIER_GROUPE_INITIATE_SUPPRIMER_SOUS_GROUPE';
export const ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUPPRIMER_SOUS_GROUPE_START = 'ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUPPRIMER_SOUS_GROUPE_START';
export const ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUPPRIMER_SOUS_GROUPE_SUCCESS = 'ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUPPRIMER_SOUS_GROUPE_SUCCESS';
export const ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUPPRIMER_SOUS_GROUPE_FAIL = 'ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUPPRIMER_SOUS_GROUPE_FAIL';
export const ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUPPRIMER_SOUS_GROUPE_RESET = 'ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUPPRIMER_SOUS_GROUPE_RESET';


/**** GROUPE && UTILISATEUR *****/

export const ADMIN_UTILISATEURS_INITIATE_AJOUTER_UTILISATEUR_AU_GROUPE = 'ADMIN_UTILISATEURS_INITIATE_AJOUTER_UTILISATEUR_AU_GROUPE';
export const ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_START = 'ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_START';
export const ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_SUCCESS = 'ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_SUCCESS';
export const ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_FAIL = 'ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_FAIL';
export const ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_RESET = 'ADMIN_UTILISATEURS_AJOUTER_UTILISATEUR_AU_GROUPE_RESET';

export const ADMIN_UTILISATEURS_INITIATE_SUPPRIMER_UTILISATEUR_AU_GROUPE = 'ADMIN_UTILISATEURS_INITIATE_SUPPRIMER_UTILISATEUR_AU_GROUPE';
export const ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_START = 'ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_START';
export const ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_SUCCESS = 'ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_SUCCESS';
export const ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_FAIL = 'ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_FAIL';
export const ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_RESET = 'ADMIN_UTILISATEURS_SUPPRIMER_UTILISATEUR_AU_GROUPE_RESET';


/**** APP ****/

export const ERRORS_THROW_ERROR_404 ='ERRORS_THROW_ERROR_404';
export const ERRORS_REMOVE_ERROR_404 ='ERRORS_REMOVE_ERROR_404';


export const ERRORS_THROW_ERROR_500 ='ERRORS_THROW_ERROR_500';
export const ERRORS_REMOVE_ERROR_500 ='ERRORS_REMOVE_ERROR_500';





/**** ASSIGNEMENTS GROUPE COURS *****/

export const ADMIN_ASSIGNEMENTS_GROUPE_COURS_INITIATE_AJOUTER_ASSIGNEMENT_GROUPE_COURS = 'ADMIN_ASSIGNEMENTS_GROUPE_COURS_INITIATE_AJOUTER_ASSIGNEMENT_GROUPE_COURS';
export const ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_START = 'ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_START';
export const ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_SUCCESS = 'ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_SUCCESS';
export const ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_FAIL = 'ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_FAIL';
export const ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_RESET = 'ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_RESET';

export const ADMIN_ASSIGNEMENTS_GROUPE_COURS_INITIATE_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS = 'ADMIN_ASSIGNEMENTS_GROUPE_COURS_INITIATE_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS';
export const ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_START = 'ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_START';
export const ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_SUCCESS = 'ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_SUCCESS';
export const ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_FAIL = 'ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_FAIL';
export const ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_RESET = 'ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_RESET';




/**** ASSIGNEMENTS GROUPE CATEGORIE *****/

export const ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_INITIATE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE = 'ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_INITIATE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE';
export const ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_START = 'ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_START';
export const ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_SUCCESS = 'ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_SUCCESS';
export const ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_FAIL = 'ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_FAIL';
export const ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_RESET = 'ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_RESET';

export const ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_INITIATE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE = 'ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_INITIATE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_';
export const ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_START = 'ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_START';
export const ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_SUCCESS = 'ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_SUCCESS';
export const ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_FAIL = 'ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_FAIL';
export const ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_RESET = 'ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_RESET';





/**** ASSIGNEMENTS GROUPE COURS *****/

export const ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_INITIATE_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_INITIATE_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_START = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_START';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_SUCCESS = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_SUCCESS';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_FAIL = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_FAIL';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_RESET = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_AJOUTER_ASSIGNEMENT_UTILISATEUR_COURS_RESET';

export const ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_INITIATE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_INITIATE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_START = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_START';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_SUCCESS = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_SUCCESS';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_FAIL = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_FAIL';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_RESET = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_RESET';




/**** ASSIGNEMENTS GROUPE CATEGORIE *****/

export const ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_INITIATE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_INITIATE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_START = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_START';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_SUCCESS = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_SUCCESS';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_FAIL = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_FAIL';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_RESET = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_RESET';

export const ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_INITIATE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_INITIATE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_START = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_START';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_SUCCESS = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_SUCCESS';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_FAIL = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_FAIL';
export const ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_RESET = 'ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_RESET';



export * from "./actionTypes2";