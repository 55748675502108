import React, {useState} from "react";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";


import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Alert from '@material-ui/lab/Alert';
import {CircularProgress} from "@material-ui/core";

import cssClasses from "./new-password.module.css"

import {validations} from "../../../utils/utils";

import logoImage from "../../../assets/fullLogo.png";
import useQuery from "../../../hooks/useQuery";

const initialState = {
    newPassword: {
        value: "",
        error: null
    },
    confirmNewPassword: {
        value: "",
        error: null
    },

};


function NewPasswordPage(props) {

    const query = useQuery();

    const [state, setState] = useState(initialState);


    const newPasswordOnChangeHandler = (event) => {
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                newPassword: {
                    value: value,
                    error: error
                }
            }
        });
    };

    const confirmNewPasswordOnChangeHandler = (event) => {
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                confirmNewPassword: {
                    value: value,
                    error: error
                }
            }
        });
    };
    const formOnSubmitHandler = (event) => {

        event.preventDefault();

        let requirementsError = false;

        const newPassword = state.newPassword.value;
        const confirmNewPassword = state.confirmNewPassword.value;

        if (!newPassword) {
            setState(prevState => {
                return {
                    ...prevState,
                    newPassword: {
                        ...prevState.newPassword,
                        error: "Le mot de passe est obligatoire"
                    }
                }
            });
            requirementsError = true;
        } else if (!validations.validateLength(newPassword, 8)) {
            setState(prevState => {
                return {
                    ...prevState,
                    newPassword: {
                        ...prevState.newPassword,
                        error: "Le mot de passe doit etre 8 characteres au minimum"
                    }
                }
            });
            requirementsError = true;
        }

        if (!confirmNewPassword) {
            setState(prevState => {
                return {
                    ...prevState,
                    confirmPassword: {
                        ...prevState.confirmPassword,
                        error: "La confirmation du mot de passe est obligatoire"
                    }
                }
            });
            requirementsError = true;
        } else if (newPassword && newPassword !== confirmNewPassword) {
            setState(prevState => {
                return {
                    ...prevState,
                    confirmNewPassword: {
                        ...prevState.confirmNewPassword,
                        error: "Les mots de passe ne sont pas identiques"
                    }
                }
            });
            requirementsError = true;
        }

        if (!requirementsError) {
            props.newPassword(query.get("email"), query.get("token"), newPassword);
        }

    };


    /*** css ***/

    const formClasses = [cssClasses.signInForm, "text-center", "border", "rounded", "p-3", "mx-auto"];
    const inputClasses = ["my-2"];
    const topContentClasses = ["text-center"];

    /*** end css ***/


    return (
        <>

            <div className={topContentClasses.join(" ")}>
                <img height={200} src={logoImage}/>
            </div>

            <form className={formClasses.join(" ")} onSubmit={formOnSubmitHandler}>
                {props.newPasswordState.error &&
                <Alert className={"mt-2 mb-3"} variant="filled"
                       severity="error">{props.newPasswordState.error.message}</Alert>
                }
                <TextField id={"email"}
                           disabled
                           className={inputClasses.join(" ")} value={query.get("email")}
                           fullWidth={true} size={"small"} label="Email" variant="outlined" autocomplete="email"/>
                <TextField autocomplete="new-password" className={inputClasses.join(" ")}
                           fullWidth={true} size={"small"} type={"password"}
                           label={<>Mot de passe<span className={"text-danger"}>*</span></>}
                           variant="outlined" onChange={newPasswordOnChangeHandler}
                           value={state.newPassword.value} error={state.newPassword.error}
                           helperText={state.newPassword.error}/>
                <TextField autocomplete="new-password" className={inputClasses.join(" ")}
                           fullWidth={true} size={"small"} type={"password"}
                           label={<>Confirmer mot de passe<span
                               className={"text-danger"}>*</span></>} variant="outlined"
                           onChange={confirmNewPasswordOnChangeHandler}
                           value={state.confirmNewPassword.value} error={state.confirmNewPassword.error}
                           helperText={state.confirmNewPassword.error}/>
                <div className={"position-relative"}>
                    <Button disabled={props.newPasswordState.loading} type={"submit"} className={inputClasses.join(" ")}
                            color={"primary"} variant={"contained"} endIcon={<Icon className={"fa fa-unlock-alt"}/>}>Se
                        Connecter</Button>
                    {props.newPasswordState.loading && <CircularProgress size={24} className={"spinner"}/>}
                </div>

            </form>

        </>

    );


}

const mapStateToProps = (state) => {
    return {
        newPasswordState: {...state.newPassword},
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        newPassword: (email, token, newPassword) => {
            dispatch(actions.authNewPassword(email, token, newPassword))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordPage);