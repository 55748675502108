import * as actionTypes from "../../../../actions/actionTypes";


export const adminCoursFetchListeCours = () => {
    return {
        type: actionTypes.ADMIN_COURS_INITIATE_FETCH_LISTE_COURS,
    }
};

export const adminCoursFetchListeCoursStart = () => {
    return {
        type: actionTypes.ADMIN_COURS_FETCH_LISTE_COURS_START
    }
};

export const adminCoursFetchListeCoursSuccess = (listeCours) => {
    return {
        type: actionTypes.ADMIN_COURS_FETCH_LISTE_COURS_SUCCESS,
        payload : {
            listeCours: listeCours
        }
    }
};

export const adminCoursFetchListeCoursFail = (error) => {
    return {
        type: actionTypes.ADMIN_COURS_FETCH_LISTE_COURS_FAIL,
        payload : {
            error : error
        }
    }
};
