import React from "react";
import { Box, Container, Grid, Typography, Link } from "@material-ui/core";
import { Facebook, Twitter, Instagram, Phone } from "@material-ui/icons";
import { homePage, lmdPage, prepaPage } from "../../routes/guestRoutes";
const Footer = () => {
  return (
    <Box
      component="footer"
      style={{
        backgroundColor: "#3f51b5",
        color: "white",
        padding: "16px 0",
        marginTop: "auto",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Prépa Profs
            </Typography>
            <Link
              to={prepaPage.path}
              color="inherit"
              style={{ display: "block" }}
            >
              Préparatoire
            </Link>
            <Link
              to={prepaPage.path}
              color="inherit"
              style={{ display: "block" }}
            >
              Préparatoire Intégrée
            </Link>
            <Link
              to={lmdPage.path}
              color="inherit"
              style={{ display: "block" }}
            >
              LMD
            </Link>
            {/* <Link to={prepaPage.path} color="inherit" style={{ display: "block" }}>
              Nos offres
            </Link> */}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              About Us
            </Typography>
            <Link
              to={homePage.path}
              color="inherit"
              style={{ display: "block" }}
            >
              About Us
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <Box>
              <Link
                href="https://www.facebook.com/prepaprof"
                color="inherit"
                style={{ marginRight: "8px" }}
              >
                <Facebook />
              </Link>
              <Link href="#" color="inherit" style={{ marginRight: "8px" }}>
                <Twitter />
              </Link>
              <Link href="#" color="inherit">
                <Instagram />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Contact
            </Typography>
            <Box display="flex" alignItems="center">
              {/* <Phone style={{ marginRight: "8px" }} />
              <Typography>+216 234 567 890</Typography> */}
            </Box>
            {/* <Typography variant="body2" style={{ marginTop: "8px" }}>
              1234 Street Name, City, State, 12345
            </Typography> */}
          </Grid>
        </Grid>
        <Box textAlign="center" paddingTop="16px">
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} Prépa Profs. All rights reserved.
          </Typography>
          <a
            href="https://storyset.com/people"
            style={{
              fontSize: "10px",
            }}
          >
            People illustrations by Storyset
          </a>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
