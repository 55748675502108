import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminUtilisateursSupprimerUtilisateurAuGroupeSaga (action) {

    yield put(actions.adminUtilisateursSupprimerUtilisateurAuGroupeStart());

    const utilisateurId = action.payload.utilisateurId;
    const groupeId = action.payload.groupeId;

    try {
        const response = yield axios.delete(generateDynamicRoute(api.supprimerUtilisateurAuGroupe,[{toReplace:":utilisateurId",value:utilisateurId},{toReplace:":groupeId",value:groupeId}]));
        yield put(actions.adminUtilisateursSupprimerUtilisateurAuGroupeSuccess(utilisateurId,groupeId));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursSupprimerUtilisateurAuGroupeFail(error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursSupprimerUtilisateurAuGroupeFail("There's an error!"));
        }
    }
}

