import * as actionTypes from "../../../../actions/actionTypes";

export const adminUtilisateursFetchUtilisateur = (utilisateurId) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_FETCH_UTILISATEUR,
        payload : {
            utilisateurId : utilisateurId
        }
    }
};

export const adminUtilisateursFetchUtilisateurStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_UTILISATEUR_START
    }
};

export const adminUtilisateursFetchUtilisateurSuccess = (utilisateur) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_UTILISATEUR_SUCCESS,
        payload : {
            utilisateur: utilisateur
        }
    }
};

export const adminUtilisateursFetchUtilisateurFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_UTILISATEUR_FAIL,
        payload : {
            error : error
        }
    }
};
