import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursSaga (action) {

    yield put(actions.adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursStart());

    const assignementGroupeCoursId = action.payload.assignementGroupeCoursId;

    try {
        const response = yield axios.delete(generateDynamicRoute(api.supprimerAssignementGroupeCours,[{toReplace:":assignementGroupeCoursId",value:assignementGroupeCoursId}]));
        yield put(actions.adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursSuccess(assignementGroupeCoursId));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursFail(error.response.data.error));
        }else {
            yield put(actions.adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursFail("There's an error!"));
        }
    }
}

