import * as actionTypes from "../../../../actions/actionTypes";

export const adminUtilisateursFetchGroupe = (groupeId) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_FETCH_GROUPE,
        payload : {
            groupeId : groupeId
        }
    }
};

export const adminUtilisateursFetchGroupeStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_START
    }
};

export const adminUtilisateursFetchGroupeSuccess = (groupe) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_SUCCESS,
        payload : {
            groupe: groupe
        }
    }
};

export const adminUtilisateursFetchGroupeFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_FAIL,
        payload : {
            error : error
        }
    }
};

export const adminUtilisateursFetchGroupeReset = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_FETCH_GROUPE_RESET
    }
};
