import React, {useEffect, useState} from "react";

import Button from '@material-ui/core/Button';
import Icon from "@material-ui/core/Icon";
import Badge from '@material-ui/core/Badge';
import Grow from '@material-ui/core/Grow';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';

import LogoHeader from "../../../assets/logoHeader.png"
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import AccountCircle from '@material-ui/icons/AccountCircle';

import * as authActions from "../../../store/actions/authActions";

import PersonAddIcon from '@material-ui/icons/PersonAdd';

import cssClasses from "./topheader.module.css";
import {Link, NavLink} from "react-router-dom";


import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


import Popper from '@material-ui/core/Popper';

import IconButton from '@material-ui/core/IconButton';
//import {adminUtilisateursAjouterUtilisateur} from "../../../store/actions/admin/utilisateurs/ajouterUtilisateurActions";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../store/actions/admin/utilisateurs/listeGroupesActions";
//import {adminUtilisateursFetchListeUtilisateurs} from "../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import NotificationMenuItem from "../../../components/NotificationMenuItem";
import SockJS from "sockjs-client";
import Stomp from "stompjs";





import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import {shoppingCartPage} from "../../../routes/utilisateurRoutes";
import {OffersPage, profilePage, studentCartPage} from "../../../routes/studentRoutes";
import {explorerCoursPage, explorerOffresPage, homePage} from "../../../routes/guestRoutes";




import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import PaymentIcon from "@material-ui/icons/Payment";



function TopHeader(props) {




    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const open = Boolean(anchorEl);


    const [open4, setOpen4] = React.useState(false);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const anchorRef = React.useRef(null);


    const handleClose4 = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen4(false);
    };



    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open4 === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open4;
    }, [open4]);








    return (
        <div>
            <AppBar position="static">
                <Toolbar className={"d-flex justify-content-between"}>

                    <div className={"d-flex position-absolute"} style={{right:10}}>

<>
                            <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Icon className={"fa fa-user-circle"} />
                        </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem><h5 className={"my-3"}><b>{props.user.firstName} {props.user.lastName}</b></h5></MenuItem>
                                <Link to={profilePage.path}>
                                    <MenuItem onClick={handleClose}>Profil</MenuItem>
                                </Link>
                                <MenuItem onClick={() => {handleClose(); props.onLogout()}}>Se déconnecter</MenuItem>
                            </Menu>


                        </>


                    </div>
                </Toolbar>
            </AppBar>
        </div>
    )

}


const mapStateToProps = (state) => {
    return {

        isAuthenticated: state.authSignIn.token != null,
        user: state.authSignIn.user,
        token: state.authSignIn.token,

    }
};

const mapDispatchToProps = (dispatch) => {
    return {

        onLogout : () => dispatch(actions.logout()),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(TopHeader)