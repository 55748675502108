import React, {useEffect, useState} from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

import {Box} from "@material-ui/core";


import Button from '@material-ui/core/Button';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckBoxIcon from '@material-ui/icons/CheckBox';


import {useHistory} from "react-router-dom";
import BlockIcon from '@material-ui/icons/Block';


import {connect} from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import Alert from "@material-ui/lab/Alert";
import DeleteWithNoOption from "../../../../components/DeleteWithNoOption";
import {
    adminCommandesFetchListeCommandes,
    adminCommandesFetchListeCommandesReset
} from "../../../../store/actions/admin/commandes/listeCommandesActions";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import GavelOutlinedIcon from "@material-ui/icons/GavelOutlined";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

function GererCommandes(props) {


    const [supprimerCommandeDialog, setSupprimerCommandeDialog] = useState({open: false, commandeId: null});

    const handleOpenSupprimerCommandeDialog = (commandeId) => {
        setSupprimerCommandeDialog({open: true, commandeId: commandeId});
    };

    const handleCloseSupprimerCommandeDialog = () => {
        setSupprimerCommandeDialog({open: false, commandeId: null});
    };

    const history = useHistory();


    /*

        useEffect(() => {

            if(props.supprimerCategorie.success || props.supprimerCours.success) {
                props.fetchListeCategories();
                props.fetchListeCours();
            }

        },[props.supprimerCategorie.success,props.supprimerCours.success])

        useEffect(() => {
            if(selected != null) {
                setActiveButtons(prevState => {
                    return{
                        ...prevState,
                        editButtonActive : true,
                        deleteButtonActive : true,
                        viewButtonActive : true
                    };
                });
            }
        },[selected]);
    */

    useEffect(() => {
        props.fetchListeCommandes();
    }, []);

    const handleSupprimerCommande = () => {
        if (supprimerCommandeDialog.commandeId) {
            props.supprimerCommandeReset();

            props.supprimerCommande(supprimerCommandeDialog.commandeId);

            handleCloseSupprimerCommandeDialog();
        }
    };

    /*
        const handleVoirDetails = () => {
            if(selected.startsWith("Categorie")) {
                const categorieId = selected.replace("Categorie","");
                history.push(generateDynamicRoute(AdminCoursDetailsCategoriePage.path,[{toReplace:":categorieId",value: categorieId}]));
            } else if(selected.startsWith("Cours")) {
                const coursId = selected.replace("Cours","");
                history.push(generateDynamicRoute(AdminCoursDetailsCoursPage.path,[{toReplace:":coursId",value: coursId}]))
            }
        };

        const handleModifier = () => {
            if(selected.startsWith("Categorie")) {
                const categorieId = selected.replace("Categorie","");
                history.push(generateDynamicRoute(AdminCoursModifierCategoriePage.path,[{toReplace:":categorieId",value: categorieId}]));
            } else if(selected.startsWith("Cours")) {
                const coursId = selected.replace("Cours","");
                history.push(generateDynamicRoute(AdminCoursModifierCoursPage.path,[{toReplace:":coursId",value: coursId}]));
            }

        };

    */


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const prixArticle = (article) => {
        if (article.offre.periodeFixe)
            return article.offre.prix;
        switch (article.duree) {
            case 1 :
                return article.offre.prix1Mois;
            case 3 :
                return article.offre.prix3Mois;
            case 6 :
                return article.offre.prix6Mois;
            case 12 :
                return article.offre.prix12Mois;

        }
    };


    const commandeStatut = (commande) => {

        if (commande.paiement) {

            switch (commande.paiement.statut) {
                case "Payé" :
                    return <Alert icon={<CheckCircleOutlineIcon fontSize="inherit"/>} className={"ml-3 px-4"}
                                  variant="filled" severity="success">
                        Payé
                    </Alert>;
                case "Refusé" :
                    return <Alert icon={<CancelOutlinedIcon fontSize="inherit"/>} className={"ml-3 px-4"}
                                  variant="filled" severity="error">
                        Refusé
                    </Alert>;
                case "En Attente De Confirmation" :
                    return <Alert icon={<GavelOutlinedIcon fontSize="inherit"/>} className={"ml-3 px-4"}
                                  variant="filled" severity="warning">
                        En Attente De Confirmation
                    </Alert>;
            }
        } else {
            return <Alert icon={<AccessTimeIcon fontSize="inherit"/>} className={"ml-3 px-4"} variant="filled"
                          severity="info">
                En attente de paiement
            </Alert>;
        }
    };


    const calculTotal = (commande) => {
        let total = 0;
        commande.articleCommandes.forEach((article) => {
            total += prixArticle(article);
        });
        return total;
    };


    const isDisabled = (commande) => {
        if (!commande.paiement)
            return true;
        else {
            if (commande.paiement.statut == "En Attente De Confirmation") {
                return false;
            }
            return true;
        }
    }


    return (
        <>
            <div className={"p-5"}>
                <Backdrop style={{zIndex: 999}} open={false}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <h1 className={"text-center mt-3 mb-4"}>Gérer les commandes</h1>

                {/*props.supprimerCategorie.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.supprimerCategorie.error}</Alert>
            }
            {props.supprimerCategorie.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Le categorie supprimé avec succees</Alert>
            }

            {props.supprimerCours.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.supprimerCours.error}</Alert>
            }
            {props.supprimerCours.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'Cours supprimé avec succees</Alert>
            */}


                <Box className={"border rounded"}>

                    {!props.fetchListeCommandesState.loading &&
                    <div>


                        <Paper>
                            <TableContainer>
                                <Table aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Numéro Commande</TableCell>
                                            <TableCell align="center">Total</TableCell>
                                            <TableCell align="center">Numéro Transaction</TableCell>
                                            <TableCell align="center">Statut</TableCell>
                                            <TableCell align="center">Accepter</TableCell>
                                            <TableCell align="center">Refusé</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.fetchListeCommandesState.listeCommandes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((commande) => {
                                            return (
                                                <TableRow key={commande.id}>
                                                    <TableCell align={"center"}>
                                                        {commande.id}
                                                    </TableCell>

                                                    <TableCell align="center">{calculTotal(commande)} DT</TableCell>

                                                    <TableCell align="center">{
                                                        commande.paiement ? commande.paiement.numeroTransaction : "Non Payé"
                                                    }</TableCell>
                                                    <TableCell align={"center"}>

                                                        {commandeStatut(commande)}
                                                    </TableCell>

                                                    <TableCell align="center">

                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            startIcon={<CheckBoxIcon/>}
                                                            disabled={isDisabled(commande)}
                                                        >
                                                            Accepter
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell align="right">


                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size="small"
                                                            startIcon={<BlockIcon/>}
                                                            disabled={isDisabled(commande)}
                                                        >
                                                            Refuser
                                                        </Button>

                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={props.fetchListeCommandesState.listeCommandes.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>

                    </div>
                    }
                    <DeleteWithNoOption open={supprimerCommandeDialog.open} onClose={handleCloseSupprimerCommandeDialog}
                                        supprimer={handleSupprimerCommande}
                                        dialogTitle={"Voulez vous vraiment supprimer l'offre ?"}/>

                </Box>
            </div>
        </>

    );


}

const mapStateToProps = (state) => {
    return {/*
        supprimerCategorie : {
            loading : state.admin.cours.supprimerCategorie.loading,
            error : state.admin.cours.supprimerCategorie.error,
            success : state.admin.cours.supprimerCategorie.success
        },
        supprimerCours : {
            loading : state.admin.cours.supprimerCours.loading,
            error : state.admin.cours.supprimerCours.error,
            success :  state.admin.cours.supprimerCours.success
        },
        listeCategories : {
            loading : state.admin.cours.listeCategories.loading,
            error : state.admin.cours.listeCategories.error,
            listeCategories : state.admin.cours.listeCategories.listeCategories,
        },*/
        fetchListeCommandesState: state.admin.commandes.listeCommandes,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchListeCommandes: () => dispatch(adminCommandesFetchListeCommandes()),
        fetchListeCommandesReset: () => dispatch(adminCommandesFetchListeCommandesReset()),
        /*supprimerCours : (coursId) => dispatch(adminCoursSupprimerCours(coursId)),
        supprimerCoursReset : () => dispatch(adminCoursSupprimerCoursReset()),*/
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GererCommandes);

