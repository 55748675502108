import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"
import * as api from "../../../../../api";

import * as actions from "../../../../actions";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminUtilisateursFetchGroupeSaga(action) {

    const groupeId = action.payload.groupeId;

    yield put(actions.adminUtilisateursFetchGroupeStart());

    try {
        const response = yield axios.get(generateDynamicRoute(api.getGroupe,[{toReplace: ":groupeId",value:groupeId}]));
        yield put(actions.adminUtilisateursFetchGroupeSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursFetchGroupeFail(error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursFetchGroupeFail("There's an error!"));
        }
    }
}