import React, {useEffect} from "react";
import RessourceModal from "../../../../components/RessourceModal";
import {Route, Link, useLocation, useHistory, useParams} from "react-router-dom";
import {explorerCoursPage, homePage} from "../../../../routes/guestRoutes";
import {useLastLocation} from "react-router-last-location";
import CoursCategorieTopCard from "../../../../components/CoursCategorieTopCard";
import TeacherRessourceList from "../../../../components/TeacherRessourceList";
import {CoursePage, TeacherMyCoursesPage, ressourcePage} from "../../../../routes/teacherRoutes";
import {
    etudiantMesCoursFetchCours,
    etudiantMesCoursFetchCoursReset,
    etudiantMesCoursFetchCoursRessources,
    etudiantMesCoursFetchCoursRessourcesReset
} from "../../../../store/actions/etudiant/mesCours";
import {connect} from "react-redux";
import CoursCategorieTopCardSkeleton from "../../../../UI/LoadingSkeleton/CoursCategorieTopCardSkeleton";
import RessourceListSkeleton from "../../../../UI/LoadingSkeleton/RessourceListSkeleton";
import RessourcePage from "./Ressource";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {dateFormatWithHoursAndMinutes, generateDynamicRoute} from "../../../../utils/utils";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import VideocamIcon from "@material-ui/icons/Videocam";
import ListItemText from "@material-ui/core/ListItemText";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

function Course(props) {


    let { coursId } = useParams();

    useEffect(()=> {
        props.fetchCours(coursId);
        props.fetchRessources(coursId);

        return () => {
            props.resetCours();
            props.resetRessources();
        }
    },[]);

    return (

        <>
            <Link to={TeacherMyCoursesPage.path}>
                    <IconButton className={"my-3"}>
                        <ArrowBackIcon />
                    </IconButton>
            </Link>

            {props.loading && <CoursCategorieTopCardSkeleton/>}
            {!props.loading && !props.error && <CoursCategorieTopCard item={props.cours}/>}

            {props.loadingRessources && <RessourceListSkeleton/>}
            {(!props.loading && !props.loadingRessources && !props.errorRessources) && <TeacherRessourceList coursId={props.cours.id} openRessource={true} sectionsRessources={props.listeRessources}/>}


            {!props.loading && !props.error && props.cours.liveSessions.length > 0 &&
                <>
                    <h3>Les sessions en direct :</h3>
                    {
                        props.cours.liveSessions.map(liveSession => {
                            return (
                                <div className={"border rounded my-4 tex-center d-flex justify-content-between align-items-center p-3"} >
                                    <div>
                                        <h5>{liveSession.title}</h5>
                                        <div>Le {dateFormatWithHoursAndMinutes(liveSession.date)}</div>
                                    </div>
                                    <a href={liveSession.link} target={"_blank"}>
                                        <IconButton variant={"contained"} color={"primary"}>
                                            <Icon className={"w-100 fa fa-eye"} />
                                        </IconButton>
                                    </a>
                                </div>
                            );
                        })
                    }
                </>
            }

            <Route path={ressourcePage.path} component={RessourcePage} />

        </>


    );

}

const mapStateToProps = (state) => {
    return {
        loading : state.etudiant.mesCours.cours.loading,
        error : state.etudiant.mesCours.cours.error,
        cours : state.etudiant.mesCours.cours.cours,
        loadingRessources : state.etudiant.mesCours.listeRessources.loading,
        errorRessources : state.etudiant.mesCours.listeRessources.error,
        listeRessources : state.etudiant.mesCours.listeRessources.listeRessources,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCours : (coursId) => dispatch(etudiantMesCoursFetchCours(coursId)),
        resetCours : () => dispatch(etudiantMesCoursFetchCoursReset()),
        fetchRessources : (coursId) => dispatch(etudiantMesCoursFetchCoursRessources(coursId)),
        resetRessources : () => dispatch(etudiantMesCoursFetchCoursRessourcesReset())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Course);