import React, {useEffect} from "react";

import Modal from '@material-ui/core/Modal';

import FormHelperText from '@material-ui/core/FormHelperText';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import {CircularProgress, Grid} from "@material-ui/core";
import {connect} from "react-redux";
import Chip from "@material-ui/core/Chip";


import Alert from "@material-ui/lab/Alert";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';



import DateFnsUtils from '@date-io/date-fns';

import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TreeListe from "../../../../../components/TreeListe";
import Box from "@material-ui/core/Box";
import {enseignantMesCoursAjouterSection} from "../../../../../store/actions/enseignant/mesCours/ajouterSection";
const initialState = {
    nom: {value : "", error : null},

    sectionParent: {value : null, error : null},

    sectionsExpanded : false,
};

function AjouterSection(props) {


    const handleSectionsExpanded = (event,isExpanded) => {
        setState(prevState => {
            return {
                ...prevState,
                sectionsExpanded : isExpanded
            }
        });
    };

    const isSectionSelected = (sectionToCheck) => {
        return state.sectionParent.value && state.sectionParent.value.id === sectionToCheck.id;
    };

    const handleSectionUnSelect = () => {
        setState(prevState => {
            return {
                ...prevState,
                sectionParent: {value: null, error :null}
            };
        });
    };


    useEffect(() => {
        return () => {

        }
    },[]);

    const [state, setState] = React.useState(initialState);

    const formOnSubmitHandler = (event) => {

        event.preventDefault();

        let error = false;

        const nom = state.nom.value;

        if(!nom) {
            error = true;
            setState(prevState => {
                return {
                    ...prevState,
                    nom: {
                        value: null,
                        error : "Le nom est obligatoire"
                    }
                }
            })
        }


        if(!error) {

            const section = {
                nom : nom,
                cours : {
                    id : props.coursId
                },
                sectionParente :state.sectionParent.value? {
                    id :  state.sectionParent.value.id }: null

            };


           props.ajouterSection(section);
        }

    };

    const nomOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                nom : {
                    value : value,
                    error: error
                }
            }
        });
    };


    useEffect(() => {

        if(props.ajouterSectionState.success)
            setState(prevState => {
                return {
                    ...prevState,
                    nom : {
                        value : "",
                        error : null
                    },

                    sectionParent:{value : null, error : null}
                }
            })

    },[props.ajouterSectionState.success]);


    const handleSectionSelect = (section) => {
        setState(prevState => {
            return {
                ...prevState,
                sectionParent:{value : section, error : null}
            };
        });
    };


    return(
        <Modal
            open={props.open}
            onClose={props.handleClose}
            style={{overflowY:"auto"}}
        >
            <div style={{maxWidth:"700px", width:"100%"}} className={"bg-white text-center border rounded mx-auto my-5"}>

                <h2>Ajouter une section</h2>

                {props.ajouterSectionState.success&& <Alert className={"mt-2 mb-3"} variant="filled" severity="success">La section ajouté avec succees</Alert>
                }


                <form className={"text-left p-4"} onSubmit={formOnSubmitHandler}>

                    <TextField error={state.nom.error ? true : false} helperText={state.nom.error}
                               onChange={nomOnChangeHandler}
                               value={state.nom.value} fullWidth={true} size={"small"} label="Nom"
                               variant="outlined"
                    className={"my-3"}/>



                    <ExpansionPanel expanded={state.sectionsExpanded} onChange={handleSectionsExpanded}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <FormControl error={state.sectionParent.error ? true : false}>
                                <h6>Section Parent : {!state.sectionsExpanded && state.sectionParent.value ? <Chip label={state.sectionParent.value.nom} onDelete={handleSectionUnSelect} /> : null}</h6>
                                {state.sectionParent.error && <FormHelperText>{state.sectionParent.error}</FormHelperText>}
                            </FormControl>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails >
                                <Grid container spacing={3}>

                                    <Grid item xs={12} md={8} >
                                        <span>Selectionner une section</span>
                                        <TreeListe onlySections={true} selectable={"Section"} liste={props.listeSections} handleSelect={handleSectionSelect} handleUnSelect={handleSectionUnSelect} isSectionSelected={isSectionSelected}/>
                                    </Grid>
                                    <Grid item xs={12} md={4} >
                                        <span>Section Selectionnée</span>
                                        <Box>
                                            {state.sectionParent.value &&
                                            <Chip
                                                label={state.sectionParent.value.nom}
                                                onDelete={handleSectionUnSelect}
                                            />}
                                        </Box>
                                    </Grid>

                                </Grid>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>


                        <div>
                            <Button disabled={props.ajouterSectionState.loading} type={"submit"} className={"my-2"}
                                    color={"primary"} variant={"contained"} endIcon={<PersonAddIcon/>}>Ajouter</Button>
                            {props.ajouterSectionState.loading && <CircularProgress size={24} /*className={spinnerClasses.join(" ")}*/ />}
                        </div>

                </form>


            </div>
        </Modal>
    );




}

const mapStateToProps = (state) => {
    return {
        ajouterSectionState : state.enseignant.mesCours.ajouterSection
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        ajouterSection : (section) => dispatch(enseignantMesCoursAjouterSection(section)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AjouterSection);