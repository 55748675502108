import * as actionTypes from "../../actions/actionTypes";
import {updateObject} from "../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    parametres: []
};

const parametresFetchParametresStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        parametres: []
    });
};

const parametresFetchParametresSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        parametres: action.payload.parametres
    });
};

const parametresFetchParametresFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        parametres: []
    });
};

function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.PARAMETRES_FETCH_PARAMETRES_START : return parametresFetchParametresStart(state,action);
        case actionTypes.PARAMETRES_FETCH_PARAMETRES_SUCCESS : return parametresFetchParametresSuccess(state,action);
        case actionTypes.PARAMETRES_FETCH_PARAMETRES_FAIL : return parametresFetchParametresFail(state,action);

        default : return state;
    }

}

export default reducer;