import * as actionTypes from "../../../actionTypes";

export const etudiantMesCoursFetchRessource = (ressourceId) => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_INITIATE_FETCH_RESSOURCE,
        payload : {
            ressourceId : ressourceId
        }
    }
};

export const etudiantMesCoursFetchRessourceStart = () => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_RESSOURCE_START
    }
};

export const etudiantMesCoursFetchRessourceSuccess = (ressource) => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_RESSOURCE_SUCCESS,
        payload : {
            ressource : ressource
        }
    }
};

export const etudiantMesCoursFetchRessourceFail = (error) => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_RESSOURCE_FAIL,
        payload : {
            error: error
        }
    }
};

export const etudiantMesCoursFetchRessourceReset = () => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_RESSOURCE_RESET
    }
};
