import * as actionTypes from "../../../actions/actionTypes";


export const UtilisateurModifierProfil= (utilisateurId,utilisateur,photo) => {
    return {
        type: actionTypes.UTILISATEUR_INITIATE_MODIFIER_PROFIL,
        payload : {
            utilisateur : utilisateur,
            utilisateurId : utilisateurId,
            photo : photo
        }
    }
};

export const UtilisateurModifierProfilStart = () => {
    return {
        type: actionTypes.UTILISATEUR_MODIFIER_PROFIL_START
    }
};

export const UtilisateurModifierProfilSuccess = (utilisateur) => {
    return {
        type: actionTypes.UTILISATEUR_MODIFIER_PROFIL_SUCCESS,
        payload : {
            utilisateur: utilisateur
        }
    }
};

export const UtilisateurModifierProfilFail = (error) => {
    return {
        type: actionTypes.UTILISATEUR_MODIFIER_PROFIL_FAIL,
        payload : {
            error : error
        }
    }
};


export const UtilisateurModifierProfilReset = () => {
    return {
        type: actionTypes.UTILISATEUR_MODIFIER_PROFIL_RESET
    }
};









export const UtilisateurModifierProfilChangerMotDePasse = (utilisateurId,utilisateur,utilisateur2) => {
    return {
        type: actionTypes.UTILISATEUR_INITIATE_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE,
        payload : {
            utilisateurId : utilisateurId,
            utilisateur : utilisateur,
            utilisateur2 : utilisateur2,
        }
    }
};

export const UtilisateurModifierProfilChangerMotDePasseStart = () => {
    return {
        type: actionTypes.UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_START
    }
};

export const UtilisateurModifierProfilChangerMotDePasseSuccess = () => {
    return {
        type: actionTypes.UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_SUCCESS

    }
};

export const UtilisateurModifierProfilChangerMotDePasseFail = (error) => {
    return {
        type: actionTypes.UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_FAIL,
        payload : {
            error : error
        }
    }
};


export const UtilisateurModifierProfilChangerMotDePasseReset = () => {
    return {
        type: actionTypes.UTILISATEUR_MODIFIER_PROFIL_CHANGER_MOT_DE_PASSE_RESET
    }
};
