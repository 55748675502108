import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeView from "@material-ui/lab/TreeView";
import React, {useEffect, useState} from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import GroupIcon from "@material-ui/icons/Group";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const initialState = {
    expanded : [],
    selected : null,
}

function TreeListeGroupesMultiAdd(props) {


    const [state,setState] = useState(initialState);

    const handleSelect = (event, item) => {
        setState(prevState => {
            return{
                ...prevState,
                selected : item
            };
        });
    };

    const handleExpand = (event, nodeIds) => {
        setState(prevState => {
            return{
                ...prevState,
                expanded : nodeIds
            };
        });
    };




    useEffect(() => {

        if(state.selected != null ) {
            const groupeId = state.selected;
            props.fetchGroupeListeGroupes(groupeId);
        }

    },[state.selected]);

    const groupeTreeItemTemplate = (groupe) => {
        return (
            <TreeItem nodeId={""+groupe.id}
                      label={
                          <div>
                              <GroupIcon/> {groupe.nom} {    (props.isGroupeSelected(groupe)) ?
                              <HighlightOffIcon color={"error"} onClick={(event)=> {event.preventDefault();props.handleGroupeUnSelect(groupe)}} />
                              :
                              <AddCircleOutlineIcon color={"primary"} onClick={(event)=> {event.preventDefault();props.handleGroupeSelect(groupe)}} />
                          }
                          </div>}>
                {
                    (groupe.content.listeGroupes.loading) ?
                        "loading"
                        : groupe.content.listeGroupes.listeGroupes.map(groupe => groupeTreeItemTemplate(groupe))
                }

            </TreeItem>
        );
    };

    const treeItems = props.listeGroupes.map((groupe,index) => {
        return groupeTreeItemTemplate(groupe);
    });

    return(

        <TreeView
            className={"text-left p-3"}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={state.expanded}
            selected={state.selected}
            onNodeToggle={handleExpand}
            onNodeSelect={handleSelect}
            disableSelection={false}
        >
            {
                treeItems
            }
        </TreeView>

    );
}

export default TreeListeGroupesMultiAdd;