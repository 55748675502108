import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* technicianVideoRequestsFetchVideoRequestSaga(action) {

    yield put(actions.technicianVideoRequestsFetchVideoRequestStart());

    const videoRequestId = action.payload.videoRequestId;


    try {
        const response = yield axios.get(generateDynamicRoute(api.technicianVideoRequestsFetchVideoRequest,[{toReplace:":videoRequestId",value:videoRequestId}]));
        console.log(response.data);
        yield put(actions.technicianVideoRequestsFetchVideoRequestSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.technicianVideoRequestsFetchVideoRequestFail(error.response.data.error));
        }else {
            yield put(actions.technicianVideoRequestsFetchVideoRequestFail("There's an error!"));
        }
    }

}