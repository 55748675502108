import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";

export function* guestHomeFetchListeCoursSpecialsSaga(action) {


    yield put(actions.guestHomeFetchListeCoursSpecialStart());

    /*const groupeData = {
        nom : action.payload.nom,
        groupeParent : action.payload.groupeParent ? {
            id : action.payload.groupeParent.id
        } : null
    };
    */
    try {
        //const response = yield axios.get("https://run.mocky.io/v3/28c42324-ef38-44cd-b840-bd33bdedb86e");
        const response = yield axios.get("http://localhost:8080/api/cours?special=true");
        const listeCours = response.data;
        yield listeCours.forEach(cours => {cours["type"] = "Cours"})
        yield put(actions.guestHomeFetchListeCoursSpecialSuccess(listeCours));
    } catch (error) {
        if(error.response) {
            yield put(actions.guestHomeFetchListeCoursSpecialFail(error.response.data.error));
        }else {
            yield put(actions.guestHomeFetchListeCoursSpecialFail("There's an error!"));
        }
    }
}