import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* technicianVideosAddVideoSaga (action) {

    yield put(actions.technicianVideosAddVideoStart());

    const video = action.payload.video;
    const files = action.payload.files;


    const formData = yield new FormData();
    yield formData.append('video', new Blob([JSON.stringify(video)], {
        type: "application/json"
    }));
    yield formData.append('sectionId', new Blob([JSON.stringify(video.section.id)], {
        type: "application/json"
    }));
    yield formData.append('files', new Blob([JSON.stringify(files)], {
        type: "application/json"
    }));


    try {
        const response = yield axios.post(api.technicianVideosAddVideo,formData);
        yield put(actions.technicianVideosAddVideoSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.technicianVideosAddVideoFail(error.response.data.error));
        }else {
            yield put(actions.technicianVideosAddVideoFail("There's an error!"));
        }
    }
}

