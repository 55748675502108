import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    listeOffres: []
};

const adminOffresFetchListeOffresStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        listeOffres: []
    });
};

const adminOffresFetchListeOffresSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        listeOffres: action.payload.listeOffres
    });
};

const adminOffresFetchListeOffresFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        listeOffres: []
    });
};

const adminOffresFetchListeOffresReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};


function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_OFFRES_FETCH_LISTE_OFFRES_START : return adminOffresFetchListeOffresStart(state,action);
        case actionTypes.ADMIN_OFFRES_FETCH_LISTE_OFFRES_SUCCESS : return adminOffresFetchListeOffresSuccess(state,action);
        case actionTypes.ADMIN_OFFRES_FETCH_LISTE_OFFRES_FAIL : return adminOffresFetchListeOffresFail(state,action);
        case actionTypes.ADMIN_OFFRES_FETCH_LISTE_OFFRES_RESET : return adminOffresFetchListeOffresReset(state,action);


        default : return state;
    }

}

export default reducer;