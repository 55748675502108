import * as actionTypes from "../../../../actions/actionTypes";

export const adminUtilisateursModifierGroupe = (groupeId,groupe) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_MODIFIER_GROUPE,
        payload : {
            groupe : groupe,
            groupeId : groupeId
        }
    }
};

export const adminUtilisateursModifierGroupeStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_START
    }
};

export const adminUtilisateursModifierGroupeSuccess = (groupe) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUCCESS,
        payload : {
            groupe: groupe
        }
    }
};

export const adminUtilisateursModifierGroupeFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_FAIL,
        payload : {
            error : error
        }
    }
};


export const adminUtilisateursModifierGroupeReset = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_RESET
    }
};




export const adminUtilisateursModifierGroupeAjouterSousGroupe = (groupeId,sousGroupeId) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_INITIATE_AJOUTER_SOUS_GROUPE,
        payload : {
            groupeId : groupeId,
            sousGroupeId : sousGroupeId
        }
    }
};

export const adminUtilisateursModifierGroupeAjouterSousGroupeStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_AJOUTER_SOUS_GROUPE_START
    }
};

export const adminUtilisateursModifierGroupeAjouterSousGroupeSuccess = (sousGroupe) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_AJOUTER_SOUS_GROUPE_SUCCESS,
        payload : {
            sousGroupe : sousGroupe
        }
    }
};

export const adminUtilisateursModifierGroupeAjouterSousGroupeFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_AJOUTER_SOUS_GROUPE_FAIL,
        payload : {
            error : error
        }
    }
};








export const adminUtilisateursModifierGroupeSupprimerSousGroupe = (groupeId,sousGroupeId) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_INITIATE_SUPPRIMER_SOUS_GROUPE,
        payload : {
            groupeId : groupeId,
            sousGroupeId : sousGroupeId
        }
    }
};

export const adminUtilisateursModifierGroupeSupprimerSousGroupeStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUPPRIMER_SOUS_GROUPE_START
    }
};

export const adminUtilisateursModifierGroupeSupprimerSousGroupeSuccess = (sousGroupeId) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUPPRIMER_SOUS_GROUPE_SUCCESS,
        payload : {
            sousGroupeId : sousGroupeId
        }
    }
};

export const adminUtilisateursModifierGroupeSupprimerSousGroupeFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_MODIFIER_GROUPE_SUPPRIMER_SOUS_GROUPE_FAIL,
        payload : {
            error : error
        }
    }
};
