import {combineReducers} from "redux";

import sideNavReducer from "./sideNav";
import authSignInReducer from "./auth/SignIn";
import requestNewPasswordReducer from "./auth/RequestNewPassword";
import newPasswordReducer from "./auth/NewPassword";
import authSignUpReducer from "./auth/signUp";
import adminReducer from "./admin/";
import guestReducer from "./guest";
import parametresReducer from "./parametres"

import etudiantReducer from "./etudiant";
import enseignantReducer from "./enseignant";

import utilisateurReducer from "./utilisateur";
import initiateAppReducer from "./initiateApp";

import technicianReducer from "./technician";

import errorsReducer from "./errors"

export default combineReducers({
    errors: errorsReducer,
    sideNav: sideNavReducer,
    authSignIn: authSignInReducer,
    requestNewPassword: requestNewPasswordReducer,
    newPassword: newPasswordReducer,
    authSignUp: authSignUpReducer,
    admin: adminReducer,
    etudiant: etudiantReducer,
    enseignant: enseignantReducer,
    guest: guestReducer,
    parametres: parametresReducer,
    utilisateur: utilisateurReducer,
    technician: technicianReducer,
    initiateApp: initiateAppReducer
});