import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";

import * as api from "../../../../../api";

export function* guestExplorerCoursFetchListeCategoriesSaga(action) {


    yield put(actions.guestExplorerCoursFetchListeCategoriesStart());

    try {
        const response = yield axios.get(api.getListeCategoriesRoot);
        const listeCategories = response.data;
        yield listeCategories.forEach(categorie => {categorie["type"] = "Categorie"})
        yield put(actions.guestExplorerCoursFetchListeCategoriesSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.guestExplorerCoursFetchListeCategoriesFail(error.response.data.error));
        }else {
            yield put(actions.guestExplorerCoursFetchListeCategoriesFail("There's an error!"));
        }
    }
}