import * as actionTypes from "../../../../actions/actionTypes";

export const adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCours = (assignementUtilisateurCoursId) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_INITIATE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS,
        payload : {
            assignementUtilisateurCoursId : assignementUtilisateurCoursId
        }
    }
};

export const adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCoursStart = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_START
    }
};

export const adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCoursReset = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_RESET
    }
};

export const adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCoursSuccess = (assignementUtilisateurCoursId) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_SUCCESS,
        payload : {
            assignementUtilisateurCoursId : assignementUtilisateurCoursId
        }
    }
};

export const adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCoursFail = (error) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_FAIL,
        payload : {
            error : error
        }
    }
};
