import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* utilisateurModifierProfilSaga (action) {

    yield put(actions.UtilisateurModifierProfilStart());

    const utilisateurId = action.payload.utilisateurId;
    const user = yield action.payload.utilisateur;



    try {
        const response = yield axios.put(generateDynamicRoute(api.utilisateurModifierProfil, [{
            toReplace: ":utilisateurId",
            value: utilisateurId
        }]), user);
        yield put(actions.UtilisateurModifierProfilSuccess(response.data));
        yield localStorage.setItem("user", JSON.stringify(response.data));
        yield put(actions.authUpdateUtilisateur(response.data));

        yield setTimeout(() => {
            window.location.href = "/";
        }, 2000)

    } catch (error) {
        if(error.response) {
            yield put(actions.UtilisateurModifierProfilFail(error.response.data.error));
        }else {
            yield put(actions.UtilisateurModifierProfilFail("There's an error!"));
        }
    }
}





export function* utilisateurModifierProfilChangerMotDePasseSaga (action) {

    yield put(actions.UtilisateurModifierProfilChangerMotDePasseStart());

    const utilisateurId = action.payload.utilisateurId;
    const utilisateur = yield action.payload.utilisateur;
    const utilisateur2 = yield action.payload.utilisateur2;


    const formData = yield new FormData();
    yield formData.append('newUser', new Blob([JSON.stringify(utilisateur)], {
        type: "application/json"
    }));
    yield formData.append('newUser2', new Blob([JSON.stringify(utilisateur2)], {
        type: "application/json"
    }));

    try {
        const response = yield axios.put(generateDynamicRoute(api.utilisateurModifierProfilChangerMotDePasse,[{toReplace:":utilisateurId",value:utilisateurId}]),formData);
        yield put(actions.UtilisateurModifierProfilChangerMotDePasseSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.UtilisateurModifierProfilChangerMotDePasseFail(error.response.data));
        }else {
            yield put(actions.UtilisateurModifierProfilChangerMotDePasseFail("There's an error!"));
        }
    }
}