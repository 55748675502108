import authRoutes from "./authRoutes";
import guestRoutes from "./guestRoutes";
import userRoutes from "./utilisateurRoutes";
import studentRoutes from "./studentRoutes";
import teacherRoutes from "./teacherRoutes";
import administratorRoutes from "./administratorRoutes";
import technicianRoutes from "./technicianRoutes";

export const topHeaderRoutes = authRoutes;

export const sideNavRoutes = guestRoutes.concat(userRoutes,studentRoutes,teacherRoutes,administratorRoutes,technicianRoutes);

const routes = topHeaderRoutes.concat(sideNavRoutes);

export default routes;
