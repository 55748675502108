import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    cours: null
};

const adminCoursAjouterCoursStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        cours: null
    });
};

const adminCoursAjouterCoursSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        cours: action.payload.cours
    });
};

const adminCoursAjouterCoursFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        cours: null
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_COURS_AJOUTER_COURS_START : return adminCoursAjouterCoursStart(state,action);
        case actionTypes.ADMIN_COURS_AJOUTER_COURS_SUCCESS : return adminCoursAjouterCoursSuccess(state,action);
        case actionTypes.ADMIN_COURS_AJOUTER_COURS_FAIL : return adminCoursAjouterCoursFail(state,action);
        default : return state;
    }

}

export default reducer;