import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";

import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* guestExplorerCoursFetchCategorieSaga(action) {


    yield put(actions.guestExplorerCoursFetchCategorieStart());

    const categorieId = yield action.payload.categorieId;

    try {
        const response = yield axios.get(generateDynamicRoute(api.getCategorie,[{toReplace:":categorieId",value:categorieId}]));
        yield put(actions.guestExplorerCoursFetchCategorieSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.guestExplorerCoursFetchCategorieFail(error.response.data.error));
        }else {
            yield put(actions.guestExplorerCoursFetchCategorieFail("There's an error!"));
        }
    }
}


export function* guestExplorerCoursFetchCategorieListeCategoriesSaga(action) {


    yield put(actions.guestExplorerCoursFetchCategorieStart());

    const categorieId = yield action.payload.categorieId;

    try {
        const response = yield axios.get(generateDynamicRoute(api.guestGetCategorieListeCategories,[{toReplace:":categorieId",value:categorieId}]));
        const listeCategories = response.data;
        listeCategories.forEach(categorie => categorie["type"] = "Categorie");
        yield put(actions.guestExplorerCoursFetchCategorieListeCategoriesSuccess(listeCategories));
    } catch (error) {
        if(error.response) {
            yield put(actions.guestExplorerCoursFetchCategorieListeCategoriesFail(error.response.data.error));
        }else {
            yield put(actions.guestExplorerCoursFetchCategorieListeCategoriesFail("There's an error!"));
        }
    }
}

export function* guestExplorerCoursFetchCategorieListeCoursSaga(action) {


    yield put(actions.guestExplorerCoursFetchCategorieListeCoursStart());

    const categorieId = yield action.payload.categorieId;

    try {
        const response = yield axios.get(generateDynamicRoute(api.guestGetCategorieListeCours,[{toReplace:":categorieId",value:categorieId}]));
        const listeCours = response.data;
        listeCours.forEach(cours => cours["type"] = "Cours");
        yield put(actions.guestExplorerCoursFetchCategorieListeCoursSuccess(listeCours));
    } catch (error) {
        if(error.response) {
            yield put(actions.guestExplorerCoursFetchCategorieListeCoursFail(error.response.data.error));
        }else {
            yield put(actions.guestExplorerCoursFetchCategorieListeCoursFail("There's an error!"));
        }
    }
}