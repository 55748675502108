import {combineReducers} from "redux";

import ajouterGroupeReducer from "./ajouterGroupe"
import modifierGroupeReducer from "./modifierGroupe"
import modifierUtilisateurReducer from "./modifierUtilisateur"
import ajouterUtilisateurReducer from "./ajouterUtilisateur"

import listeGroupesReducer from "./listeGroupes"
import listeUtilisateursReducer from "./listeUtilisateurs"

import supprimerGroupeReducer from "./supprimerGroupe"
import supprimerUtilisateurReducer from "./supprimerUtilisateur"


import ajouterUtilisateurAuGroupeReducer from "./ajouterUtilisateurAuGroupe"
import supprimerUtilisateurAuGroupeReducer from "./supprimerUtilisateurAuGroupe"

import groupeReducer from "./groupe"
import utilisateurReducer from "./utilisateur"


export default combineReducers({
    ajouterGroupe : ajouterGroupeReducer,
    modifierGroupe : modifierGroupeReducer,
    modifierUtilisateur : modifierUtilisateurReducer,
    ajouterUtilisateur : ajouterUtilisateurReducer,
    listeGroupes : listeGroupesReducer,
    listeUtilisateurs : listeUtilisateursReducer,
    supprimerGroupe : supprimerGroupeReducer,
    supprimerUtilisateur : supprimerUtilisateurReducer,
    ajouterUtilisateurAuGroupe : ajouterUtilisateurAuGroupeReducer,
    supprimerUtilisateurAuGroupe : supprimerUtilisateurAuGroupeReducer,
    groupe : groupeReducer,
    utilisateur : utilisateurReducer
});