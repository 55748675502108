import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    video: null
};

const technicianVideosAddVideoStart = (state,action) => {
    return updateObject(state,{
        success : false,
        loading : true,
        error: null,
        video: null
    });
};

const technicianVideosAddVideoSuccess = (state,action) => {
    return updateObject(state,{
        success : true,
        loading : false,
        error: null,
        video: action.payload.video
    });
};

const technicianVideosAddVideoFail = (state,action) => {
    return updateObject(state,{
        success : false,
        loading : false,
        error:action.payload.error,
        video: null
    });
};

const technicianVideosAddVideoReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.TECHNICIAN_VIDEOS_ADD_VIDEO_START : return technicianVideosAddVideoStart(state,action);
        case actionTypes.TECHNICIAN_VIDEOS_ADD_VIDEO_SUCCESS : return technicianVideosAddVideoSuccess(state,action);
        case actionTypes.TECHNICIAN_VIDEOS_ADD_VIDEO_FAIL : return technicianVideosAddVideoFail(state,action);
        case actionTypes.TECHNICIAN_VIDEOS_ADD_VIDEO_RESET : return technicianVideosAddVideoReset(state,action);
        default : return state;
    }
};

export default reducer;
