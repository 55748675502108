import {combineReducers} from "redux";

import mesCoursReducer from "./mesCours";
import explorerOffresReducer from "./explorerOffres";
import commandesReducer from "./commandes";


export default combineReducers({
    mesCours : mesCoursReducer,
    explorerOffres : explorerOffresReducer,
    commandes : commandesReducer,
});