import * as actionTypes from "../../../../actions/actionTypes";
import {findCategorie, updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    listeCategories: []
};

const adminCoursFetchListeCategoriesStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        listeCategories: []
    });
};

const adminCoursFetchListeCategoriesSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        listeCategories: action.payload.listeCategories
    });
};

const adminCoursFetchListeCategoriesFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        listeCategories: []
    });
};




const adminCoursFetchCategorieListeCategoriesStart =(state,action) => {

    const categorieId = action.payload.categorieId;
    
    let newListeCategories= [...state.listeCategories];

    const selectedCategorie = findCategorie(newListeCategories,categorieId);




    if(selectedCategorie) {

        selectedCategorie.content = {
                    ...selectedCategorie.content,
                    listeCategories : {
                    loading : true,
                        error : null,
                        listeCategories : []
                }
        };

    }

    return updateObject(state, {
        ...state,
        listeCategories : newListeCategories
    });

};


const adminCoursFetchCategorieListeCategoriesSuccess =(state,action) => {


    const categorieId = action.payload.categorieId;

    let newListeCategories = [...state.listeCategories];

  const categorieListeCategories = action.payload.listeCategories;



    const selectedCategorie = findCategorie(newListeCategories,categorieId);

    if(selectedCategorie) {

        selectedCategorie.content = {
            ...selectedCategorie.content,
            listeCategories : {
                loading : false,
                error : null,
                listeCategories : categorieListeCategories
            }
        };

    }

    return updateObject(state, {
        ...state,
        listeCategories : newListeCategories
    });
};

const adminCoursFetchCategorieListeCategoriesFail =(state,action) => {

    const categorieId = action.payload.categorieId;


    let newListeCategories = [...state.listeCategories];

    const selectedCategorie = findCategorie(newListeCategories,categorieId);


    if(selectedCategorie) {

        selectedCategorie.content = {
                ...selectedCategorie.content,
                listeCategories : {
                    loading : false,
                    error : action.payload.error,
                    listeCategories : []
                }
            };

    }

    return updateObject(state, {
        ...state,
        listeCategories : newListeCategories
    });
};






const adminCoursFetchCategorieListeCoursStart =(state,action) => {

    const categorieId = action.payload.categorieId;

    let newListeCategories = [...state.listeCategories];

    const selectedCategorie = findCategorie(newListeCategories,categorieId);

    if(selectedCategorie) {

        selectedCategorie.content= {
                ...selectedCategorie.content,
                listeCours : {
                    loading : true,
                    error : null,
                    listeCours : []
                }
            };

    }

    return updateObject(state, {
        ...state,
        listeCategories : newListeCategories
    })

};


const adminCoursFetchCategorieListeCoursSuccess =(state,action) => {

    const categorieId = action.payload.categorieId;

    let newListeCategories = [...state.listeCategories];

    const selectedCategorie = findCategorie(newListeCategories,categorieId);

    if(selectedCategorie) {

        selectedCategorie.content = {
                ...selectedCategorie.content,
                listeCours : {
                    loading : false,
                    error : null,
                    listeCours : action.payload.listeCours
                }
            };

    }

    return updateObject(state, {
        ...state,
        listeCategories : newListeCategories
    });
};

const adminCoursFetchCategorieListeCoursFail =(state,action) => {

    const categorieId = action.payload.categorieId;




    let newListeCategories = [...state.listeCategories];


    const selectedCategorie = findCategorie(newListeCategories,categorieId);

    if(selectedCategorie) {

        selectedCategorie.content = {
                ...selectedCategorie.content,
                listeCours : {
                    loading : false,
                    error : action.payload.error,
                    listeCours : []
                }
            };

    }

    return updateObject(state, {
        ...state,
        listeCategories : newListeCategories
    });
};








function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_COURS_FETCH_LISTE_CATEGORIES_START : return adminCoursFetchListeCategoriesStart(state,action);
        case actionTypes.ADMIN_COURS_FETCH_LISTE_CATEGORIES_SUCCESS : return adminCoursFetchListeCategoriesSuccess(state,action);
        case actionTypes.ADMIN_COURS_FETCH_LISTE_CATEGORIES_FAIL : return adminCoursFetchListeCategoriesFail(state,action);


        case actionTypes.ADMIN_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_START : return adminCoursFetchCategorieListeCategoriesStart(state,action);
        case actionTypes.ADMIN_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_SUCCESS : return adminCoursFetchCategorieListeCategoriesSuccess(state,action);
        case actionTypes.ADMIN_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_FAIL : return adminCoursFetchCategorieListeCategoriesFail(state,action);

        case actionTypes.ADMIN_COURS_FETCH_CATEGORIE_LISTE_COURS_START : return adminCoursFetchCategorieListeCoursStart(state,action);
        case actionTypes.ADMIN_COURS_FETCH_CATEGORIE_LISTE_COURS_SUCCESS : return adminCoursFetchCategorieListeCoursSuccess(state,action);
        case actionTypes.ADMIN_COURS_FETCH_CATEGORIE_LISTE_COURS_FAIL : return adminCoursFetchCategorieListeCoursFail(state,action);

        default : return state;
    }

}

export default reducer;