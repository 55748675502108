import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* technicianCoursesFetchCoursesListSaga(action) {

    yield put(actions.technicianCoursesFetchCoursesListStart());


    try {
        const response = yield axios.get(api.technicianCoursesFetchCoursesList);
        yield put(actions.technicianCoursesFetchCoursesListSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.technicianCoursesFetchCoursesListFail(error.response.data.error));
        }else {
            yield put(actions.technicianCoursesFetchCoursesListFail("There's an error!"));
        }
    }

}