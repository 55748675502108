import * as actionTypes from "../../actionTypes";

export const etudiantMesCoursFetchListeCours = (utilisateurId) => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_INITIATE_FETCH_LISTE_COURS,
        payload : {
            utilisateurId : utilisateurId
        }
    }
};

export const etudiantMesCoursFetchListeCoursStart = () => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_LISTE_COURS_START
    }
};

export const etudiantMesCoursFetchListeCoursSuccess = (listeCours) => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_LISTE_COURS_SUCCESS,
        payload : {
            listeCours : listeCours
        }
    }
};

export const etudiantMesCoursFetchListeCoursFail = (error) => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_LISTE_COURS_FAIL,
        payload : {
            error: error
        }
    }
};

export const etudiantMesCoursFetchListeCoursReset = () => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_LISTE_COURS_RESET
    }
};