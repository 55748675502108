import * as actionTypes from "../../../actionTypes";

export const enseignantDemandeVideosAjouterDemandeVideo = (demandeVideo,video,videoFileRequests,files) => {
    return {
        type : actionTypes.ENSEIGNANT_DEMANDE_VIDEOS_INITIATE_AJOUTER_DEMANDE_VIDEO,
        payload : {
            demandeVideo : demandeVideo,
            video : video,
            videoFileRequests : videoFileRequests,
            files : files
        }
    }
};

export const enseignantDemandeVideosAjouterDemandeStart = () => {
    return {
        type : actionTypes.ENSEIGNANT_DEMANDE_VIDEOS_AJOUTER_DEMANDE_VIDEO_START
    }
};

export const enseignantDemandeVideosAjouterDemandeSuccess = (demandeVideo) => {
    return {
        type : actionTypes.ENSEIGNANT_DEMANDE_VIDEOS_AJOUTER_DEMANDE_VIDEO_SUCCESS,
        payload : {
            demandeVideo : demandeVideo
        }
    }
};

export const enseignantDemandeVideosAjouterDemandeFail = (error) => {
    return {
        type : actionTypes.ENSEIGNANT_DEMANDE_VIDEOS_AJOUTER_DEMANDE_VIDEO_FAIL,
        payload : {
            error: error
        }
    }
};

export const enseignantDemandeVideosAjouterDemandeReset = () => {
    return {
        type : actionTypes.ENSEIGNANT_DEMANDE_VIDEOS_AJOUTER_DEMANDE_VIDEO_RESET
    }
};
