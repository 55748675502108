import React, {useEffect} from "react";

import CoursCategorieList from "../../../components/CoursCategoriePaginatedList";
import {guestHomeFetchListeCoursCategoriesSpecial} from "../../../store/actions/guest/home/listeCoursSpecialsActions";
import {connect} from "react-redux";
import {guestExplorerCoursFetchListeCours} from "../../../store/actions/guest/explorerCours/listeCours";
import {guestExplorerCoursFetchListeCategories} from "../../../store/actions/guest/explorerCours/listeCategories";
import Grid from "@material-ui/core/Grid";
import CategorieCoursListSkeleton from "../../../UI/LoadingSkeleton/CategorieCoursListSkeleton";
import {Box} from "@material-ui/core";

function ExplorerCours(props) {

    useEffect(()=> {
        props.fetchListeCours();
        props.fetchListeCategories();
    },[]);

    return (
        <div className={"p-5"}>
        <div className={"p-3"} >
            <h1 className={"text-center"}>Explorer nos cours</h1>

            {(props.listeCategories.loading || props.listeCours.loading) &&
            (<><Grid className={"my-3"} container={true} spacing={3}>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                    <CategorieCoursListSkeleton/>
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                    <CategorieCoursListSkeleton/>
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                    <CategorieCoursListSkeleton/>
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3} >
                    <Box display={{ xs: 'none', sm:'block',md:'none',lg: 'block' }}>
                        <CategorieCoursListSkeleton/>
                    </Box>
                </Grid>
            </Grid>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                    <CategorieCoursListSkeleton/>
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                    <CategorieCoursListSkeleton/>
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                    <CategorieCoursListSkeleton/>
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3} >
                    <Box display={{ xs: 'none', sm:'block',md:'none',lg: 'block' }}>
                        <CategorieCoursListSkeleton/>
                    </Box>
                </Grid>
            </Grid></>)}
            {!props.listeCategories.loading && !props.listeCours.loading &&
            <CoursCategorieList categoriesCours={props.listeCategories.listeCategories.concat(props.listeCours.listeCours)} />}
        </div>
        </div>
    );

}

const mapStateToProps = (state) => {
    return {
        listeCours : {
            loading : state.guest.explorerCours.listeCours.loading,
            error : state.guest.explorerCours.listeCours.error,
            listeCours : state.guest.explorerCours.listeCours.listeCours,
        },
        listeCategories : {
            loading : state.guest.explorerCours.listeCategories.loading,
            error : state.guest.explorerCours.listeCategories.error,
            listeCategories : state.guest.explorerCours.listeCategories.listeCategories,
        },
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        //addGroupe : (nom,groupeParent) => dispatch(adminUsersAddGroupe(nom,groupeParent)),
        fetchListeCours : () => dispatch(guestExplorerCoursFetchListeCours()),
        fetchListeCategories : () => dispatch(guestExplorerCoursFetchListeCategories()),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(ExplorerCours);
