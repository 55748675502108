


import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"
import * as api from "../../../../../api";

import * as actions from "../../../../actions";

export function* adminOffresFetchListeOffresSaga(action) {


    yield put(actions.adminOffresFetchListeOffresStart());

    try {
        const response = yield axios.get(api.adminOffresFetchListeOffres);
        const listeOffres = response.data;
        yield put(actions.adminOffresFetchListeOffresSuccess(listeOffres));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminOffresFetchListeOffresFail(error.response.data.error));
        }else {
            yield put(actions.adminOffresFetchListeOffresFail("There's an error!"));
        }
    }
}