import * as actionTypes from "../../../../actions/actionTypes";

export const adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorie = (assignementUtilisateurCategorie) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_INITIATE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE,
        payload : {
            assignementUtilisateurCategorie : assignementUtilisateurCategorie
        }
    }
};

export const adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieStart = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_START
    }
};

export const adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieReset = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_RESET
    }
};

export const adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieSuccess = (assignementUtilisateurCategorie) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_SUCCESS,
        payload : {
            assignementUtilisateurCategorie: assignementUtilisateurCategorie
        }
    }
};

export const adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieFail = (error) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_FAIL,
        payload : {
            error : error
        }
    }
};
