import React from "react";
import Slider from "react-slick";

import FeedbackCard from "../FeedbackCard";
import { ArrowForward, ArrowBack } from "@material-ui/icons";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "#3f51b5" }}
      onClick={onClick}
    >
      <ArrowForward />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "#3f51b5" }}
      onClick={onClick}
    >
      <ArrowBack />
    </div>
  );
}

const CardCarousel = ({ cards }) => {
  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {cards.map((card, index) => (
        <FeedbackCard key={index} {...card} />
      ))}
    </Slider>
  );
};

export default CardCarousel;
