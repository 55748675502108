import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    categorie: null
};

const adminUtilisateursAjouterCategorieStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        categorie: null
    });
};

const adminUtilisateursAjouterCategorieSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        categorie: action.payload.categorie
    });
};

const adminUtilisateursAjouterCategorieFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        categorie: null
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_COURS_AJOUTER_CATEGORIE_START : return adminUtilisateursAjouterCategorieStart(state,action);
        case actionTypes.ADMIN_COURS_AJOUTER_CATEGORIE_SUCCESS : return adminUtilisateursAjouterCategorieSuccess(state,action);
        case actionTypes.ADMIN_COURS_AJOUTER_CATEGORIE_FAIL : return adminUtilisateursAjouterCategorieFail(state,action);
        default : return state;
    }

}

export default reducer;