import * as actionTypes from "../../../../actions/actionTypes";

export const adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorie = (assignementUtilisateurCategorieId) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_INITIATE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE,
        payload : {
            assignementUtilisateurCategorieId : assignementUtilisateurCategorieId
        }
    }
};

export const adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorieStart = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_START
    }
};

export const adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorieReset = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_RESET
    }
};

export const adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorieSuccess = (assignementUtilisateurCategorieId) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_SUCCESS,
        payload : {
            assignementUtilisateurCategorieId : assignementUtilisateurCategorieId
        }
    }
};

export const adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorieFail = (error) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_FAIL,
        payload : {
            error : error
        }
    }
};
