import * as actionTypes from "../../../actionTypes";

export const etudiantFetchNotes= (utilisateurId,ressourceId) => {
    return {
        type : actionTypes.ETUDIANT_INITIATE_FETCH_NOTES,
        payload : {
            utilisateurId : utilisateurId,
            ressourceId : ressourceId,
        }
    }
};

export const etudiantFetchNotesStart = () => {
    return {
        type : actionTypes.ETUDIANT_FETCH_NOTES_START
    }
};

export const etudiantFetchNotesSuccess = (notes) => {
    return {
        type : actionTypes.ETUDIANT_FETCH_NOTES_SUCCESS,
        payload : {
            notes : notes
        }
    }
};

export const etudiantFetchNotesFail = (error) => {
    return {
        type : actionTypes.ETUDIANT_FETCH_NOTES_FAIL,
        payload : {
            error: error
        }
    }
};

export const etudiantFetchNotesReset = () => {
    return {
        type : actionTypes.ETUDIANT_FETCH_NOTES_RESET
    }
};











export const etudiantAjouterNote= (note) => {
    return {
        type : actionTypes.ETUDIANT_INITIATE_AJOUTER_NOTE,
        payload : {
            note : note
        }
    }
};

export const etudiantAjouterNoteStart = () => {
    return {
        type : actionTypes.ETUDIANT_AJOUTER_NOTE_START
    }
};

export const etudiantAjouterNoteSuccess = (note) => {
    return {
        type : actionTypes.ETUDIANT_AJOUTER_NOTE_SUCCESS,
        payload : {
            note : note
        }
    }
};

export const etudiantAjouterNoteFail = (error) => {
    return {
        type : actionTypes.ETUDIANT_AJOUTER_NOTE_FAIL,
        payload : {
            error: error
        }
    }
};

export const etudiantAjouterNoteReset = () => {
    return {
        type : actionTypes.ETUDIANT_AJOUTER_NOTE_RESET
    }
};











export const etudiantSupprimerNote = (noteId) => {
    return {
        type : actionTypes.ETUDIANT_INITIATE_SUPPRIMER_NOTE,
        payload : {
            noteId : noteId
        }
    }
};

export const etudiantSupprimerNoteStart = () => {
    return {
        type : actionTypes.ETUDIANT_SUPPRIMER_NOTE_START
    }
};

export const etudiantSupprimerNoteSuccess = (noteId) => {
    return {
        type : actionTypes.ETUDIANT_SUPPRIMER_NOTE_SUCCESS,
        payload : {
            noteId : noteId
        }
    }
};

export const etudiantSupprimerNoteFail = (error) => {
    return {
        type : actionTypes.ETUDIANT_SUPPRIMER_NOTE_FAIL,
        payload : {
            error: error
        }
    }
};

export const etudiantSupprimerNoteReset = () => {
    return {
        type : actionTypes.ETUDIANT_SUPPRIMER_NOTE_RESET
    }
};




