import * as actionTypes from "../../../actionTypes";

export const technicianTagsFetchTagsList = () => {
    return {
        type : actionTypes.TECHNICIAN_TAGS_INITIATE_FETCH_TAGS_LIST
    }
};

export const technicianTagsFetchTagsListStart = () => {
    return {
        type : actionTypes.TECHNICIAN_TAGS_FETCH_TAGS_LIST_START
    }
};

export const technicianTagsFetchTagsListSuccess = (tagsList) => {
    return {
        type : actionTypes.TECHNICIAN_TAGS_FETCH_TAGS_LIST_SUCCESS,
        payload : {
            tagsList : tagsList
        }
    }
};

export const technicianTagsFetchTagsListFail = (error) => {
    return {
        type : actionTypes.TECHNICIAN_TAGS_FETCH_TAGS_LIST_FAIL,
        payload : {
            error: error
        }
    }
};

export const technicianTagsFetchTagsListReset = () => {
    return {
        type : actionTypes.TECHNICIAN_TAGS_FETCH_TAGS_LIST_RESET
    }
};

