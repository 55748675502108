import React, {useEffect} from "react";

import Modal from '@material-ui/core/Modal';

import FormHelperText from '@material-ui/core/FormHelperText';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import {CircularProgress, Grid} from "@material-ui/core";
import {connect} from "react-redux";
import Chip from "@material-ui/core/Chip";


import Alert from "@material-ui/lab/Alert";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';



import DateFnsUtils from '@date-io/date-fns';

import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TreeListe from "../../../../../components/TreeListe";
import Box from "@material-ui/core/Box";
import {enseignantMesCoursAjouterSection} from "../../../../../store/actions/enseignant/mesCours/ajouterSection";
import {DropzoneDialogBase} from "material-ui-dropzone";
import {enseignantMesCoursAjouterFichier} from "../../../../../store/actions/enseignant/mesCours/ajouterFichier";
const initialState = {
    nom: {value : "", error : null},

    description: {value : "", error : null},

    section: {value : null, error : null},

    sectionsExpanded : false,

    fichier : null
};

function AjouterFicher(props) {


    const handleSectionsExpanded = (event,isExpanded) => {
        setState(prevState => {
            return {
                ...prevState,
                sectionsExpanded : isExpanded
            }
        });
    };

    const isSectionSelected = (sectionToCheck) => {
        return state.section.value && state.section.value.id === sectionToCheck.id;
    };

    const handleSectionUnSelect = () => {
        setState(prevState => {
            return {
                ...prevState,
                section: {value: null, error :null}
            };
        });
    };


    useEffect(() => {
        return () => {

        }
    },[]);

    const [state, setState] = React.useState(initialState);

    const formOnSubmitHandler = (event) => {

        event.preventDefault();

        let error = false;

        const nom = state.nom.value;
        const description = state.description.value;
        const section = state.section.value;

        if(!nom) {
            error = true;
            setState(prevState => {
                return {
                    ...prevState,
                    nom: {
                        value: null,
                        error : "Le nom est obligatoire"
                    }
                }
            })
        }

        if(!section) {
            error = true;
            setState(prevState => {
                return {
                    ...prevState,
                    section: {
                        value: null,
                        error : "La section est obligatoire"
                    }
                }
            })
        }


        if(!error) {

            const fichier = {
                nom : nom,
                description : description,
                section : { id : section.id}

            };


           props.ajouterFichier(fichier,state.fichier.file);
        }

    };

    const nomOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                nom : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const descriptionOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                description : {
                    value : value,
                    error: error
                }
            }
        });
    };


    useEffect(() => {

        if(props.ajouterFichierState.success)
            setState(initialState)

    },[props.ajouterFichierState.success]);


    const handleSectionSelect = (section) => {
        setState(prevState => {
            return {
                ...prevState,
                section:{value : section, error : null}
            };
        });
    };






    const [open, setOpen] = React.useState(false);
    const [fileObjects, setFileObjects] = React.useState([]);





    return(
        <Modal
            open={props.open}
            onClose={props.handleClose}
            style={{overflowY:"auto"}}
        >
            <div style={{maxWidth:"700px", width:"100%"}} className={"bg-white text-center border rounded mx-auto my-5"}>

                <h2>Ajouter une fichier</h2>

                {props.ajouterFichierState.success&& <Alert className={"mt-2 mb-3"} variant="filled" severity="success">La section ajouté avec succees</Alert>
                }


                <form className={"text-left p-4"} onSubmit={formOnSubmitHandler}>

                    <TextField error={state.nom.error ? true : false} helperText={state.nom.error}
                               onChange={nomOnChangeHandler}
                               value={state.nom.value} fullWidth={true} size={"small"} label="Nom"
                               variant="outlined"
                    className={"my-3"}/>


                    <TextField error={state.description.error ? true : false} helperText={state.description.error}
                               onChange={descriptionOnChangeHandler}
                               value={state.description.value} fullWidth={true} size={"small"} label="Description"
                               multiline
                               rows={3}
                               rowsMax={5}
                               variant="outlined"
                    className={"my-3"}/>

                    <span className={"my-2 d-block"}>
                        Fichier : {state.fichier ? state.fichier.file.name: null}
                    </span>


                    <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                        Choisir fichier
                    </Button>

                    <DropzoneDialogBase
                        filesLimit={1}
                        fileObjects={fileObjects}
                        cancelButtonText={"cancel"}
                        submitButtonText={"submit"}
                        previewText={"Prev"}
                        maxFileSize={500000000}
                        showAlerts={false}
                        open={open}
                        onAdd={newFileObjs => {
                            setFileObjects(newFileObjs);
                        }}
                        onDelete={deleteFileObj => {
                            setFileObjects([]);
                        }}
                        onClose={() => setOpen(false)}
                        onSave={() => {
                            setState(prevState => {
                                return {
                                    ...prevState,
                                    fichier : fileObjects[0]
                                }
                            });
                            setOpen(false);
                        }}
                        showPreviews={true}
                        showFileNamesInPreview={true}
                    />



                    <ExpansionPanel className={"my-3"} expanded={state.sectionsExpanded} onChange={handleSectionsExpanded}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <FormControl error={state.section.error ? true : false}>
                                <h6>Section : {!state.sectionsExpanded && state.section.value ? <Chip label={state.section.value.nom} onDelete={handleSectionUnSelect} /> : null}</h6>
                                {state.section.error && <FormHelperText>{state.section.error}</FormHelperText>}
                            </FormControl>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails >
                                <Grid container spacing={3}>

                                    <Grid item xs={12} md={8} >
                                        <span>Selectionner une section</span>
                                        <TreeListe onlySections={true} selectable={"Section"} liste={props.listeSections} handleSelect={handleSectionSelect} handleUnSelect={handleSectionUnSelect} isSectionSelected={isSectionSelected}/>
                                    </Grid>
                                    <Grid item xs={12} md={4} >
                                        <span>Section Selectionnée</span>
                                        <Box>
                                            {state.section.value &&
                                            <Chip
                                                label={state.section.value.nom}
                                                onDelete={handleSectionUnSelect}
                                            />}
                                        </Box>
                                    </Grid>

                                </Grid>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>


                        <div className={"text-center"}>
                            <Button disabled={props.ajouterFichierState.loading} type={"submit"} className={"my-2"}
                                    color={"primary"} variant={"contained"} endIcon={<PersonAddIcon/>}>Ajouter</Button>
                            {props.ajouterFichierState.loading && <CircularProgress size={24} /*className={spinnerClasses.join(" ")}*/ />}
                        </div>

                </form>


            </div>
        </Modal>
    );




}

const mapStateToProps = (state) => {
    return {
        ajouterFichierState : state.enseignant.mesCours.ajouterFichier
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        ajouterFichier : (fichier,fichier2) => dispatch(enseignantMesCoursAjouterFichier(fichier,fichier2)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AjouterFicher);