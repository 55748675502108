import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    assignementUtilisateurCategorie: null
};

const adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        assignementUtilisateurCategorie: null
    });
};

const adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        assignementUtilisateurCategorie: action.payload.assignementUtilisateurCategorie
    });
};

const adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        assignementUtilisateurCategorie: null
    });
};

const adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_START : return adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieStart(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_SUCCESS : return adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieSuccess(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_FAIL : return adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieFail(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_CATEGORIE_AJOUTER_ASSIGNEMENT_UTILISATEUR_CATEGORIE_RESET : return adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieReset(state,action);
        default : return state;
    }

}

export default reducer;