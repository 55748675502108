import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"
import * as api from "../../../../../api";

import * as actions from "../../../../actions";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* etudiantCommandesFetchListeCommandesSaga(action) {


    yield put(actions.etudiantCommandesFetchListeCommandesStart());

    try {
        const utilisateurId =  action.payload.utilisateurId;
        const response = yield axios.get(generateDynamicRoute(api.studentOrdersFetchOrderList,[{toReplace:":utilisateurId",value:utilisateurId}]));
        const listeCommandes = response.data;
        yield put(actions.etudiantCommandesFetchListeCommandesSuccess(listeCommandes));
    } catch (error) {
        if(error.response) {
            yield put(actions.etudiantCommandesFetchListeCommandesFail(error.response.data.error));
        }else {
            yield put(actions.etudiantCommandesFetchListeCommandesFail("There's an error!"));
        }
    }
}