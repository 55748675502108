import React, {useEffect} from "react";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";
import {generateDynamicRoute} from "../../utils/utils";
import {guestCategoriePage, guestCoursPage} from "../../routes/guestRoutes";


function CoursCategorieListItem(props) {

    const categorieCours = props.categorieCours;

    useEffect(() => {

        console.log(categorieCours);
    },[]);

    return(
        <Card>
                <CardMedia
                    style={{
                        height:"140px"
                    }}
                    image={categorieCours.photo}
                />
                <CardContent className={"text-center"}>
                    <h5>
                        {categorieCours.titre}
                    </h5>
                    <p>
                        {categorieCours.descriptionCourte}
                    </p>
                    <div className={"text-center"} >
                        <Link to={categorieCours.type === "Cours" ?
                                generateDynamicRoute(guestCoursPage.path,[{toReplace : ":coursId",value:categorieCours.id}])
                                : generateDynamicRoute(guestCategoriePage.path,[{toReplace : ":categorieId",value:categorieCours.id}])} >
                            <Button size="small" variant={"contained"} color="primary">
                                Plus de détails
                            </Button>
                        </Link>
                    </div>
                </CardContent>
        </Card>
    );

}

export default CoursCategorieListItem;