import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : true,
    error: null,
    commande: null
};

const utilisateurCommandeFetchCommandeStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        commande: null
    });
};

const utilisateurCommandeFetchCommandeSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        commande: action.payload.commande
    });
};

const utilisateurCommandeFetchCommandeFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        commande: null
    });
};

const utilisateurCommandeFetchCommandeReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.UTILISATEUR_COMMANDE_FETCH_COMMANDE_START : return utilisateurCommandeFetchCommandeStart(state,action);
        case actionTypes.UTILISATEUR_COMMANDE_FETCH_COMMANDE_SUCCESS : return utilisateurCommandeFetchCommandeSuccess(state,action);
        case actionTypes.UTILISATEUR_COMMANDE_FETCH_COMMANDE_FAIL : return utilisateurCommandeFetchCommandeFail(state,action);
        case actionTypes.UTILISATEUR_COMMANDE_FETCH_COMMANDE_RESET : return utilisateurCommandeFetchCommandeReset(state,action);
        default : return state;
    }

}

export default reducer;