import React, {useState} from "react";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";


import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Alert from '@material-ui/lab/Alert';
import {CircularProgress} from "@material-ui/core";

import cssClasses from "./signin.module.css"

import {validations} from "../../../utils/utils";

import logoImage from "../../../assets/fullLogo.png";
import {NavLink} from "react-router-dom";

const initialState = {
    email : {
        value: "",
        error: ""
    },
    password : {
        value: "",
        error: ""
    }
};



function SignIn(props) {

    const [state,setState] = useState(initialState);


    const emailOnChangeHandler = (event) => {
        const value = event.target.value;
        const error = value ? validations.validateEmail(value) ? null : "Email non valid" : null;
        setState(prevState => {
            return {
                ...prevState,
                email : {
                    value : value,
                    error : error
                }
            }
        })
    };

    const passwordOnChangeHandler = (event) => {
        const value = event.target.value;
        const error = value ? validations.validateLength(value,8) ? null : "Le mot de passe doit etre 8 characteres au minimum" : null;
        setState(prevState => {
            return {
                ...prevState,
                password : {
                    value : value,
                    error : error
                }
            }
        })
    };

    const formOnSubmitHandler = (event) => {
        event.preventDefault();

        let requirementsError = false;

        const email = state.email.value;
        const password = state.password.value;

        if (!email) {
            setState(prevState => {
                return {
                    ...prevState,
                    email: {
                        ...prevState.email,
                        error: "L'Email est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if (!password) {
            setState(prevState => {
                return {
                    ...prevState,
                    password: {
                        ...prevState.password,
                        error: "Le mot de paase est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if (!requirementsError && !state.email.error && !state.email.error)
            props.signIn(state.email.value,state.password.value);

    };



    /*** css ***/

    const formClasses = [cssClasses.signInForm, "text-center", "border", "rounded", "p-3","mx-auto"];
    const inputClasses = ["my-2"];
    const topContentClasses = ["text-center"];

    /*** end css ***/



    return (
        <>

            <div className={topContentClasses.join(" ")}>
                <img height={200} src={logoImage} />
            </div>

            <form className={formClasses.join(" ")} onSubmit={formOnSubmitHandler}>
                {props.signInState.error &&
                <Alert className={"mt-2 mb-3"} variant="filled"
                       severity="error">{props.signInState.error.message}</Alert>
                }
                <TextField id={"email"} error={state.email.error ? true : false} helperText={state.email.error}
                           className={inputClasses.join(" ")} onChange={emailOnChangeHandler} value={state.email.value}
                           fullWidth={true} size={"small"} label="Email" variant="outlined" autocomplete="email"/>
                <TextField id={"password"} className={inputClasses.join(" ")} fullWidth={true} size={"small"}
                           type={"password"} label="Mot de passe" variant="outlined" onChange={passwordOnChangeHandler}
                           value={state.password.value} error={state.password.error} helperText={state.password.error}/>

                <div className={"my-3"}>
                    <NavLink to={"/auth/request-new-password"} className={"text-primary"}>Mot de passe oublié
                        ?</NavLink>
                </div>

                <div className={"position-relative"}>
                    <Button disabled={props.signInState.loading} type={"submit"} className={inputClasses.join(" ")}
                            color={"primary"} variant={"contained"} endIcon={<Icon className={"fa fa-unlock-alt"}/>}>Se
                        Connecter</Button>
                    {props.signInState.loading && <CircularProgress size={24} className={"spinner"}/>}
                </div>

            </form>

            </>

    );


}

const mapStateToProps = (state) => {
    return {
        signInState : {...state.authSignIn},
        signUpState : {...state.authSignUp}
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (email,password) => {dispatch(actions.authSignIn(email,password))}
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(SignIn);