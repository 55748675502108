import * as actionTypes from "../../actionTypes";

export const enseignantMesCoursFetchListeCours = (utilisateurId) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_INITIATE_FETCH_LISTE_COURS,
        payload : {
            utilisateurId : utilisateurId
        }
    }
};

export const enseignantMesCoursFetchListeCoursStart = () => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_FETCH_LISTE_COURS_START
    }
};

export const enseignantMesCoursFetchListeCoursSuccess = (listeCours) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_FETCH_LISTE_COURS_SUCCESS,
        payload : {
            listeCours : listeCours
        }
    }
};

export const enseignantMesCoursFetchListeCoursFail = (error) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_FETCH_LISTE_COURS_FAIL,
        payload : {
            error: error
        }
    }
};

export const enseignantMesCoursFetchListeCoursReset = () => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_FETCH_LISTE_COURS_RESET
    }
};