import React, { useEffect } from "react";
import { Link, Route, useParams } from "react-router-dom";
import CoursCategorieTopCard from "../../../../components/CoursCategorieTopCard";
import RessourceList from "../../../../components/RessourceList";
import {
  ressourcePage,
  StudentMyCoursesPage,
} from "../../../../routes/studentRoutes";
import {
  etudiantMesCoursFetchCours,
  etudiantMesCoursFetchCoursReset,
  etudiantMesCoursFetchCoursRessources,
  etudiantMesCoursFetchCoursRessourcesReset,
  etudiantMesCoursFetchListeCours,
  etudiantMesCoursFetchListeCoursReset,
} from "../../../../store/actions/etudiant/mesCours";
import { connect } from "react-redux";
import CoursCategorieTopCardSkeleton from "../../../../UI/LoadingSkeleton/CoursCategorieTopCardSkeleton";
import RessourceListSkeleton from "../../../../UI/LoadingSkeleton/RessourceListSkeleton";
import RessourcePage from "./Ressource";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { dateFormatWithHoursAndMinutes } from "../../../../utils/utils";
import cssClasses from "./course.module.css";

function Course(props) {
  let { coursId } = useParams();

  const checkEnrolled = () => {
    if (!props.coursesList || props.coursesList.length === 0 || !props.cours) {
      return true;
    }
    const course = props.coursesList.filter((c) => c.id == props.cours.id);
    if (!course || course.length === 0 || !course[0].enrolled) {
      return false;
    }
    return true;
  };

  const isEnrolled = checkEnrolled();

  useEffect(() => {
    props.fetchListeCours(props.userId);
    props.fetchCours(coursId);
    props.fetchRessources(coursId);

    return () => {
      props.resetListeCours();
      props.resetCours();
      props.resetRessources();
    };
  }, []);

  return (
    <div className={cssClasses.container}>
      {props.loading && (
        <>
          <Link to={StudentMyCoursesPage.path}>
            <IconButton className={"my-3"}>
              <ArrowBackIcon />
            </IconButton>
          </Link>
          {/* <CoursCategorieTopCardSkeleton />{" "} */}
        </>
      )}
      {!props.loading && !props.error && (
        <CoursCategorieTopCard
          backArrow={StudentMyCoursesPage.path}
          item={props.cours}
          isEnrolled={isEnrolled}
        />
      )}

      {props.loadingRessources && <RessourceListSkeleton />}
      {!props.loading && !props.loadingRessources && !props.errorRessources && (
        <RessourceList
          coursId={props.cours.id}
          isEnrolled={isEnrolled}
          openRessource={true}
          sectionsRessources={props.listeRessources}
        />
      )}

      {!props.loading &&
        !props.error &&
        props.cours.liveSessions.length > 0 && (
          <>
            <h3>Les séances en direct :</h3>
            {props.cours.liveSessions.map((liveSession) => {
              return (
                <div
                  style={
                    !isEnrolled
                      ? {
                          background: "grey",
                          "z-index": "99999",
                          opacity: "0.5",
                        }
                      : {}
                  }
                  className={
                    "border rounded my-4 tex-center d-flex justify-content-between align-items-center p-4"
                  }
                >
                  <div>
                    <h5>{liveSession.title}</h5>
                    <div>
                      Le {dateFormatWithHoursAndMinutes(liveSession.date)}
                    </div>
                  </div>

                  {isEnrolled ? (
                    <a href={liveSession.link} target={"_blank"}>
                      <IconButton variant={"contained"} color={"primary"}>
                        <Icon className={"w-100 fa fa-eye"} />
                      </IconButton>
                    </a>
                  ) : (
                    <IconButton variant={"contained"} color={"primary"}>
                      <Icon color={"primary"} className={"w-100 fa fa-eye"} />
                    </IconButton>
                  )}
                </div>
              );
            })}
          </>
        )}

      <Route path={ressourcePage.path} component={RessourcePage} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.etudiant.mesCours.cours.loading,
    error: state.etudiant.mesCours.cours.error,
    cours: state.etudiant.mesCours.cours.cours,
    coursesList: state.etudiant.mesCours.listeCours.listeCours,
    loadingRessources: state.etudiant.mesCours.listeRessources.loading,
    errorRessources: state.etudiant.mesCours.listeRessources.error,
    listeRessources: state.etudiant.mesCours.listeRessources.listeRessources,
    userId: state.authSignIn.user.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchListeCours: (userId) =>
      dispatch(etudiantMesCoursFetchListeCours(userId)),
    resetListeCours: () => dispatch(etudiantMesCoursFetchListeCoursReset()),
    fetchCours: (coursId) => dispatch(etudiantMesCoursFetchCours(coursId)),
    resetCours: () => dispatch(etudiantMesCoursFetchCoursReset()),
    fetchRessources: (coursId) =>
      dispatch(etudiantMesCoursFetchCoursRessources(coursId)),
    resetRessources: () =>
      dispatch(etudiantMesCoursFetchCoursRessourcesReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Course);
