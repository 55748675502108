import {combineReducers} from "redux";

import notificationsReducer from "./notifications";
import profilReducer from "./profil";
import cartReducer from "./cart";
import commandeReducer from "./commande";

export default combineReducers({
    profil : profilReducer,
    notifications : notificationsReducer,
    cart : cartReducer,
    commande : commandeReducer,
});