import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import {Link} from "react-router-dom";
import {generateDynamicRoute} from "../../utils/utils";
import {ressourcePage} from "../../routes/studentRoutes";

function RessourceListItem(props) {

        const item = props.item;
        const coursId = props.coursId;

        const openRessource = props.openRessource ? true : false;

        switch (item.type) {
            case "Section" :
                return (
                    <ExpansionPanel className={"w-100 border my-1"}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon/>}
                            className={"pl-3"}
                        >
                            <div className={"d-flex align-items-center"}>
                                <PermMediaIcon/><h6 className={"ml-2 m-0"}>{item.nom}</h6></div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={"ml-3 pr-0 d-block"}>
                            {item.sousSections.concat(item.ressources).map(subItem => <RessourceListItem coursId={coursId} openRessource={openRessource} item={subItem} />)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            case "Fichier" :
                    if(openRessource) {
                        return (<Link to={generateDynamicRoute(ressourcePage.path,[{toReplace:":coursId",value:coursId},{toReplace:":ressourceId",value:item.id}])}>
                            <div className={"w-100 my-3 pl-3"}>
                                <h6><PictureAsPdfIcon/> {item.nom}</h6>
                            </div>
                        </Link>);
                    } else {
                        return (<div className={"w-100 my-3 pl-3"}>
                            <h6><PictureAsPdfIcon/> {item.nom}</h6>
                        </div>);
                    };
            case  "Video" :
                if(openRessource) {
                    return (
                        <Link to={generateDynamicRoute(ressourcePage.path,[{toReplace:":coursId",value:coursId},{toReplace:":ressourceId",value:item.id}])}>
                            <div className={"w-100 my-3 pl-3"}>
                                <h6><OndemandVideoIcon/> {item.nom}</h6>
                            </div>
                        </Link>);
                } else {
                    return (<div className={"w-100 my-3 pl-3"}>
                        <h6><OndemandVideoIcon/> {item.nom}</h6>
                    </div>);
                }
            default : return null
        }

}

export default RessourceListItem;