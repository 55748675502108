import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Autocomplete from '@material-ui/lab/Autocomplete';

import Icon from "@material-ui/core/Icon";
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {Grid} from "@material-ui/core";
import {connect} from "react-redux";

import Alert from "@material-ui/lab/Alert";


import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {
    adminUtilisateursAjouterUtilisateur,
    adminUtilisateursAjouterUtilisateurReset
} from "../../../store/actions/admin/utilisateurs/ajouterUtilisateurActions";
import {
    adminCoursFetchCategorieListeCategories,
    adminCoursFetchListeCategories,
    authSignUp
} from "../../../store/actions";
import {NavLink, Redirect} from "react-router-dom";
import {signInPage} from "../../../routes/authRoutes";
import {validations} from "../../../utils/utils";

const initialState = {

    email : {
        value: "",
        error: null
    },
    password : {
        value: "",
        error: null
    },
    confirmPassword : {
        value: "",
        error: null
    },

    firstName : {
        value: "",
        error: null
    },
    lastName : {
        value: "",
        error: null
    },

    address : {
        value : "",
        error : null
    },

    zipCode : {
        value : "",
        error : null
    },

    city : {
        value : "",
        error : null
    },

    country : {
        value : {name:"Tunisia"},
        error : null
    },

    phoneNumber : {
        value : "",
        error : null
    },

    categoryError : null
};


function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}






function getSteps() {
    return ['Créer votre compte', 'Choix du classe','Terminé'];
}






function SignUp(props) {





    const [selectedCategory, setSelectedCategory] = React.useState([]);


    const handleChange = (event,index) => {

        if(index === selectedCategory.length)
            setSelectedCategory(prevState => {
                return [...prevState,event.target.value]
            });
        else {
            setSelectedCategory(prevState => {
                var arr = prevState.slice(0,index);
                arr[index] = event.target.value;

                return arr;
            });
        }



    };

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {

        if(activeStep === 0) {

            if(validateSignUpForm()) {
                return;
            }

        }
        if(activeStep === 1) {
            if(!validateClasse()) {
                return;
            }

            formOnSubmitHandler();
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };


    const [state,setState] = useState(initialState);



    const validateSignUpForm = () => {

        let requirementsError = false;


        const email = state.email.value;
        const phoneNumber = state.phoneNumber.value;
        const password = state.password.value;
        const confirmPassword = state.confirmPassword.value;
        const firstName = state.firstName.value;
        const lastName = state.lastName.value;
        const address = state.address.value;
        const zipCode = state.zipCode.value;
        const city = state.city.value;
        const country = state.country.value.name;



        if(!email) {
            setState(prevState => {
                return {
                    ...prevState,
                    email : {
                        ...prevState.email,
                        error : "L'Email est obligatoire"
                    }
                }
            });
            requirementsError = true;
        } else if(!validations.validateEmail(email)) {
            setState(prevState => {
                return {
                    ...prevState,
                    email : {
                        ...prevState.email,
                        error : "Email non valid"
                    }
                }
            });
            requirementsError = true;
        }

        if(!phoneNumber) {
            setState(prevState => {
                return {
                    ...prevState,
                    phoneNumber : {
                        ...prevState.phoneNumber,
                        error : "Le numéro de téléphone est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!firstName) {
            setState(prevState => {
                return {
                    ...prevState,
                    firstName : {
                        ...prevState.firstName,
                        error : "Le prenom est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!lastName) {
            setState(prevState => {
                return {
                    ...prevState,
                    lastName : {
                        ...prevState.lastName,
                        error : "Le nom est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!password) {
            setState(prevState => {
                return {
                    ...prevState,
                    password : {
                        ...prevState.password,
                        error : "Le mot de passe est obligatoire"
                    }
                }
            });
            requirementsError = true;
        } else if(!validations.validateLength(password,8)) {
            setState(prevState => {
                return {
                    ...prevState,
                    password : {
                        ...prevState.password,
                        error : "Le mot de passe doit etre 8 characteres au minimum"
                    }
                }
            });
            requirementsError = true;
        }

        if(!confirmPassword) {
            setState(prevState => {
                return {
                    ...prevState,
                    confirmPassword: {
                        ...prevState.confirmPassword,
                        error: "La confirmation du mot de passe est obligatoire"
                    }
                }
            });
            requirementsError = true;
        } else if (password && password !== confirmPassword) {
            setState(prevState => {
                return {
                    ...prevState,
                    confirmPassword: {
                        ...prevState.confirmPassword,
                        error: "Les mots de passe ne sont pas identiques"
                    }
                }
            });
            requirementsError = true;
        }

        return requirementsError;
    };



    /*** Form ***/

    const firstNameOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                firstName : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const lastNameOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                lastName : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const emailOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                email : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const passwordOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                password : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const confirmPasswordOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                confirmPassword : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const addressOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                address : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const cityOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                city : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const zipCodeOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                zipCode : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const countryOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                country : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const phoneNumberOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                phoneNumber : {
                    value : value,
                    error: error
                }
            }
        });
    };


    const validateClasse = () => {
        if(selectedCategory.length < 1) {
            setState(prevState => {
                return {
                    ...prevState,
                    categoryError: "Le classe est obligatoire"
                }
            });
            return false;
        }

        if (selectedCategory[selectedCategory.length -1].subCategories.length > 0) {
            setState(prevState => {
                return {
                    ...prevState,
                    categoryError: "Le classe est invalide"
            }
            });
            return false;
        }

        return true;

    };


    const formOnSubmitHandler = (event) => {



        const catId = selectedCategory[selectedCategory.length -1].id;

        const password = state.password.value;
        const email = state.email.value;
        const firstName = state.firstName.value;
        const lastName = state.lastName.value;
        const address = state.address.value;
        const zipCode = state.zipCode.value;
        const city = state.city.value;
        const country = state.country.value.name;
        const phoneNumber = state.phoneNumber.value;

        const user = {
            firstName : firstName,
            lastName : lastName,
            email : email.toLowerCase(),
            password : password,
            address : address,
            zipCode : zipCode,
            city : city,
            country : country,
            phoneNumber : phoneNumber,
            category : {
                id : catId
            }
        };

        props.signUp(user);


    };

    /*** Form ***/

    const inputClasses = ["my-2"];

    useEffect(()=> {
       props.fetchListeCategories();
    },[]);




    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <div>
                        <h2>Créer un compte</h2>
                        {props.error &&
                        <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.error.message}</Alert>
                        }
                        {props.success&&
                        <Redirect to={signInPage.path} />
                        }
                        <form onSubmit={formOnSubmitHandler}>
                            <Grid container spacing={3} className={"my-3"}>

                                <Grid item sm={6} className={"w-100"}>
                                    <TextField id={"email"} className={inputClasses.join(" ")} fullWidth={true}
                                               size={"small"}
                                               autoComplete={"off"}
                                               label={<>Email<span className={"text-danger"}>*</span></>}
                                               variant="outlined" type={"email"} onChange={emailOnChangeHandler}
                                               value={state.email.value} error={state.email.error}
                                               helperText={state.email.error}/>
                                </Grid>
                                <Grid item sm={6} className={"w-100"}>
                                    <TextField id={"phone"} className={inputClasses.join(" ")} fullWidth={true}
                                               size={"small"}
                                               autoComplete={"off"}
                                               label={<>Numero Telephone<span className={"text-danger"}>*</span></>}
                                               variant="outlined" onChange={phoneNumberOnChangeHandler}
                                               value={state.phoneNumber.value} error={state.phoneNumber.error}
                                               helperText={state.phoneNumber.error}/>
                                </Grid>
                                <Grid item sm={6} className={"text-left w-100"}>
                                    <TextField autocomplete="new-password" className={inputClasses.join(" ")}
                                               fullWidth={true} size={"small"} type={"password"}
                                               label={<>Mot de passe<span className={"text-danger"}>*</span></>}
                                               variant="outlined" onChange={passwordOnChangeHandler}
                                               value={state.password.value} error={state.password.error}
                                               helperText={state.password.error}/>
                                </Grid>
                                <Grid item md={6} className={"text-left w-100"}>
                                    <TextField autocomplete="new-password" className={inputClasses.join(" ")}
                                               fullWidth={true} size={"small"} type={"password"}
                                               label={<>Confirmer mot de passe<span
                                                   className={"text-danger"}>*</span></>} variant="outlined"
                                               onChange={confirmPasswordOnChangeHandler}
                                               value={state.confirmPassword.value} error={state.confirmPassword.error}
                                               helperText={state.confirmPassword.error}/>
                                </Grid>
                                <Grid item sm={6} className={"w-100"}>
                                    <TextField error={state.firstName.error ? true : false}
                                               helperText={state.firstName.error}
                                               label={<>Prénom<span className={"text-danger"}>*</span></>}
                                               className={inputClasses.join(" ")} onChange={firstNameOnChangeHandler}
                                               value={state.firstName.value} fullWidth={true} size={"small"}
                                               variant="outlined"/>
                                </Grid>
                                <Grid item sm={6} className={"w-100"}>
                                    <TextField className={inputClasses.join(" ")} fullWidth={true} size={"small"}
                                               label={<>Nom<span className={"text-danger"}>*</span></>}
                                               variant="outlined" onChange={lastNameOnChangeHandler}
                                               value={state.lastName.value} error={state.lastName.error}
                                               helperText={state.lastName.error}/>
                                </Grid>
                                <Grid item sm={6} className={"w-100"}>
                                    <TextField error={state.address.error ? true : false}
                                               helperText={state.address.error} className={inputClasses.join(" ")}
                                               onChange={addressOnChangeHandler} value={state.address.value}
                                               fullWidth={true} size={"small"} label="Adresse" variant="outlined"/>
                                </Grid>
                                <Grid item sm={6} className={"w-100"}>
                                    <TextField className={inputClasses.join(" ")} fullWidth={true} size={"small"}
                                               label="Ville" variant="outlined" onChange={cityOnChangeHandler}
                                               value={state.city.value} error={state.city.error}
                                               helperText={state.city.error}/>
                                </Grid>
                                <Grid item sm={6} className={"w-100"}>
                                    <TextField className={inputClasses.join(" ")} fullWidth={true} size={"small"}
                                               label="Code Postale" variant="outlined" onChange={zipCodeOnChangeHandler}
                                               value={state.zipCode.value} error={state.zipCode.error}
                                               helperText={state.zipCode.error}/>
                                </Grid>

                                <Grid item sm={6} className={"w-100"}>
                                    <Autocomplete
                                        className={inputClasses.join(" ")}
                                        fullWidth={true}
                                        options={countries}
                                        autoHighlight
                                        value={state.country.value}
                                        onChange={(event, value) => setState(prevState => {
                                            return {...prevState, country: {value: value, error: null}}
                                        })}
                                        getOptionSelected={(option) => state.country.value === option.name}

                                        getOptionLabel={(option) => option.name}

                                        renderOption={(option) => (
                                            <React.Fragment>
                                                <span className={"mr-2"}>{countryToFlag(option.code)}</span>
                                                {option.name} ( {option.code} )
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Choisissez un pays"
                                                fullWidth={true} size={"small"}
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>


                            </Grid>
                            {/*
                            <div className={"position-relative"}>
                                <Button disabled={props.loading} type={"submit"} className={inputClasses.join(" ")} color={"primary"} variant={"contained"} endIcon={<InputIcon />}>S'inscrire</Button>
                                {props.loading && <CircularProgress  size={24}/>}
                            </div>
                            */}
                        </form>


                    </div>
                );
            case 1:
                return choixClasse();
            case 2:
                return (

                    props.loading  ?
                    <CircularProgress size={50} />
                    :
                        <div className={"text-center"}>
                            <div style={{maxWidth:400}} className={"m-auto"}>

                                {props.error && <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.error.message}</Alert> }
                                {props.success && <>
                                <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Votre compte a été créé avec succès</Alert>
                                <NavLink to={"/auth/signin"}>
                                    <Button color={"primary"} variant={"contained"} endIcon={<Icon className={"fa fa-unlock-alt"} />} >
                                        Clicker ici pour se connecter
                                    </Button>
                                </NavLink></>}
                            </div>
                        </div>
                );

    default:
                return 'Unknown stepIndex';
        }
    }



    return(
        <>

            <div className={"text-center border rounded p-3"}>

                <>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>


                    <div>
                        {activeStep === steps.length ? null : (
                            <div className={"py-3"}>
                                <div className={"py-3"}>
                                    {getStepContent(activeStep)}
                                </div>
                                <div>
                                    {activeStep === steps.length - 1 ? null : <Button variant="contained" color="primary" onClick={handleNext}>Suivant</Button>}
                                </div>
                            </div>
                        )}
                    </div>


                </>


            </div>



        </>
    );



    function choixClasse() {
        return (
<>
            <div className="my-5">

                {state.categoryError &&
                <Alert className={"mt-2 mb-3 mx-auto"} style={{maxWidth:320}} variant="filled" severity="error">{state.categoryError}</Alert>
                }
                    <div className={"d-flex justify-content-center align-items-center"}>

                        <div className={"mx-3"}><span>Votre classe <span style={{color:"red"}}>(*)</span></span></div>
                        <div className={"d-flex flex-column"}
                             style={{
                                 minWidth: 190
                             }}>

                            {selectedCategory.length > 0 ?
                                <>


                                    <FormControl className={"my-2"}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedCategory[0]}
                                            onChange={(event)=>handleChange(event,0)}
                                        >
                                            {
                                                props.listeCategories.listeCategories.map(cat => {
                                                    return <MenuItem value={cat}>{cat.title}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>


                                {
                                    selectedCategory.map((cat,index) => {

                                        if(cat.subCategories.length > 0)

                                        return <FormControl className={"my-2"}>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        style={{
                                                            minWidth : 300
                                                        }}
                                                        value={selectedCategory[index+1] ? selectedCategory[index+1] : null}
                                                        onChange={(event)=>handleChange(event,index+1)}
                                                    >
                                                        {
                                                            selectedCategory[index].subCategories.map(cat => {
                                                                return <MenuItem value={cat}>{cat.title}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>;
                                    })

                                }

                                </>
                            :



                            <FormControl>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={"Selectionner   "}
                                    onChange={(event)=>handleChange(event,0)}
                                >
                                    {
                                        props.listeCategories.listeCategories.map(cat => {
                                            return <MenuItem value={cat}>{cat.title}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>

                            }
                        </div>


                    </div>



            </div>

        </>



        )

    }

}

const mapStateToProps = (state) => {
    return {
        ...state.authSignUp,
        listeCategories : {
            loading : state.admin.cours.listeCategories.loading,
            error : state.admin.cours.listeCategories.error,
            listeCategories : state.admin.cours.listeCategories.listeCategories,
        }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

        signUp : (user) => dispatch(authSignUp(user)),

        ajouterUtilisateur : (utilisateur,photo,sendEmail,generatePassword) => dispatch(adminUtilisateursAjouterUtilisateur(utilisateur,photo,sendEmail,generatePassword)),
        ajouterUtilisateurReset : () => dispatch(adminUtilisateursAjouterUtilisateurReset()),

        fetchListeCategories : () => dispatch(adminCoursFetchListeCategories()),
        fetchCategorieListeCategories : (categorieId) => dispatch(adminCoursFetchCategorieListeCategories(categorieId)),

    }

};

export default connect(mapStateToProps,mapDispatchToProps)(SignUp);





const countries = [
    {name: 'Afghanistan', code: 'AF'},
    {name: 'Åland Islands', code: 'AX'},
    {name: 'Albania', code: 'AL'},
    {name: 'Algeria', code: 'DZ'},
    {name: 'American Samoa', code: 'AS'},
    {name: 'AndorrA', code: 'AD'},
    {name: 'Angola', code: 'AO'},
    {name: 'Anguilla', code: 'AI'},
    {name: 'Antarctica', code: 'AQ'},
    {name: 'Antigua and Barbuda', code: 'AG'},
    {name: 'Argentina', code: 'AR'},
    {name: 'Armenia', code: 'AM'},
    {name: 'Aruba', code: 'AW'},
    {name: 'Australia', code: 'AU'},
    {name: 'Austria', code: 'AT'},
    {name: 'Azerbaijan', code: 'AZ'},
    {name: 'Bahamas', code: 'BS'},
    {name: 'Bahrain', code: 'BH'},
    {name: 'Bangladesh', code: 'BD'},
    {name: 'Barbados', code: 'BB'},
    {name: 'Belarus', code: 'BY'},
    {name: 'Belgium', code: 'BE'},
    {name: 'Belize', code: 'BZ'},
    {name: 'Benin', code: 'BJ'},
    {name: 'Bermuda', code: 'BM'},
    {name: 'Bhutan', code: 'BT'},
    {name: 'Bolivia', code: 'BO'},
    {name: 'Bosnia and Herzegovina', code: 'BA'},
    {name: 'Botswana', code: 'BW'},
    {name: 'Bouvet Island', code: 'BV'},
    {name: 'Brazil', code: 'BR'},
    {name: 'British Indian Ocean Territory', code: 'IO'},
    {name: 'Brunei Darussalam', code: 'BN'},
    {name: 'Bulgaria', code: 'BG'},
    {name: 'Burkina Faso', code: 'BF'},
    {name: 'Burundi', code: 'BI'},
    {name: 'Cambodia', code: 'KH'},
    {name: 'Cameroon', code: 'CM'},
    {name: 'Canada', code: 'CA'},
    {name: 'Cape Verde', code: 'CV'},
    {name: 'Cayman Islands', code: 'KY'},
    {name: 'Central African Republic', code: 'CF'},
    {name: 'Chad', code: 'TD'},
    {name: 'Chile', code: 'CL'},
    {name: 'China', code: 'CN'},
    {name: 'Christmas Island', code: 'CX'},
    {name: 'Cocos (Keeling) Islands', code: 'CC'},
    {name: 'Colombia', code: 'CO'},
    {name: 'Comoros', code: 'KM'},
    {name: 'Congo', code: 'CG'},
    {name: 'Congo, The Democratic Republic of the', code: 'CD'},
    {name: 'Cook Islands', code: 'CK'},
    {name: 'Costa Rica', code: 'CR'},
    {name: 'Cote D\'Ivoire', code: 'CI'},
    {name: 'Croatia', code: 'HR'},
    {name: 'Cuba', code: 'CU'},
    {name: 'Cyprus', code: 'CY'},
    {name: 'Czech Republic', code: 'CZ'},
    {name: 'Denmark', code: 'DK'},
    {name: 'Djibouti', code: 'DJ'},
    {name: 'Dominica', code: 'DM'},
    {name: 'Dominican Republic', code: 'DO'},
    {name: 'Ecuador', code: 'EC'},
    {name: 'Egypt', code: 'EG'},
    {name: 'El Salvador', code: 'SV'},
    {name: 'Equatorial Guinea', code: 'GQ'},
    {name: 'Eritrea', code: 'ER'},
    {name: 'Estonia', code: 'EE'},
    {name: 'Ethiopia', code: 'ET'},
    {name: 'Falkland Islands (Malvinas)', code: 'FK'},
    {name: 'Faroe Islands', code: 'FO'},
    {name: 'Fiji', code: 'FJ'},
    {name: 'Finland', code: 'FI'},
    {name: 'France', code: 'FR'},
    {name: 'French Guiana', code: 'GF'},
    {name: 'French Polynesia', code: 'PF'},
    {name: 'French Southern Territories', code: 'TF'},
    {name: 'Gabon', code: 'GA'},
    {name: 'Gambia', code: 'GM'},
    {name: 'Georgia', code: 'GE'},
    {name: 'Germany', code: 'DE'},
    {name: 'Ghana', code: 'GH'},
    {name: 'Gibraltar', code: 'GI'},
    {name: 'Greece', code: 'GR'},
    {name: 'Greenland', code: 'GL'},
    {name: 'Grenada', code: 'GD'},
    {name: 'Guadeloupe', code: 'GP'},
    {name: 'Guam', code: 'GU'},
    {name: 'Guatemala', code: 'GT'},
    {name: 'Guernsey', code: 'GG'},
    {name: 'Guinea', code: 'GN'},
    {name: 'Guinea-Bissau', code: 'GW'},
    {name: 'Guyana', code: 'GY'},
    {name: 'Haiti', code: 'HT'},
    {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
    {name: 'Holy See (Vatican City State)', code: 'VA'},
    {name: 'Honduras', code: 'HN'},
    {name: 'Hong Kong', code: 'HK'},
    {name: 'Hungary', code: 'HU'},
    {name: 'Iceland', code: 'IS'},
    {name: 'India', code: 'IN'},
    {name: 'Indonesia', code: 'ID'},
    {name: 'Iran, Islamic Republic Of', code: 'IR'},
    {name: 'Iraq', code: 'IQ'},
    {name: 'Ireland', code: 'IE'},
    {name: 'Isle of Man', code: 'IM'},
    {name: 'Israel', code: 'IL'},
    {name: 'Italy', code: 'IT'},
    {name: 'Jamaica', code: 'JM'},
    {name: 'Japan', code: 'JP'},
    {name: 'Jersey', code: 'JE'},
    {name: 'Jordan', code: 'JO'},
    {name: 'Kazakhstan', code: 'KZ'},
    {name: 'Kenya', code: 'KE'},
    {name: 'Kiribati', code: 'KI'},
    {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
    {name: 'Korea, Republic of', code: 'KR'},
    {name: 'Kuwait', code: 'KW'},
    {name: 'Kyrgyzstan', code: 'KG'},
    {name: 'Lao People\'S Democratic Republic', code: 'LA'},
    {name: 'Latvia', code: 'LV'},
    {name: 'Lebanon', code: 'LB'},
    {name: 'Lesotho', code: 'LS'},
    {name: 'Liberia', code: 'LR'},
    {name: 'Libyan Arab Jamahiriya', code: 'LY'},
    {name: 'Liechtenstein', code: 'LI'},
    {name: 'Lithuania', code: 'LT'},
    {name: 'Luxembourg', code: 'LU'},
    {name: 'Macao', code: 'MO'},
    {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
    {name: 'Madagascar', code: 'MG'},
    {name: 'Malawi', code: 'MW'},
    {name: 'Malaysia', code: 'MY'},
    {name: 'Maldives', code: 'MV'},
    {name: 'Mali', code: 'ML'},
    {name: 'Malta', code: 'MT'},
    {name: 'Marshall Islands', code: 'MH'},
    {name: 'Martinique', code: 'MQ'},
    {name: 'Mauritania', code: 'MR'},
    {name: 'Mauritius', code: 'MU'},
    {name: 'Mayotte', code: 'YT'},
    {name: 'Mexico', code: 'MX'},
    {name: 'Micronesia, Federated States of', code: 'FM'},
    {name: 'Moldova, Republic of', code: 'MD'},
    {name: 'Monaco', code: 'MC'},
    {name: 'Mongolia', code: 'MN'},
    {name: 'Montserrat', code: 'MS'},
    {name: 'Morocco', code: 'MA'},
    {name: 'Mozambique', code: 'MZ'},
    {name: 'Myanmar', code: 'MM'},
    {name: 'Namibia', code: 'NA'},
    {name: 'Nauru', code: 'NR'},
    {name: 'Nepal', code: 'NP'},
    {name: 'Netherlands', code: 'NL'},
    {name: 'Netherlands Antilles', code: 'AN'},
    {name: 'New Caledonia', code: 'NC'},
    {name: 'New Zealand', code: 'NZ'},
    {name: 'Nicaragua', code: 'NI'},
    {name: 'Niger', code: 'NE'},
    {name: 'Nigeria', code: 'NG'},
    {name: 'Niue', code: 'NU'},
    {name: 'Norfolk Island', code: 'NF'},
    {name: 'Northern Mariana Islands', code: 'MP'},
    {name: 'Norway', code: 'NO'},
    {name: 'Oman', code: 'OM'},
    {name: 'Pakistan', code: 'PK'},
    {name: 'Palau', code: 'PW'},
    {name: 'Palestinian Territory, Occupied', code: 'PS'},
    {name: 'Panama', code: 'PA'},
    {name: 'Papua New Guinea', code: 'PG'},
    {name: 'Paraguay', code: 'PY'},
    {name: 'Peru', code: 'PE'},
    {name: 'Philippines', code: 'PH'},
    {name: 'Pitcairn', code: 'PN'},
    {name: 'Poland', code: 'PL'},
    {name: 'Portugal', code: 'PT'},
    {name: 'Puerto Rico', code: 'PR'},
    {name: 'Qatar', code: 'QA'},
    {name: 'Reunion', code: 'RE'},
    {name: 'Romania', code: 'RO'},
    {name: 'Russian Federation', code: 'RU'},
    {name: 'RWANDA', code: 'RW'},
    {name: 'Saint Helena', code: 'SH'},
    {name: 'Saint Kitts and Nevis', code: 'KN'},
    {name: 'Saint Lucia', code: 'LC'},
    {name: 'Saint Pierre and Miquelon', code: 'PM'},
    {name: 'Saint Vincent and the Grenadines', code: 'VC'},
    {name: 'Samoa', code: 'WS'},
    {name: 'San Marino', code: 'SM'},
    {name: 'Sao Tome and Principe', code: 'ST'},
    {name: 'Saudi Arabia', code: 'SA'},
    {name: 'Senegal', code: 'SN'},
    {name: 'Serbia and Montenegro', code: 'CS'},
    {name: 'Seychelles', code: 'SC'},
    {name: 'Sierra Leone', code: 'SL'},
    {name: 'Singapore', code: 'SG'},
    {name: 'Slovakia', code: 'SK'},
    {name: 'Slovenia', code: 'SI'},
    {name: 'Solomon Islands', code: 'SB'},
    {name: 'Somalia', code: 'SO'},
    {name: 'South Africa', code: 'ZA'},
    {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
    {name: 'Spain', code: 'ES'},
    {name: 'Sri Lanka', code: 'LK'},
    {name: 'Sudan', code: 'SD'},
    {name: 'Suriname', code: 'SR'},
    {name: 'Svalbard and Jan Mayen', code: 'SJ'},
    {name: 'Swaziland', code: 'SZ'},
    {name: 'Sweden', code: 'SE'},
    {name: 'Switzerland', code: 'CH'},
    {name: 'Syrian Arab Republic', code: 'SY'},
    {name: 'Taiwan, Province of China', code: 'TW'},
    {name: 'Tajikistan', code: 'TJ'},
    {name: 'Tanzania, United Republic of', code: 'TZ'},
    {name: 'Thailand', code: 'TH'},
    {name: 'Timor-Leste', code: 'TL'},
    {name: 'Togo', code: 'TG'},
    {name: 'Tokelau', code: 'TK'},
    {name: 'Tonga', code: 'TO'},
    {name: 'Trinidad and Tobago', code: 'TT'},
    {name: 'Tunisia', code: 'TN'},
    {name: 'Turkey', code: 'TR'},
    {name: 'Turkmenistan', code: 'TM'},
    {name: 'Turks and Caicos Islands', code: 'TC'},
    {name: 'Tuvalu', code: 'TV'},
    {name: 'Uganda', code: 'UG'},
    {name: 'Ukraine', code: 'UA'},
    {name: 'United Arab Emirates', code: 'AE'},
    {name: 'United Kingdom', code: 'GB'},
    {name: 'United States', code: 'US'},
    {name: 'United States Minor Outlying Islands', code: 'UM'},
    {name: 'Uruguay', code: 'UY'},
    {name: 'Uzbekistan', code: 'UZ'},
    {name: 'Vanuatu', code: 'VU'},
    {name: 'Venezuela', code: 'VE'},
    {name: 'Viet Nam', code: 'VN'},
    {name: 'Virgin Islands, British', code: 'VG'},
    {name: 'Virgin Islands, U.S.', code: 'VI'},
    {name: 'Wallis and Futuna', code: 'WF'},
    {name: 'Western Sahara', code: 'EH'},
    {name: 'Yemen', code: 'YE'},
    {name: 'Zambia', code: 'ZM'},
    {name: 'Zimbabwe', code: 'ZW'}
];