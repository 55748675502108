import {combineReducers} from "redux";

import homeReducer from "./home";
import explorerCoursReducer from "./explorerCours";
import explorerOffresReducer from "./explorerOffres";

export default combineReducers({
    home : homeReducer,
    explorerCours : explorerCoursReducer,
    explorerOffres : explorerOffresReducer
});