export const updateObject = (object, values) => {
    return {
        ...object,
        ...values
    }
};


export const validations = {

    validateEmail : (email) => {
        const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return pattern.test(email);
    },

    validateLength : (text, length) => {
        return text.length >= length;
    }

};

export const generateDynamicRoute = (route,placeholders) => {
    let newRoute = route;
    placeholders.forEach(placeholder => {
        newRoute = newRoute.replace(placeholder.toReplace,placeholder.value);
    });
    return newRoute;
};


export const findGroupes = (groupes,groupeId) => {

    const gropesFound = [];



    const findGroupe = (groupe) => {
        if(groupe.id == groupeId)
            gropesFound.push(groupe);
        else
            groupe.content.listeGroupes.listeGroupes.forEach(groupe2 => {
                findGroupe(groupe2)
            });
    };

    groupes.forEach(groupe => findGroupe(groupe));

    return gropesFound;

};

export const findGroupe = (groupes,groupeId) => {


    let foundGroupe = null;

    const findGroupe2 = (groupe) => {


        if(groupe.id == groupeId) {
            foundGroupe = groupe;
        }else {
            let i = 0;
            while(i < groupe.content.listeGroupes.listeGroupes.length) {
                findGroupe2(groupe.content.listeGroupes.listeGroupes[i]);
                i++;
            }
        }

    };

    groupes.forEach(findGroupe2);

    return foundGroupe;

};

export const findCategorie = (categories,categorieId) => {


    let foundCategorie = null;

    const findCategorie2 = (categorie) => {


        if(categorie.id == categorieId) {
            foundCategorie = categorie;
        }else {
            let i = 0;
            while(i < categorie.content.listeCategories.listeCategories.length) {
                findCategorie2(categorie.content.listeCategories.listeCategories[i]);
                i++;
            }
        }

    };

    categories.forEach(findCategorie2);

    return foundCategorie;

};

export const dateFormat = (date) => {
    const dateObject = new Date(date);

    let day = dateObject.getDate();
    if(day < 10)
        day = "0" + day;

    let month = dateObject.getMonth() + 1;
    if(month < 10)
        month = "0" + month;



    return day+"/" + month + "/" +dateObject.getFullYear()
};

export const dateFormatWithHoursAndMinutes = (date) => {
    const dateObject = new Date(date);


    let hours = dateObject.getHours();
    if(hours < 10)
        hours = "0" + hours;

    let minutes = dateObject.getMinutes();
    if(minutes < 10)
        minutes = "0" + minutes;

    return dateFormat(date) +" " + hours +":" + minutes;
};