import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios";
import * as api from "../../../../../api";

import * as actions from "../../../../actions";

export function* adminUtilisateursFetchListeUtilisateursSaga(action) {


    yield put(actions.adminUtilisateursFetchListeUtilisateursStart());

    try {
        const response = yield axios.get(api.getListeUtilisateurs);
        const listeUtilisateurs = response.data;
        yield listeUtilisateurs.forEach(utilisateur => {utilisateur["type"] = "Utilisateur"})
        yield put(actions.adminUtilisateursFetchListeUtilisateursSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursFetchListeUtilisateursFail(error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursFetchListeUtilisateursFail("There's an error!"));
        }
    }
}