import React, { useEffect } from "react";
import "./App.css";
import Layout from "./hoc/layout";
import { connect } from "react-redux";
import * as actions from "./store/actions";
import Test from "./containers/Test";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App(props) {
  useEffect(() => {
    props.onTryAutoSignIn();
    props.fetchParametres();

    //props.fetchParametres();
  }, []);

  return <Layout />;
}

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignIn: () => dispatch(actions.tryAutoSignIn()),
    fetchParametres: () => dispatch(actions.parametresFetchParametres()),
  };
};

export default connect(null, mapDispatchToProps)(App);
