import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* enseignantDemandeVideosAjouterDemandeVideoSaga (action) {

    yield put(actions.enseignantDemandeVideosAjouterDemandeStart());

    const demandeVideo = action.payload.demandeVideo;
    const video = action.payload.video;
    const videoFileRequests = action.payload.videoFileRequests;
    const files = action.payload.files;

    const formData = yield new FormData();
    yield formData.append('videoRequest', new Blob([JSON.stringify(demandeVideo)], {
        type: "application/json"
    }));
    yield formData.append("video",video);
    yield formData.append('videoFileRequests', new Blob([JSON.stringify(videoFileRequests)], {
        type: "application/json"
    }));

    for (let i = 0; i < files.length; i++) {
        yield formData.append("files",files[i]);
    }

    console.log(formData);


    try {
        const response = yield axios.post(api.enseignantAjouterDemandeVideo,formData);
        yield put(actions.enseignantDemandeVideosAjouterDemandeSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.enseignantDemandeVideosAjouterDemandeFail(error.response.data.error));
        }else {
            yield put(actions.enseignantDemandeVideosAjouterDemandeFail("There's an error!"));
        }
    }
}

