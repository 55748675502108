import * as actionTypes from "../../../actions/actionTypes";

export const utilisateurCartAddOffreSuccess = (article) => {
    return {
        type: actionTypes.UTILISATEUR_CART_ADD_OFFRE_SUCCESS,
        payload : {
            article : article
        }
    }
};

export const utilisateurCartRemoveOffreSuccess = (offer) => {
    return {
        type: actionTypes.UTILISATEUR_CART_REMOVE_OFFRE_SUCCESS,
        payload : {
            offer : offer
        }
    }
};

export const utilisateurCartAddOffre = (article,userId) => {
    return {
        type: actionTypes.UTILISATEUR_CART_INITIATE_ADD_OFFRE,
        payload : {
            article : article,
            userId : userId,
        }
    }
};

export const utilisateurCartRemoveOffre = (offer) => {
    return {
        type: actionTypes.UTILISATEUR_CART_INITIATE_REMOVE_OFFRE,
        payload : {
            offer : offer
        }
    }
};


export const utilisateurCartInitiateLoadCart = (offers) => {
    return {
        type: actionTypes.UTILISATEUR_CART_INITIATE_LOAD_CART
    }
};


export const utilisateurCartLoadCart = (articles) => {
    return {
        type: actionTypes.UTILISATEUR_CART_LOAD_CART,
        payload : {
            articles : articles
        }
    }
};

export const utilisateurCartResetCartSuccess = () => {
    return {
        type: actionTypes.UTILISATEUR_CART_RESET_CART_SUCCESS
    }
};


export const utilisateurCartInitiateResetCart = () => {
    return {
        type: actionTypes.UTILISATEUR_CART_INITIATE_RESET_CART
    }
};