import * as actionTypes from "../../../actionTypes";



export const guestExplorerCoursFetchListeCategories = () => {
    return {
        type: actionTypes.GUEST_EXPLORER_COURS_INITIATE_FETCH_LISTE_CATEGORIES,
    }
};


export const guestExplorerCoursFetchListeCategoriesStart = () => {
    return {
        type: actionTypes.GUEST_EXPLORER_COURS_FETCH_LISTE_CATEGORIES_START
    }
};

export const guestExplorerCoursFetchListeCategoriesSuccess = (listeCategories) => {
    return {
        type: actionTypes.GUEST_EXPLORER_COURS_FETCH_LISTE_CATEGORIES_SUCCESS,
        payload : {
            listeCategories: listeCategories
        }
    }
};

export const guestExplorerCoursFetchListeCategoriesFail = (error) => {
    return {
        type: actionTypes.GUEST_EXPLORER_COURS_FETCH_LISTE_CATEGORIES_FAIL,
        payload : {
            error : error
        }
    }
};












export const guestExplorerCoursFetchCategorieListeCategories = (categorieId) => {
    return {
        type: actionTypes.GUEST_EXPLORER_COURS_INITIATE_FETCH_CATEGORIE_LISTE_CATEGORIES,
        payload : {
            categorieId : categorieId
        }
    }
};


export const guestExplorerCoursFetchCategorieListeCategoriesStart = () => {
    return {
        type: actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_START
    }
};

export const guestExplorerCoursFetchCategorieListeCategoriesSuccess = (listeCategories) => {
    return {
        type: actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_SUCCESS,
        payload : {
            listeCategories: listeCategories
        }
    }
};

export const guestExplorerCoursFetchCategorieListeCategoriesFail = (error) => {
    return {
        type: actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_FAIL,
        payload : {
            error : error
        }
    }
};










export const guestExplorerCoursFetchCategorieListeCours = (categorieId) => {
    return {
        type: actionTypes.GUEST_EXPLORER_COURS_INITIATE_FETCH_CATEGORIE_LISTE_COURS,
        payload : {
            categorieId : categorieId
        }
    }
};


export const guestExplorerCoursFetchCategorieListeCoursStart = () => {
    return {
        type: actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_COURS_START
    }
};

export const guestExplorerCoursFetchCategorieListeCoursSuccess = (listeCours) => {
    return {
        type: actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_COURS_SUCCESS,
        payload : {
            listeCours: listeCours
        }
    }
};

export const guestExplorerCoursFetchCategorieListeCoursFail = (error) => {
    return {
        type: actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_COURS_FAIL,
        payload : {
            error : error
        }
    }
};






