import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

import AddIcon from '@material-ui/icons/Add';

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import {CircularProgress} from "@material-ui/core";
import {Grid} from "@material-ui/core";

import {connect} from "react-redux";

import Alert from "@material-ui/lab/Alert";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";



import Chip from '@material-ui/core/Chip';
import Box from "@material-ui/core/Box";
import {
    adminUtilisateursAjouterGroupe, adminUtilisateursAjouterGroupeFetchGroupeListeGroupes,
    adminUtilisateursAjouterGroupeFetchListeGroupes
} from "../../../../store/actions/admin/utilisateurs/ajouterGroupeActions";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {findGroupes, generateDynamicRoute} from "../../../../utils/utils";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import {Link, Switch, useHistory, useParams} from "react-router-dom";
import {adminUtilisateursFetchGroupe} from "../../../../store/actions/admin/utilisateurs/groupeActions";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import AssignementsTable from "../../../../components/AssignementsTable";
import AjouterAssignementGroupeCoursModal from "../../AjouterAssignementGroupeCoursModal";
import CustomRoute from "../../../../hoc/CustomRoute";
import {
    AdminUtilisateursModifierGroupeAjouterAssignementGroupeCategoriePage,
    AdminUtilisateursModifierGroupeAjouterAssignementGroupeCoursPage,
    AdminUtilisateursModifierUtilisateurAjouterAssignementUtilisateurCategoriePage,
    AdminUtilisateursModifierUtilisateurAjouterAssignementUtilisateurCoursPage
} from "../../../../routes/administratorRoutes";
import {ajouterAssignementGroupeCours} from "../../../../api";
import {adminAssignementsGroupeCoursSupprimerAssignementGroupeCours} from "../../../../store/actions/admin/assignementsGroupeCours/supprimerAssignementGroupeCoursActions";
import {adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorie} from "../../../../store/actions/admin/assignementsGroupeCategorie/supprimerAssignementGroupeCategorieActions";
import {
    adminUtilisateursModifierGroupe,
    adminUtilisateursModifierGroupeSupprimerSousGroupe
} from "../../../../store/actions/admin/utilisateurs/modifierGroupeActions";
import GroupesTable from "../../../../components/GroupesTable";
import UtilisateursTable from "../../../../components/UtilisateursTable";
import {adminUtilisateursSupprimerUtilisateurAuGroupe} from "../../../../store/actions/admin/utilisateurs/supprimerUtilisateurAuGroupeActions";
import TreeListe from "../../../../components/TreeListe";
import userPhotoPlaceholder from "../../../../assets/user-photo-placeholder.png";
import {adminUtilisateursFetchUtilisateur} from "../../../../store/actions/admin/utilisateurs/utilisateurActions";
import {
    adminUtilisateursModifierUtilisateur,
    adminUtilisateursModifierUtilisateurChangerMotDePasse, adminUtilisateursModifierUtilisateurReset
} from "../../../../store/actions/admin/utilisateurs/modifierUtilisateurActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SwitchMUI from "@material-ui/core/Switch";
import {DropzoneDialogBase} from "material-ui-dropzone";
import AjouterGroupeModal from "./AjouterGroupeModal";
import {adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCours} from "../../../../store/actions/admin/assignementsUtilisateurCours/supprimerAssignementUtilisateurCoursActions";
import {adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorie} from "../../../../store/actions/admin/assignementsUtilisateurCategorie/supprimerAssignementUtilisateurCategorieActions";

const initialState = {
    email : {
        value: "",
        error: null
    },

    motDePasse : {
        value: "",
        error: null
    },

    nom : {
        value: "",
        error: null
    },
    prenom : {
        value: "",
        error: null
    },

    adresse : {
        value : "",
        error : null
    },

    codePostal : {
        value : "",
        error : null
    },

    ville : {
        value : "",
        error : null
    },

    pays : {
        value : "",
        error : null
    },

    numeroTelephone : {
        value : "",
        error : null
    },

    administrateur : {
        value : false,
        error : null
    },
    actif : {
        value : true,
        error: null
    },
    photo : null,
    previewPhoto : userPhotoPlaceholder,


    groupes : [],
    assignementUtilisateurCours : [],
    assignementUtilisateurCategorie : [],



    genererMotDePasse : false,
    envoyerEmail : false,



    ajouterGroupeModalOpen : false,
    ajouterUtilisateurModalOpen : false,
};

function ModifierUtilisateur(props) {


    const [state,setState] = useState(initialState);


    let { utilisateurId } = useParams();


    const handleOpenAjouterGroupeModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                ajouterGroupeModalOpen : true
            }
        })
    };

    const handleCloseAjouterGroupeModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                ajouterGroupeModalOpen : false
            }
        })
    };






    useEffect(() => {
        if(props.ajouterAssignementGroupeCategorieState.success && props.ajouterAssignementGroupeCategorieState.assignementGroupeCategorie) {
            setState(prevState => {
                const newState = {
                    ...prevState,
                    assignementGroupeCategorie : [...prevState.assignementGroupeCategorie,props.ajouterAssignementGroupeCategorieState.assignementGroupeCategorie]
                };
                return newState;
            })
        }
    },[props.ajouterAssignementGroupeCategorieState.success]);

    useEffect(() => {
        if(props.ajouterAssignementGroupeCoursState.success && props.ajouterAssignementGroupeCoursState.assignementGroupeCours) {
            setState(prevState => {
                const newState = {
                    ...prevState,
                    assignementGroupeCours : [...prevState.assignementGroupeCategorie,props.ajouterAssignementGroupeCoursState.assignementGroupeCours]
                };
                return newState;
            })
        }
    },[props.ajouterAssignementGroupeCoursState.success]);














    /*** Form ***/

    const nomOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                nom : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const prenomOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                prenom : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const emailOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                email : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const motDePasseOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                motDePasse : {
                    value : value,
                    error: error
                }
            }
        });
    };


    const adresseOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                adresse : {
                    value : value,
                    error: error
                }
            }
        });
    };


    const villeOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                ville : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const codePostalOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                codePostal : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const numeroTelephoneOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                numeroTelephone : {
                    value : value,
                    error: error
                }
            }
        });
    };



    const administrateurOnChangeHandler = (event) =>{
        const checked = event.target.checked;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                administrateur : {
                    value : checked,
                    error: error
                }
            }
        });
    };

    const actifOnChangeHandler = (event) =>{
        const checked = event.target.checked;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                actif : {
                    value : checked,
                    error: error
                }
            }
        });
    };

    const genererMotDePasseOnChangeHandler = (event) =>{
        const checked = event.target.checked;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                genererMotDePasse : checked,
                motDePasse : {
                    value : prevState.motDePasse.value,
                    error : checked ? null : prevState.motDePasse.error
                }
            }
        });
    };

    const envoyerEmailOnChangeHandler = (event) =>{
        const checked = event.target.checked;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                envoyerEmail : checked
            }
        });
    };

    const formOnSubmitHandler = (event) => {
        event.preventDefault();
        let requirementsError = false;

        const email = state.email.value;
        const nom = state.nom.value;
        const prenom = state.prenom.value;
        const adresse = state.adresse.value;
        const codePostal = state.codePostal.value;
        const ville = state.ville.value;
        const pays = state.pays.value.name;
        const numeroTelephone = state.numeroTelephone.value;
        const administrateur = state.administrateur.value;
        const actif = state.actif.value;

        if(!nom) {
            setState(prevState => {
                return {
                    ...prevState,
                    nom : {
                        ...prevState.nom,
                        error : "Le nom est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!requirementsError) {
            const utilisateur = {
                nom : nom,
                email : email,
                prenom : prenom,
                adresse : adresse,
                codePostal : codePostal,
                ville : ville,
                pays : pays,
                numeroTelephone : numeroTelephone,
                administrateur : administrateur,
                actif : actif
            };

            const photo = state.photo ? state.photo.file : null;
            props.modifierUtilisateur(utilisateurId,utilisateur,photo);
        }

    };


    const formChangerMotDePasseOnSubmitHandler= (event) => {
        event.preventDefault();
        let requirementsError = false;

        const motDePasse = state.motDePasse.value;
        const sendEmail = state.envoyerEmail;
        const generatePassword = state.genererMotDePasse;

        if(!generatePassword && !motDePasse) {
            setState(prevState => {
                return {
                    ...prevState,
                    motDePasse : {
                        ...prevState.motDePasse,
                        error : "Le mot de passe est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!requirementsError) {
            const utilisateur = {
                motDePasse : state.motDePasse.value
            };
            props.changerMotDePasse(utilisateurId,utilisateur,sendEmail,generatePassword);
        }

    };

    /*** Form ***/


    const inputClasses = ["my-2"];


    useEffect(() => {
        props.fetchUtilisateur(utilisateurId);
        return () => {
            props.modifierUtilisateurReset();
        }
    },[]);

    useEffect(() => {
        if(props.utilisateur.utilisateur) {
            setState(prevState => {
                return {
                    ...prevState,
                    email : {
                        value: props.utilisateur.utilisateur.email,
                        error: null
                    },
                    nom : {
                        value: props.utilisateur.utilisateur.nom,
                        error: null
                    },
                    prenom : {
                        value: props.utilisateur.utilisateur.prenom,
                        error: null
                    },

                    adresse : {
                        value : props.utilisateur.utilisateur.adresse,
                        error : null
                    },

                    codePostal : {
                        value : props.utilisateur.utilisateur.codePostal,
                        error : null
                    },

                    ville : {
                        value : props.utilisateur.utilisateur.ville,
                        error : null
                    },

                    pays : {
                        value : {name : props.utilisateur.utilisateur.pays},
                        error : null
                    },

                    numeroTelephone : {
                        value : props.utilisateur.utilisateur.numeroTelephone,
                        error : null
                    },

                    administrateur : {
                        value : props.utilisateur.utilisateur.administrateur,
                        error : null
                    },
                    actif : {
                        value : props.utilisateur.utilisateur.actif,
                        error: null
                    },
                    previewPhoto : props.utilisateur.utilisateur.photo ? props.utilisateur.utilisateur.photo : userPhotoPlaceholder,

                    groupes : props.utilisateur.utilisateur.groupes,
                    assignementUtilisateurCours : props.utilisateur.utilisateur.assignementUtilisateurCours,
                    assignementUtilisateurCategorie : props.utilisateur.utilisateur.assignementUtilisateurCategorie,

                }
            });
        }
    },[props.utilisateur.utilisateur]);



    useEffect(() => {
        if(props.ajouterUtilisateurAuGroupeState.success && props.ajouterUtilisateurAuGroupeState.groupe) {
            setState(prevState => {
                const newState = {
                    ...prevState,
                    groupes : [...prevState.groupes,props.ajouterUtilisateurAuGroupeState.groupe]
                }
                return newState;
            })
        }
    },[props.ajouterUtilisateurAuGroupeState.success]);


    useEffect(() => {
        if(props.supprimerUtilisateurAuGroupeState.success && props.supprimerUtilisateurAuGroupeState.groupeId) {
            setState(prevState => {
                const newState = {
                    ...prevState,
                    groupes :  prevState.groupes.filter((groupe) => groupe.id != props.supprimerUtilisateurAuGroupeState.groupeId)
                };
                return newState;
            })
        }
    },[props.supprimerUtilisateurAuGroupeState.success]);

    useEffect(() => {
        if(props.supprimerAssignementUtilisateurCoursState.success && props.supprimerAssignementUtilisateurCoursState.assignementUtilisateurCoursId) {
            setState(prevState => {
                const newState = {
                    ...prevState,
                    assignementUtilisateurCours :  prevState.assignementUtilisateurCours.filter((assignementUtilisateurCours) => assignementUtilisateurCours.id != props.supprimerAssignementUtilisateurCoursState.assignementUtilisateurCoursId)
                };
                return newState;
            })
        }
    },[props.supprimerAssignementUtilisateurCoursState.success]);


    useEffect(() => {
        if(props.supprimerAssignementUtilisateurCategorieState.success && props.supprimerAssignementUtilisateurCategorieState.assignementUtilisateurCategorieId) {
            setState(prevState => {
                const newState = {
                    ...prevState,
                    assignementUtilisateurCategorie :  prevState.assignementUtilisateurCategorie.filter((assignementUtilisateurCategorie) => assignementUtilisateurCategorie.id != props.supprimerAssignementUtilisateurCategorieState.assignementUtilisateurCategorieId)
                };
                return newState;
            })
        }
    },[props.supprimerAssignementUtilisateurCategorieState.success]);


    const handleSupprimerUtilisateurAuGroupe = (groupe) => {
        props.supprimerUtilisateurAuGroupe(props.utilisateur.utilisateur.id,groupe.id);
    };


    const handleSupprimerAssignementUtilisateurCours = (assignementUtilisateurCours) => {

        props.supprimerAssignementUtilisateurCours(assignementUtilisateurCours.id);

    };

    const handleSupprimerAssignementUtilisateurCategorie = (assignementUtilisateurCategorie) => {

        props.supprimerAssignementUtilisateurCategorie(assignementUtilisateurCategorie.id);

    };


    useEffect(() => {
        setState(prevState => {
            return {
                ...prevState,
                previewPhoto : state.photo ? state.photo.data : userPhotoPlaceholder
            };
        });
    },[state.photo]);


    const [open, setOpen] = React.useState(false);
    const [fileObjects, setFileObjects] = React.useState([]);


    return(
<>
        <div className={"text-center border rounded p-3"}>

            <h2>Modifier Utilisateur</h2>
            {props.modifierUtilisateurState.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.error}</Alert>
            }
            {props.modifierUtilisateurState.success &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'utilisateur {props.modifierUtilisateurState.utilisateur.nom} ajouté avec succees</Alert>
            }



            {!props.utilisateur.loading && !props.utilisateur.error &&
            <form onSubmit={formOnSubmitHandler}>
                <Grid container spacing={3} className={"my-3 text-left"}>

                    <Grid item xs={12} className={"text-center"}>

                        <img style={{height:"200px",width:"200px"}} className={"mr-3"} src={state.previewPhoto}/>

                        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                            Choisir Image
                        </Button>

                        <DropzoneDialogBase

                            showAlerts={false}
                            acceptedFiles={['image/*']}
                            filesLimit={1}
                            fileObjects={fileObjects}
                            cancelButtonText={"cancel"}
                            submitButtonText={"submit"}
                            previewText={"Prev"}
                            maxFileSize={5000000}
                            open={open}
                            onAdd={newFileObjs => {
                                setFileObjects([].concat(fileObjects, newFileObjs));
                            }}
                            onDelete={deleteFileObj => {
                                setFileObjects([]);
                            }}
                            onClose={() => setOpen(false)}
                            onSave={() => {
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        photo : fileObjects[0]
                                    }
                                });
                                setOpen(false);
                            }}
                            showPreviews={true}
                            showFileNamesInPreview={true}
                        />

                    </Grid>


                    <Grid item xs={12}>
                        <TextField error={state.nom.error ? true : false} helperText={state.nom.error}
                                   className={inputClasses.join(" ")} onChange={nomOnChangeHandler}
                                   value={state.nom.value} fullWidth={true} size={"small"} label="Nom"
                                   variant="outlined"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField className={inputClasses.join(" ")} fullWidth={true} size={"small"} label="Email" variant="outlined" onChange={emailOnChangeHandler} value={state.email.value} error={state.email.error} helperText={state.email.error}  />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField error={state.nom.error ? true : false} helperText={state.nom.error} className={inputClasses.join(" ")} onChange={nomOnChangeHandler} value={state.nom.value} fullWidth={true} size={"small"} label="Nom" variant="outlined" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField className={inputClasses.join(" ")} fullWidth={true} size={"small"} label="Prenom" variant="outlined" onChange={prenomOnChangeHandler} value={state.prenom.value} error={state.prenom.error} helperText={state.prenom.error}  />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField error={state.adresse.error ? true : false} helperText={state.adresse.error} className={inputClasses.join(" ")} onChange={adresseOnChangeHandler} value={state.adresse.value} fullWidth={true} size={"small"} label="Adresse" variant="outlined" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField className={inputClasses.join(" ")} fullWidth={true} size={"small"} label="Ville" variant="outlined" onChange={villeOnChangeHandler} value={state.ville.value} error={state.ville.error} helperText={state.ville.error}  />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField className={inputClasses.join(" ")} fullWidth={true} size={"small"} label="Code Postale" variant="outlined" onChange={codePostalOnChangeHandler} value={state.codePostal.value} error={state.codePostal.error} helperText={state.codePostal.error}  />
                    </Grid>

                    <Grid item xs={6}>
                        <Autocomplete
                            className={inputClasses.join(" ")}
                            fullWidth={true}
                            options={countries}
                            autoHighlight
                            value={state.pays.value}
                            //onSelect={()=> alert("a")}
                            onChange={(event,value) => setState(prevState => {return {...prevState,pays : {value : value,error:null}}})}
                            getOptionSelected={(option,value) => state.pays.value.name === option.name}

                            getOptionLabel={ (option) => option.name}

                            renderOption={(option) => (
                                <React.Fragment>
                                    <span className={"mr-2"}>{countryToFlag(option.code)}</span>
                                    {option.name} ( {option.code} )
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Choose a country"
                                    fullWidth={true} size={"small"}
                                    variant="outlined"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField className={inputClasses.join(" ")} fullWidth={true} size={"small"} label="Numero Telephone" variant="outlined" onChange={numeroTelephoneOnChangeHandler} value={state.numeroTelephone.value} error={state.numeroTelephone.error} helperText={state.numeroTelephone.error}  />
                    </Grid>
                    <Grid item xs={6} className={"text-left"}>

                        <FormControlLabel
                            control={<SwitchMUI checked={state.actif.value} onChange={actifOnChangeHandler} />}
                            label="Actif"
                        />
                        <br/>
                        <FormControlLabel
                            control={<SwitchMUI checked={state.administrateur.value} onChange={administrateurOnChangeHandler} />}
                            label="Administrateur"
                        />

                    </Grid>



                </Grid>
                <div className={"position-relative"}>
                    <Button disabled={props.modifierUtilisateurState.loading} type={"submit"} className={inputClasses.join(" ")}
                            color={"primary"} variant={"contained"} endIcon={<PersonAddIcon/>}>Modifier</Button>
                    {props.modifierUtilisateurState.loading && <CircularProgress size={24} /*className={spinnerClasses.join(" ")}*/ />}
                </div>
            </form>
            }

        </div>




    <div className={"text-center border rounded my-3 p-3"}>

        <h2>Changer Mot De Passe</h2>

        {props.modifierUtilisateurState.changerMotDePasse.error &&
        <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.error}</Alert>
        }
        {props.modifierUtilisateurState.changerMotDePasse.success&&
        <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Le mot de passe changé avec succees</Alert>
        }

        <form onSubmit={formChangerMotDePasseOnSubmitHandler}>
            <Grid container spacing={3} className={"my-3 text-left"}>

                <Grid item xs={12} className={"text-left"}>
                    <TextField className={inputClasses.join(" ")} fullWidth={true} size={"small"} type={"password"} label="Mot de passe" variant="outlined" onChange={motDePasseOnChangeHandler} value={state.genererMotDePasse ? '' : state.motDePasse.value} error={state.motDePasse.error} helperText={state.motDePasse.error}  />
                    <FormControlLabel
                        control={<SwitchMUI checked={state.genererMotDePasse} onChange={genererMotDePasseOnChangeHandler} />}
                        label="Generer le mot de passe automatiquement"
                    />
                    <br/>
                    <FormControlLabel
                        control={<SwitchMUI checked={state.envoyerEmail} onChange={envoyerEmailOnChangeHandler} />}
                        label="envoyer les données par email"
                    />
                </Grid>

            </Grid>

            <div className={"position-relative"}>
                <Button disabled={props.modifierUtilisateurState.changerMotDePasse.loading} type={"submit"} className={inputClasses.join(" ")}
                        color={"primary"} variant={"contained"} endIcon={<PersonAddIcon/>}>Changer Mot De Passe</Button>
                {props.modifierUtilisateurState.changerMotDePasse.loading && <CircularProgress size={24} /*className={spinnerClasses.join(" ")}*/ />}
            </div>

        </form>

    </div>


    {!props.utilisateur.loading && !props.utilisateur.error &&

        <>
        <div className={"text-center border rounded p-3 my-3"}>

            <Grid container>

                <Grid item xs={12} className={"my3"}>

                    <h4 className={"text-left"}><b>Groupes : </b><IconButton>
                        <AddIcon onClick={handleOpenAjouterGroupeModal} color={"primary"} />
                    </IconButton></h4>

                    {props.supprimerUtilisateurAuGroupeState.success&&
                    <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Le groupe supprimé avec succees</Alert>
                    }


                    <GroupesTable groupes={state.groupes} showSupprimerButton={true} supprimer={handleSupprimerUtilisateurAuGroupe} />

                </Grid>

                {/*
                    <Grid item xs={12} className={"my3"}>

                        <h4  className={"text-left"}><b>Utilisateurs : </b><IconButton onClick={handleOpenAjouterUtilisateurModal}>
                            <AddIcon color={"primary"} />
                        </IconButton></h4>
                        <UtilisateursTable utilisateurs={props.groupe.groupe.utilisateurs} showSupprimerButton={true} supprimer={handleSupprimerUtilisateurAuGroupe} />

                    </Grid>

                    <Grid
                } item xs={12} className={"my3"}>

                    <h4 className={"text-left"}><b>Assignements Groupe Categories : </b><Link to={generateDynamicRoute(AdminUtilisateursModifierGroupeAjouterAssignementGroupeCategoriePage.path,[{toReplace:":groupeId",value:props.groupe.groupe.id}])} ><IconButton>
                        <AddIcon color={"primary"} />
                    </IconButton></Link></h4>
                    <AssignementsTable type={"GroupeCategorie"} supprimer={handleSupprimerAssignementGroupeCategorie} showSupprimerButton={true} assignements={props.groupe.groupe.assignementGroupeCategorie}/>

                </Grid>

*/}
                <Grid item xs={12} className={"my3"}>

                    <h4 className={"text-left"}><b>Assignements Utilisateur Cours : </b><Link to={generateDynamicRoute(AdminUtilisateursModifierUtilisateurAjouterAssignementUtilisateurCoursPage.path,[{toReplace:":utilisateurId",value:props.utilisateur.utilisateur.id}])} ><IconButton >
                        <AddIcon color={"primary"} />
                    </IconButton></Link></h4>

                    {props.supprimerAssignementUtilisateurCoursState.success&&
                    <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'assignement supprimé avec succees</Alert>
                    }

                    <AssignementsTable type={"UtilisateurCours"} supprimer={handleSupprimerAssignementUtilisateurCours} showSupprimerButton={true} assignements={state.assignementUtilisateurCours}/>

                </Grid>

                <Grid item xs={12} className={"my3"}>

                    <h4 className={"text-left"}><b>Assignements Utilisateur Categorie : </b><Link to={generateDynamicRoute(AdminUtilisateursModifierUtilisateurAjouterAssignementUtilisateurCategoriePage.path,[{toReplace:":utilisateurId",value:props.utilisateur.utilisateur.id}])} ><IconButton >
                    <AddIcon color={"primary"} />
                    </IconButton></Link></h4>

                    {props.supprimerAssignementUtilisateurCategorieState.success&&
                    <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'assignement supprimé avec succees</Alert>
                    }

                    <AssignementsTable type={"UtilisateurCategorie"} supprimer={handleSupprimerAssignementUtilisateurCategorie} showSupprimerButton={true} assignements={state.assignementUtilisateurCategorie}/>

                </Grid>



            </Grid>
        </div>



        <AjouterGroupeModal utilisateur={props.utilisateur.utilisateur} open={state.ajouterGroupeModalOpen} handleClose={handleCloseAjouterGroupeModal} />


    </>

    }





        <Switch>
            {props.routes.map(route => {
                return CustomRoute(route);
            })}
        </Switch>

    </>
    );

}

const mapStateToProps = (state) => {
    return {


        ajouterUtilisateurAuGroupeState : state.admin.utilisateurs.ajouterUtilisateurAuGroupe,
        supprimerUtilisateurAuGroupeState : state.admin.utilisateurs.supprimerUtilisateurAuGroupe,

        ajouterAssignementGroupeCoursState : state.admin.assignementsGroupeCours.ajouterAssignementGroupeCours,
        //ajouterAssignementGroupeCoursState : state.admin.assignementsGroupeCours.ajouterAssignementGroupeCours,
        supprimerAssignementUtilisateurCoursState : state.admin.assignementsUtilisateurCours.supprimerAssignementUtilisateurCours,
        ajouterAssignementGroupeCategorieState : state.admin.assignementsGroupeCategorie.ajouterAssignementGroupeCategorie,
        supprimerAssignementUtilisateurCategorieState : state.admin.assignementsUtilisateurCategorie.supprimerAssignementUtilisateurCategorie,
        modifierUtilisateurState : state.admin.utilisateurs.modifierUtilisateur,


        utilisateur : state.admin.utilisateurs.utilisateur,
        listeGroupes : state.admin.utilisateurs.listeGroupes
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUtilisateur : (utilisateurId) => dispatch(adminUtilisateursFetchUtilisateur(utilisateurId)),



        modifierUtilisateur : (utilisateurId,utilisateur,photo) => dispatch(adminUtilisateursModifierUtilisateur(utilisateurId,utilisateur,photo)),
        modifierUtilisateurReset : () => dispatch(adminUtilisateursModifierUtilisateurReset()),


        changerMotDePasse: (utilisateurId,utilisateur,sendEmail,generatePassword) => dispatch(adminUtilisateursModifierUtilisateurChangerMotDePasse(utilisateurId,utilisateur,sendEmail,generatePassword) ),


        fetchListeGroupes : () => dispatch(adminUtilisateursFetchListeGroupes()),
        fetchListeUtilisateurs : () => dispatch(adminUtilisateursFetchListeUtilisateurs()),
        fetchGroupeListeGroupes : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeGroupes(groupeId)),
        fetchGroupeListeUtilisateurs : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeUtilisateurs(groupeId)),



        supprimerUtilisateurAuGroupe : (utilisateurId,groupeId) => dispatch(adminUtilisateursSupprimerUtilisateurAuGroupe(utilisateurId,groupeId)),

        supprimerAssignementUtilisateurCours : (assignementUtilisateurCoursId) => dispatch(adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCours(assignementUtilisateurCoursId)),
        supprimerAssignementUtilisateurCategorie : (assignementUtilisateurCategorieId) => dispatch(adminAssignementsUtilisateurCategorieSupprimerAssignementUtilisateurCategorie(assignementUtilisateurCategorieId))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(ModifierUtilisateur);

function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}

const countries = [
    {name: 'Afghanistan', code: 'AF'},
    {name: 'Åland Islands', code: 'AX'},
    {name: 'Albania', code: 'AL'},
    {name: 'Algeria', code: 'DZ'},
    {name: 'American Samoa', code: 'AS'},
    {name: 'AndorrA', code: 'AD'},
    {name: 'Angola', code: 'AO'},
    {name: 'Anguilla', code: 'AI'},
    {name: 'Antarctica', code: 'AQ'},
    {name: 'Antigua and Barbuda', code: 'AG'},
    {name: 'Argentina', code: 'AR'},
    {name: 'Armenia', code: 'AM'},
    {name: 'Aruba', code: 'AW'},
    {name: 'Australia', code: 'AU'},
    {name: 'Austria', code: 'AT'},
    {name: 'Azerbaijan', code: 'AZ'},
    {name: 'Bahamas', code: 'BS'},
    {name: 'Bahrain', code: 'BH'},
    {name: 'Bangladesh', code: 'BD'},
    {name: 'Barbados', code: 'BB'},
    {name: 'Belarus', code: 'BY'},
    {name: 'Belgium', code: 'BE'},
    {name: 'Belize', code: 'BZ'},
    {name: 'Benin', code: 'BJ'},
    {name: 'Bermuda', code: 'BM'},
    {name: 'Bhutan', code: 'BT'},
    {name: 'Bolivia', code: 'BO'},
    {name: 'Bosnia and Herzegovina', code: 'BA'},
    {name: 'Botswana', code: 'BW'},
    {name: 'Bouvet Island', code: 'BV'},
    {name: 'Brazil', code: 'BR'},
    {name: 'British Indian Ocean Territory', code: 'IO'},
    {name: 'Brunei Darussalam', code: 'BN'},
    {name: 'Bulgaria', code: 'BG'},
    {name: 'Burkina Faso', code: 'BF'},
    {name: 'Burundi', code: 'BI'},
    {name: 'Cambodia', code: 'KH'},
    {name: 'Cameroon', code: 'CM'},
    {name: 'Canada', code: 'CA'},
    {name: 'Cape Verde', code: 'CV'},
    {name: 'Cayman Islands', code: 'KY'},
    {name: 'Central African Republic', code: 'CF'},
    {name: 'Chad', code: 'TD'},
    {name: 'Chile', code: 'CL'},
    {name: 'China', code: 'CN'},
    {name: 'Christmas Island', code: 'CX'},
    {name: 'Cocos (Keeling) Islands', code: 'CC'},
    {name: 'Colombia', code: 'CO'},
    {name: 'Comoros', code: 'KM'},
    {name: 'Congo', code: 'CG'},
    {name: 'Congo, The Democratic Republic of the', code: 'CD'},
    {name: 'Cook Islands', code: 'CK'},
    {name: 'Costa Rica', code: 'CR'},
    {name: 'Cote D\'Ivoire', code: 'CI'},
    {name: 'Croatia', code: 'HR'},
    {name: 'Cuba', code: 'CU'},
    {name: 'Cyprus', code: 'CY'},
    {name: 'Czech Republic', code: 'CZ'},
    {name: 'Denmark', code: 'DK'},
    {name: 'Djibouti', code: 'DJ'},
    {name: 'Dominica', code: 'DM'},
    {name: 'Dominican Republic', code: 'DO'},
    {name: 'Ecuador', code: 'EC'},
    {name: 'Egypt', code: 'EG'},
    {name: 'El Salvador', code: 'SV'},
    {name: 'Equatorial Guinea', code: 'GQ'},
    {name: 'Eritrea', code: 'ER'},
    {name: 'Estonia', code: 'EE'},
    {name: 'Ethiopia', code: 'ET'},
    {name: 'Falkland Islands (Malvinas)', code: 'FK'},
    {name: 'Faroe Islands', code: 'FO'},
    {name: 'Fiji', code: 'FJ'},
    {name: 'Finland', code: 'FI'},
    {name: 'France', code: 'FR'},
    {name: 'French Guiana', code: 'GF'},
    {name: 'French Polynesia', code: 'PF'},
    {name: 'French Southern Territories', code: 'TF'},
    {name: 'Gabon', code: 'GA'},
    {name: 'Gambia', code: 'GM'},
    {name: 'Georgia', code: 'GE'},
    {name: 'Germany', code: 'DE'},
    {name: 'Ghana', code: 'GH'},
    {name: 'Gibraltar', code: 'GI'},
    {name: 'Greece', code: 'GR'},
    {name: 'Greenland', code: 'GL'},
    {name: 'Grenada', code: 'GD'},
    {name: 'Guadeloupe', code: 'GP'},
    {name: 'Guam', code: 'GU'},
    {name: 'Guatemala', code: 'GT'},
    {name: 'Guernsey', code: 'GG'},
    {name: 'Guinea', code: 'GN'},
    {name: 'Guinea-Bissau', code: 'GW'},
    {name: 'Guyana', code: 'GY'},
    {name: 'Haiti', code: 'HT'},
    {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
    {name: 'Holy See (Vatican City State)', code: 'VA'},
    {name: 'Honduras', code: 'HN'},
    {name: 'Hong Kong', code: 'HK'},
    {name: 'Hungary', code: 'HU'},
    {name: 'Iceland', code: 'IS'},
    {name: 'India', code: 'IN'},
    {name: 'Indonesia', code: 'ID'},
    {name: 'Iran, Islamic Republic Of', code: 'IR'},
    {name: 'Iraq', code: 'IQ'},
    {name: 'Ireland', code: 'IE'},
    {name: 'Isle of Man', code: 'IM'},
    {name: 'Israel', code: 'IL'},
    {name: 'Italy', code: 'IT'},
    {name: 'Jamaica', code: 'JM'},
    {name: 'Japan', code: 'JP'},
    {name: 'Jersey', code: 'JE'},
    {name: 'Jordan', code: 'JO'},
    {name: 'Kazakhstan', code: 'KZ'},
    {name: 'Kenya', code: 'KE'},
    {name: 'Kiribati', code: 'KI'},
    {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
    {name: 'Korea, Republic of', code: 'KR'},
    {name: 'Kuwait', code: 'KW'},
    {name: 'Kyrgyzstan', code: 'KG'},
    {name: 'Lao People\'S Democratic Republic', code: 'LA'},
    {name: 'Latvia', code: 'LV'},
    {name: 'Lebanon', code: 'LB'},
    {name: 'Lesotho', code: 'LS'},
    {name: 'Liberia', code: 'LR'},
    {name: 'Libyan Arab Jamahiriya', code: 'LY'},
    {name: 'Liechtenstein', code: 'LI'},
    {name: 'Lithuania', code: 'LT'},
    {name: 'Luxembourg', code: 'LU'},
    {name: 'Macao', code: 'MO'},
    {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
    {name: 'Madagascar', code: 'MG'},
    {name: 'Malawi', code: 'MW'},
    {name: 'Malaysia', code: 'MY'},
    {name: 'Maldives', code: 'MV'},
    {name: 'Mali', code: 'ML'},
    {name: 'Malta', code: 'MT'},
    {name: 'Marshall Islands', code: 'MH'},
    {name: 'Martinique', code: 'MQ'},
    {name: 'Mauritania', code: 'MR'},
    {name: 'Mauritius', code: 'MU'},
    {name: 'Mayotte', code: 'YT'},
    {name: 'Mexico', code: 'MX'},
    {name: 'Micronesia, Federated States of', code: 'FM'},
    {name: 'Moldova, Republic of', code: 'MD'},
    {name: 'Monaco', code: 'MC'},
    {name: 'Mongolia', code: 'MN'},
    {name: 'Montserrat', code: 'MS'},
    {name: 'Morocco', code: 'MA'},
    {name: 'Mozambique', code: 'MZ'},
    {name: 'Myanmar', code: 'MM'},
    {name: 'Namibia', code: 'NA'},
    {name: 'Nauru', code: 'NR'},
    {name: 'Nepal', code: 'NP'},
    {name: 'Netherlands', code: 'NL'},
    {name: 'Netherlands Antilles', code: 'AN'},
    {name: 'New Caledonia', code: 'NC'},
    {name: 'New Zealand', code: 'NZ'},
    {name: 'Nicaragua', code: 'NI'},
    {name: 'Niger', code: 'NE'},
    {name: 'Nigeria', code: 'NG'},
    {name: 'Niue', code: 'NU'},
    {name: 'Norfolk Island', code: 'NF'},
    {name: 'Northern Mariana Islands', code: 'MP'},
    {name: 'Norway', code: 'NO'},
    {name: 'Oman', code: 'OM'},
    {name: 'Pakistan', code: 'PK'},
    {name: 'Palau', code: 'PW'},
    {name: 'Palestinian Territory, Occupied', code: 'PS'},
    {name: 'Panama', code: 'PA'},
    {name: 'Papua New Guinea', code: 'PG'},
    {name: 'Paraguay', code: 'PY'},
    {name: 'Peru', code: 'PE'},
    {name: 'Philippines', code: 'PH'},
    {name: 'Pitcairn', code: 'PN'},
    {name: 'Poland', code: 'PL'},
    {name: 'Portugal', code: 'PT'},
    {name: 'Puerto Rico', code: 'PR'},
    {name: 'Qatar', code: 'QA'},
    {name: 'Reunion', code: 'RE'},
    {name: 'Romania', code: 'RO'},
    {name: 'Russian Federation', code: 'RU'},
    {name: 'RWANDA', code: 'RW'},
    {name: 'Saint Helena', code: 'SH'},
    {name: 'Saint Kitts and Nevis', code: 'KN'},
    {name: 'Saint Lucia', code: 'LC'},
    {name: 'Saint Pierre and Miquelon', code: 'PM'},
    {name: 'Saint Vincent and the Grenadines', code: 'VC'},
    {name: 'Samoa', code: 'WS'},
    {name: 'San Marino', code: 'SM'},
    {name: 'Sao Tome and Principe', code: 'ST'},
    {name: 'Saudi Arabia', code: 'SA'},
    {name: 'Senegal', code: 'SN'},
    {name: 'Serbia and Montenegro', code: 'CS'},
    {name: 'Seychelles', code: 'SC'},
    {name: 'Sierra Leone', code: 'SL'},
    {name: 'Singapore', code: 'SG'},
    {name: 'Slovakia', code: 'SK'},
    {name: 'Slovenia', code: 'SI'},
    {name: 'Solomon Islands', code: 'SB'},
    {name: 'Somalia', code: 'SO'},
    {name: 'South Africa', code: 'ZA'},
    {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
    {name: 'Spain', code: 'ES'},
    {name: 'Sri Lanka', code: 'LK'},
    {name: 'Sudan', code: 'SD'},
    {name: 'Suriname', code: 'SR'},
    {name: 'Svalbard and Jan Mayen', code: 'SJ'},
    {name: 'Swaziland', code: 'SZ'},
    {name: 'Sweden', code: 'SE'},
    {name: 'Switzerland', code: 'CH'},
    {name: 'Syrian Arab Republic', code: 'SY'},
    {name: 'Taiwan, Province of China', code: 'TW'},
    {name: 'Tajikistan', code: 'TJ'},
    {name: 'Tanzania, United Republic of', code: 'TZ'},
    {name: 'Thailand', code: 'TH'},
    {name: 'Timor-Leste', code: 'TL'},
    {name: 'Togo', code: 'TG'},
    {name: 'Tokelau', code: 'TK'},
    {name: 'Tonga', code: 'TO'},
    {name: 'Trinidad and Tobago', code: 'TT'},
    {name: 'Tunisia', code: 'TN'},
    {name: 'Turkey', code: 'TR'},
    {name: 'Turkmenistan', code: 'TM'},
    {name: 'Turks and Caicos Islands', code: 'TC'},
    {name: 'Tuvalu', code: 'TV'},
    {name: 'Uganda', code: 'UG'},
    {name: 'Ukraine', code: 'UA'},
    {name: 'United Arab Emirates', code: 'AE'},
    {name: 'United Kingdom', code: 'GB'},
    {name: 'United States', code: 'US'},
    {name: 'United States Minor Outlying Islands', code: 'UM'},
    {name: 'Uruguay', code: 'UY'},
    {name: 'Uzbekistan', code: 'UZ'},
    {name: 'Vanuatu', code: 'VU'},
    {name: 'Venezuela', code: 'VE'},
    {name: 'Viet Nam', code: 'VN'},
    {name: 'Virgin Islands, British', code: 'VG'},
    {name: 'Virgin Islands, U.S.', code: 'VI'},
    {name: 'Wallis and Futuna', code: 'WF'},
    {name: 'Western Sahara', code: 'EH'},
    {name: 'Yemen', code: 'YE'},
    {name: 'Zambia', code: 'ZM'},
    {name: 'Zimbabwe', code: 'ZW'}
];