import React, {useEffect} from "react";

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from "@material-ui/core/IconButton";


import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Typography from '@material-ui/core/Typography';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import DeleteIcon from '@material-ui/icons/Delete';
import Label from '@material-ui/icons/Label';
import {Box} from "@material-ui/core";


import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import {Link} from "react-router-dom";

function UserList({items,selected,handleSelect,expanded,handelExpand}) {


    const generateTreeItem = (item) => {
        console.log(item);
        return (
            item.type === "Groupe" ?
                (!item.content.listeGroupes.loading && !item.content.listeUtilisateurs.loading) ?
                    <TreeItem nodeId={"Groupe"+item.id} label={<div><GroupIcon/> {item.nom} </div>}>
                        {item.content.listeGroupes.listeGroupes.concat(item.content.listeUtilisateurs.listeUtilisateurs).map((subItem) => {
                            return generateTreeItem(subItem);
                        })}
                    </TreeItem>
                    :   <TreeItem nodeId={"Groupe"+item.id} label={<div><GroupIcon/> {item.nom} </div>}>
                            <TreeItem nodeId={"Loading"+item.id} label={<CircularProgress size={25} thickness={3} />}/>
                        </TreeItem>
            :   <TreeItem nodeId={"Utilisateur"+item.id} label={<div><PersonIcon/> {item.nom} {item.prenom} </div>}/>
        );
    };



    const treeItems = items.map((item) => {
        return generateTreeItem(item);
        /*return (
            item.type === "Groupe" ?
                (!item.content.listeGroupes.loading && !item.content.listeUtilisateurs.loading) ?
                    <TreeItem nodeId={"Groupe"+item.id} label={<div><GroupIcon/> {item.nom} </div>}/>
                    :   <TreeItem nodeId={"Groupe"+item.id} label={<div><GroupIcon/> {item.nom} </div>}>
                            <TreeItem nodeId={"Loading"+item.id} label={<CircularProgress size={25} thickness={3} />}/>
                        </TreeItem>
            :   <TreeItem nodeId={"Utilisateur"+1} label={<div><PersonIcon/> {item.nom} {item.prenom} </div>}/>
        );*/
    });

    return (
            <TreeView
                className={"p-3"}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handelExpand}
                onNodeSelect={handleSelect}
            >
                {treeItems}
            </TreeView>
    );


}

export default UserList;
