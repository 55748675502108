import * as actionTypes from "../../../../../actions/actionTypes";
import {updateObject} from "../../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    commande: null
};

const etudiantCommandeCreerCommandeStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        commande: null
    });
};

const etudiantCommandeCreerCommandeSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        commande: action.payload.commande
    });
};

const etudiantCommandeCreerCommandeFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        commande: null
    });
};

const etudiantCommandeCreerCommandeReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ETUDIANT_COMMANDE_CREER_COMMANDE_START : return etudiantCommandeCreerCommandeStart(state,action);
        case actionTypes.ETUDIANT_COMMANDE_CREER_COMMANDE_SUCCESS : return etudiantCommandeCreerCommandeSuccess(state,action);
        case actionTypes.ETUDIANT_COMMANDE_CREER_COMMANDE_FAIL : return etudiantCommandeCreerCommandeFail(state,action);
        case actionTypes.ETUDIANT_COMMANDE_CREER_COMMANDE_RESET : return etudiantCommandeCreerCommandeReset(state,action);
        default : return state;
    }

}

export default reducer;