import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";

export function* adminCoursAjouterCategorieSaga (action) {

    yield put(actions.adminCoursAjouterCategorieStart());

    const categorie = action.payload.categorie;
    const photo = action.payload.photo;

    const formData = yield new FormData();
    yield formData.append('categorie', new Blob([JSON.stringify(categorie)], {
        type: "application/json"
    }));
    yield formData.append("photo",photo);

    try {
        const response = yield axios.post(api.ajouterCategorie,formData);
        yield put(actions.adminCoursAjouterCategorieSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminCoursAjouterCategorieFail(error.response.data.error));
        }else {
            yield put(actions.adminCoursAjouterCategorieFail("There's an error!"));
        }
    }
}

