import * as actionTypes from "../../../actionTypes";

export const technicianCoursesFetchCoursesList = () => {
    return {
        type : actionTypes.TECHNICIAN_COURSES_INITIATE_FETCH_COURSES_LIST
    }
};

export const technicianCoursesFetchCoursesListStart = () => {
    return {
        type : actionTypes.TECHNICIAN_COURSES_FETCH_COURSES_LIST_START
    }
};

export const technicianCoursesFetchCoursesListSuccess = (coursesList) => {
    return {
        type : actionTypes.TECHNICIAN_COURSES_FETCH_COURSES_LIST_SUCCESS,
        payload : {
            coursesList : coursesList
        }
    }
};

export const technicianCoursesFetchCoursesListFail = (error) => {
    return {
        type : actionTypes.TECHNICIAN_COURSES_FETCH_COURSES_LIST_FAIL,
        payload : {
            error: error
        }
    }
};

export const technicianCoursesFetchCoursesListReset = () => {
    return {
        type : actionTypes.TECHNICIAN_COURSES_FETCH_COURSES_LIST_RESET
    }
};

