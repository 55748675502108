import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

import AddIcon from '@material-ui/icons/Add';

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import {CircularProgress} from "@material-ui/core";
import {Grid} from "@material-ui/core";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import {connect} from "react-redux";

import Alert from "@material-ui/lab/Alert";



import Chip from '@material-ui/core/Chip';
import Box from "@material-ui/core/Box";

import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {findGroupes, generateDynamicRoute} from "../../../utils/utils";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import {adminUtilisateursFetchGroupe} from "../../../store/actions/admin/utilisateurs/groupeActions";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import AssignementsTable from "../../../components/AssignementsTable";
import AjouterAssignementGroupeCoursModal from "../AjouterAssignementGroupeCoursModal";
import CustomRoute from "../../../hoc/CustomRoute";
import {
    AdminUtilisateursModifierGroupeAjouterAssignementGroupeCategoriePage,
    AdminUtilisateursModifierGroupeAjouterAssignementGroupeCoursPage
} from "../../../routes/administratorRoutes";
import {ajouterAssignementGroupeCours} from "../../../api";
import {adminAssignementsGroupeCoursSupprimerAssignementGroupeCours} from "../../../store/actions/admin/assignementsGroupeCours/supprimerAssignementGroupeCoursActions";
import {adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorie} from "../../../store/actions/admin/assignementsGroupeCategorie/supprimerAssignementGroupeCategorieActions";
import {
    adminUtilisateursModifierGroupe,
    adminUtilisateursModifierGroupeSupprimerSousGroupe
} from "../../../store/actions/admin/utilisateurs/modifierGroupeActions";
import {adminUtilisateursSupprimerUtilisateurAuGroupe} from "../../../store/actions/admin/utilisateurs/supprimerUtilisateurAuGroupeActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Switch from "@material-ui/core/Switch";
import {
    adminParametresModifierParametres,
    adminParametresModifierParametresReset
} from "../../../store/actions/admin/parametres/modifierParametres";


const initialState = {
    websiteDescription : {
        value: "",
        error: null
    },

    montrerLesCoursSpeciaux : false,
};

function Parametres(props) {


    const [state,setState] = useState(initialState);


    useEffect(() => {
        return () => {
            props.modifierParametresReset();
        }
    },[])


    /*** Form ***/

    const websiteDescriptionOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                websiteDescription : {
                    value : value,
                    error: error
                }
            }
        });
    };

    const montrerLesCoursSpeciauxOnChangeHandler = (event) =>{
        const checked = event.target.checked;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                montrerLesCoursSpeciaux : checked
            }
        });
    };

    const formOnSubmitHandler = (event) => {
        event.preventDefault();

        const parametres = {
            websiteDescription: state.websiteDescription.value,
            montrerLesCoursSpeciaux: state.montrerLesCoursSpeciaux
        };

        props.modifierParametres(parametres);

    };

    /*** Form ***/



    const inputClasses = ["my-2"];



    useEffect(() => {
        if(props.parametres.parametres)
            setState(prevState => {
                return {
                    ...prevState,
                    websiteDescription : {
                        value: props.parametres.parametres.websiteDescription,
                        error: null
                    },
                    montrerLesCoursSpeciaux: props.parametres.parametres.montrerLesCoursSpeciaux === "true"

                }
            });
    },[props.parametres.parametres]);

    return(
<>
    <div className={"p-5"}>
        <div className={"text-center border rounded p-3"}>

            <h2>Gérer les paramètres</h2>

            {props.modifierParametresState.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.error}</Alert>
            }
            {props.modifierParametresState.success &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Les paramètres sont à jour</Alert>
            }

            {(!props.parametres.loading && !props.parametres.error) ?
            <form onSubmit={formOnSubmitHandler}>
                <Grid container spacing={3} className={"my-3 text-left"}>
                    <Grid item xs={12}>
                        <TextField error={state.websiteDescription.error ? true : false} helperText={state.websiteDescription.error}
                                   className={inputClasses.join(" ")} onChange={websiteDescriptionOnChangeHandler}
                                   value={state.websiteDescription.value} fullWidth={true} size={"small"} label="Description"
                                   variant="outlined"/>
                    </Grid>
                    <Grid item xs={6} className={"text-left"}>
                        <FormControlLabel
                            control={<Switch checked={state.montrerLesCoursSpeciaux} onChange={montrerLesCoursSpeciauxOnChangeHandler} />}
                            label="Montrer les cours spéciaux"
                        />
                    </Grid>
                </Grid>
                <div className={"position-relative"}>
                    <Button disabled={props.modifierParametresState.loading} type={"submit"} className={inputClasses.join(" ")}
                            color={"primary"} variant={"contained"} endIcon={<PersonAddIcon/>}>Mettre à jour</Button>
                    {props.modifierParametresState.loading && <CircularProgress size={24} /*className={spinnerClasses.join(" ")}*/ />}
                </div>
            </form>
            :null}

        </div>

    </div>
    </>
    );

}

const mapStateToProps = (state) => {
    return {

        modifierParametresState : state.admin.parametres.modifierParametres,
        parametres : state.parametres

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        modifierParametres : (parametres) => dispatch(adminParametresModifierParametres(parametres)),
        modifierParametresReset : () => dispatch(adminParametresModifierParametresReset()),


    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Parametres);