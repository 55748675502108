import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    listeCommandes: []
};

const adminCommandesFetchListeCommandesStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        listeCommandes: []
    });
};

const adminCommandesFetchListeCommandesSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        listeCommandes: action.payload.listeCommandes
    });
};

const adminCommandesFetchListeCommandesFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        listeCommandes: []
    });
};

const adminCommandesFetchListeCommandesReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};


function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_COMMANDES_FETCH_LISTE_COMMANDES_START : return adminCommandesFetchListeCommandesStart(state,action);
        case actionTypes.ADMIN_COMMANDES_FETCH_LISTE_COMMANDES_SUCCESS : return adminCommandesFetchListeCommandesSuccess(state,action);
        case actionTypes.ADMIN_COMMANDES_FETCH_LISTE_COMMANDES_FAIL : return adminCommandesFetchListeCommandesFail(state,action);
        case actionTypes.ADMIN_COMMANDES_FETCH_LISTE_COMMANDES_RESET : return adminCommandesFetchListeCommandesReset(state,action);


        default : return state;
    }

}

export default reducer;