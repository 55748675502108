import React, {useEffect} from "react";

import Modal from '@material-ui/core/Modal';

import FormHelperText from '@material-ui/core/FormHelperText';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import {CircularProgress, Grid} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";
import {CoursePage} from "../../../../../routes/studentRoutes";
import {generateDynamicRoute} from "../../../../../utils/utils";
import {AdminUtilisateursModifierGroupePage} from "../../../../../routes/administratorRoutes";
import {adminUtilisateursFetchGroupe} from "../../../../../store/actions/admin/utilisateurs/groupeActions";
import {adminUtilisateursAjouterGroupe} from "../../../../../store/actions/admin/utilisateurs/ajouterGroupeActions";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import {connect} from "react-redux";
import Chip from "@material-ui/core/Chip";


import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';



import DateFnsUtils from '@date-io/date-fns';

import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {adminAssignementsGroupeCoursAjouterAssignementGroupeCours} from "../../../../../store/actions/admin/assignementsGroupeCours/ajouterAssignementGroupeCoursActions";
import Alert from "@material-ui/lab/Alert";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TreeListeGroupesSingleAdd from "../../../../../components/TreeListeGroupesSingleAdd";
import Box from "@material-ui/core/Box";
import TreeListeGroupesMultiAdd from "../../../../../components/TreeListeGroupesMultiAdd";
import {adminCoursFetchListeCours} from "../../../../../store/actions/admin/cours/listeCoursActions";
import TreeListe from "../../../../../components/TreeListe";
import {
    adminCoursFetchCategorieListeCategories, adminCoursFetchCategorieListeCours,
    adminCoursFetchListeCategories
} from "../../../../../store/actions/admin/cours/listeCategoriesActions";
import {adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorie} from "../../../../../store/actions/admin/assignementsGroupeCategorie/ajouterAssignementGroupeCategorieActions";
import {adminUtilisateursModifierGroupeAjouterSousGroupe} from "../../../../../store/actions/admin/utilisateurs/modifierGroupeActions";
import {adminUtilisateursAjouterUtilisateurAuGroupe} from "../../../../../store/actions/admin/utilisateurs/ajouterUtilisateurAuGroupeActions";

const initialState = {
    utilisateur: {value : null, error : null},
    utilisateursExpanded : false,
};

function AjouterUtilisateur(props) {



    const handleUtilisateursExpanded = (event,isExpanded) => {
        setState(prevState => {
            return {
                ...prevState,
                utilisateursExpanded : isExpanded
            }
        });
    };

    useEffect(() => {
        props.fetchListeGroupes();
        props.fetchListeUtilisateurs();
    },[]);

    const [state, setState] = React.useState(initialState);

    const formOnSubmitHandler = (event) => {

        event.preventDefault();

        let error = false;

        const utilisateur = state.utilisateur.value;

        if(!utilisateur) {
            error = true;
            setState(prevState => {
                return {
                    ...prevState,
                    utilisateur: {
                        value: null,
                        error : "L'utilisateur est obligatoire"
                    }
                }
            })
        }


        if(!error) {
            props.ajouterUtilisateurAuGroupe(utilisateur.id,props.groupe.id);
        }

    };

    const handleUtilisateurSelect = (utilisateur) => {
        setState(prevState => {
            return {
                ...prevState,
                utilisateur: {value : utilisateur, error : null }
            };
        });
    };

    const handleUtilisateurUnSelect = () => {
        setState(prevState => {
            return {
                ...prevState,
                utilisateur: {value : null, error : null }
            };
        });
    };


    const isUtilisateurSelected = (groupeToCheck) => {
        return state.utilisateur.value && state.utilisateur.value.id === groupeToCheck.id;
    };


    return(
        <Modal
            open={props.open}
            onClose={props.handleClose}
            style={{overflowY:"auto"}}
        >
            <div style={{maxWidth:"700px", width:"100%"}} className={"bg-white text-center border rounded mx-auto my-5"}>

                <h2>Ajouter un utilisateur</h2>

                {props.ajouterUtilisateurAuGroupeState.error &&
                <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.ajouterUtilisateurAuGroupeState.error}</Alert>
                }
                {props.ajouterUtilisateurAuGroupeState.success&&
                <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'utilisateur ajouté avec succees</Alert>
                }


                <form className={"text-left p-4"} onSubmit={formOnSubmitHandler}>


                        <ExpansionPanel expanded={state.utilisateursExpanded} onChange={handleUtilisateursExpanded}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <FormControl error={state.utilisateur.error ? true : false}>
                                <h6>Utilisateur : {!state.utilisateursExpanded && state.utilisateur.value ? <Chip label={state.utilisateur.value.nom + state.utilisateur.value.prenom} onDelete={handleUtilisateurUnSelect} /> : null}</h6>
                                    {state.utilisateur.error && <FormHelperText>{state.utilisateur.error}</FormHelperText>}
                                </FormControl>

                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails >
                                {props.listeGroupes.loading || props.listeUtilisateurs.loading ?
                                    <CircularProgress size={50} thickness={4} />
                                    :
                                    <Grid container spacing={3}>

                                        <Grid item xs={12} md={8} >
                                            <span>Selectionner un utilisateur</span>

                                            <TreeListe fetchGroupeListeGroupes={props.fetchGroupeListeGroupes} fetchGroupeListeUtilisateurs={props.fetchGroupeListeUtilisateurs} selectable={"Utilisateur"} liste={props.listeGroupes.listeGroupes.concat(props.listeUtilisateurs.listeUtilisateurs)} handleSelect={handleUtilisateurSelect} handleUnSelect={handleUtilisateurUnSelect} isUtilisateurSelected={isUtilisateurSelected}/>

                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <span>Utilisateur Selectionné</span>
                                            <Box>
                                                {state.utilisateur.value &&
                                                <Chip
                                                    label={state.utilisateur.value.nom + state.utilisateur.value.prenom}
                                                    onDelete={handleUtilisateurUnSelect}
                                                />}
                                            </Box>
                                        </Grid>

                                    </Grid>
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>



                                    <div>
                                        <Button disabled={props.ajouterUtilisateurAuGroupeState.loading} type={"submit"} className={"my-2"}
                                                color={"primary"} variant={"contained"} endIcon={<PersonAddIcon/>}>Ajouter</Button>
                                        {props.ajouterUtilisateurAuGroupeState.loading && <CircularProgress size={24} /*className={spinnerClasses.join(" ")}*/ />}
                                    </div>

                </form>


            </div>
        </Modal>
    );




}

const mapStateToProps = (state) => {
    return {
        ajouterUtilisateurAuGroupeState : state.admin.utilisateurs.ajouterUtilisateurAuGroupe,
        listeGroupes : state.admin.utilisateurs.listeGroupes,
        listeUtilisateurs : state.admin.utilisateurs.listeUtilisateurs,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        ajouterUtilisateurAuGroupe : (utilisateurId,groupeId) => dispatch(adminUtilisateursAjouterUtilisateurAuGroupe(utilisateurId,groupeId)),

        fetchListeGroupes : () => dispatch(adminUtilisateursFetchListeGroupes()),
        fetchListeUtilisateurs : () => dispatch(adminUtilisateursFetchListeUtilisateurs()),
        fetchGroupeListeGroupes : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeGroupes(groupeId)),
        fetchGroupeListeUtilisateurs : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeUtilisateurs(groupeId)),

    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AjouterUtilisateur);