import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    assignementGroupeCours: null
};

const adminAssignementsGroupeCoursAjouterAssignementGroupeCoursStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        assignementGroupeCours: null
    });
};

const adminAssignementsGroupeCoursAjouterAssignementGroupeCoursSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        assignementGroupeCours: action.payload.assignementGroupeCours
    });
};

const adminAssignementsGroupeCoursAjouterAssignementGroupeCoursFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        assignementGroupeCours: null
    });
};


const adminAssignementsGroupeCoursAjouterAssignementGroupeCoursReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_START : return adminAssignementsGroupeCoursAjouterAssignementGroupeCoursStart(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_SUCCESS : return adminAssignementsGroupeCoursAjouterAssignementGroupeCoursSuccess(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_FAIL : return adminAssignementsGroupeCoursAjouterAssignementGroupeCoursFail(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_RESET : return adminAssignementsGroupeCoursAjouterAssignementGroupeCoursReset(state,action);
        default : return state;
    }

}

export default reducer;