import * as actionTypes from "../../../actions/actionTypes";
import {updateObject} from "../../../../utils/utils";

const initialState = {
    token: null,
    user: null,
    loading: false,
    error:null,
    loadingAutoLogin : true
};

const authSignInStart = (state,action) => {
    return updateObject(state,{
        ...initialState,
        loading: true,
        error: null
    })
};

const authSignInSuccess = (state,action) => {
    return updateObject(state,{
        ...initialState,
        loading: false,
        error: null,
        token: action.payload.token,
        user: action.payload.user
    })
};


const authSignInFail = (state,action) => {
    return updateObject(state,{
        ...initialState,
        loading: false,
        error: action.payload.error,
        token:null,
        user:null,
    })
};

const logoutSuccess = (state,action) => {
    return updateObject(state, {
        ...initialState,
        token: null,
        user: null
    })
};

//TODO

export const authUpdateUtilisateur = (state,action) => {
    return updateObject(state, {
        ...state,
        utilisateur : action.payload.utilisateur
    });

};



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_SIGNIN_START : return authSignInStart(state,action);
        case actionTypes.AUTH_SIGNIN_SUCCESS : return authSignInSuccess(state,action);
        case actionTypes.AUTH_SIGNIN_FAIL : return authSignInFail(state,action);
        case actionTypes.AUTH_LOGOUT_SUCCESS : return logoutSuccess(state,action);
        case actionTypes.AUTH_UPDATE_UTILISATEUR : return authUpdateUtilisateur(state,action);
        default : return state;
    }
};

export default reducer;