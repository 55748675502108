const endPoint = "https://api.prepaprofs.com/";
// const endPoint = "http://localhost:8080/";

export const authSignIn = endPoint + "api/auth/signIn";
export const authRequestPassword = endPoint + "api/auth/request-new-password";
export const authNewPassword = endPoint + "api/auth/new-password";
export const authSignUp = endPoint + "api/auth/signUp";

/*** Categories ***/

export const guestGetCategories = endPoint + "api/categories/withSubCategories";

export const getListeCoursOuvert = endPoint + "api/cours?public=true";
export const getListeCoursOuvertSpecial =
  endPoint + "api/cours?public=true&special=true";

/*** Groupes ****/

export const getListeGroupesRoot = endPoint + "api/groupes?tree=true";
export const getGroupeSousGroupes =
  endPoint + "api/groupes/:groupeId/sousGroupes";
export const getGroupeUtilisateurs =
  endPoint + "api/groupes/:groupeId/utilisateurs";

export const getGroupe = endPoint + "api/groupes/:groupeId";

export const ajouterGroupe = endPoint + "api/groupes";

export const supprimerGroupe =
  endPoint +
  "api/groupes/:groupeId?deleteSousGroupes=:deleteSousGroupes&deleteUtilisateurs=:deleteUtilisateurs";
export const modifierGroupe = endPoint + "api/groupes/:groupeId";

/**** GROUPE SOUS GROUPES *****/

export const ajouterSousGroupe =
  endPoint + "api/groupes/:groupeId/sousGroupes/:sousGroupeId";
export const supprimerSousGroupe =
  endPoint + "api/groupes/:groupeId/sousGroupes/:sousGroupeId";

/*** Utilisateurs ****/

export const getListeUtilisateurs = endPoint + "api/utilisateurs?tree=true";
export const getUtilisateur = endPoint + "api/utilisateurs/:utilisateurId";
export const modifierUtilisateur = endPoint + "api/utilisateurs/:utilisateurId";
export const modifierUtilisateurChangerMotDePasse =
  endPoint +
  "api/utilisateurs/:utilisateurId/changerMotDePasse?sendEmail=:sendEmail&generatePassword=:generatePassword";
export const ajouterUtilisateur =
  endPoint +
  "api/utilisateurs?sendEmail=:sendEmail&generateMotDePasse=:generatePassword";
export const supprimerUtilisateur =
  endPoint + "api/utilisateurs/:utilisateurId";

/*** Utilisateurs GROupes ****/

export const ajouterUtilisateurAuGroupe =
  endPoint + "api/utilisateurs/:utilisateurId/groupes/:groupeId";
export const supprimerUtilisateurAuGroupe =
  endPoint + "api/utilisateurs/:utilisateurId/groupes/:groupeId";

/**** CAT ****/

export const getListeCategoriesRoot =
  endPoint + "api/categories?tree=true&ouvert=true";
export const getCategorieSousCategories =
  endPoint + "api/categories/:categorieId/sousCategories";
export const getCategorieCours = endPoint + "api/categories/:categorieId/cours";
export const supprimerCategorie =
  endPoint +
  "api/categories/:categorieId?deleteSousCategories=:deleteSousCategories&deleteCours=:deleteCours";

/**** COURS ****/

export const getListeCoursRoot = endPoint + "api/categories/courses";
export const ajouterCours = endPoint + "api/cours";
export const getCourse = endPoint + "api/courses/:coursId";
export const supprimerCours = endPoint + "api/cours/:coursId";

/**** RESSOURCES ****/
export const getListeRessources = "coursId";

/**** SECTIONS ****/
export const getListeSections = endPoint + "api/courses/:coursId/sections";

/**** CATEGORIES ***/
export const getCategorie = endPoint + "api/categories/:categorieId";
export const ajouterCategorie = endPoint + "api/categories";
export const guestGetCategorieListeCategories =
  endPoint + "api/categories/:categorieId/sousCategories?ouvert=true";
export const guestGetCategorieListeCours =
  endPoint + "api/categories/:categorieId/cours?ouvert=true";

/*** APPRENANT COURS ***/

/*** ASS G COURS ***/

export const ajouterAssignementGroupeCours =
  endPoint + "api/assignementGroupeCours";
export const supprimerAssignementGroupeCours =
  endPoint + "api/assignementGroupeCours/:assignementGroupeCoursId";

export const ajouterAssignementUtilisateurCours =
  endPoint + "api/assignementUtilisateurCours";
export const supprimerAssignementUtilisateurCours =
  endPoint + "api/assignementUtilisateurCours/:assignementUtilisateurCoursId";

/*** ASS G CAT ***/

export const ajouterAssignementGroupeCategorie =
  endPoint + "api/assignementGroupeCategorie";
export const supprimerAssignementGroupeCategorie =
  endPoint + "api/assignementGroupeCategorie/:assignementGroupeCategorieId";

/*** ASS G CAT ***/

export const ajouterAssignementUtilisateurCategorie =
  endPoint + "api/assignementUtilisateurCategorie";
export const supprimerAssignementUtilisateurCategorie =
  endPoint +
  "api/assignementUtilisateurCategorie/:assignementUtilisateurCategorieId";

export const utilisateurTopNotifications =
  endPoint +
  "api/notifications/utilisateur/:utilisateurId?topNotifications=true";

export const parametres = endPoint + "api/settings";

export const studentMyCourses =
  endPoint + "api/users/:utilisateurId/courses?role=ROLE_APPRENANT";

export const studentFetchCourse =
  endPoint + "api/courses/:coursId?role=ROLE_APPRENANT";

export const studentFetchCourseSections =
  endPoint + "api/courses/:coursId/sections?role=ROLE_APPRENANT";

export const studentFetchVideo =
  endPoint + "api/videos/:ressourceId?role=ROLE_APPRENANT";

export const etudiantFetchNotes =
  endPoint + "api/utilisateurs/:utilisateurId/notes/:ressourceId";

export const etudiantAjouterNote = endPoint + "api/utilisateurs/notes";

export const etudiantSupprimerNote =
  endPoint + "api/utilisateurs/notes/:noteId";

export const enseignantMesCours =
  endPoint + "api/users/:utilisateurId/courses?role=ROLE_ENSEIGNANT";

export const enseignantFetchCours =
  endPoint + "api/cours/:coursId?role=ROLE_ENSEIGNANT";

export const enseignantFetchCoursSections =
  endPoint + "api/cours/:coursId/sections?role=ROLE_ENSEIGNANT";

export const enseignantAjouterSection = endPoint + "api/sections";

export const enseignantAjouterFichier = endPoint + "api/ressources/fichiers";

export const adminModifierParametres = endPoint + "api/parametres";

export const guestFetchListeCoursSpeciaux =
  endPoint + "api/cours?special=true&ouvert=true";
export const guestFetchListeCategoriesSpeciales =
  endPoint + "api/categories?speciale=true&ouvert=true";

export const utilisateurModifierProfil = endPoint + "api/users/:utilisateurId";
export const utilisateurModifierProfilChangerMotDePasse =
  endPoint + "api/users/:utilisateurId/changePassword";

/**** GUEST_OFFRES ****/

export const getListeOffres = endPoint + "api/offers/all";

export const studentCreateOrder = endPoint + "api/orders";
export const utilisateurCommandeFetchCommande =
  endPoint + "api/orders/:commandeId";
export const utilisateurCommandePayerCommande =
  endPoint + "api/orders/:commandeId/pay";

/**** ADMIN OFFRES ***/
export const adminOffresFetchListeOffres = endPoint + "api/offers";

export const adminCommandesFetchListeCommandes = endPoint + "api/commandes";

/**** ETUDIANT OFFRES ****/

export const studentOffersList = endPoint + "api/offers/category/:categorieId";

/**** ETUDIANT Commandes ****/

export const studentOrdersFetchOrderList =
  endPoint + "api/orders/user/:utilisateurId";

export const enseignantAjouterDemandeVideo = endPoint + "api/videoRequests";
export const enseignantAjouterDemandeFichier =
  "http://34.71.163.240/api/ressources/demandeFichiers";

/************* TECHNICIAN ****/

export const technicianCoursesFetchCoursesList = endPoint + "api/courses";
export const technicianTagsFetchTagsList = endPoint + "api/tags";

export const technicianVideoRequestsFetchVideoRequestsList =
  endPoint + "api/videoRequests";

export const technicianVideoRequestsFetchVideoRequest =
  endPoint + "api/videoRequests/:videoRequestId";
export const technicianVideoRequestsTerminateVideoRequest =
  endPoint + "api/videoRequests/:videoRequestId/terminate";

export const technicianVideosAddVideo = endPoint + "api/videos";
