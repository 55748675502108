import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"
import * as api from "../../../../../api";

import * as actions from "../../../../actions";

export function* adminCoursFetchListeCoursSaga(action) {


    yield put(actions.adminCoursFetchListeCoursStart());

    try {
        const response = yield axios.get(api.getListeCoursRoot);
        const listeUtilisateurs = response.data;
        yield listeUtilisateurs.forEach(utilisateur => {utilisateur["type"] = "Cours"})
        yield put(actions.adminCoursFetchListeCoursSuccess(listeUtilisateurs));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminCoursFetchListeCoursFail(error.response.data.error));
        }else {
            yield put(actions.adminCoursFetchListeCoursFail("There's an error!"));
        }
    }
}

