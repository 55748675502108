import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    assignementGroupeCoursId : null
};

const adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        assignementGroupeCoursId : null
    });
};

const adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        assignementGroupeCoursId : action.payload.assignementGroupeCoursId
    });
};

const adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        assignementGroupeCoursId : null
    });
};

const adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};


function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_START : return adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursStart(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_SUCCESS : return adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursSuccess(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_FAIL : return adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursFail(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_RESET : return adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursReset(state,action);
        default : return state;
    }

}

export default reducer;