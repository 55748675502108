import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { dateFormat } from "../../../utils/utils";

import PaymentIcon from "@material-ui/icons/Payment";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { utilisateurCartRemoveOffre } from "../../../store/actions/utilisateur/cart";
import { connect } from "react-redux";
import {
  utilisateurCommandeCreerCommande,
  utilisateurCommandeCreerCommandeReset,
} from "../../../store/actions/utilisateur/commande/creerCommande";
import { commandePage } from "../../../routes/utilisateurRoutes";
import { generateDynamicRoute } from "../../../utils/utils";
import { StudentOrderPage } from "../../../routes/studentRoutes";

function ShoppingCart(props) {
  const [toCommande, setToCommande] = useState(false);

  const prixArticle = (article) => {
    if (article.offer.fixedPrice) return article.offer.prix;
    switch (article.duree) {
      case 1:
        return article.offer.price1;
      case 3:
        return article.offer.price3;
      case 6:
        return article.offer.price6;
      case 12:
        return article.offer.price12;
    }
  };

  const dureeArticle = (article) => {
    if (article.offer.fixedPeriod)
      return (
        "De " +
        dateFormat(article.offer.startDate) +
        " à " +
        dateFormat(article.offer.endDate)
      );
    else {
      return article.duree + " mois";
    }
  };

  const handleCreateOrder = () => {
    const orderArticles = [];

    props.cartArticles.forEach((article) => {
      orderArticles.push({
        offer: {
          id: article.offer.id,
        },
        duration: article.duree,
      });
    });

    props.creerCommande(orderArticles);
  };

  useEffect(() => {
    return () => {
      props.creerCommandeReset();
    };
  }, []);

  useEffect(() => {
    if (props.creerCommandeState.success) setToCommande(true);
  }, [props.creerCommandeState.success]);

  return toCommande ? (
    <Redirect
      to={generateDynamicRoute(StudentOrderPage.path, [
        { toReplace: ":commandeId", value: props.creerCommandeState.order.id },
      ])}
    />
  ) : (
    <>
      <div className={"p-5"}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align={"left"}></TableCell>
                <TableCell align={"left"}>Offre</TableCell>
                <TableCell align={"left"}>Prix</TableCell>
                <TableCell align={"left"}>Durrée</TableCell>
                <TableCell align={"right"}>Supprimée ?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.cartArticles.map((article) => {
                const offer = article.offer;
                return (
                  <TableRow>
                    <TableCell align={"left"}>
                      <img src={offer.photo} height={75} width={150} />{" "}
                    </TableCell>
                    <TableCell align={"left"}>{offer.title}</TableCell>
                    <TableCell align={"left"}>
                      {prixArticle(article)} DT
                    </TableCell>
                    <TableCell align={"left"}>
                      {dureeArticle(article)}
                    </TableCell>
                    <TableCell align={"right"}>
                      <IconButton>
                        <DeleteForeverIcon
                          color={"secondary"}
                          onClick={() => props.removeOffre(offer)}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={"text-center my-5"}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<PaymentIcon />}
            onClick={handleCreateOrder}
            disabled={props.creerCommandeState.loading}
          >
            Payer maintenant
          </Button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.authSignIn.user.id,
    cartArticles: state.utilisateur.cart.articles,
    creerCommandeState: state.utilisateur.commande.creerCommande,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeOffre: (article) => dispatch(utilisateurCartRemoveOffre(article)),
    creerCommande: (orderArticles) =>
      dispatch(utilisateurCommandeCreerCommande(orderArticles)),
    creerCommandeReset: () => dispatch(utilisateurCommandeCreerCommandeReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
