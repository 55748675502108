import {put} from "redux-saga/effects";


import * as actions from "../../../actions";

export function* utilisateurAddOffreToCartSaga(action) {

    const article = action.payload.article;
    const cart = yield JSON.parse(localStorage.getItem("cart"));

    if(cart) {
        yield cart.articles.push(article);
        yield localStorage.setItem("cart", JSON.stringify(cart));
    } else {
        const cart = {
            userId : action.payload.userId,
            articles : [article]
        };

        yield localStorage.setItem("cart", JSON.stringify(cart));
    }

    yield put(actions.utilisateurCartAddOffreSuccess(article));
}

export function* utilisateurRemoveOffreFromCartSaga(action) {


    const offer = action.payload.offer;

    const cart = yield JSON.parse(localStorage.getItem("cart"));

    if(cart) {


        cart.articles = yield cart.articles.filter((article) => article.offer.id != offer.id);
        yield localStorage.setItem("cart", JSON.stringify(cart));

        yield put(actions.utilisateurCartRemoveOffreSuccess(offer));

    }

}

export function* loadCartSaga(action) {
    const cart = yield JSON.parse(localStorage.getItem("cart"));


    if(cart) {
        const userId = cart.userId;
        if(userId === action.payload.user.id) {

            const articles = cart.articles;

            yield put(actions.utilisateurCartLoadCart(articles));

        } else {
            yield localStorage.removeItem("cart");
        }
    }
}


export function* resetCartSaga(action) {


    localStorage.removeItem("cart");
    yield put(actions.utilisateurCartResetCartSuccess());

}