import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";

import VideocamIcon from "@material-ui/icons/Videocam";
import { generateDynamicRoute } from "../../utils/utils";
import { ressourcePage } from "../../routes/studentRoutes";
import { Link } from "react-router-dom";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import cssClasses from "./resourcelist.module.css";

function RessourceList(props) {
  const sectionsRessources = props.sectionsRessources;
  const coursId = props.coursId;

  const openRessource = props.openRessource ? true : false;

  const [showRessource, setShowRessource] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);

  const showRessourceHandler = (section) => {
    setCurrentSection(section);
  };

  useEffect(() => {
    if (currentSection != null) {
      setShowRessource(true);
    } else {
      setShowRessource(false);
    }
  }, [currentSection]);

  /*  const sectionsRessourcesItems = sectionsRessources.map(item => {
          return (
              <>
                  <ListItem button onClick={() => showRessourceHandler(item)}>
                      <ListItemIcon>
                          <PermMediaIcon/>
                      </ListItemIcon>
                      <ListItemText primary={item.nom}/>
                  </ListItem>

                  <Divider/>
              </>
          );

      });*/

  const sectionsRessourcesItems2 = sectionsRessources.map((item, index) => {
    return (
      <ListItem
        button
        onClick={() => showRessourceHandler(item)}
        className={
          cssClasses.chapter +
          " border rounded my-4 tex-center d-flex align-items-center p-3"
        }
      >
        <div
          style={{ width: 43, height: 43 }}
          className={"border rounded-circle text-center bg-primary text-white"}
        >
          <h5 className={"m-2"}>
            {index + 1 < 10 ? "0" + (index + 1) : index + 1}
          </h5>
        </div>
        <h5 className={"m-0 p-0 ml-3"}>{item.name}</h5>
      </ListItem>
    );
  });

  const ressources = currentSection
    ? currentSection.videos.map((item) => {
        return (
          <div className={cssClasses.chapter}>
            <Divider />
            {props.isEnrolled ? (
              <Link
                to={generateDynamicRoute(ressourcePage.path, [
                  {
                    toReplace: ":coursId",
                    value: coursId,
                  },
                  { toReplace: ":sectionId", value: currentSection.id },
                  {
                    toReplace: ":ressourceId",
                    value: item.id,
                  },
                ])}
              >
                <ListItem>
                  <ListItemIcon>
                    <VideocamIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                  <h6 style={{ fontSize: "16px" }}>
                    {item.videoFiles.length}{" "}
                    <PictureAsPdfIcon
                      color={"primary"}
                      className={"mr-2 ml-2"}
                    />
                  </h6>
                </ListItem>
              </Link>
            ) : (
              <div className={"position-relative"}>
                <div
                  className="w-100 h-100 position-absolute"
                  style={{
                    background: "grey",
                    "z-index": "99999",
                    opacity: "0.5",
                  }}
                />
                <Link>
                  <ListItem>
                    <ListItemIcon>
                      <VideocamIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                    <h6 style={{ fontSize: "16px" }}>
                      {item.videoFiles.length}{" "}
                      <PictureAsPdfIcon
                        color={"primary"}
                        className={"mr-2 ml-2"}
                      />
                    </h6>
                  </ListItem>
                </Link>
              </div>
            )}
          </div>
        );
      })
    : [];

  return (
    <>
      {!showRessource ? (
        sectionsRessourcesItems2
      ) : (
        <div className={"border my-5 " + cssClasses.chapter}>
          <div className={"d-flex align-items-center"}>
            <IconButton
              onClick={() => {
                setCurrentSection(null);
              }}
              className={"my-3"}
            >
              <ArrowBackIcon />
            </IconButton>

            <div className={"w-100"}>
              <h3 className={"text-center"} style={{ color: "#3f51b5" }}>
                {currentSection ? currentSection.name : null}
              </h3>
            </div>
          </div>

          <List className={"pt-0"}>{ressources}</List>
        </div>
      )}
    </>
  );
}

export default RessourceList;
