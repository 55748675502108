import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    demandeFichier: null
};

const enseignantDemandeFichiersAjouterDemandeFichierStart = (state,action) => {
    return updateObject(state,{
        success : false,
        loading : true,
        error: null,
        demandeFichier: null
    });
};

const enseignantDemandeFichiersAjouterDemandeFichierSuccess = (state,action) => {
    return updateObject(state,{
        success : true,
        loading : false,
        error: null,
        demandeFichier: action.payload.demandeFichier
    });
};

const enseignantDemandeFichiersAjouterDemandeFichierFail = (state,action) => {
    return updateObject(state,{
        success : false,
        loading : false,
        error:action.payload.error,
        demandeFichier: null
    });
};

const enseignantDemandeFichiersAjouterDemandeFichierReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.ENSEIGNANT_DEMANDE_FICHIERS_AJOUTER_DEMANDE_FICHIER_START : return enseignantDemandeFichiersAjouterDemandeFichierStart(state,action);
        case actionTypes.ENSEIGNANT_DEMANDE_FICHIERS_AJOUTER_DEMANDE_FICHIER_SUCCESS : return enseignantDemandeFichiersAjouterDemandeFichierSuccess(state,action);
        case actionTypes.ENSEIGNANT_DEMANDE_FICHIERS_AJOUTER_DEMANDE_FICHIER_FAIL : return enseignantDemandeFichiersAjouterDemandeFichierFail(state,action);
        case actionTypes.ENSEIGNANT_DEMANDE_FICHIERS_AJOUTER_DEMANDE_FICHIER_RESET : return enseignantDemandeFichiersAjouterDemandeFichierReset(state,action);
        default : return state;
    }
};

export default reducer;
