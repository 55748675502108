import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    assignementGroupeCategorie: null
};

const adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        assignementGroupeCategorie: null
    });
};

const adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        assignementGroupeCategorie: action.payload.assignementGroupeCategorie
    });
};

const adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        assignementGroupeCategorie: null
    });
};


const adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_START : return adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieStart(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_SUCCESS : return adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieSuccess(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_FAIL : return adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieFail(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_AJOUTER_ASSIGNEMENT_GROUPE_CATEGORIE_RESET : return adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorieReset(state,action);
        default : return state;
    }

}

export default reducer;