import React, {useState} from "react";
import Modal from '@material-ui/core/Modal';
import {useHistory} from "react-router-dom";
import {CoursePage} from "../../routes/studentRoutes";
import ReactPlayer from "react-player";


import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';

function RessourceModal(props) {

    const history = useHistory();


    const handleClose = () => {

        history.push(CoursePage.path);
        //history.goBack();
    };


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    return (
        <>
        <Modal open={true}
               onClose={handleClose} >

            <div style={{height:"100%",width:"100%",overflowY: "auto"}} className={"bg-white"}>

                <button onClick={handleClose}>X</button>
                <div className={"container my-5"}>
                    <h2 className={"text-center"}>Chapter 1 : Variables</h2>
                    <ReactPlayer url='http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4' playing width={"100%"} height={"100%"} controls={true} />
                    <p>
                        rjefjerfnrjefnre krefjnrefkjer krfjnrejfnejkrnfekr kjrfnkerjnfkerjnfre jfenrkfjnrekfjnre fkjnerkfnrekjf
                        jrefnkejrnfke jfnrekjfnerk rjkfnerkjnfkern jfekrnfre
                    </p>


                    <div className={"my-4"}>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="scrollable force tabs example"
                                centered
                            >
                                <Tab label="Item One" icon={<PhoneIcon />} id='scrollable-force-tab-0' aria-controls='scrollable-force-tabpanel-0' />
                                <Tab label="Item One" icon={<PhoneIcon />} id='scrollable-force-tab-1' aria-controls='scrollable-force-tabpanel-1' />
                                <Tab label="Item One" icon={<PhoneIcon />} id='scrollable-force-tab-2' aria-controls='scrollable-force-tabpanel-1' />

                            </Tabs>
                        </AppBar>
                        <div className={"py-3 border"}>
                            <div
                                role="tabpanel"
                                hidden={value !== 0}
                                id={`scrollable-force-tabpanel-0`}
                                aria-labelledby={`scrollable-force-tab-0`}

                            >
                                kdezdkzekdze,d dkzekdkezd kezdkzedkezk
                            </div>

                            <div
                                role="tabpanel"
                                hidden={value !== 1}
                                id={`scrollable-force-tabpanel-1`}
                                aria-labelledby={`scrollable-force-tab-1`}

                            >
                                kdezdkzekdze,d dkzekdkezd kezdkzedkezk 1
                            </div>

                            <div
                                role="tabpanel"
                                hidden={value !== 2}
                                id={`scrollable-force-tabpanel-2`}
                                aria-labelledby={`scrollable-force-tab-2`}

                            >
                                kdezdkzekdze,d dkzekdkezd kezdkzedkezk 2
                            </div>

                        </div>
                    </div>

                </div>
            </div>


        </Modal>
            </>
    );
    
    
}

export default RessourceModal;