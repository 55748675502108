import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminCoursSupprimerCategorieSaga (action) {

    yield put(actions.adminCoursSupprimerCategorieStart());

    const categorie = action.payload.categorie;
    const supprimerSousCategories = action.payload.supprimerSousCategories;
    const supprimerCours = action.payload.supprimerCours;
    try {
        const response = yield axios.delete(generateDynamicRoute(api.supprimerCategorie,[
                            {toReplace:":categorieId",value:categorie},
                            {toReplace:":deleteSousCategories",value:supprimerSousCategories},
                            {toReplace:":deleteCours",value:supprimerCours},

                            ]));
        yield put(actions.adminCoursSupprimerCategorieSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminCoursSupprimerCategorieFail(error.response.data.error));
        }else {
            yield put(actions.adminCoursSupprimerCategorieFail("There's an error!"));
        }
    }
}

