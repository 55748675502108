import React, {useEffect} from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

import {Box} from "@material-ui/core";


import Button from '@material-ui/core/Button';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckBoxIcon from '@material-ui/icons/CheckBox';


import {Link} from "react-router-dom";


import {connect} from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import Alert from "@material-ui/lab/Alert";
import {dateFormatWithHoursAndMinutes, generateDynamicRoute} from "../../../../utils/utils";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import GavelOutlinedIcon from "@material-ui/icons/GavelOutlined";
import {
    etudiantCommandesFetchListeCommandes,
    etudiantCommandesFetchListeCommandesReset
} from "../../../../store/actions/etudiant/commandes/listeCommandesActions";
import {StudentOrderPage} from "../../../../routes/studentRoutes";

function OrdersList(props) {


/*

    useEffect(() => {

        if(props.supprimerCategorie.success || props.supprimerCours.success) {
            props.fetchListeCategories();
            props.fetchListeCours();
        }

    },[props.supprimerCategorie.success,props.supprimerCours.success])

    useEffect(() => {
        if(selected != null) {
            setActiveButtons(prevState => {
                return{
                    ...prevState,
                    editButtonActive : true,
                    deleteButtonActive : true,
                    viewButtonActive : true
                };
            });
        }
    },[selected]);
*/

    useEffect(() => {
        props.fetchListeCommandes(props.userId);
    },[]);

/*
    const handleVoirDetails = () => {
        if(selected.startsWith("Categorie")) {
            const categorieId = selected.replace("Categorie","");
            history.push(generateDynamicRoute(AdminCoursDetailsCategoriePage.path,[{toReplace:":categorieId",value: categorieId}]));
        } else if(selected.startsWith("Cours")) {
            const coursId = selected.replace("Cours","");
            history.push(generateDynamicRoute(AdminCoursDetailsCoursPage.path,[{toReplace:":coursId",value: coursId}]))
        }
    };

    const handleModifier = () => {
        if(selected.startsWith("Categorie")) {
            const categorieId = selected.replace("Categorie","");
            history.push(generateDynamicRoute(AdminCoursModifierCategoriePage.path,[{toReplace:":categorieId",value: categorieId}]));
        } else if(selected.startsWith("Cours")) {
            const coursId = selected.replace("Cours","");
            history.push(generateDynamicRoute(AdminCoursModifierCoursPage.path,[{toReplace:":coursId",value: coursId}]));
        }

    };

*/



    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const prixArticle = (article) => {
        if(article.offer.fixedPeriod)
            return article.offer.prix;
        switch (article.duration) {
            case 1 :
                return article.offer.price1;
            case 3 :
                return article.offer.price3;
            case 6 :
                return article.offer.price6;
            case 12 :
                return article.offer.price12;

        }
    };



    const commandeStatut = (order) => {

        if (order.onlinePayment) {

            switch (order.onlinePayment.status) {
                case "Payé" :
                    return <Alert icon={<CheckCircleOutlineIcon fontSize="inherit"/>} className={"ml-3 px-4"}
                                  variant="filled" severity="success">
                        Payé
                    </Alert>;
                case "Refusé" :
                    return <Alert icon={<CancelOutlinedIcon fontSize="inherit"/>} className={"ml-3 px-4"}
                                  variant="filled" severity="error">
                        Refusé
                    </Alert>;
                case "En attente de paiement" :
                    return <Alert icon={<GavelOutlinedIcon fontSize="inherit"/>} className={"ml-3 px-4"}
                                  variant="filled" severity="warning">
                        En attente de paiement
                    </Alert>;
            }
        }
    };



    const calculTotal = (order) => {
        let total = 0;
        order.orderArticles.forEach((article) => {
            total += article.price;
        });
        return total;
    };


    const isDisabled = (order) => {
        if (!order.onlinePayment) {
            return true;
        }

        if (order.onlinePayment.status != "En attente de paiement")
            return true;
        else {
            return false
        }
    }




    return (
        <>
            <div className={"p-5"}>
            <Backdrop style={{zIndex : 999}} open={false}>
                <CircularProgress color="inherit" />
            </Backdrop>
        <h1 className={"text-center mt-3 mb-4"}>Mes Commandes</h1>

            {/*props.supprimerCategorie.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.supprimerCategorie.error}</Alert>
            }
            {props.supprimerCategorie.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Le categorie supprimé avec succees</Alert>
            }

            {props.supprimerCours.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.supprimerCours.error}</Alert>
            }
            {props.supprimerCours.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'Cours supprimé avec succees</Alert>
            */}


        <Box className={"border rounded"}>

            {!props.fetchListeCommandesState.loading ?
                <div>


                    <Paper >
                        <TableContainer>
                            <Table aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Date</TableCell>
                                        <TableCell align="center">Numéro Commande</TableCell>
                                        <TableCell align="center">Total</TableCell>
                                        <TableCell align="center">Numéro Transaction</TableCell>
                                        <TableCell align="center">Payé Par</TableCell>
                                        <TableCell align="center">Statut</TableCell>
                                        <TableCell align="center">Payer</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.fetchListeCommandesState.listeCommandes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order) => {
                                        return (
                                            <TableRow key={order.id}>
                                                <TableCell align={"center"}>
                                                    {dateFormatWithHoursAndMinutes(order.date)}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    {order.id}
                                                </TableCell>

                                                <TableCell align="center">{calculTotal(order)} DT</TableCell>

                                                <TableCell align="center">{
                                                    order.payment ? order.payment.transactionNumber : "Non Payé"
                                                }</TableCell>


                                                <TableCell align="center">
                                                    {
                                                        order.payment ? order.payment.setting.id : "Non Payé"
                                                    }
                                                </TableCell>
                                                <TableCell align={"center"}>

                                                    {commandeStatut(order)}
                                                </TableCell>

                                                <TableCell align="center">

                                                    {
                                                        isDisabled(order) ?
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="large"
                                                                startIcon={<CheckBoxIcon/>}
                                                                disabled={isDisabled(order)}
                                                            >
                                                                Payer
                                                            </Button>
                                                            :
                                                            <>

                                                                <Link to={generateDynamicRoute(StudentOrderPage.path, [{
                                                                    toReplace: ":commandeId",
                                                                    value: order.id
                                                                }])}
                                                                ><Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="large"
                                                                    startIcon={<CheckBoxIcon/>}
                                                                >
                                                                    Payer
                                                                </Button></Link>
                                                            </>
                                                    }


                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={props.fetchListeCommandesState.listeCommandes.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>

                </div>

                :
                <div className={"p-5 "} >
                    <div className={"mx-auto loadingContainer"}>
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <div> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Box>
            </div>
</>

    );


}

const mapStateToProps = (state) => {
    return {
        fetchListeCommandesState  : state.etudiant.commandes.listeCommandes,
        userId : state.authSignIn.user.id
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchListeCommandes : (userId) => dispatch(etudiantCommandesFetchListeCommandes(userId)),
        fetchListeCommandesReset : () => dispatch(etudiantCommandesFetchListeCommandesReset()),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(OrdersList);

