import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { guestExplorerCoursFetchListeCours } from "../../../store/actions";
import classes from "./prepa.module.css";
import { Button } from "@material-ui/core";
import { Accordion } from "@material-ui/core";
import { AccordionSummary } from "@material-ui/core";
import { AccordionDetails } from "@material-ui/core";
import ExploreCourseContent from "../../../components/ExploreCourseContent/ExploreCourseContent";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink } from "react-router-dom";
import CoursCategorieTopCardSkeleton from "../../../UI/LoadingSkeleton/CoursCategorieTopCardSkeleton";

function Universitaire(props) {
  const [category, setCategory] = useState("MP1");
  const [sections, setSections] = useState([]);
  useEffect(() => {
    props.fetchCourses(category);
  }, [category]);
  useEffect(() => {
    const course = props.coursesList.response.find(
      (course) => course.title === "Analyse"
    );
    if (course) {
      setSections(course.sections);
    }
  }, [props.coursesList.response]);
  const sectionsList = sections.map((section, index) => {
    return <li key={index}>{section.name}</li>;
  });
  function handleClick(category) {
    switch (category) {
      case "MP1":
        setCategory("MP2");
        break;
      case "MP2":
        setCategory("MP1");
        break;
      case "PC1":
        setCategory("PC2");
        break;
      case "PC2":
        setCategory("PC1");
        break;
      case "PT1":
        setCategory("PT2");
        break;
      case "PT2":
        setCategory("PT1");
        break;
    }
  }
  const MP = ["MP1", "MP2"];
  const PC = ["PC1", "PC2"];
  const PT = ["PT1", "PT2"];
  function getNextClass(category) {
    switch (category) {
      case "MP1":
        return MP;
      case "MP2":
        return MP;
      case "PC1":
        return PC;
      case "PC2":
        return PC;
      case "PT1":
        return PT;
      case "PT2":
        return PT;
    }
  }
  const myRef = useRef(null);

  const executeScroll = (category) => {
    setCategory(category);
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  function getColor(category) {
    return (
      (category === "MP1" || category === "MP2" ? classes.button1 : "") +
      (category === "PT1" || category === "PT2" ? classes.button2 : "") +
      (category === "PC1" || category === "PC2" ? classes.button3 : "")
    );
  }

  const courseList = props.coursesList.response.map((course, index) => {
    return (
      <Accordion>
        <AccordionSummary
          className={classes.panel1 + " " + getColor(category)}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {course.title}
        </AccordionSummary>
        <AccordionDetails>
          <ExploreCourseContent sections={course.sections} />
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <h1>Préparatoire</h1>
        <h3>MP-PC-PT</h3>

        <p>
          {" "}
          Accédez à l'une des meilleures collections de cours en PDF et vidéos,
          dédiés aux étudiants en préparatoire
        </p>
        <div className="row" ref={myRef}>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button + " " + classes.button1}
              onClick={() => executeScroll("MP1")}
            >
              MP
            </Button>
            <Button
              className={classes.button + " " + classes.button2}
              onClick={() => executeScroll("PT1")}
            >
              PT
            </Button>
            <Button
              className={classes.button + " " + classes.button3}
              onClick={() => executeScroll("PC1")}
            >
              PC
            </Button>
          </div>
        </div>

        <div
          className={
            "card " +
            classes.cardContainer +
            " " +
            (category === "MP1" || category === "MP2" ? classes.green : "") +
            " " +
            (category === "PC1" || category === "PC2" ? classes.red : "")
          }
        >
          <div className="card-body">
            <div>
              {props.coursesList.loading && <CoursCategorieTopCardSkeleton />}
            </div>
            <div className={classes.header}>
              <h4>Notre contenu pour {category}</h4>
              <Dropdown>
                <Dropdown.Toggle
                  className={getColor(category)}
                  id="dropdown-basic"
                >
                  {category}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => handleClick(getNextClass(category)[1])}
                  >
                    {getNextClass(category)[0]}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleClick(getNextClass(category)[0])}
                  >
                    {getNextClass(category)[1]}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="card-text">
              <ul class="list-group">{courseList}</ul>
            </div>
            <div className={classes.buttonContainer}>
              <NavLink to={"/Offers"}>
                <Button
                  className={classes.offersButton + " " + getColor(category)}
                >
                  Consulter les offres
                </Button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coursesList: {
      loading: state.guest.explorerCours.listeCours.loading,
      error: state.guest.explorerCours.listeCours.error,
      response: state.guest.explorerCours.listeCours.listeCours,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCourses: (category) =>
      dispatch(guestExplorerCoursFetchListeCours(category)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Universitaire);
