import React, {useEffect} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import {
    adminUtilisateursSupprimerGroupe,
    adminUtilisateursSupprimerGroupeReset
} from "../../../../store/actions/admin/utilisateurs/supprimerGroupe";
import {
    adminUtilisateursSupprimerUtilisateur,
    adminUtilisateursSupprimerUtilisateurReset
} from "../../../../store/actions/admin/utilisateurs/supprimerUtilisateur";
import {connect} from "react-redux";
import {adminUtilisateursFetchGroupe} from "../../../../store/actions/admin/utilisateurs/groupeActions";
import {Grid} from "@material-ui/core";


import BlockIcon from '@material-ui/icons/Block';

import MaterialTable from 'material-table';

import MUIDataTable from "mui-datatables";



import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';



import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import UserList from "../../../../components/UsersList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import GroupIcon from "@material-ui/icons/Group";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PersonIcon from "@material-ui/icons/Person";
import AssignementsTable from "../../../../components/AssignementsTable";
import UtilisateursTable from "../../../../components/UtilisateursTable";
import GroupesTable from "../../../../components/GroupesTable";
import {generateDynamicRoute} from "../../../../utils/utils";
import {
    AdminUtilisateursModifierGroupeAjouterAssignementGroupeCoursPage,
    AdminUtilisateursModifierGroupePage
} from "../../../../routes/administratorRoutes";
import AddIcon from "@material-ui/icons/Add";

function DetailsGroupe(props) {

    let { groupeId } = useParams();

    const history = useHistory();

    useEffect(() => {
        props.fetchGroupe(groupeId);
    },[groupeId]);


    return(

        <div className={"text-center border rounded p-3"}>

        <h2>Details Groupe 
            <Link to={generateDynamicRoute(AdminUtilisateursModifierGroupePage.path,[{toReplace:":groupeId",value:groupeId}])}>
            <IconButton >
                <EditIcon style={{color :"#ff9800"}} />
            </IconButton>
            </Link>
        </h2>

        {!props.loading && !props.error &&
            <div>
        <Grid container spacing={3} className={"my-3"}>
            <Grid item xs={12}>
                <TextField className={"my-2"} value={props.groupe.nom} disabled={true} fullWidth={true} size={"small"} label="Nom" variant="outlined" />
            </Grid>
        </Grid>

                <div className={"text-left"}>
                    <h6>Groupe Parent : {props.groupe.groupeParent ? <Chip label={props.groupe.groupeParent.nom}/> : <Chip label={<BlockIcon />}/> }</h6>


                    <div className={"my3"}>
                        <h4><b>Sous Groupes : </b></h4>
                        <GroupesTable groupes={props.groupe.sousGroupes} />
                    </div>


                    <div className={"my3"}>
                        <h4><b>Utilisateurs : </b></h4>
                        <UtilisateursTable utilisateurs={props.groupe.utilisateurs} />
                    </div>

                    <div className={"my3"}>
                        <h4><b>Assignements Cours : </b></h4>
                        <AssignementsTable type={"GroupeCours"}  assignements={props.groupe.assignementGroupeCours}/>

                    </div>

                    <div className={"my3"}>
                        <h4><b>Assignements Groupe Cours : </b></h4>
                        <AssignementsTable type={"GroupeCours"} assignements={props.groupe.assignementGroupeCours}/>
                    </div>

                </div>

            </div>}


    </div>);


}


const mapStateToProps = (state) => {
    return {
        loading : state.admin.utilisateurs.groupe.loading,
        error : state.admin.utilisateurs.groupe.error,
        groupe : state.admin.utilisateurs.groupe.groupe,
        listeGroupes : {
            loading : state.admin.utilisateurs.listeGroupes.loading,
            error : state.admin.utilisateurs.listeGroupes.error,
            listeGroupes : state.admin.utilisateurs.listeGroupes.listeGroupes,
        },
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        //addGroupe : (nom,groupeParent) => dispatch(adminUsersAddGroupe(nom,groupeParent)),
        fetchGroupe : (groupeId) => dispatch(adminUtilisateursFetchGroupe(groupeId)),

        //resetListeGroupes : () => dispatch(etudiantMesCoursFetchListeCoursReset())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(DetailsGroupe);