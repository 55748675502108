import * as actionTypes from "../../../../actions/actionTypes";

export const adminAssignementsGroupeCoursSupprimerAssignementGroupeCours = (assignementGroupeCoursId) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_INITIATE_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS,
        payload : {
            assignementGroupeCoursId : assignementGroupeCoursId
        }
    }
};

export const adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursStart = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_START
    }
};

export const adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursReset = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_RESET
    }
};

export const adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursSuccess = (assignementGroupeCoursId) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_SUCCESS,
        payload : {
            assignementGroupeCoursId : assignementGroupeCoursId
        }
    }
};

export const adminAssignementsGroupeCoursSupprimerAssignementGroupeCoursFail = (error) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_SUPPRIMER_ASSIGNEMENT_GROUPE_COURS_FAIL,
        payload : {
            error : error
        }
    }
};
