import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    categorie: [],
    listeCategories : {
        loading : true,
        error: null,
        listeCategories: [],
    },
    listeCours : {
        loading : true,
        error: null,
        listeCours: [],
    }
};

const guestExplorerCoursFetchCategorieStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        categorie: null
    });
};

const guestExplorerCoursFetchCategorieSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        categorie: action.payload.categorie
    });
};

const guestExplorerCoursFetchCategorieFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        categorie: null
    });
};





const guestExplorerCoursFetchCategorieListeCategoriesStart = (state,action) => {
    return updateObject(state,{
        ...state,
        listeCategories : {
            loading: true,
            error: null,
            listeCategories: []
        }
    });
};

const guestExplorerCoursFetchCategorieListeCategoriesSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        listeCategories : {
            loading: false,
            error: null,
            listeCategories: action.payload.listeCategories
        }
    });
};

const guestExplorerCoursFetchCategorieListeCategoriesFail = (state,action) => {
    return updateObject(state,{
        ...state,
        listeCategories : {
            loading: false,
            error: action.payload.error,
            listeCategories: []
        }
    });
};









const guestExplorerCoursFetchCategorieListeCoursStart = (state,action) => {
    return updateObject(state,{
        ...state,
        listeCours : {
            loading: true,
            error: null,
            listeCours: []
        }
    });
};

const guestExplorerCoursFetchCategorieListeCoursSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        listeCours : {
            loading: false,
            error: null,
            listeCours: action.payload.listeCours
        }
    });
};

const guestExplorerCoursFetchCategorieListeCoursFail = (state,action) => {
    return updateObject(state,{
        ...state,
        listeCours : {
            loading: false,
            error: action.payload.error,
            listeCours: []
        }
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_START : return guestExplorerCoursFetchCategorieStart(state,action);
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_SUCCESS : return guestExplorerCoursFetchCategorieSuccess(state,action);
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_FAIL : return guestExplorerCoursFetchCategorieFail(state,action);


        case actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_START : return guestExplorerCoursFetchCategorieListeCategoriesStart(state,action);
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_SUCCESS : return guestExplorerCoursFetchCategorieListeCategoriesSuccess(state,action);
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_CATEGORIES_FAIL : return guestExplorerCoursFetchCategorieListeCategoriesFail(state,action);


        case actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_COURS_START : return guestExplorerCoursFetchCategorieListeCoursStart(state,action);
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_COURS_SUCCESS : return guestExplorerCoursFetchCategorieListeCoursSuccess(state,action);
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_CATEGORIE_LISTE_COURS_FAIL : return guestExplorerCoursFetchCategorieListeCoursFail(state,action);

        default : return state;
    }

}

export default reducer;