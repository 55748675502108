import * as actionTypes from "../../../actionTypes";

export const enseignantMesCoursAjouterSection = (section) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_INITIATE_AJOUTER_SECTION,
        payload : {
            section : section
        }
    }
};

export const enseignantMesCoursAjouterSectionStart = () => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_SECTION_START
    }
};

export const enseignantMesCoursAjouterSectionSuccess = (section) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_SECTION_SUCCESS,
        payload : {
            section : section
        }
    }
};

export const enseignantMesCoursAjouterSectionFail = (error) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_SECTION_FAIL,
        payload : {
            error: error
        }
    }
};

export const enseignantMesCoursAjouterSectionReset = () => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_SECTION_RESET
    }
};
