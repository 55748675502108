import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    listeCategories: []
};

const guestExplorerCoursFetchListeCategoriesStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        listeCategories: []
    });
};

const guestExplorerCoursFetchListeCategoriesSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        listeCategories: action.payload.listeCategories
    });
};

const guestExplorerCoursFetchListeCategoriesFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        listeCategories: []
    });
};

function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_LISTE_CATEGORIES_START : return guestExplorerCoursFetchListeCategoriesStart(state,action);
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_LISTE_CATEGORIES_SUCCESS : return guestExplorerCoursFetchListeCategoriesSuccess(state,action);
        case actionTypes.GUEST_EXPLORER_COURS_FETCH_LISTE_CATEGORIES_FAIL : return guestExplorerCoursFetchListeCategoriesFail(state,action);

        default : return state;
    }

}

export default reducer;