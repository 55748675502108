import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {

    loading : true,
    error : null,
    cours: null

};

const enseignantMesCoursFetchCoursStart = (state,action) => {
    return updateObject(state,{
        loading:true,
        error:null,
        cours: null
    });
};

const enseignantMesCoursFetchCoursSuccess = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:null,
        cours: action.payload.cours
    });
};

const enseignantMesCoursFetchCoursFail = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:action.payload.error,
        cours: null
    });
};

const enseignantMesCoursFetchCoursReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_START : return enseignantMesCoursFetchCoursStart(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_SUCCESS : return enseignantMesCoursFetchCoursSuccess(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_FAIL : return enseignantMesCoursFetchCoursFail(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_COURS_RESET : return enseignantMesCoursFetchCoursReset(state,action);
        default : return state;
    }
};

export default reducer;
