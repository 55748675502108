import React from "react";

import Skeleton from '@material-ui/lab/Skeleton';
import CoursListItemSkeleton from "../CoursListItemSkeleton";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import RessourceListItemSkeleton from "../RessourceListItemSkeleton";


function RessourceListSkeleton() {

    return (
        <List className={"border rounded my-3"}>
            <RessourceListItemSkeleton />
            <Divider component="li" />
            <RessourceListItemSkeleton />
            <Divider component="li" />
            <RessourceListItemSkeleton />
            <Divider component="li" />
            <RessourceListItemSkeleton />
        </List>

    );
}

export default RessourceListSkeleton;