import React, {useEffect, useState} from "react";
import List from "@material-ui/core/List";
import RessourceListItem from "../RessourceListItem";


import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import PermMediaIcon from "@material-ui/icons/PermMedia";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";

import TimerIcon from '@material-ui/icons/Timer';


import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import VideocamIcon from '@material-ui/icons/Videocam';
import {generateDynamicRoute} from "../../utils/utils";
import {ressourcePage} from "../../routes/teacherRoutes";
import {Link} from "react-router-dom";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import cssClasses from "./resourcelist.module.css";

function TeacherRessourceList(props) {

    const sectionsRessources = props.sectionsRessources;
    const coursId = props.coursId;

    const openRessource = props.openRessource ? true : false;

    const [showRessource,setShowRessource] = useState(false);
    const [currentSection,setCurrentSection] = useState(null);




    const showRessourceHandler = (section) => {
        setCurrentSection(section);

    };

    useEffect(() => {


        if(currentSection != null) {
            setShowRessource(true);
        } else {
            setShowRessource(false);
        }


    },[currentSection]);


  /*  const sectionsRessourcesItems = sectionsRessources.map(item => {
        return (
            <>
                <ListItem button onClick={() => showRessourceHandler(item)}>
                    <ListItemIcon>
                        <PermMediaIcon/>
                    </ListItemIcon>
                    <ListItemText primary={item.nom}/>
                </ListItem>

                <Divider/>
            </>
        );

    });*/


    const sectionsRessourcesItems2 = sectionsRessources.map((item,index) => {
        return (
            <ListItem button onClick={() => showRessourceHandler(item)} className={cssClasses.chapter + " border rounded my-4 tex-center d-flex align-items-center p-3"} >
                <div style={{width:50,height:50}} className={"border rounded-circle text-center"}><h3 className={"m-2"}>{(index+1) < 10 ? "0"+(index+1) : (index+1)}</h3></div>
                <h6 className={"m-0 p-0 ml-3"}>{item.name}</h6>
            </ListItem>
        );

    });


        const ressources = currentSection ? currentSection.videos.map(item => {
            return (
                <>

                    <Divider />
                    {openRessource ?
                        <Link to={generateDynamicRoute(ressourcePage.path,[{toReplace:":coursId",value:coursId},{toReplace:":sectionId",value:currentSection.id},{toReplace:":ressourceId",value:item.id}])}>
                        <ListItem>
                        <ListItemIcon>
                            <VideocamIcon/>
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                            <h6 style={{fontSize: "16px"}}> {item.videoFiles.length} <PictureAsPdfIcon className={"mr-2"}/></h6>
                        </ListItem> </Link>

                        :

                        <ListItem>
                        <ListItemIcon>
                            <VideocamIcon/>
                        </ListItemIcon>
                            <ListItemText primary={item.name} />

                            <h6 style={{fontSize: "16px"}}> {item.videoFiles.length} <PictureAsPdfIcon className={"mr-2"}/></h6>
                    </ListItem> }


                </>
            );

    }) : [] ;

    return (
        <>

        {!showRessource ?

            sectionsRessourcesItems2
                :
            <div className={"border my-5"}>

                <IconButton onClick={() => {setCurrentSection(null)}} className={"my-3"}>
                    <ArrowBackIcon />
                </IconButton>

                <h3 className={"text-center"}>{currentSection ? currentSection.nom : null}</h3>

                <List >
                    {ressources}
                </List>
            </div>
             }
                </>
    );

}

export default TeacherRessourceList;