import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {

    loading : true,
    error : null,
    coursesList: []

};

const technicianCoursesFetchCoursesListStart = (state,action) => {
    return updateObject(state,{
        loading:true,
        error:null,
        coursesList: []
    });
};

const technicianCoursesFetchCoursesListSuccess = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:null,
        coursesList: action.payload.coursesList
    });
};

const technicianCoursesFetchCoursesListFail = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:action.payload.error,
        coursesList: []
    });
};

const technicianCoursesFetchCoursesListReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.TECHNICIAN_COURSES_FETCH_COURSES_LIST_START : return technicianCoursesFetchCoursesListStart(state,action);
        case actionTypes.TECHNICIAN_COURSES_FETCH_COURSES_LIST_SUCCESS : return technicianCoursesFetchCoursesListSuccess(state,action);
        case actionTypes.TECHNICIAN_COURSES_FETCH_COURSES_LIST_FAIL : return technicianCoursesFetchCoursesListFail(state,action);
        case actionTypes.TECHNICIAN_COURSES_FETCH_COURSES_LIST_RESET : return technicianCoursesFetchCoursesListReset(state,action);
        default : return state;
    }
};

export default reducer;