import React, {useEffect} from "react";

import Modal from '@material-ui/core/Modal';

import FormHelperText from '@material-ui/core/FormHelperText';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import {CircularProgress, Grid} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";
import {CoursePage} from "../../../routes/studentRoutes";
import {generateDynamicRoute} from "../../../utils/utils";
import {AdminUtilisateursModifierUtilisateurPage} from "../../../routes/administratorRoutes";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import {connect} from "react-redux";
import Chip from "@material-ui/core/Chip";


import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';



import DateFnsUtils from '@date-io/date-fns';

import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {adminAssignementsGroupeCoursAjouterAssignementGroupeCours} from "../../../store/actions/admin/assignementsGroupeCours/ajouterAssignementGroupeCoursActions";
import Alert from "@material-ui/lab/Alert";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TreeListeGroupesSingleAdd from "../../../components/TreeListeGroupesSingleAdd";
import Box from "@material-ui/core/Box";
import TreeListeGroupesMultiAdd from "../../../components/TreeListeGroupesMultiAdd";
import {adminCoursFetchListeCours} from "../../../store/actions/admin/cours/listeCoursActions";
import TreeListe from "../../../components/TreeListe";
import {
    adminCoursFetchCategorieListeCategories, adminCoursFetchCategorieListeCours,
    adminCoursFetchListeCategories
} from "../../../store/actions/admin/cours/listeCategoriesActions";
import {adminAssignementsGroupeCategorieAjouterAssignementGroupeCategorie} from "../../../store/actions/admin/assignementsGroupeCategorie/ajouterAssignementGroupeCategorieActions";
import {
    adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorie,
    adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieReset
} from "../../../store/actions/admin/assignementsUtilisateurCategorie/ajouterAssignementUtilisateurCategorieActions";

const initialState = {
    utilisateur: {value : null, error : null},
    categorie: {value : null, error : null},
    role: {value : null, error : null} ,
    dateDebutEnabled : false,
    dateDebut : new Date(),
    dateFinEnabled : false,
    dateFin : new Date(),
    utilisateursExpanded : false,
    categoriesExpanded : false
};

function AjouterAssignementUtilisateurCategorieModal(props) {


    const history = useHistory();

    let { utilisateurId } = useParams();

    const handleUtilisateursExpanded = (event,isExpanded) => {
        setState(prevState => {
            return {
                ...prevState,
                utilisateursExpanded : isExpanded
            }
        });
    };

    const handleCategoriesExpanded = (event,isExpanded) => {
        setState(prevState => {
            return {
                ...prevState,
                categoriesExpanded : isExpanded
            }
        });
    };

    useEffect(() => {

        if(props.utilisateur.utilisateur) {
            setState(prevState => {
                return {
                    ...prevState,
                    utilisateur:{ value : props.utilisateur.utilisateur, error : null}
                }
            })
        }

    },[props.utilisateur.utilisateur]);


    useEffect(() => {
        props.fetchListeGroupes();
        props.fetchListeUtilisateurs();
        props.fetchListeCategories();

        return () => {
            props.ajouterAssignementUtilisateurCategorieReset();
        }

    },[]);

    const [state, setState] = React.useState(initialState);

    const handleDateDebutChange = (date) => {
        setState(prevState => {
            return {
                ...prevState,
                dateDebut : date
            }
        })
    };
    const handleDateFinChange = (date) => {
        setState(prevState => {
            return {
                ...prevState,
                dateFin: date
            }
        })
    };

    const handleChangeRole = (event) => {
        const value = event.target.value;
        setState(prevState => {
            return {
                ...prevState,
                role : {value: value, error:null}
            }
        })
    };

    const handleChangeDateDebutEnabled = (event) => {
        const checked = event.target.checked;
        setState(prevState => {
            return {
                ...prevState,
                dateDebutEnabled: checked
            }
        });
    };

    const handleChangeDateFinEnabled = (event) => {
        const checked = event.target.checked;
        setState(prevState => {
            return {
                ...prevState,
                dateFinEnabled: checked
            }
        });
    };



    const handleClose = () => {

        history.push(generateDynamicRoute(AdminUtilisateursModifierUtilisateurPage.path,[{toReplace:":utilisateurId",value:utilisateurId}]));

    };

    const formOnSubmitHandler = (event) => {

        event.preventDefault();

        let error = false;

        const utilisateur = state.utilisateur.value;
        const categorie = state.categorie.value;
        const role = state.role.value;

        if(!utilisateur) {
            error = true;
            setState(prevState => {
                return {
                    ...prevState,
                    utilisateur: {
                        value: null,
                        error : "L'utilisateur est obligatoire"
                    }
                }
            })
        }

        if(!categorie) {
            error = true;
            setState(prevState => {
                return {
                    ...prevState,
                    categorie: {
                        value: null,
                        error : "La categorie est obligatoire"
                    }
                }
            })
        }

        if(!role) {
            error = true;
            setState(prevState => {
                return {
                    ...prevState,
                    role: {
                        value: null,
                        error : "Le role est obligatoire"
                    }
                }
            })
        }

        if(!error) {

            const assignementUtilisateurCategorie = {
                utilisateur : {
                    id : utilisateur.id
                },
                categorie : {
                    id : categorie.id
                },
                role : role,
                dateDebut : state.dateDebutEnabled ? state.dateDebut : null,
                dateFin : state.dateFinEnabled ? state.dateFin : null
            };


            props.ajouterAssignementUtilisateurCategorie(assignementUtilisateurCategorie);
        }

    };

    const handleUtilisateurSelect = (utilisateur) => {
        setState(prevState => {
            return {
                ...prevState,
                utilisateur: {value : utilisateur, error : null }
            };
        });
    };

    const handleUtilisateurUnSelect = () => {
        setState(prevState => {
            return {
                ...prevState,
                utilisateur: {value : null, error : null }
            };
        });
    };

    const handleCategorieSelect = (categorie) => {
        setState(prevState => {
            return {
                ...prevState,
                categorie:{value : categorie, error : null}
            };
        });
    };

    const handleCategorieUnSelect = () => {
        setState(prevState => {
            return {
                ...prevState,
                categorie: {value: null, error :null}
            };
        });
    };


    const isCategorieSelected = (categorieToCheck) => {
        return state.categorie.value && state.categorie.value.id === categorieToCheck.id;
    };


    const isUtilisateurSelected = (utilisateurToCheck) => {
        return state.utilisateur.value && state.utilisateur.value.id === utilisateurToCheck.id;
    };


    return(
        <Modal
            open={true}
            onClose={handleClose}
            style={{overflowY:"auto"}}
        >
            <div style={{maxWidth:"700px", width:"100%"}} className={"bg-white text-center border rounded mx-auto my-5 p-3"}>

                <h2>Assigner un utilisateur à une categorie</h2>

                {props.assignementUtilisateurCategorie.error &&
                <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.assignementUtilisateurCategorie.error}</Alert>
                }
                {props.assignementUtilisateurCategorie.success&&
                <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'assignement ajouté avec succees</Alert>
                }


                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <form className={"text-left p-4"} onSubmit={formOnSubmitHandler}>


                        <ExpansionPanel expanded={state.utilisateursExpanded} onChange={handleUtilisateursExpanded}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <FormControl error={state.utilisateur.error ? true : false}>
                                <h6>Utilisateur : {!state.utilisateursExpanded && state.utilisateur.value ? <Chip label={state.utilisateur.value.nom} onDelete={handleUtilisateurUnSelect} /> : null}</h6>
                                    {state.utilisateur.error && <FormHelperText>{state.utilisateur.error}</FormHelperText>}
                                </FormControl>

                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails >
                                {props.listeUtilisateurs.loading ?
                                    <CircularProgress size={50} thickness={4} />
                                    :
                                    <Grid container spacing={3}>

                                        <Grid item xs={12} md={8} >
                                            <span>Selectionner un utilisateur</span>

                                            <TreeListe fetchGroupeListeGroupes={props.fetchGroupeListeGroupes}  fetchGroupeListeUtilisateurs={props.fetchGroupeListeUtilisateurs} selectable={"Utilisateur"} liste={props.listeGroupes.listeGroupes.concat(props.listeUtilisateurs.listeUtilisateurs)}  handleSelect={handleUtilisateurSelect} handleUnSelect={handleUtilisateurUnSelect} isUtilisateurSelected={isUtilisateurSelected}/>

                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <span>Utilisateur Selectionné</span>
                                            <Box>
                                                {state.utilisateur.value &&
                                                <Chip
                                                    label={state.utilisateur.value.nom}
                                                    onDelete={handleUtilisateurUnSelect}
                                                />}
                                            </Box>
                                        </Grid>

                                    </Grid>
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>




                    <ExpansionPanel expanded={state.categoriesExpanded} onChange={handleCategoriesExpanded}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <FormControl error={state.categorie.error ? true : false}>
                                <h6>Categorie : {!state.categoriesExpanded && state.categorie.value ? <Chip label={state.categorie.value.titre} onDelete={handleCategorieUnSelect} /> : null}</h6>
                                {state.categorie.error && <FormHelperText>{state.categorie.error}</FormHelperText>}
                            </FormControl>
                            </ExpansionPanelSummary>
                        <ExpansionPanelDetails >
                            {props.listeCategories.loading ?
                                <CircularProgress size={50} thickness={4} />
                                :
                                <Grid container spacing={3}>

                                    <Grid item xs={12} md={8} >
                                        <span>Selectionner une categorie</span>
                                        <TreeListe onlyCategories={true} fetchCategorieListeCategories={props.fetchCategorieListeCategories}  selectable={"Categorie"} liste={props.listeCategories.listeCategories} handleSelect={handleCategorieSelect} handleUnSelect={handleCategorieUnSelect} isCategorieSelected={isCategorieSelected}/>
                                    </Grid>
                                    <Grid item xs={12} md={4} >
                                        <span>Categorie Selectionné</span>
                                        <Box>
                                            {state.categorie.value &&
                                            <Chip
                                                label={state.categorie.value.titre}
                                                onDelete={handleCategorieUnSelect}
                                            />}
                                        </Box>
                                    </Grid>

                                </Grid>
                            }
                        </ExpansionPanelDetails>
                    </ExpansionPanel>



                    <FormControl error={state.role.error ? true : false} fullWidth={true} className={"my-3"}>
                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.role.value}
                            onChange={handleChangeRole}
                        >
                            <MenuItem value={'ROLE_APPRENANT'}>Apprenant</MenuItem>
                            <MenuItem value={'ROLE_ENSEIGNANT'}>Enseignant</MenuItem>
                        </Select>
                        {state.role.error && <FormHelperText>{state.role.error}</FormHelperText>}
                    </FormControl>


                    <FormControlLabel
                        control={<Switch checked={state.dateDebutEnabled} onChange={handleChangeDateDebutEnabled} />}
                        label="Avoir un date debut"
                    />

                    <br />
                    <DateTimePicker value={state.dateDebut}
                                    fullWidth={true}
                                    label="Date Debut"
                                    disabled={!state.dateDebutEnabled}
                                    disablePast
                                    ampm={false}
                                    format="yyyy/MM/dd HH:mm" inputVariant={"outlined"} onChange={handleDateDebutChange} />

                    <FormControlLabel
                        control={<Switch checked={state.dateFinEnabled} onChange={handleChangeDateFinEnabled} />}
                        label="Avoir un date fin"
                    />

                    <DateTimePicker value={state.dateDebut}
                                    fullWidth={true}
                                    label="Date Debut"
                                    disabled={!state.dateFinEnabled}
                                    disablePast
                                    ampm={false}
                                    format="yyyy/MM/dd HH:mm" inputVariant={"outlined"} onChange={handleDateFinChange} />

                                    <div className={"text-center"}>
                                        <Button disabled={props.assignementUtilisateurCategorie.loading} type={"submit"} className={"my-2"}
                                                color={"primary"} variant={"contained"} endIcon={<PersonAddIcon/>}>Ajouter</Button>
                                        {props.assignementUtilisateurCategorie.loading && <CircularProgress size={24} /*className={spinnerClasses.join(" ")}*/ />}
                                    </div>

                </form>
                </MuiPickersUtilsProvider>


            </div>
        </Modal>
    );




}

const mapStateToProps = (state) => {
    return {
        assignementUtilisateurCategorie : state.admin.assignementsUtilisateurCategorie.ajouterAssignementUtilisateurCategorie,
        utilisateur : state.admin.utilisateurs.utilisateur,
        listeGroupes : state.admin.utilisateurs.listeGroupes,
        listeUtilisateurs : state.admin.utilisateurs.listeUtilisateurs,
        listeCategories : state.admin.cours.listeCategories
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        ajouterAssignementUtilisateurCategorie : (assignementUtilisateurCategorie) => dispatch(adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorie(assignementUtilisateurCategorie)),
        ajouterAssignementUtilisateurCategorieReset : () => dispatch(adminAssignementsUtilisateurCategorieAjouterAssignementUtilisateurCategorieReset()),
        fetchListeGroupes : () => dispatch(adminUtilisateursFetchListeGroupes()),
        fetchListeUtilisateurs : () => dispatch(adminUtilisateursFetchListeGroupes()),
        fetchGroupeListeGroupes : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeGroupes(groupeId)),
        fetchGroupeListeUtilisateurs : (groupeId) => dispatch(adminUtilisateursFetchGroupeListeUtilisateurs(groupeId)),

        fetchListeCategories : () => dispatch(adminCoursFetchListeCategories()),
        fetchCategorieListeCategories : (categorieId) => dispatch(adminCoursFetchCategorieListeCategories(categorieId))

    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AjouterAssignementUtilisateurCategorieModal);