import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieSaga (action) {

    yield put(actions.adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieStart());

    const assignementGroupeCategorieId = action.payload.assignementGroupeCategorieId;

    try {
        const response = yield axios.delete(generateDynamicRoute(api.supprimerAssignementGroupeCategorie,[{toReplace:":assignementGroupeCategorieId",value:assignementGroupeCategorieId}]));
        yield put(actions.adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieSuccess(assignementGroupeCategorieId));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieFail(error.response.data.error));
        }else {
            yield put(actions.adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieFail("There's an error!"));
        }
    }
}

