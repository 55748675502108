import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { generateDynamicRoute } from "../../utils/utils";
import Grid from "@material-ui/core/Grid";
import { CoursePage } from "../../routes/studentRoutes";
import { Box, useMediaQuery } from "@material-ui/core";
import cssClasses from "./studentCourseItem.module.css";
import Alert from "@material-ui/lab/Alert";
import { CheckCircleOutline } from "@material-ui/icons";

function StudentCourseItem(props) {
  const course = props.course;

  // const checkEnrolled = () => {
  //   if (!props.coursesList || props.coursesList.length === 0 || !props.cours) {
  //     return true;
  //   }
  //   const course = props.coursesList.filter((c) => c.id == props.cours.id);
  //   if (!course || course.length === 0 || !course[0].enrolled) {
  //     return false;
  //   }
  //   return true;
  // };
  const isMobile = useMediaQuery("(max-width:600px)");
  const mobileCard = () => {
    return (
      <Box className={cssClasses.cardContainer}>
        <div className={cssClasses.cardMobile}>
          <h6
            style={{
              textAlign: "center",
            }}
          >
            <b>{course.title}</b>
          </h6>
          {course.enrolled ? (
            <Alert
              icon={<CheckCircleOutline fontSize="inherit" />}
              severity="success"
              variant="filled"
              className={cssClasses.smallIcon}
            >
              Activé
            </Alert>
          ) : (
            <Alert
              severity="error"
              variant="filled"
              className={cssClasses.smallIcon}
            >
              Non Activé
            </Alert>
          )}
          <img
            src={course.photo}
            alt={course.title}
            className={cssClasses.mobileimg}
          />
          <Link
            to={generateDynamicRoute(CoursePage.path, [
              { toReplace: ":coursId", value: course.id },
            ])}
          >
            <button className={cssClasses.mobileButton}>
              <b>Suivre</b>
            </button>
          </Link>
        </div>
      </Box>
    );
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      {isMobile ? (
        mobileCard()
      ) : (
        <Box className={cssClasses.cardContainer}>
          <div className={cssClasses.card}>
            <div className={cssClasses.cardFront}>
              {course.enrolled ? (
                <Alert
                  icon={<CheckCircleOutline fontSize="inherit" />}
                  severity="success"
                  variant="filled"
                  className={cssClasses.smallIcon}
                >
                  Activé
                </Alert>
              ) : (
                <Alert
                  severity="error"
                  variant="filled"
                  className={cssClasses.smallIcon}
                >
                  Non Activé
                </Alert>
              )}
              <h4>{course.title}</h4>
            </div>
            <div className={cssClasses.cardBack}>
              <img
                src={course.photo}
                alt={course.title}
                className={cssClasses.img}
              />
              <h5>{course.title}</h5>
              <Link
                to={generateDynamicRoute(CoursePage.path, [
                  { toReplace: ":coursId", value: course.id },
                ])}
              >
                <button className={cssClasses.buttonBack}>Suivre</button>
              </Link>
            </div>
          </div>
        </Box>
      )}

      {/* <Card className={"position-relative"}>
            <CardMedia
                style={{
                    height:"140px",
                    backgroundSize: "contain"
                }}
                image={course.photo}
            />
            <CardContent className={"text-center"}>
                <h5>
                    {course.title}
                </h5>
                <p>
                    {course.smallDescription}
                </p>
                <div className={"text-center"} >
                    <Link to={generateDynamicRoute(CoursePage.path,[{toReplace: ':coursId',value:course.id}])}>
                        <Button variant={"contained"} color="primary">
                            Suivre
                        </Button>
                    </Link>
                </div>
            </CardContent>
        </Card> */}
    </Grid>
  );
}

export default StudentCourseItem;
