import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";
const initialState = {

    loading : true,
    error : null,
    ressource: null

};

const etudiantMesCoursFetchRessourceStart = (state,action) => {
    return updateObject(state,{
        loading:true,
        error:null,
        ressource: null
    });
};

const etudiantMesCoursFetchRessourceSuccess = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:null,
        ressource: action.payload.ressource
    });
};

const etudiantMesCoursFetchRessourceFail = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:action.payload.error,
        ressource: null
    });
};

const etudiantMesCoursFetchRessourceReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.ETUDIANT_MES_COURS_FETCH_RESSOURCE_START : return etudiantMesCoursFetchRessourceStart(state,action);
        case actionTypes.ETUDIANT_MES_COURS_FETCH_RESSOURCE_SUCCESS : return etudiantMesCoursFetchRessourceSuccess(state,action);
        case actionTypes.ETUDIANT_MES_COURS_FETCH_RESSOURCE_FAIL : return etudiantMesCoursFetchRessourceFail(state,action);
        case actionTypes.ETUDIANT_MES_COURS_FETCH_RESSOURCE_RESET : return etudiantMesCoursFetchRessourceReset(state,action);
        default : return state;
    }
};

export default reducer;
