import React from "react";


import LMDPhoto from "../../../assets/lmd.png";
import Box from '@material-ui/core/Box';

import {connect} from "react-redux";
import {guestHomeFetchListeCoursSpecial} from "../../../store/actions/guest/home/listeCoursSpecialsActions";
import {guestHomeFetchListeCategoriesSpecial} from "../../../store/actions/guest/home/listeCategoriesSpecialsActions";


import {makeStyles} from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';

import Button from '@material-ui/core/Button';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import VideocamIcon from '@material-ui/icons/Videocam';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CheckIcon from '@material-ui/icons/Check';
import {Link} from "react-router-dom";
import PersonAddIcon from "@material-ui/icons/PersonAdd";


const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
}));

const tiers = [
    {
        title: 'أساسي',
        price: '0',
        description: ['10 users included', '2 GB of storage', 'Help center access', 'Email support'],
        buttonText: 'Plus de details',
        buttonVariant: 'contained',
        classes: ['7eme', '8eme', '9eme'],
        timeline: [{icon : <VideoLibraryIcon/>,color:"primary",title:"Videos",subtitle:"Des videos enregistré"},
            {icon : <VideocamIcon/>,color:"secondary",title:"Séance directe",subtitle:"Des séance directe ",},
            {icon : <AssignmentIcon color={"primary"}/>,color:"primary",title:"Exercices",subtitle:"Des exercices corrigés" ,variant:"outlined"},
            {icon : <PictureAsPdfIcon/>,color:"secondary",title:"PDF",subtitle:"Des résumé sous forme PDF"},
            {icon : <CheckIcon/>,color:"primary",title:"Astuces",subtitle:"Des astuces"},
        ]
    },
    {
        title: 'Secondaire',
        price: '15',
        description: [
            '20 users included',
            '10 GB of storage',
            'Help center access',
            'Priority email support',
        ],
        buttonText: 'Plus de details',
        buttonVariant: 'contained',
        classes: ['1ere', '2eme', '3eme','Bac'],
        timeline: [{icon : <VideoLibraryIcon/>,color:"primary",title:"Videos",subtitle:"Des videos enregistré"},
            {icon : <VideocamIcon/>,color:"secondary",title:"Séance directe",subtitle:"Des séance directe ",},
            {icon : <AssignmentIcon color={"primary"}/>,color:"primary",title:"Exercices",subtitle:"Des exercices corrigés" ,variant:"outlined"},
            {icon : <PictureAsPdfIcon/>,color:"secondary",title:"PDF",subtitle:"Des résumé sous forme PDF"},
            {icon : <CheckIcon/>,color:"primary",title:"Astuces",subtitle:"Des astuces"},
        ]
    },
    {
        title: 'Universitaire',
        price: '30',
        description: [
            '50 users included',
            '30 GB of storage',
            'Help center access',
            'Phone & email support',
        ],
        buttonText: 'Plus de details',
        buttonVariant: 'contained',
        classes: ['LMD', 'Prepa', 'Ing','...'],
        timeline: [{icon : <VideoLibraryIcon/>,color:"primary",title:"Videos",subtitle:"Des videos enregistré"},
            {icon : <VideocamIcon/>,color:"secondary",title:"Séance directe",subtitle:"Des séance directe ",},
            {icon : <AssignmentIcon color={"primary"}/>,color:"primary",title:"Exercices",subtitle:"Des exercices corrigés" ,variant:"outlined"},
            {icon : <PictureAsPdfIcon/>,color:"secondary",title:"PDF",subtitle:"Des résumé sous forme PDF"},
            {icon : <CheckIcon/>,color:"primary",title:"Astuces",subtitle:"Des astuces"},
        ]

    },
];



function Universitaire(props) {


    const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    return(
        <>

            <img src={LMDPhoto} className={"w-100"}/>



            <Container component="main" className={"p-3 my-3"}>

                {
                    /*

                <Box>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="7 eme" {...a11yProps(0)} />
                            <Tab label="8 eme" {...a11yProps(1)} />
                            <Tab label="9 eme" {...a11yProps(2)} />
                        </Tabs>
                    </AppBar>

                    <Box boxShadow={3}>
                    <div
                        role="tabpanel"
                        hidden={value !== 0}
                        id={`scrollable-auto-tabpanel-${0}`}
                        aria-labelledby={`scrollable-auto-tab-${0}`}
                    >
                        {value === 0 && (
                            <Box p={3} textAlign={"center"} display={"flex"}>

                                <Box m={3}>
                                    <Paper variant="outlined" elevation={10} >
                                        <Box p={3}>
                                            <Box mb={2}>
                                                <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                            </Box>
                                            <Box component={"h6"} m={0} p={0}>Physique</Box>
                                        </Box>
                                    </Paper>
                                </Box>
                                <Box m={3}>
                                    <Paper variant="outlined" elevation={10} >
                                        <Box p={3}>
                                            <Box mb={2}>
                                                <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                            </Box>
                                            <Box component={"h6"} m={0} p={0}>Physique</Box>
                                        </Box>
                                    </Paper>
                                </Box>
                                <Box m={3}>
                                    <Paper variant="outlined" elevation={10} >
                                        <Box p={3}>
                                            <Box mb={2}>
                                                <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                            </Box>
                                            <Box component={"h6"} m={0} p={0}>Physique</Box>
                                        </Box>
                                    </Paper>
                                </Box>
                                <Box m={3}>
                                    <Paper variant="outlined" elevation={10} >
                                        <Box p={3}>
                                            <Box mb={2}>
                                                <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                            </Box>
                                            <Box component={"h6"} m={0} p={0}>Physique</Box>
                                        </Box>
                                    </Paper>
                                </Box>
                            </Box>
                        )}
                    </div>
                    <div
                        role="tabpanel"
                        hidden={value !== 1}
                        id={`scrollable-auto-tabpanel-${1}`}
                        aria-labelledby={`scrollable-auto-tab-${1}`}
                    >
                        {value === 1 && (
                            <Box p={3}>
                                bbbbb
                            </Box>
                        )}
                    </div>
                    <div
                        role="tabpanel"
                        hidden={value !== 2}
                        id={`scrollable-auto-tabpanel-${2}`}
                        aria-labelledby={`scrollable-auto-tab-${2}`}
                    >
                        {value === 2 && (
                            <Box p={3}>
                                iiiii
                            </Box>
                        )}
                    </div>
                    </Box>

                    <Box boxShadow={3}>
                        <div
                            role="tabpanel"
                            hidden={value !== 0}
                            id={`scrollable-auto-tabpanel-${0}`}
                            aria-labelledby={`scrollable-auto-tab-${0}`}
                        >
                            {value === 0 && (
                                <Box p={3}>
                                    <Box textAlign={"center"} component={"h3"}>Physique</Box>
                                    <Grid container={true}>
                                        <Grid item={true} xs={6}>
                                            <Box textAlign={"center"} component={"h5"}>Exemples vidéos :</Box>

                                            <Box p={1} borderRight={1} pr={3}>
                                            <Grid container={true} spacing={3}>

                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                                                    </Box>
                                                </Grid>

                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                                                    </Box>
                                                </Grid>

                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                                                    </Box>
                                                </Grid>

                                            </Grid>
                                            </Box>


                                        </Grid>
                                        <Grid item={true} xs={6}>
                                            <Box textAlign={"center"} component={"h5"}>Exemples fichiers : </Box>

                                            <Box p={1} pl={3} textAlign={"center"}>

                                                <Grid container={true} spacing={3} justifyContent={"center"}>
                                                    <Grid item={true}>
                                                        <Paper variant="outlined" elevation={10} >
                                                            <Box p={3}>
                                                                <Box mb={2}>
                                                                    <CloudDownloadIcon fontSize={"large"} color={"primary"}/>
                                                                </Box>
                                                                <Box component={"h6"} m={0} p={0}>Résumé N: 1</Box>
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item={true}>
                                                        <Paper variant="outlined" elevation={10} >
                                                            <Box p={3}>
                                                                <Box mb={2}>
                                                                    <CloudDownloadIcon fontSize={"large"} color={"primary"}/>
                                                                </Box>
                                                                <Box component={"h6"} m={0} p={0}>Résumé N: 2</Box>
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item={true}>
                                                        <Paper variant="outlined" elevation={10} >
                                                            <Box p={3}>
                                                                <Box mb={2}>
                                                                    <CloudDownloadIcon fontSize={"large"} color={"primary"}/>
                                                                </Box>
                                                                <Box component={"h6"} m={0} p={0}>Résumé N: 3</Box>
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item={true}>
                                                        <Paper variant="outlined" elevation={10} >
                                                            <Box p={3}>
                                                                <Box mb={2}>
                                                                    <CloudDownloadIcon fontSize={"large"} color={"primary"}/>
                                                                </Box>
                                                                <Box component={"h6"} m={0} p={0}>Résumé N: 4</Box>
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item={true}>
                                                        <Paper variant="outlined" elevation={10} >
                                                            <Box p={3}>
                                                                <Box mb={2}>
                                                                    <CloudDownloadIcon fontSize={"large"} color={"primary"}/>
                                                                </Box>
                                                                <Box component={"h6"} m={0} p={0}>Résumé N: 5</Box>
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>


                                            </Box>

                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </div>
                    </Box>
                </Box>


*/
                }
                <Box my={4} className={"text-center"} >
                    <Link to={"/auth/signup"}>

                        <Button size={"large"} color={"primary"} variant={"contained"} endIcon={<PersonAddIcon />} >
                            S'inscrire maintenant !!
                        </Button>

                    </Link>
                </Box>
            </Container>

        </>
    );

}


const mapStateToProps = (state) => {
    return {
        parametres : {
            loading : state.parametres.loading,
            error : state.parametres.loading,
            parametres : state.parametres.parametres,
        },
        listeCoursSpecials : {
            loading : state.guest.home.listeCoursSpecials.loading,
            error : state.guest.home.listeCoursSpecials.error,
            listeCoursSpecials : state.guest.home.listeCoursSpecials.listeCoursSpecials,
        },
        listeCategoriesSpecials : {
            loading : state.guest.home.listeCategoriesSpecials.loading,
            error : state.guest.home.listeCategoriesSpecials.error,
            listeCategoriesSpecials : state.guest.home.listeCategoriesSpecials.listeCategoriesSpecials,
        }

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchListeCoursSpecials : () => dispatch(guestHomeFetchListeCoursSpecial()),
        fetchListeCategoriesSpecials : () => dispatch(guestHomeFetchListeCategoriesSpecial()),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Universitaire);
