import React, {useEffect} from "react";
import CategorieCoursListSkeleton from "../../../UI/LoadingSkeleton/CategorieCoursListSkeleton";
import HomePageDescriptionSkeleton from "../../../UI/LoadingSkeleton/HomePageDescriptionSkeleton";



import secondairePhoto from "../../../assets/secondaire.png"

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

import {connect} from "react-redux";
import {guestHomeFetchListeCoursSpecial} from "../../../store/actions/guest/home/listeCoursSpecialsActions";
import CoursCategorieList from "../../../components/CoursCategoriePaginatedList";
import {guestHomeFetchListeCategoriesSpecial} from "../../../store/actions/guest/home/listeCategoriesSpecialsActions";
import OffersCaroussel from "../../../components/OffersCaroussel";
import HomeIntro from "../../../components/HomeIntro";

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';


import { makeStyles } from '@material-ui/core/styles';


import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';


import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';

import Button from '@material-ui/core/Button';


import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import AcUnitIcon from '@material-ui/icons/AcUnit';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import VideocamIcon from '@material-ui/icons/Videocam';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CheckIcon from '@material-ui/icons/Check';


import Paper from '@material-ui/core/Paper';


import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import cssClasses from "../Home2/home.module.css";
import {Link} from "react-router-dom";
import PersonAddIcon from "@material-ui/icons/PersonAdd";


const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
}));

const tiers = [
    {
        title: 'أساسي',
        price: '0',
        description: ['10 users included', '2 GB of storage', 'Help center access', 'Email support'],
        buttonText: 'Plus de details',
        buttonVariant: 'contained',
        classes: ['7eme', '8eme', '9eme'],
        timeline: [{icon : <VideoLibraryIcon/>,color:"primary",title:"Videos",subtitle:"Des videos enregistré"},
            {icon : <VideocamIcon/>,color:"secondary",title:"Séance directe",subtitle:"Des séance directe ",},
            {icon : <AssignmentIcon color={"primary"}/>,color:"primary",title:"Exercices",subtitle:"Des exercices corrigés" ,variant:"outlined"},
            {icon : <PictureAsPdfIcon/>,color:"secondary",title:"PDF",subtitle:"Des résumé sous forme PDF"},
            {icon : <CheckIcon/>,color:"primary",title:"Astuces",subtitle:"Des astuces"},
        ]
    },
    {
        title: 'Secondaire',
        price: '15',
        description: [
            '20 users included',
            '10 GB of storage',
            'Help center access',
            'Priority email support',
        ],
        buttonText: 'Plus de details',
        buttonVariant: 'contained',
        classes: ['1ere', '2eme', '3eme','Bac'],
        timeline: [{icon : <VideoLibraryIcon/>,color:"primary",title:"Videos",subtitle:"Des videos enregistré"},
            {icon : <VideocamIcon/>,color:"secondary",title:"Séance directe",subtitle:"Des séance directe ",},
            {icon : <AssignmentIcon color={"primary"}/>,color:"primary",title:"Exercices",subtitle:"Des exercices corrigés" ,variant:"outlined"},
            {icon : <PictureAsPdfIcon/>,color:"secondary",title:"PDF",subtitle:"Des résumé sous forme PDF"},
            {icon : <CheckIcon/>,color:"primary",title:"Astuces",subtitle:"Des astuces"},
        ]
    },
    {
        title: 'Universitaire',
        price: '30',
        description: [
            '50 users included',
            '30 GB of storage',
            'Help center access',
            'Phone & email support',
        ],
        buttonText: 'Plus de details',
        buttonVariant: 'contained',
        classes: ['LMD', 'Prepa', 'Ing','...'],
        timeline: [{icon : <VideoLibraryIcon/>,color:"primary",title:"Videos",subtitle:"Des videos enregistré"},
            {icon : <VideocamIcon/>,color:"secondary",title:"Séance directe",subtitle:"Des séance directe ",},
            {icon : <AssignmentIcon color={"primary"}/>,color:"primary",title:"Exercices",subtitle:"Des exercices corrigés" ,variant:"outlined"},
            {icon : <PictureAsPdfIcon/>,color:"secondary",title:"PDF",subtitle:"Des résumé sous forme PDF"},
            {icon : <CheckIcon/>,color:"primary",title:"Astuces",subtitle:"Des astuces"},
        ]

    },
];



function Secondaire(props) {


    const classes = useStyles();


    const [matiere, setMatiere] = React.useState(null);

    const handleChangeMatiere = (newMatiere) => {
        setMatiere(newMatiere);
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setMatiere(null);
        setValue(newValue);
    };




    const [matiere2, setMatiere2] = React.useState(null);

    const handleChangeMatiere2 = (newMatiere) => {
        setMatiere2(newMatiere);
    };

    const [value2, setValue2] = React.useState(0);

    const handleChange2 = (event, newValue) => {

        setMatiere2(null);
        setValue2(newValue);
    };



    const [matiere3, setMatiere3] = React.useState(null);

    const handleChangeMatiere3 = (newMatiere) => {
        setMatiere3(newMatiere);
    };


    const [value3, setValue3] = React.useState(0);

    const handleChange3 = (event, newValue) => {

        setMatiere3(null);
        setValue3(newValue);
    };



    const [matiere4, setMatiere4] = React.useState(null);

    const handleChangeMatiere4 = (newMatiere) => {
        setMatiere4(newMatiere);
    };

    const [value4, setValue4] = React.useState(0);

    const handleChange4 = (event, newValue) => {

        setMatiere4(null);
        setValue4(newValue);
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    return(
        <>

            <img src={secondairePhoto} className={"w-100"}/>



            <Container component="main" className={"p-3 my-3"}>


                {/*}
                <Box  my={5}>
                    <Box boxShadow={10} bgcolor="primary.main"  textAlign={"center"} color="primary.contrastText">
                        <Box component={"h2"} p={3} m={0}>1 ere</Box>
                    </Box>

                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="1 ere" {...a11yProps(0)} />
                        </Tabs>
                    </AppBar>

                    <Box boxShadow={3}>
                    <div
                        role="tabpanel"
                        hidden={value !== 0}
                        id={`scrollable-auto-tabpanel-${0}`}
                        aria-labelledby={`scrollable-auto-tab-${0}`}
                    >
                        {value === 0 && (
                            <Box p={3} textAlign={"center"} display={"flex"}>

                                <Box m={3}>
                                    <Paper variant="outlined" elevation={10} onClick={() => handleChangeMatiere(1)} >
                                        <Box p={3}>
                                            <Box mb={2}>
                                                <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                            </Box>
                                            <Box component={"h6"} m={0} p={0}>Physique</Box>
                                        </Box>
                                    </Paper>
                                </Box>
                                <Box m={3}>
                                    <Paper variant="outlined" elevation={10} >
                                        <Box p={3}>
                                            <Box mb={2}>
                                                <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                            </Box>
                                            <Box component={"h6"} m={0} p={0}>Physique</Box>
                                        </Box>
                                    </Paper>
                                </Box>
                                <Box m={3}>
                                    <Paper variant="outlined" elevation={10} >
                                        <Box p={3}>
                                            <Box mb={2}>
                                                <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                            </Box>
                                            <Box component={"h6"} m={0} p={0}>Physique</Box>
                                        </Box>
                                    </Paper>
                                </Box>
                                <Box m={3}>
                                    <Paper variant="outlined" elevation={10} >
                                        <Box p={3}>
                                            <Box mb={2}>
                                                <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                            </Box>
                                            <Box component={"h6"} m={0} p={0}>Physique</Box>
                                        </Box>
                                    </Paper>
                                </Box>
                            </Box>
                        )}
                    </div>
                    </Box>
                    {matiere != null &&
                    <Box boxShadow={3}>
                        <div
                        >
                                <Box p={3}>
                                    <Box textAlign={"center"} component={"h3"}>Physique</Box>
                                    <Grid container={true}>
                                        <Grid item={true} xs={6}>
                                            <Box textAlign={"center"} component={"h5"}>Exemples vidéos :</Box>

                                            <Box p={1} borderRight={1} pr={3}>
                                                <Grid container={true} spacing={3}>

                                                    <Grid item={true} xs={4}>
                                                        <Box component={"div"} className={cssClasses.videoContainer}>
                                                            <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen/>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item={true} xs={4}>
                                                        <Box component={"div"} className={cssClasses.videoContainer}>
                                                            <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen/>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item={true} xs={4}>
                                                        <Box component={"div"} className={cssClasses.videoContainer}>
                                                            <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen/>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item={true} xs={4}>
                                                        <Box component={"div"} className={cssClasses.videoContainer}>
                                                            <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen/>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item={true} xs={4}>
                                                        <Box component={"div"} className={cssClasses.videoContainer}>
                                                            <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen/>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item={true} xs={4}>
                                                        <Box component={"div"} className={cssClasses.videoContainer}>
                                                            <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen/>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item={true} xs={4}>
                                                        <Box component={"div"} className={cssClasses.videoContainer}>
                                                            <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen/>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item={true} xs={4}>
                                                        <Box component={"div"} className={cssClasses.videoContainer}>
                                                            <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen/>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item={true} xs={4}>
                                                        <Box component={"div"} className={cssClasses.videoContainer}>
                                                            <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen/>
                                                        </Box>
                                                    </Grid>

                                                </Grid>
                                            </Box>


                                        </Grid>
                                        <Grid item={true} xs={6}>
                                            <Box textAlign={"center"} component={"h5"}>Exemples fichiers : </Box>

                                            <Box p={1} pl={3} textAlign={"center"}>

                                                <Grid container={true} spacing={3} justifyContent={"center"}>
                                                    <Grid item={true}>
                                                        <Paper variant="outlined" elevation={10}>
                                                            <Box p={3}>
                                                                <Box mb={2}>
                                                                    <CloudDownloadIcon fontSize={"large"}
                                                                                       color={"primary"}/>
                                                                </Box>
                                                                <Box component={"h6"} m={0} p={0}>Résumé N: 1</Box>
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item={true}>
                                                        <Paper variant="outlined" elevation={10}>
                                                            <Box p={3}>
                                                                <Box mb={2}>
                                                                    <CloudDownloadIcon fontSize={"large"}
                                                                                       color={"primary"}/>
                                                                </Box>
                                                                <Box component={"h6"} m={0} p={0}>Résumé N: 2</Box>
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item={true}>
                                                        <Paper variant="outlined" elevation={10}>
                                                            <Box p={3}>
                                                                <Box mb={2}>
                                                                    <CloudDownloadIcon fontSize={"large"}
                                                                                       color={"primary"}/>
                                                                </Box>
                                                                <Box component={"h6"} m={0} p={0}>Résumé N: 3</Box>
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item={true}>
                                                        <Paper variant="outlined" elevation={10}>
                                                            <Box p={3}>
                                                                <Box mb={2}>
                                                                    <CloudDownloadIcon fontSize={"large"}
                                                                                       color={"primary"}/>
                                                                </Box>
                                                                <Box component={"h6"} m={0} p={0}>Résumé N: 4</Box>
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item={true}>
                                                        <Paper variant="outlined" elevation={10}>
                                                            <Box p={3}>
                                                                <Box mb={2}>
                                                                    <CloudDownloadIcon fontSize={"large"}
                                                                                       color={"primary"}/>
                                                                </Box>
                                                                <Box component={"h6"} m={0} p={0}>Résumé N: 5</Box>
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>


                                            </Box>

                                        </Grid>
                                    </Grid>
                                </Box>
                        </div>
                    </Box>
                    }
                </Box>

                <Box my={5}>
                    <Box boxShadow={10} bgcolor="primary.main"  textAlign={"center"} color="primary.contrastText">
                        <Box component={"h2"} p={3} m={0}>2 eme</Box>
                    </Box>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value2}
                            onChange={handleChange2}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="2 eme SC" {...a11yProps(0)} />
                            <Tab label="2 eme INFO" {...a11yProps(1)} />
                            <Tab label="2 eme ECO" {...a11yProps(2)} />
                            <Tab label="2 eme LETTRE" {...a11yProps(3)} />
                        </Tabs>
                    </AppBar>

                    <Box boxShadow={3}>
                        <div
                            role="tabpanel"
                            hidden={value2 !== 0}
                            id={`scrollable-auto-tabpanel-${0}`}
                            aria-labelledby={`scrollable-auto-tab-${0}`}
                        >
                            {value2 === 0 && (
                                <Box p={3} textAlign={"center"} display={"flex"}>

                                    <Box m={3}>
                                        <Paper variant="outlined" elevation={10} onClick={() => handleChangeMatiere2(1)} >
                                            <Box p={3}>
                                                <Box mb={2}>
                                                    <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                                </Box>
                                                <Box component={"h6"} m={0} p={0}>Physique</Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                    <Box m={3}>
                                        <Paper variant="outlined" elevation={10} >
                                            <Box p={3}>
                                                <Box mb={2}>
                                                    <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                                </Box>
                                                <Box component={"h6"} m={0} p={0}>Physique</Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                    <Box m={3}>
                                        <Paper variant="outlined" elevation={10} >
                                            <Box p={3}>
                                                <Box mb={2}>
                                                    <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                                </Box>
                                                <Box component={"h6"} m={0} p={0}>Physique</Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                    <Box m={3}>
                                        <Paper variant="outlined" elevation={10} >
                                            <Box p={3}>
                                                <Box mb={2}>
                                                    <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                                </Box>
                                                <Box component={"h6"} m={0} p={0}>Physique</Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                </Box>
                            )}
                        </div>
                        <div
                            role="tabpanel"
                            hidden={value2 !== 1}
                            id={`scrollable-auto-tabpanel-${1}`}
                            aria-labelledby={`scrollable-auto-tab-${1}`}
                        >
                            {value2 === 1 && (
                                <Box p={3}>
                                    bbbbb
                                </Box>
                            )}
                        </div>
                        <div
                            role="tabpanel"
                            hidden={value2 !== 2}
                            id={`scrollable-auto-tabpanel-${2}`}
                            aria-labelledby={`scrollable-auto-tab-${2}`}
                        >
                            {value2 === 2 && (
                                <Box p={3}>
                                    iiiii
                                </Box>
                            )}
                        </div>

                        <div
                            role="tabpanel"
                            hidden={value2 !== 3}
                            id={`scrollable-auto-tabpanel-${3}`}
                            aria-labelledby={`scrollable-auto-tab-${3}`}
                        >
                            {value2 === 3 && (
                                <Box p={3}>
                                    ccccccc
                                </Box>
                            )}
                        </div>
                    </Box>

                    {matiere2 != null &&
                    <Box boxShadow={3}>
                        <div
                        >
                            <Box p={3}>
                                <Box textAlign={"center"} component={"h3"}>Physique</Box>
                                <Grid container={true}>
                                    <Grid item={true} xs={6}>
                                        <Box textAlign={"center"} component={"h5"}>Exemples vidéos :</Box>

                                        <Box p={1} borderRight={1} pr={3}>
                                            <Grid container={true} spacing={3}>

                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>

                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>

                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>

                                            </Grid>
                                        </Box>


                                    </Grid>
                                    <Grid item={true} xs={6}>
                                        <Box textAlign={"center"} component={"h5"}>Exemples fichiers : </Box>

                                        <Box p={1} pl={3} textAlign={"center"}>

                                            <Grid container={true} spacing={3} justifyContent={"center"}>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 1</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 2</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 3</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 4</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 5</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Grid>


                                        </Box>

                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </Box>
                    }
                </Box>

                <Box  my={5}>
                    <Box boxShadow={10} bgcolor="primary.main"  textAlign={"center"} color="primary.contrastText">
                        <Box component={"h2"} p={3} m={0}>3 eme</Box>
                    </Box>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value3}
                            onChange={handleChange3}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="3 eme SCIENCE" {...a11yProps(0)} />
                            <Tab label="3 eme MATH" {...a11yProps(1)} />
                            <Tab label="3 eme ECO" {...a11yProps(2)} />
                            <Tab label="3 eme LETTRE" {...a11yProps(3)} />
                            <Tab label="3 eme INFO" {...a11yProps(4)} />
                        </Tabs>
                    </AppBar>

                    <Box boxShadow={3}>
                        <div
                            role="tabpanel"
                            hidden={value3 !== 0}
                            id={`scrollable-auto-tabpanel-${0}`}
                            aria-labelledby={`scrollable-auto-tab-${0}`}
                        >
                            {value3 === 0 && (
                                <Box p={3} textAlign={"center"} display={"flex"}>

                                    <Box m={3}>
                                        <Paper variant="outlined" elevation={10} onClick={() => handleChangeMatiere3(1)}>
                                            <Box p={3}>
                                                <Box mb={2}>
                                                    <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                                </Box>
                                                <Box component={"h6"} m={0} p={0}>Physique</Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                    <Box m={3}>
                                        <Paper variant="outlined" elevation={10} >
                                            <Box p={3}>
                                                <Box mb={2}>
                                                    <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                                </Box>
                                                <Box component={"h6"} m={0} p={0}>Physique</Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                    <Box m={3}>
                                        <Paper variant="outlined" elevation={10} >
                                            <Box p={3}>
                                                <Box mb={2}>
                                                    <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                                </Box>
                                                <Box component={"h6"} m={0} p={0}>Physique</Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                    <Box m={3}>
                                        <Paper variant="outlined" elevation={10} >
                                            <Box p={3}>
                                                <Box mb={2}>
                                                    <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                                </Box>
                                                <Box component={"h6"} m={0} p={0}>Physique</Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                </Box>
                            )}
                        </div>
                        <div
                            role="tabpanel"
                            hidden={value3 !== 1}
                            id={`scrollable-auto-tabpanel-${1}`}
                            aria-labelledby={`scrollable-auto-tab-${1}`}
                        >
                            {value3 === 1 && (
                                <Box p={3}>
                                    bbbbb
                                </Box>
                            )}
                        </div>
                        <div
                            role="tabpanel"
                            hidden={value3 !== 2}
                            id={`scrollable-auto-tabpanel-${2}`}
                            aria-labelledby={`scrollable-auto-tab-${2}`}
                        >
                            {value3 === 2 && (
                                <Box p={3}>
                                    iiiii
                                </Box>
                            )}
                        </div>
                    </Box>
                    {matiere3 != null &&
                    <Box boxShadow={3}>
                        <div
                        >
                            <Box p={3}>
                                <Box textAlign={"center"} component={"h3"}>Physique</Box>
                                <Grid container={true}>
                                    <Grid item={true} xs={6}>
                                        <Box textAlign={"center"} component={"h5"}>Exemples vidéos :</Box>

                                        <Box p={1} borderRight={1} pr={3}>
                                            <Grid container={true} spacing={3}>

                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>

                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>

                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>

                                            </Grid>
                                        </Box>


                                    </Grid>
                                    <Grid item={true} xs={6}>
                                        <Box textAlign={"center"} component={"h5"}>Exemples fichiers : </Box>

                                        <Box p={1} pl={3} textAlign={"center"}>

                                            <Grid container={true} spacing={3} justifyContent={"center"}>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 1</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 2</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 3</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 4</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 5</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Grid>


                                        </Box>

                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </Box>
                    }
                </Box>

                <Box my={5}>
                    <Box boxShadow={10} bgcolor="primary.main"  textAlign={"center"} color="primary.contrastText">
                        <Box component={"h2"} p={3} m={0}>Baccalauréat</Box>
                    </Box>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value4}
                            onChange={handleChange4}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="7 eme" {...a11yProps(0)} />
                            <Tab label="8 eme" {...a11yProps(1)} />
                            <Tab label="9 eme" {...a11yProps(2)} />
                        </Tabs>
                    </AppBar>

                    <Box boxShadow={3}>
                        <div
                            role="tabpanel"
                            hidden={value4 !== 0}
                            id={`scrollable-auto-tabpanel-${0}`}
                            aria-labelledby={`scrollable-auto-tab-${0}`}
                        >
                            {value4 === 0 && (
                                <Box p={3} textAlign={"center"} display={"flex"}>

                                    <Box m={3}>
                                        <Paper variant="outlined" elevation={10} onClick={() => handleChangeMatiere4(1)} >
                                            <Box p={3}>
                                                <Box mb={2}>
                                                    <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                                </Box>
                                                <Box component={"h6"} m={0} p={0}>Physique</Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                    <Box m={3}>
                                        <Paper variant="outlined" elevation={10} >
                                            <Box p={3}>
                                                <Box mb={2}>
                                                    <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                                </Box>
                                                <Box component={"h6"} m={0} p={0}>Physique</Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                    <Box m={3}>
                                        <Paper variant="outlined" elevation={10} >
                                            <Box p={3}>
                                                <Box mb={2}>
                                                    <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                                </Box>
                                                <Box component={"h6"} m={0} p={0}>Physique</Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                    <Box m={3}>
                                        <Paper variant="outlined" elevation={10} >
                                            <Box p={3}>
                                                <Box mb={2}>
                                                    <AcUnitIcon fontSize={"large"} color={"secondary"}/>
                                                </Box>
                                                <Box component={"h6"} m={0} p={0}>Physique</Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                </Box>
                            )}
                        </div>
                        <div
                            role="tabpanel"
                            hidden={value4 !== 1}
                            id={`scrollable-auto-tabpanel-${1}`}
                            aria-labelledby={`scrollable-auto-tab-${1}`}
                        >
                            {value4 === 1 && (
                                <Box p={3}>
                                    bbbbb
                                </Box>
                            )}
                        </div>
                        <div
                            role="tabpanel"
                            hidden={value4 !== 2}
                            id={`scrollable-auto-tabpanel-${2}`}
                            aria-labelledby={`scrollable-auto-tab-${2}`}
                        >
                            {value4 === 2 && (
                                <Box p={3}>
                                    iiiii
                                </Box>
                            )}
                        </div>
                    </Box>

                    {matiere4 != null &&
                    <Box boxShadow={3}>
                        <div
                        >
                            <Box p={3}>
                                <Box textAlign={"center"} component={"h3"}>Physique</Box>
                                <Grid container={true}>
                                    <Grid item={true} xs={6}>
                                        <Box textAlign={"center"} component={"h5"}>Exemples vidéos :</Box>

                                        <Box p={1} borderRight={1} pr={3}>
                                            <Grid container={true} spacing={3}>

                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>

                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>

                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={4}>
                                                    <Box component={"div"} className={cssClasses.videoContainer}>
                                                        <iframe src="https://www.youtube.com/embed/zhUgaKb0s5A"
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen/>
                                                    </Box>
                                                </Grid>

                                            </Grid>
                                        </Box>


                                    </Grid>
                                    <Grid item={true} xs={6}>
                                        <Box textAlign={"center"} component={"h5"}>Exemples fichiers : </Box>

                                        <Box p={1} pl={3} textAlign={"center"}>

                                            <Grid container={true} spacing={3} justifyContent={"center"}>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 1</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 2</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 3</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 4</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item={true}>
                                                    <Paper variant="outlined" elevation={10}>
                                                        <Box p={3}>
                                                            <Box mb={2}>
                                                                <CloudDownloadIcon fontSize={"large"}
                                                                                   color={"primary"}/>
                                                            </Box>
                                                            <Box component={"h6"} m={0} p={0}>Résumé N: 5</Box>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Grid>


                                        </Box>

                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </Box>
                    }
                </Box>

                <Box my={4} className={"text-center"} >
                    <Link to={"/auth/signup"}>

                        <Button size={"large"} color={"primary"} variant={"contained"} endIcon={<PersonAddIcon />} >
                            S'inscrire maintenant !!
                        </Button>

                    </Link>
                </Box>
               */
                }
            </Container>

        </>
    );

}


const mapStateToProps = (state) => {
    return {
        parametres : {
            loading : state.parametres.loading,
            error : state.parametres.loading,
            parametres : state.parametres.parametres,
        },
        listeCoursSpecials : {
            loading : state.guest.home.listeCoursSpecials.loading,
            error : state.guest.home.listeCoursSpecials.error,
            listeCoursSpecials : state.guest.home.listeCoursSpecials.listeCoursSpecials,
        },
        listeCategoriesSpecials : {
            loading : state.guest.home.listeCategoriesSpecials.loading,
            error : state.guest.home.listeCategoriesSpecials.error,
            listeCategoriesSpecials : state.guest.home.listeCategoriesSpecials.listeCategoriesSpecials,
        }

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchListeCoursSpecials : () => dispatch(guestHomeFetchListeCoursSpecial()),
        fetchListeCategoriesSpecials : () => dispatch(guestHomeFetchListeCategoriesSpecial()),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Secondaire);
