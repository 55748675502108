
import axios from "axios"
import {getAuthToken} from "./auth";
import {store} from "../index";
import {errorsThrowError404} from "../store/actions/errors";
import * as action from "../store/actions";

const axiosInstance = axios.create();


axiosInstance.interceptors.request.use((config) => {

    const token = getAuthToken();

    if (token)
        config.headers.Authorization = token;
    return config;
},(error) => {

    return Promise.reject(error);
});

const UNAUTHORIZED = 401;
const NOTFOUND = 404;
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        const {status} = error.response;
        if (status === UNAUTHORIZED) {
            store.dispatch(action.logout());
        } else if(status === NOTFOUND) {
                store.dispatch(errorsThrowError404());
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;



