import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Autocomplete from '@material-ui/lab/Autocomplete';

import Icon from "@material-ui/core/Icon";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonAddIcon from '@material-ui/icons/PersonAdd';


import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


import coursPhotoPlaceholder from "../../../../assets/user-photo-placeholder.png";

import {Grid} from "@material-ui/core";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import {connect} from "react-redux";

import Alert from "@material-ui/lab/Alert";



import Chip from '@material-ui/core/Chip';
import Box from "@material-ui/core/Box";
import {
    adminUtilisateursAjouterGroupe, adminUtilisateursAjouterGroupeFetchGroupeListeGroupes,
    adminUtilisateursAjouterGroupeFetchListeGroupes
} from "../../../../store/actions/admin/utilisateurs/ajouterGroupeActions";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {findGroupes} from "../../../../utils/utils";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import {DropzoneDialogBase} from "material-ui-dropzone";
import {adminUtilisateursAjouterUtilisateur} from "../../../../store/actions/admin/utilisateurs/ajouterUtilisateurActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TreeListeGroupesMultiAdd from "../../../../components/TreeListeGroupesMultiAdd";
import {
    adminCoursFetchCategorieListeCategories, adminCoursFetchCategorieListeCours,
    adminCoursFetchListeCategories
} from "../../../../store/actions/admin/cours/listeCategoriesActions";
import {adminCoursFetchListeCours} from "../../../../store/actions/admin/cours/listeCoursActions";
import TreeListe from "../../../../components/TreeListe";
import {adminCoursAjouterCours} from "../../../../store/actions/admin/cours/ajouterCoursActions";

const initialState = {

    titre : {
        value: "",
        error: null
    },
    descriptionCourte : {
        value: "",
        error: null
    },

    descriptionLongue : {
        value: "",
        error: null
    },

    special : {
        value : false,
        error : null
    },
    ouvert : {
        value : true,
        error : null
    },
    actif : {
        value : true,
        error: null
    },
    photo : null,
    previewPhoto : coursPhotoPlaceholder,
    categories : [],
    expanded : []
};


function AjouterCours(props) {


    const [open, setOpen] = React.useState(false);
    const [fileObjects, setFileObjects] = React.useState([]);

    const [state,setState] = useState(initialState);


    const [categoriesExpanded,setCategoriesExpanded] = useState(false);

    const handleCategoriesExpanded = (event,isExpanded) => {
        setCategoriesExpanded(isExpanded);
    };

    const handleCategorieSelect = (categorie) => {
        setState(prevState => {
            return {
                ...prevState,
                categories: prevState.categories.concat(categorie)
            };
        });
    };

    const handleCategorieUnSelect = (UnSelectedCategorie) => {
        setState(prevState => {
            return {
                ...prevState,
                categories : prevState.categories.filter((categorie) => categorie.id !== UnSelectedCategorie.id)
            };
        });
    };

    const isCategorieSelected = (categorieToCheck) => {
        return state.categories.findIndex((categorie) => categorie.id === categorieToCheck.id) !== -1;
    };


    /*** Form ***/

    const titreOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                titre : {
                    value : value,
                    error: error
                }
            }
        });
    };


    const specialOnChangeHandler = (event) =>{
        const checked = event.target.checked;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                special : {
                    value : checked,
                    error: error
                }
            }
        });
    };

    const ouvertOnChangeHandler = (event) =>{
        const checked = event.target.checked;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                ouvert : {
                    value : checked,
                    error: error
                }
            }
        });
    };

    const actifOnChangeHandler = (event) =>{
        const checked = event.target.checked;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                actif : {
                    value : checked,
                    error: error
                }
            }
        });
    };

    const formOnSubmitHandler = (event) => {
        event.preventDefault();
        let requirementsError = false;

        const titre = state.titre.value;

        if(!titre) {
            setState(prevState => {
                return {
                    ...prevState,
                    titre : {
                        ...prevState.titre,
                        error : "Le titre est obligatoire"
                    }
                }
            });
            requirementsError = true;
        }

        if(!requirementsError) {
            const cours = {
                titre : titre,
                categories:  state.categories,
                special : state.special.value,
                ouvert : state.ouvert.value,
                actif : state.actif.value,
            };
            const photo = state.photo ? state.photo.file : null;
            props.ajouterCours(cours,photo);
        }

    };

    /*** Form ***/

    const inputClasses = ["my-2"];


    useEffect(() => {
        props.fetchListeCategories();

        props.fetchListeCours();
    },[]);

    useEffect(() => {
        setState(initialState);
        props.fetchListeCategories();
    },[props.success]);

    const selectedCategoriesChips = state.categories.map((categorie) => {
        return <Chip
            label={categorie.titre}
            onDelete={() => handleCategorieUnSelect(categorie)}
        />
    });

    useEffect(() => {
        setState(prevState => {
            return {
                ...prevState,
                previewPhoto : state.photo ? state.photo.data : coursPhotoPlaceholder
            };
        });
    },[state.photo]);




    return(
<>


    <div className={"p-5"}>
        <div className={"text-center border rounded p-3"}>

            <h2>Ajouter Cours</h2>
            {props.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.error}</Alert>
            }
            {props.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'cours {props.cours.nom} ajouté avec succees</Alert>
            }
            <form onSubmit={formOnSubmitHandler}>
                <Grid container spacing={3} className={"my-3"}>

                    <Grid item xs={12} className={"text-center"}>

                        <img style={{height:"200px",width:"200px"}} className={"mr-3"} src={state.previewPhoto}/>

                        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                            Choisir Image
                        </Button>

                        <DropzoneDialogBase

                            showAlerts={false}
                            acceptedFiles={['image/*']}
                            filesLimit={1}
                            fileObjects={fileObjects}
                            cancelButtonText={"cancel"}
                            submitButtonText={"submit"}
                            previewText={"Prev"}
                            maxFileSize={5000000}
                            open={open}
                            onAdd={newFileObjs => {
                                setFileObjects([].concat(fileObjects, newFileObjs));
                            }}
                            onDelete={deleteFileObj => {
                                setFileObjects([]);
                            }}
                            onClose={() => setOpen(false)}
                            onSave={() => {
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        photo : fileObjects[0]
                                    }
                                });
                                setOpen(false);
                            }}
                            showPreviews={true}
                            showFileNamesInPreview={true}
                        />

                    </Grid>
                    
                    <Grid item xs={12}>
                        <TextField className={inputClasses.join(" ")} fullWidth={true} size={"small"} label="Titre" variant="outlined" onChange={titreOnChangeHandler} value={state.titre.value} error={state.titre.error} helperText={state.titre.error}  />
                    </Grid>


                    <Grid item xs={6} className={"text-left"}>

                        <FormControlLabel
                            control={<Switch checked={state.special.value} onChange={specialOnChangeHandler} />}
                            label="Spécial"
                        />
                        <br/>
                        <FormControlLabel
                            control={<Switch checked={state.ouvert.value} onChange={ouvertOnChangeHandler} />}
                            label="Ouvert"
                        />
                        <br/>
                        <FormControlLabel
                            control={<Switch checked={state.actif.value} onChange={actifOnChangeHandler} />}
                            label="Actif"
                        />

                    </Grid>


                    <Grid item xs={12}>
                        <ExpansionPanel expanded={categoriesExpanded} onChange={handleCategoriesExpanded}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <h6>Categories : {!categoriesExpanded ? selectedCategoriesChips : null}</h6>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails >
                                {props.listeCategories.loading ?
                                    <CircularProgress size={50} thickness={4} />
                                    :
                                    <Grid container spacing={3}>

                                        <Grid item xs={12} md={8} >
                                            <span>Selectionner une categorie</span>

                                            <TreeListe fetchCategorieListeCategories={props.fetchCategorieListeCategories} selectable={"Categorie"} liste={props.listeCategories.listeCategories} onlyCategories={true} handleSelect={handleCategorieSelect} handleUnSelect={handleCategorieUnSelect} isCategorieSelected={isCategorieSelected}/>
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <span>Categorie Selectionné</span>
                                            <Box>
                                                {selectedCategoriesChips}
                                            </Box>
                                        </Grid>

                                    </Grid>
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>

                </Grid>
                <div className={"position-relative"}>
                    <Button disabled={props.loading} type={"submit"} className={inputClasses.join(" ")} color={"primary"} variant={"contained"} endIcon={<PersonAddIcon />}>Ajouter</Button>
                    {props.loading && <CircularProgress  size={24}/>}
                </div>
            </form>

        </div>
    </div>
    </>
    );

}

const mapStateToProps = (state) => {
    return {
        ...state.admin.cours.ajouterCours,
        listeCategories : state.admin.cours.listeCategories
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        ajouterCours : (cours,photo) => dispatch(adminCoursAjouterCours(cours,photo)),
        fetchListeCategories : () => dispatch(adminCoursFetchListeCategories()),
        fetchListeCours : () => dispatch(adminCoursFetchListeCours()),
        fetchCategorieListeCategories : (categorieId) => dispatch(adminCoursFetchCategorieListeCategories(categorieId)),
        fetchCategorieListeCours : (categorieId) => dispatch(adminCoursFetchCategorieListeCours(categorieId)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AjouterCours);

