import {put} from "redux-saga/effects";
import axios from "../../../../api/axios"

import * as api from "../../../../api";

import * as actions from "../../../actions";
import {generateDynamicRoute} from "../../../../utils/utils";

export function* utilisateurFetchTopNotificationsSaga(action) {


    yield put(actions.utilisateurFetchTopNotifcationsStart());

    const utilisateurId = action.payload.utilisateurId;


    try {
        const response = yield axios.get(generateDynamicRoute(api.utilisateurTopNotifications,[{toReplace:":utilisateurId",value:utilisateurId}]));

        yield put(actions.utilisateurFetchTopNotifcationsSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.utilisateurFetchTopNotifcationsFail(error.response.data.error));
        }else {
            yield put(actions.utilisateurFetchTopNotifcationsFail("There's an error!"));
        }
    }
}