import * as actionTypes from "../../../../actions/actionTypes";

export const adminCoursSupprimerCours = (cours) => {
    return {
        type: actionTypes.ADMIN_COURS_INITIATE_SUPPRIMER_COURS,
        payload : {
            cours : cours
        }
    }
};

export const adminCoursSupprimerCoursStart = () => {
    return {
        type: actionTypes.ADMIN_COURS_SUPPRIMER_COURS_START
    }
};

export const adminCoursSupprimerCoursSuccess = () => {
    return {
        type: actionTypes.ADMIN_COURS_SUPPRIMER_COURS_SUCCESS
    }
};

export const adminCoursSupprimerCoursFail = (error) => {
    return {
        type: actionTypes.ADMIN_COURS_SUPPRIMER_COURS_FAIL,
        payload : {
            error : error
        }
    }
};

export const adminCoursSupprimerCoursReset = () => {
    return {
        type: actionTypes.ADMIN_COURS_SUPPRIMER_COURS_RESET
    }
};
