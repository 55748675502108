import * as actionTypes from "../../../actions/actionTypes";
import {updateObject} from "../../../../utils/utils";

const initialState = {
    loading: false,
    error: null,
};

const authNewPasswordStart = (state, action) => {
    return updateObject(state, {
        ...initialState,
        loading: true,
        error: null
    })
};

const authNewPasswordFail = (state, action) => {
    return updateObject(state, {
        ...initialState,
        loading: false,
        error: action.payload.error,
    })
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_NEW_PASSWORD_START :
            return authNewPasswordStart(state, action);
        case actionTypes.AUTH_NEW_PASSWORD_FAIL :
            return authNewPasswordFail(state, action);
        default :
            return state;
    }
};

export default reducer;