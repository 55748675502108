import React, {useEffect, useState} from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import MenuBookIcon from '@material-ui/icons/MenuBook';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AddIcon from '@material-ui/icons/Add';

import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import {Box} from "@material-ui/core";


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';


import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';


import {Link, useHistory} from "react-router-dom";
import {
    AdminCoursAjouterCategoriePage,
    AdminCoursAjouterCoursPage,
    AdminCoursDetailsCategoriePage, AdminCoursDetailsCoursPage,
    AdminCoursModifierCategoriePage,
    AdminCoursModifierCoursPage,
    AdminUtilisateursAjouterGroupePage,
    AdminUtilisateursAjouterUtilisateurPage,
    AdminUtilisateursDetailsGroupePage,
    AdminUtilisateursDetailsUtilisateurPage,
    AdminUtilisateursModifierGroupePage,
    AdminUtilisateursModifierUtilisateurPage
} from "../../../../routes/administratorRoutes";


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



import {connect} from "react-redux";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import Backdrop from "@material-ui/core/Backdrop";
import {
    adminUtilisateursSupprimerGroupe,
    adminUtilisateursSupprimerGroupeReset
} from "../../../../store/actions/admin/utilisateurs/supprimerGroupe";
import Alert from "@material-ui/lab/Alert";
import {
    adminUtilisateursSupprimerUtilisateur,
    adminUtilisateursSupprimerUtilisateurReset
} from "../../../../store/actions/admin/utilisateurs/supprimerUtilisateur";
import {dateFormat, generateDynamicRoute} from "../../../../utils/utils";
import SupprimerUtilisateurDialog from "../../../../components/SupprimerUtilisateurDialog";
import SupprimerGroupeDialog from "../../../../components/SupprimerGroupeDialog";
import TreeListe from "../../../../components/TreeListe";
import {
    adminCoursFetchCategorieListeCategories, adminCoursFetchCategorieListeCours,
    adminCoursFetchListeCategories
} from "../../../../store/actions/admin/cours/listeCategoriesActions";
import {adminCoursFetchListeCours} from "../../../../store/actions/admin/cours/listeCoursActions";
import SupprimerCategorieDialog from "../../../../components/SupprimerCategorieDialog";
import {
    adminCoursSupprimerCategorie,
    adminCoursSupprimerCategorieReset
} from "../../../../store/actions/admin/cours/supprimerCategorieActions";
import {
    adminCoursSupprimerCours,
    adminCoursSupprimerCoursReset
} from "../../../../store/actions/admin/cours/supprimerCoursActions";
import SupprimerCoursDialog from "../../../../components/SupprimerCoursDialog";
import {
    adminOffresFetchListeOffres, adminOffresFetchListeOffresReset,
    adminOffresFetchListeOffresSuccess
} from "../../../../store/actions/admin/offres/listeOffresActions";
import DeleteWithNoOption from "../../../../components/DeleteWithNoOption";

function GererOffres(props) {


    const [supprimerOffreDialog,setSupprimerOffreDialog] = useState({open:false, offreId:null});

    const handleOpenSupprimerOffreDialog = (offreId) => {
        setSupprimerOffreDialog({open : true,offreId :offreId});
    };

    const handleCloseSupprimerOffreDialog = () => {
        setSupprimerOffreDialog({open:false,offreId: null});
    };

    const history = useHistory();



/*

    useEffect(() => {

        if(props.supprimerCategorie.success || props.supprimerCours.success) {
            props.fetchListeCategories();
            props.fetchListeCours();
        }

    },[props.supprimerCategorie.success,props.supprimerCours.success])

    useEffect(() => {
        if(selected != null) {
            setActiveButtons(prevState => {
                return{
                    ...prevState,
                    editButtonActive : true,
                    deleteButtonActive : true,
                    viewButtonActive : true
                };
            });
        }
    },[selected]);
*/

    useEffect(() => {
        props.fetchListeOffres();
    },[]);

    const handleSupprimerOffre = () => {
        if(supprimerOffreDialog.offreId) {
            props.supprimerOffreReset();

            props.supprimerOffre(supprimerOffreDialog.offreId);

            handleCloseSupprimerOffreDialog();
        }
    };

/*
    const handleVoirDetails = () => {
        if(selected.startsWith("Categorie")) {
            const categorieId = selected.replace("Categorie","");
            history.push(generateDynamicRoute(AdminCoursDetailsCategoriePage.path,[{toReplace:":categorieId",value: categorieId}]));
        } else if(selected.startsWith("Cours")) {
            const coursId = selected.replace("Cours","");
            history.push(generateDynamicRoute(AdminCoursDetailsCoursPage.path,[{toReplace:":coursId",value: coursId}]))
        }
    };

    const handleModifier = () => {
        if(selected.startsWith("Categorie")) {
            const categorieId = selected.replace("Categorie","");
            history.push(generateDynamicRoute(AdminCoursModifierCategoriePage.path,[{toReplace:":categorieId",value: categorieId}]));
        } else if(selected.startsWith("Cours")) {
            const coursId = selected.replace("Cours","");
            history.push(generateDynamicRoute(AdminCoursModifierCoursPage.path,[{toReplace:":coursId",value: coursId}]));
        }

    };

*/



    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };







    return (
        <>
            <div className={"p-5"}>
            <Backdrop style={{zIndex : 999}} open={false}>
                <CircularProgress color="inherit" />
            </Backdrop>
        <h1 className={"text-center mt-3 mb-4"}>Gérer les offres</h1>

            {/*props.supprimerCategorie.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.supprimerCategorie.error}</Alert>
            }
            {props.supprimerCategorie.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Le categorie supprimé avec succees</Alert>
            }

            {props.supprimerCours.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.supprimerCours.error}</Alert>
            }
            {props.supprimerCours.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'Cours supprimé avec succees</Alert>
            */}


        <Box className={"border rounded"}>
            <div className={"text-center p-3"} >
                    <Link to={AdminCoursAjouterCoursPage.path} >
                        <div className={"d-flex justify-content-center align-items-center"}>
                            <IconButton >
                                <AddIcon color={"primary"} />
                                <Icon className={"fa fa-handshake p-0 w-auto"} color={"primary"}/>
                            </IconButton>
                            <h5 className={"p-0 m-0"}>
                                Ajouter une Offre
                            </h5>
                        </div>
                    </Link>
            </div>
            <Divider />

            {!props.fetchListeOffresState.loading &&
                <div>


                    <Paper >
                        <TableContainer>
                            <Table aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="center">Favoris</TableCell>
                                        <TableCell align="center">Titre</TableCell>
                                        <TableCell align="center">Durée</TableCell>
                                        <TableCell align="center">Prix</TableCell>
                                        <TableCell align="center">Modifier</TableCell>
                                        <TableCell align="right">Supprimer</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.fetchListeOffresState.listeOffres.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((offre) => {
                                        return (
                                            <TableRow key={offre.id}>
                                                <TableCell align={"left"}>
                                                    <img style={{width:150}} src={offre.photo} alt={offre.titre}/>
                                                </TableCell>
                                                <TableCell align="center"><StarIcon color={"secondary"}/></TableCell>
                                                <TableCell align="center">{offre.titre}</TableCell>
                                                <TableCell align="center">

                                                    {
                                                        offre.periodeFixe ? "De " + dateFormat(offre.dateDebut) + " à " + dateFormat(offre.dateFin) : "Abonnement"
                                                    }


                                                </TableCell>
                                                <TableCell align="center">

                                                    {
                                                        offre.periodeFixe ? offre.prix + " DT" : <Grid container={true} direction={"column"}>

                                                            <Grid item={true}>
                                                                <Grid container={true}>
                                                                    <Grid item={true} xs={1}></Grid>
                                                                    <Grid item={true} xs={2} className={"border p-2"}>1</Grid>
                                                                    <Grid item={true} xs={2} className={"border p-2"}>3</Grid>
                                                                    <Grid item={true} xs={2} className={"border p-2"}>6</Grid>
                                                                    <Grid item={true} xs={2} className={"border p-2"}>12</Grid>
                                                                    <Grid item={true} xs={3} className={"border p-2"}><b>Mois</b></Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item={true}>
                                                                <Grid container={true}>
                                                                    <Grid item={true} xs={1} ></Grid>
                                                                    <Grid item={true} xs={2} className={"border p-2"}>{offre.prix1Mois}</Grid>
                                                                    <Grid item={true} xs={2} className={"border p-2"}>{offre.prix3Mois}</Grid>
                                                                    <Grid item={true} xs={2} className={"border p-2"}>{offre.prix6Mois}</Grid>
                                                                    <Grid item={true} xs={2} className={"border p-2"}>{offre.prix12Mois}</Grid>
                                                                    <Grid item={true} xs={3} className={"border p-2"}><b>DT</b></Grid>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    }</TableCell>
                                                <TableCell align="center">
                                                    <IconButton>
                                                        <EditIcon style={{color : "#ff9800"}}  onClick={() => {}} />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <IconButton>
                                                        <DeleteForeverIcon color={"secondary"} onClick={() => {handleOpenSupprimerOffreDialog(offre.id)}} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={props.fetchListeOffresState.listeOffres.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>

                </div>
            }
            <DeleteWithNoOption open={supprimerOffreDialog.open} onClose={handleCloseSupprimerOffreDialog} supprimer={handleSupprimerOffre}  dialogTitle={"Voulez vous vraiment supprimer l'offre ?"}/>

        </Box>
            </div>
</>

    );


}

const mapStateToProps = (state) => {
    return {/*
        supprimerCategorie : {
            loading : state.admin.cours.supprimerCategorie.loading,
            error : state.admin.cours.supprimerCategorie.error,
            success : state.admin.cours.supprimerCategorie.success
        },
        supprimerCours : {
            loading : state.admin.cours.supprimerCours.loading,
            error : state.admin.cours.supprimerCours.error,
            success :  state.admin.cours.supprimerCours.success
        },
        listeCategories : {
            loading : state.admin.cours.listeCategories.loading,
            error : state.admin.cours.listeCategories.error,
            listeCategories : state.admin.cours.listeCategories.listeCategories,
        },*/
        fetchListeOffresState  : state.admin.offres.listeOffres,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchListeOffres : () => dispatch(adminOffresFetchListeOffres()),
        fetchListeCoursReset : () => dispatch(adminOffresFetchListeOffresReset()),
        /*supprimerCours : (coursId) => dispatch(adminCoursSupprimerCours(coursId)),
        supprimerCoursReset : () => dispatch(adminCoursSupprimerCoursReset()),*/
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(GererOffres);

