import * as actionTypes from "../../../actionTypes";

export const enseignantMesCoursAjouterFichier = (fichier,fichier2) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_INITIATE_AJOUTER_FICHIER,
        payload : {
            fichier : fichier,
            fichier2 : fichier2
        }
    }
};

export const enseignantMesCoursAjouterFichierStart = () => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_FICHIER_START
    }
};

export const enseignantMesCoursAjouterFichierSuccess = (fichier) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_FICHIER_SUCCESS,
        payload : {
            fichier : fichier
        }
    }
};

export const enseignantMesCoursAjouterFichierFail = (error) => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_FICHIER_FAIL,
        payload : {
            error: error
        }
    }
};

export const enseignantMesCoursAjouterFichierReset = () => {
    return {
        type : actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_FICHIER_RESET
    }
};
