import * as actionTypes from "../../../../actions/actionTypes";

export const adminUtilisateursAjouterGroupe = (groupe) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_INITIATE_AJOUTER_GROUPE,
        payload : {
            groupe : groupe
        }
    }
};

export const adminUtilisateursAjouterGroupeStart = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_AJOUTER_GROUPE_START
    }
};

export const adminUtilisateursAjouterGroupeSuccess = (groupe) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_AJOUTER_GROUPE_SUCCESS,
        payload : {
            groupe: groupe
        }
    }
};

export const adminUtilisateursAjouterGroupeFail = (error) => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_AJOUTER_GROUPE_FAIL,
        payload : {
            error : error
        }
    }
};


export const adminUtilisateursAjouterGroupeReset = () => {
    return {
        type: actionTypes.ADMIN_UTILISATEURS_AJOUTER_GROUPE_RESET
    }
};