import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    section: null
};

const enseignantMesCoursAjouterSectionStart = (state,action) => {
    return updateObject(state,{
        success : false,
        loading : true,
        error: null,
        section: null
    });
};

const enseignantMesCoursAjouterSectionSuccess = (state,action) => {
    return updateObject(state,{
        success : true,
        loading : false,
        error: null,
        section: action.payload.section
    });
};

const enseignantMesCoursAjouterSectionFail = (state,action) => {
    return updateObject(state,{
        success : false,
        loading : false,
        error:action.payload.error,
        section: null
    });
};

const enseignantMesCoursAjouterSectionReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_SECTION_START : return enseignantMesCoursAjouterSectionStart(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_SECTION_SUCCESS : return enseignantMesCoursAjouterSectionSuccess(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_SECTION_FAIL : return enseignantMesCoursAjouterSectionFail(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_SECTION_RESET : return enseignantMesCoursAjouterSectionReset(state,action);
        default : return state;
    }
};

export default reducer;
