import React from "react";
import cssClasses from "./offer.module.css";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Done } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

function Offer(props) {
  const offer = props.offer;
  const description = offer.description.split("/").map((item) => {
    return (
      <li className={item.startsWith("*") ? "font-weight-bold" : ""}>
        <Done className={cssClasses.doneIcon} />
        {item.startsWith("*") ? item.substring(1) : item}
      </li>
    );
  });

  const [months, setMonths] = React.useState(1);

  const handleAddToCart = () => {
    props.handleAddToCart(offer, months);
  };

  const handleMonths = (value) => {
    setMonths(value);
  };
  const OfferPrice = () => {
    if (offer.price) return offer.price;

    switch (months) {
      case 1:
        return offer.price1;
      case 3:
        return offer.price3;
      case 6:
        return offer.price6;
      case 12:
        return offer.price12;
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <div className={cssClasses.card}>
        {" "}
        <p className={cssClasses.title}>{offer.title}</p>
        <div className={cssClasses.content}>
          <img src={offer.photo} alt="Offer Image" />
          {!props.isLoggedIn ? (
            <p className={cssClasses.smallDescription}>
              {" "}
              Offre pour les étudiants {offer.category}
            </p>
          ) : (
            <p className={cssClasses.smallDescription}></p>
          )}
          <ul className={cssClasses.description}>{description}</ul>
          {props.isLoggedIn ? (
            <div>
              <div className={cssClasses.priceButton}>
                {!offer.price ? (
                  <div>
                    <p className={cssClasses.months}>{months} Mois</p>
                    <div className={cssClasses.numberButtons}>
                      {/* Buttons with numbers */}
                      <button
                        className={`${cssClasses.numberButton} ${
                          months === 1 ? cssClasses.selected : ""
                        }`}
                        onClick={() => handleMonths(1)}
                        value={1}
                      >
                        1
                      </button>
                      <button
                        className={`${cssClasses.numberButton} ${
                          months === 3 ? cssClasses.selected : ""
                        }`}
                        onClick={() => handleMonths(3)}
                        value={3}
                      >
                        3
                      </button>
                      <button
                        className={`${cssClasses.numberButton} ${
                          months === 6 ? cssClasses.selected : ""
                        }`}
                        onClick={() => handleMonths(6)}
                        value={6}
                      >
                        6
                      </button>
                      <button
                        className={`${cssClasses.numberButton} ${
                          months === 12 ? cssClasses.selected : ""
                        }`}
                        onClick={() => handleMonths(12)}
                        value={12}
                      >
                        12
                      </button>
                    </div>
                  </div>
                ) : null}

                <p className={cssClasses.price}>{OfferPrice()} DT</p>
              </div>
              {props.offerSelectable ? (
                <Button
                  className={cssClasses.buyButton}
                  onClick={handleAddToCart}
                >
                  Ajouter au Panier
                </Button>
              ) : (
                <Button className={cssClasses.disabledButton} disabled>
                  Offre ajoutée au panier
                </Button>
              )}
            </div>
          ) : (
            <div className={cssClasses.priceButtonWrapper}>
              <p className={cssClasses.price}>
                {offer.price !== null && offer.price !== 0
                  ? offer.price + " DT"
                  : "à partir de " + offer.price1 + " DT"}
              </p>
              <NavLink to={"/auth/signup"}>
                <Button className={cssClasses.buyButton}>
                  Commencer Maintenant
                </Button>
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </Grid>
  );
}

export default Offer;
