import {combineReducers} from "redux";

import ajouterCategorieReducer from "./ajouterCategorie"
import modifierGroupeReducer from "./modifierGroupe"
import modifierUtilisateurReducer from "./modifierUtilisateur"
import ajouterCoursReducer from "./ajouterCours"

import listeOffresReducer from "./listeOffres"
import listeCoursReducer from "./listeCours"

import supprimerCategorieReducer from "./supprimerCategorie"
import supprimerCoursReducer from "./supprimerCours"


import ajouterUtilisateurAuGroupeReducer from "./ajouterUtilisateurAuGroupe"
import supprimerUtilisateurAuGroupeReducer from "./supprimerUtilisateurAuGroupe"

import groupeReducer from "./groupe"
import utilisateurReducer from "./utilisateur"


export default combineReducers({
    /*ajouterCategorie : ajouterCategorieReducer,
    modifierGroupe : modifierGroupeReducer,
    modifierUtilisateur : modifierUtilisateurReducer,
    ajouterCours : ajouterCoursReducer,*/
    listeOffres: listeOffresReducer,
    /*listeCours : listeCoursReducer,
    supprimerCategorie : supprimerCategorieReducer,
    supprimerCours : supprimerCoursReducer,
    ajouterUtilisateurAuGroupe : ajouterUtilisateurAuGroupeReducer,
    supprimerUtilisateurAuGroupe : supprimerUtilisateurAuGroupeReducer,
    groupe : groupeReducer,
    utilisateur : utilisateurReducer*/
});