import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    assignementGroupeCategorieId : null,
};

const adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,
        assignementGroupeCategorieId : null
    });
};

const adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        assignementGroupeCategorieId : action.payload.assignementGroupeCategorieId
    });
};

const adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        assignementGroupeCategorieId : null
    });
};

const adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};


function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_START : return adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieStart(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_SUCCESS : return adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieSuccess(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_FAIL : return adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieFail(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_CATEGORIE_SUPPRIMER_ASSIGNEMENT_GROUPE_CATEGORIE_RESET : return adminAssignementsGroupeCategorieSupprimerAssignementGroupeCategorieReset(state,action);
        default : return state;
    }

}

export default reducer;