import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* adminUtilisateursModifierUtilisateurSaga (action) {

    yield put(actions.adminUtilisateursModifierUtilisateurStart());

    const utilisateurId = action.payload.utilisateurId;

    const utilisateur = yield action.payload.utilisateur;
    const photo = yield action.payload.photo;

    const formData = yield new FormData();
    yield formData.append('utilisateur', new Blob([JSON.stringify(utilisateur)], {
        type: "application/json"
    }));
    yield formData.append("photo",photo);


    try {
        const response = yield axios.put(generateDynamicRoute(api.modifierUtilisateur,[{toReplace:":utilisateurId",value:utilisateurId}]),formData);
        yield put(actions.adminUtilisateursModifierUtilisateurSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursModifierUtilisateurFail(error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursModifierUtilisateurFail("There's an error!"));
        }
    }
}





export function* adminUtilisateursModifierUtilisateurChangerMotDePasseSaga (action) {

    yield put(actions.adminUtilisateursModifierUtilisateurChangerMotDePasseStart());

    const utilisateurId = action.payload.utilisateurId;
    const utilisateur = yield action.payload.utilisateur;
    const sendEmail = yield action.payload.sendEmail;
    const generatePassword = yield action.payload.generatePassword;

    try {
        const response = yield axios.put(generateDynamicRoute(api.modifierUtilisateurChangerMotDePasse,[{toReplace:":utilisateurId",value:utilisateurId},{toReplace:":sendEmail",value:sendEmail},{toReplace:":generatePassword",value:generatePassword}]),utilisateur);
        yield put(actions.adminUtilisateursModifierUtilisateurChangerMotDePasseSuccess(response.data));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursModifierUtilisateurChangerMotDePasseFail(error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursModifierUtilisateurChangerMotDePasseFail("There's an error!"));
        }
    }
}

