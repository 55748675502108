import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    listeCoursSpecials: []
};

const guestHomeFetchListeCoursSpecialsStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        listeCoursSpecials: []
    });
};

const guestHomeFetchListeCoursSpecialsSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        listeCoursSpecials: action.payload.listeCoursSpecials
    });
};

const guestHomeFetchListeCoursSpecialsFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        listeCoursSpecials: []
    });
};

function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.GUEST_HOME_FETCH_LISTE_COURS_SPECIALS_START : return guestHomeFetchListeCoursSpecialsStart(state,action);
        case actionTypes.GUEST_HOME_FETCH_LISTE_COURS_SPECIALS_SUCCESS : return guestHomeFetchListeCoursSpecialsSuccess(state,action);
        case actionTypes.GUEST_HOME_FETCH_LISTE_COURS_SPECIALS_FAIL : return guestHomeFetchListeCoursSpecialsFail(state,action);

        default : return state;
    }

}

export default reducer;