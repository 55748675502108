import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    video: null
};

const enseignantMesCoursAjouterVideoStart = (state,action) => {
    return updateObject(state,{
        success : false,
        loading : true,
        error: null,
        video: null
    });
};

const enseignantMesCoursAjouterVideoSuccess = (state,action) => {
    return updateObject(state,{
        success : true,
        loading : false,
        error: null,
        video: action.payload.video
    });
};

const enseignantMesCoursAjouterVideoFail = (state,action) => {
    return updateObject(state,{
        success : false,
        loading : false,
        error:action.payload.error,
        video: null
    });
};

const enseignantMesCoursAjouterVideoReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_VIDEO_START : return enseignantMesCoursAjouterVideoStart(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_VIDEO_SUCCESS : return enseignantMesCoursAjouterVideoSuccess(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_VIDEO_FAIL : return enseignantMesCoursAjouterVideoFail(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_AJOUTER_VIDEO_RESET : return enseignantMesCoursAjouterVideoReset(state,action);
        default : return state;
    }
};

export default reducer;
