import React, {useEffect, useState} from "react";
import SideNav from "../../containers/Guest/SideNav";
import {Route, Switch} from 'react-router-dom'
import routes from "../../routes";
import MainContent from "../../containers/Guest/MainContent";
import cssClasses from "./layout.module.css"

import * as actions from "../../store/actions";

import { useHistory } from "react-router-dom";
import {connect} from "react-redux";
import Error404 from "../../components/Error404";

import loading from "../../assets/loading.svg"
import LayoutEtudiant from "./layoutEtudiant";
import LayoutEnseignant from "./layoutEnseignant";
import LayoutTechnician from "./layoutTechnician";

function Layout(props) {

    let history = useHistory();

    const [unlistenState,setUnlistenState] = useState(null);

    useEffect(() => {
        if (props.error404) {
            const unlisten = history.listen((location, action) => {
                props.removeError404();
            });
            setUnlistenState((prevState) => {
                return unlisten;
            });
        } else {
            if(unlistenState != null) {
                unlistenState();
                setUnlistenState(null);
            }
        }
    },[props.error404]);



    return(
        <>


            <div className={cssClasses.layout}>
                {
                    !props.autoLoginCompleted ?

                        <div className={"bg-white d-flex justify-content-center align-items-center"} style={{height:"100vh"}}>
                            <div className={"mx-auto loadingContainer"}>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <div>
                                                            <div>
                                                                <div> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :

                        <>

                            <Switch>
                                <Route path={"/technician/"} component={LayoutTechnician}/>
                                <Route path={"/enseignant/"} component={LayoutEnseignant}/>
                                <Route path={"/student/"} component={LayoutEtudiant}/>
                                <Route path={"/"} >
                                    <SideNav />
                                    <MainContent/>
                                </Route>
                            </Switch>
                        </>

                }
            </div>
        </>
    );

}

const mapStateToProps = (state) => {
    return {
        ...state.errors,
        autoLoginCompleted : state.initiateApp.autoLoginCompleted
        }
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeError404 : () => {dispatch(actions.errorsRemoveError404())}
    }
};



export default connect(mapStateToProps,mapDispatchToProps)(Layout);

