import * as actionTypes from "../../actions/actionTypes";
import {updateObject} from "../../../utils/utils";

const initialState = {
    autoLoginCompleted : false
};

const appLoadingAutoLoginComplete = (state,action) => {
    return updateObject(state,{
        ...state,
        autoLoginCompleted : true
    });
};


function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.APP_LOADING_AUTO_LOGIN_COMPLETE : return appLoadingAutoLoginComplete(state,action);
        default : return state;
    }

}

export default reducer;