import * as actionTypes from "../../../actionTypes";

export const etudiantMesCoursFetchCours = (coursId) => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_INITIATE_FETCH_COURS,
        payload : {
            coursId : coursId
        }
    }
};

export const etudiantMesCoursFetchCoursStart = () => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_COURS_START
    }
};

export const etudiantMesCoursFetchCoursSuccess = (cours) => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_COURS_SUCCESS,
        payload : {
            cours : cours
        }
    }
};

export const etudiantMesCoursFetchCoursFail = (error) => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_COURS_FAIL,
        payload : {
            error: error
        }
    }
};

export const etudiantMesCoursFetchCoursReset = () => {
    return {
        type : actionTypes.ETUDIANT_MES_COURS_FETCH_COURS_RESET
    }
};
