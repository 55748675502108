import React from "react";

import CoursCategorieList from "../../components/CoursCategoriePaginatedList";
import CustomRoute from "../../hoc/CustomRoute";
import {Switch} from "react-router-dom";

function PublicCoursList(props) {


    return (
        <Switch>
            {props.routes.map(route => {
                return CustomRoute(route);
            })}
        </Switch>
    );

}

export default PublicCoursList;