import * as actionTypes from "../../../../actions/actionTypes";

export const adminAssignementsGroupeCoursAjouterAssignementGroupeCours = (assignementGroupeCours) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_INITIATE_AJOUTER_ASSIGNEMENT_GROUPE_COURS,
        payload : {
            assignementGroupeCours : assignementGroupeCours
        }
    }
};

export const adminAssignementsGroupeCoursAjouterAssignementGroupeCoursStart = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_START
    }
};

export const adminAssignementsGroupeCoursAjouterAssignementGroupeCoursReset = () => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_RESET
    }
};

export const adminAssignementsGroupeCoursAjouterAssignementGroupeCoursSuccess = (assignementGroupeCours) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_SUCCESS,
        payload : {
            assignementGroupeCours: assignementGroupeCours
        }
    }
};

export const adminAssignementsGroupeCoursAjouterAssignementGroupeCoursFail = (error) => {
    return {
        type: actionTypes.ADMIN_ASSIGNEMENTS_GROUPE_COURS_AJOUTER_ASSIGNEMENT_GROUPE_COURS_FAIL,
        payload : {
            error : error
        }
    }
};
