import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    success : false,
    loading : false,
    error: null,
    assignementUtilisateurCoursId : null
};

const adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCoursStart = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : true,
        error: null,

        assignementUtilisateurCoursId : null
    });
};

const adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCoursSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        success : true,
        loading : false,
        error: null,
        assignementUtilisateurCoursId : action.payload.assignementUtilisateurCoursId
    });
};

const adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCoursFail = (state,action) => {
    return updateObject(state,{
        ...state,
        success : false,
        loading : false,
        error: action.payload.error,
        assignementUtilisateurCoursId : null
    });
};


const adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCoursReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};





function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_START : return adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCoursStart(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_SUCCESS : return adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCoursSuccess(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_FAIL : return adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCoursFail(state,action);
        case actionTypes.ADMIN_ASSIGNEMENTS_UTILISATEUR_COURS_SUPPRIMER_ASSIGNEMENT_UTILISATEUR_COURS_RESET : return adminAssignementsUtilisateurCoursSupprimerAssignementUtilisateurCoursReset(state,action);
        default : return state;
    }

}

export default reducer;