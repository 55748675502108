import React, {useEffect, useState} from "react";

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from "@material-ui/core/IconButton";

import MenuBookIcon from '@material-ui/icons/MenuBook';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AddIcon from '@material-ui/icons/Add';


import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Typography from '@material-ui/core/Typography';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import DeleteIcon from '@material-ui/icons/Delete';
import Label from '@material-ui/icons/Label';
import {Box} from "@material-ui/core";


import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';


import {Link, useHistory} from "react-router-dom";
import UserList from "../../../../components/UsersList";
import {
    AdminCoursAjouterCategoriePage,
    AdminCoursAjouterCoursPage,
    AdminCoursDetailsCategoriePage, AdminCoursDetailsCoursPage,
    AdminCoursModifierCategoriePage,
    AdminCoursModifierCoursPage,
    AdminUtilisateursAjouterGroupePage,
    AdminUtilisateursAjouterUtilisateurPage,
    AdminUtilisateursDetailsGroupePage,
    AdminUtilisateursDetailsUtilisateurPage,
    AdminUtilisateursModifierGroupePage,
    AdminUtilisateursModifierUtilisateurPage
} from "../../../../routes/administratorRoutes";


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



import {connect} from "react-redux";
import {
    adminUtilisateursFetchGroupeListeGroupes, adminUtilisateursFetchGroupeListeUtilisateurs,
    adminUtilisateursFetchListeGroupes
} from "../../../../store/actions/admin/utilisateurs/listeGroupesActions";
import {adminUtilisateursFetchListeUtilisateurs} from "../../../../store/actions/admin/utilisateurs/listeUtilisateursActions";
import Backdrop from "@material-ui/core/Backdrop";
import {
    adminUtilisateursSupprimerGroupe,
    adminUtilisateursSupprimerGroupeReset
} from "../../../../store/actions/admin/utilisateurs/supprimerGroupe";
import Alert from "@material-ui/lab/Alert";
import {
    adminUtilisateursSupprimerUtilisateur,
    adminUtilisateursSupprimerUtilisateurReset
} from "../../../../store/actions/admin/utilisateurs/supprimerUtilisateur";
import {generateDynamicRoute} from "../../../../utils/utils";
import SupprimerUtilisateurDialog from "../../../../components/SupprimerUtilisateurDialog";
import SupprimerGroupeDialog from "../../../../components/SupprimerGroupeDialog";
import TreeListe from "../../../../components/TreeListe";
import {
    adminCoursFetchCategorieListeCategories, adminCoursFetchCategorieListeCours,
    adminCoursFetchListeCategories
} from "../../../../store/actions/admin/cours/listeCategoriesActions";
import {adminCoursFetchListeCours} from "../../../../store/actions/admin/cours/listeCoursActions";
import SupprimerCategorieDialog from "../../../../components/SupprimerCategorieDialog";
import {
    adminCoursSupprimerCategorie,
    adminCoursSupprimerCategorieReset
} from "../../../../store/actions/admin/cours/supprimerCategorieActions";
import {
    adminCoursSupprimerCours,
    adminCoursSupprimerCoursReset
} from "../../../../store/actions/admin/cours/supprimerCoursActions";
import SupprimerCoursDialog from "../../../../components/SupprimerCoursDialog";

function GererCours(props) {

    const [selected, setSelected] = React.useState();

    const [supprimerCoursDialogOpen,setSupprimerCoursDialogOpen] = useState(false);
    const [supprimerCategorieDialogOpen,setSupprimerCategorieDialogOpen] = useState(false);

    const handleOpenSupprimerCoursDialog = () => {
        setSupprimerCoursDialogOpen(true);
    };

    const handleCloseSupprimerCoursDialog = () => {
        setSupprimerCoursDialogOpen(false);
    };

    const handleOpenSupprimerCategorieDialog = () => {
        setSupprimerCategorieDialogOpen(true);
    };

    const handleCloseSupprimerCategorieDialog = () => {
        setSupprimerCategorieDialogOpen(false);
    };

    const handleOpenSupprimerDialog = () => {
        if(selected.startsWith("Cours"))
            handleOpenSupprimerCoursDialog();
        else if(selected.startsWith("Categorie"))
            handleOpenSupprimerCategorieDialog();
    };

    const handleCloseSupprimerDialog = () => {
        handleCloseSupprimerCoursDialog();
        handleCloseSupprimerCategorieDialog();
    }


    const history = useHistory();



    const [activeButtons, setActiveButtons] = React.useState({
        addCoursButtonActive :true,
        addCategorieButtonActive : true,
        editButtonActive : false,
        deleteButtonActive : false,
        viewButtonActive : false
    });


    useEffect(() => {

        if(props.supprimerCategorie.success || props.supprimerCours.success) {
            props.fetchListeCategories();
            props.fetchListeCours();
        }

    },[props.supprimerCategorie.success,props.supprimerCours.success])

    useEffect(() => {
        if(selected != null) {
            setActiveButtons(prevState => {
                return{
                    ...prevState,
                    editButtonActive : true,
                    deleteButtonActive : true,
                    viewButtonActive : true
                };
            });
        }
    },[selected]);

    const dispatchSelected = (selected) => {
        setSelected(selected);
    };


    useEffect(() => {
        props.fetchListeCategories();
        props.fetchListeCours();
    },[]);

    const handleSupprimerCours = () => {

        props.supprimerCategorieReset();
        props.supprimerCoursReset();

        if(selected.startsWith("Cours")) {
            const coursId = selected.replace("Cours","");
            props.onSupprimerCours(coursId);
        }

        handleCloseSupprimerDialog();
    };

    const handleSupprimerCategorie = (supprimerSousCategories,supprimerCours) => {

        props.supprimerCategorieReset();
        props.supprimerCoursReset();

        if(selected.startsWith("Categorie")) {
            const categorieId = selected.replace("Categorie","");
            props.onSupprimerCategorie(categorieId,supprimerSousCategories,supprimerCours);
        }

        handleCloseSupprimerDialog();
    };


    const handleVoirDetails = () => {
        if(selected.startsWith("Categorie")) {
            const categorieId = selected.replace("Categorie","");
            history.push(generateDynamicRoute(AdminCoursDetailsCategoriePage.path,[{toReplace:":categorieId",value: categorieId}]));
        } else if(selected.startsWith("Cours")) {
            const coursId = selected.replace("Cours","");
            history.push(generateDynamicRoute(AdminCoursDetailsCoursPage.path,[{toReplace:":coursId",value: coursId}]))
        }
    };

    const handleModifier = () => {
        if(selected.startsWith("Categorie")) {
            const categorieId = selected.replace("Categorie","");
            history.push(generateDynamicRoute(AdminCoursModifierCategoriePage.path,[{toReplace:":categorieId",value: categorieId}]));
        } else if(selected.startsWith("Cours")) {
            const coursId = selected.replace("Cours","");
            history.push(generateDynamicRoute(AdminCoursModifierCoursPage.path,[{toReplace:":coursId",value: coursId}]));
        }

    };

    return (
        <>
            <div className={"p-5"}>
            <Backdrop style={{zIndex : 999}} open={props.supprimerCategorie.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        <h1 className={"text-center"}>Gérer les cours</h1>

            {props.supprimerCategorie.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.supprimerCategorie.error}</Alert>
            }
            {props.supprimerCategorie.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">Le categorie supprimé avec succees</Alert>
            }

            {props.supprimerCours.error &&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="error">{props.supprimerCours.error}</Alert>
            }
            {props.supprimerCours.success&&
            <Alert className={"mt-2 mb-3"} variant="filled" severity="success">L'Cours supprimé avec succees</Alert>
            }


        <Box className={"border rounded"}>
            <Grid container className={"p-3"} alignItems="center" justify={"center"} spacing={3}>
                <Grid item >
                    <Link to={AdminCoursAjouterCoursPage.path} >
                        <IconButton disabled={!activeButtons.addCoursButtonActive}>
                            <AddIcon color={activeButtons.addCoursButtonActive ? "primary" : "disabled"} />
                            <MenuBookIcon color={activeButtons.addCoursButtonActive ? "primary" : "disabled"} />
                        </IconButton>
                    </Link>
                </Grid>
                <Grid item >
                    <Link to={AdminCoursAjouterCategoriePage.path}>
                        <IconButton disabled={!activeButtons.addCategorieButtonActive}>
                            <AddIcon color={activeButtons.addCategorieButtonActive ? "primary" : "disabled"} />
                            <LibraryBooksIcon color={activeButtons.addCategorieButtonActive ? "primary" : "disabled"} />
                        </IconButton>
                    </Link>
                </Grid>
                <Grid item >
                    <IconButton onClick={handleModifier} disabled={!activeButtons.editButtonActive}>
                        <EditIcon style={{color :activeButtons.editButtonActive? "#ff9800" : null}} color={!activeButtons.editButtonActive ?"disabled" : ""} />
                    </IconButton>
                </Grid>
                <Grid item >
                    <IconButton onClick={handleVoirDetails} disabled={!activeButtons.viewButtonActive}>
                        <VisibilityIcon style={{color : activeButtons.viewButtonActive? "#4caf50" : null}} color={!activeButtons.viewButtonActive ?"disabled" : ""} />
                    </IconButton>
                </Grid>
                <Grid item >
                    <IconButton disabled={!activeButtons.deleteButtonActive} onClick={handleOpenSupprimerDialog}>
                        <DeleteForeverIcon color={activeButtons.deleteButtonActive ? "error" : "disabled"} />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />

            {!props.listeCategories.loading && !props.listeCours.loading &&
<div>
            <TreeListe dispatchSelected={dispatchSelected} fetchCategorieListeCours={props.fetchCategorieListeCours} fetchCategorieListeCategories={props.fetchCategorieListeCategories} liste={props.listeCategories.listeCategories.concat(props.listeCours.listeCours)} />


           </div>

            }
            <SupprimerCategorieDialog open={supprimerCategorieDialogOpen} onClose={handleCloseSupprimerCategorieDialog} supprimer={handleSupprimerCategorie}/>
            <SupprimerCoursDialog open={supprimerCoursDialogOpen} onClose={handleOpenSupprimerCoursDialog} supprimer={handleSupprimerCours}/>

            {/*
            <SupprimerUtilisateurDialog open={supprimerUtilisateurDialogOpen} onClose={handleCloseSupprimerUtilisateurDialog} supprimer={handleSupprimerUtilisateur}/>
            <SupprimerGroupeDialog open={supprimerGroupeDialogOpen} onClose={handleCloseSupprimerGroupeDialog} supprimer={handleSupprimerGroupe}/>
*/
            }

        </Box>
            </div>
</>

    );


}

const mapStateToProps = (state) => {
    return {
        supprimerCategorie : {
            loading : state.admin.cours.supprimerCategorie.loading,
            error : state.admin.cours.supprimerCategorie.error,
            success : state.admin.cours.supprimerCategorie.success
        },
        supprimerCours : {
            loading : state.admin.cours.supprimerCours.loading,
            error : state.admin.cours.supprimerCours.error,
            success :  state.admin.cours.supprimerCours.success
        },
        listeCategories : {
            loading : state.admin.cours.listeCategories.loading,
            error : state.admin.cours.listeCategories.error,
            listeCategories : state.admin.cours.listeCategories.listeCategories,
        },
        listeCours : {
            loading : state.admin.cours.listeCours.loading,
            error : state.admin.cours.listeCours.error,
            listeCours : state.admin.cours.listeCours.listeCours,
        }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        //addGroupe : (nom,groupeParent) => dispatch(adminUsersAddGroupe(nom,groupeParent)),
        fetchListeCategories : () => dispatch(adminCoursFetchListeCategories()),
        fetchListeCours : () => dispatch(adminCoursFetchListeCours()),
        fetchCategorieListeCategories : (categorieId) => dispatch(adminCoursFetchCategorieListeCategories(categorieId)),
        fetchCategorieListeCours : (categorieId) => dispatch(adminCoursFetchCategorieListeCours(categorieId)),

        onSupprimerCategorie : (categorieId,supprimerSousCategories,supprimerCours) => dispatch(adminCoursSupprimerCategorie(categorieId,supprimerSousCategories,supprimerCours)),
        supprimerCategorieReset : () => dispatch(adminCoursSupprimerCategorieReset()),
        onSupprimerCours : (coursId) => dispatch(adminCoursSupprimerCours(coursId)),
        supprimerCoursReset : () => dispatch(adminCoursSupprimerCoursReset()),
        //resetListeCategories : () => dispatch(etudiantMesCoursFetchListeCoursReset())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(GererCours);

