import * as actionTypes from "../../../../actions/actionTypes2";


export const adminOffresFetchListeOffres = () => {
    return {
        type: actionTypes.ADMIN_OFFRES_INITIATE_FETCH_LISTE_OFFRES,
    }
};

export const adminOffresFetchListeOffresStart = () => {
    return {
        type: actionTypes.ADMIN_OFFRES_FETCH_LISTE_OFFRES_START
    }
};

export const adminOffresFetchListeOffresSuccess = (listeOffres) => {
    return {
        type: actionTypes.ADMIN_OFFRES_FETCH_LISTE_OFFRES_SUCCESS,
        payload : {
            listeOffres: listeOffres
        }
    }
};

export const adminOffresFetchListeOffresFail = (error) => {
    return {
        type: actionTypes.ADMIN_OFFRES_FETCH_LISTE_OFFRES_FAIL,
        payload : {
            error : error
        }
    }
};


export const adminOffresFetchListeOffresReset = () => {
    return {
        type: actionTypes.ADMIN_OFFRES_FETCH_LISTE_OFFRES_RESET
    }
};


