import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";



export function* adminUtilisateursAjouterUtilisateurAuGroupeSaga (action) {

    yield put(actions.adminUtilisateursAjouterUtilisateurAuGroupeStart());

    const utilisateurId = action.payload.utilisateurId;
    const groupeId = action.payload.groupeId;

    try {
        const response = yield axios.put(generateDynamicRoute(api.ajouterUtilisateurAuGroupe,[{toReplace:":utilisateurId",value:utilisateurId},{toReplace:":groupeId",value:groupeId}]));

        const utilisateur = response.data.utilisateur;
        const groupe = response.data.groupe;

        yield put(actions.adminUtilisateursAjouterUtilisateurAuGroupeSuccess(utilisateur,groupe));
    } catch (error) {
        if(error.response) {
            yield put(actions.adminUtilisateursAjouterUtilisateurAuGroupeFail(error.response.data.error));
        }else {
            yield put(actions.adminUtilisateursAjouterUtilisateurAuGroupeFail("There's an error!"));
        }
    }
}