import React, {useEffect} from "react";

import Modal from '@material-ui/core/Modal';

import FormHelperText from '@material-ui/core/FormHelperText';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import {CircularProgress, Grid} from "@material-ui/core";
import {connect} from "react-redux";
import Chip from "@material-ui/core/Chip";


import Alert from "@material-ui/lab/Alert";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';



import DateFnsUtils from '@date-io/date-fns';

import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {etudiantAjouterNote} from "../../../../../../store/actions/etudiant/mesCours/notesActions";
const initialState = {
    note: {value : "", error : null}
};

function AjouterNote(props) {

    useEffect(() => {
        return () => {

        }
    },[]);

    const [state, setState] = React.useState(initialState);

    const formOnSubmitHandler = (event) => {

        event.preventDefault();

        let error = false;

        const note = state.note.value;

        if(!note) {
            error = true;
            setState(prevState => {
                return {
                    ...prevState,
                    note: {
                        value: null,
                        error : "La note est obligatoire"
                    }
                }
            })
        }


        if(!error) {

            const formNote = {
                contenu : note,
                ressource : {id : props.ressource.id},
                utilisateur : {id : props.utilisateur.id},
            }

            console.log(formNote)


           props.ajouterNote(formNote);
        }

    };

    const noteOnChangeHandler = (event) =>{
        const value = event.target.value;
        const error = null;
        setState(prevState => {
            return {
                ...prevState,
                note : {
                    value : value,
                    error: error
                }
            }
        });
    };


    useEffect(() => {

        if(props.ajouterNoteState.success)
            setState(prevState => {
                return {
                    ...prevState,
                    note : {
                        value : "",
                        error : null
                    }
                }
            })

    },[props.ajouterNoteState.success]);



    return(
        <Modal
            open={props.open}
            onClose={props.handleClose}
            style={{overflowY:"auto"}}
        >
            <div style={{maxWidth:"700px", width:"100%"}} className={"bg-white text-center border rounded mx-auto my-5"}>

                <h2>Ajouter une note</h2>

                {props.ajouterNoteState.success&& <Alert className={"mt-2 mb-3"} variant="filled" severity="success">La note ajouté avec succees</Alert>
                }


                <form className={"text-left p-4"} onSubmit={formOnSubmitHandler}>

                    <TextField error={state.note.error ? true : false} helperText={state.note.error}
                               onChange={noteOnChangeHandler}
                               value={state.note.value} fullWidth={true} size={"small"} label="Note"
                               variant="outlined"/>


                        <div>
                            <Button disabled={props.ajouterNoteState.loading} type={"submit"} className={"my-2"}
                                    color={"primary"} variant={"contained"} endIcon={<PersonAddIcon/>}>Ajouter</Button>
                            {props.ajouterNoteState.loading && <CircularProgress size={24} /*className={spinnerClasses.join(" ")}*/ />}
                        </div>

                </form>


            </div>
        </Modal>
    );




}

const mapStateToProps = (state) => {
    return {
        ajouterNoteState : state.etudiant.mesCours.notes.ajouterNote,
        utilisateur : state.authSignIn.utilisateur,
        ressource : state.etudiant.mesCours.ressource.ressource
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        ajouterNote : (note) => dispatch(etudiantAjouterNote(note)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AjouterNote);