import {put} from "redux-saga/effects";

import axios from "../../../../../api/axios"

import * as actions from "../../../../actions";
import * as api from "../../../../../api";
import {generateDynamicRoute} from "../../../../../utils/utils";

export function* etudiantMesCoursFetchCoursRessourcesSaga(action) {

    yield put(actions.etudiantMesCoursFetchCoursRessourcesStart());

    const coursId = action.payload.coursId;

    try {
        const response = yield axios.get(generateDynamicRoute(api.studentFetchCourseSections, [{
            toReplace: ":coursId",
            value: coursId
        }]));//"https://run.mocky.io/v3/9720e6a4-ff4f-4ca3-8060-8cc89f35da15");
        const data = response.data.filter(item => item.active);

        data.forEach(section => {
            section.videos = section.videos.filter(video => video.active)
        })

        yield put(actions.etudiantMesCoursFetchCoursRessourcesSuccess(data));
    } catch (error) {
        if(error.response) {
            yield put(actions.etudiantMesCoursFetchCoursRessourcesFail(error.response.data.error));
        }else {
            yield put(actions.etudiantMesCoursFetchCoursRessourcesFail("There's an error!"));
        }
    }

}