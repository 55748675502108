import Profil from "../containers/Student/Profil";
import ShoppingCart from "../containers/Student/ShoppingCart";
import Commande from "../containers/Student/Orders/Order";
import Home from "../containers/Guest/Home";


export const profilPage = {
    type: "route",
    path: "/profil",
    label: "Profil",
    component:Profil,
    icon:"fa fa-id-card",
    requireAuthentication:true
};
export const shoppingCartPage = {
    type: "route",
    path: "/cart",
    component:ShoppingCart,
    icon:"fa fa-id-card",
    notInSideNav : true,
    requireAuthentication:true
};



export const mesCommandesPage = {
    type: "route",
    path: "/mesCommandes",
    label: "Mes commandes",
    component:Home,
    icon:"fa fa-list",
    requireAuthentication:true
};

const routes = [
    profilPage,
    shoppingCartPage,
    mesCommandesPage,
];

export default routes;