import ListeCours from "../containers/Enseignant/MyCourses/CoursesList";
import MesCours from "../containers/Enseignant/MyCourses";
import CoursPage from "../containers/Enseignant/MyCourses/Course";
import DemandeVideo from "../containers/Enseignant/DemandeVideo";
import DemandeFichier from "../containers/Enseignant/DemandeFichier";
import VideoRequest from "../containers/Enseignant/VideoRequest";
import RessourceModal from "../components/RessourceModal";
import MyCourses from "../containers/Student/MyCourses";


export const listeCoursPage = {
    type:"route",
    path:"/enseignant/mesCours",
    label:"Mes Cours",
    component: ListeCours,
    icon:"fa fa-home",
    requireAuthentication:true,
    requiredRole:'ROLE_ENSEIGNANT',
};

export const coursPage = {
    type:"route",
    path:"/enseignant/mesCours/cours/:coursId",
    label:"Mes Cours",
    component: CoursPage,
    icon:"fa fa-home",
    requiredRole:'ROLE_ENSEIGNANT',
    requireAuthentication:true
};

export const mesCoursPage = {
    type:"route",
    path:"/enseignant/mesCours/",
    label:"Mes Cours",
    component: MesCours,
    icon:"fa fa-book",
    requireAuthentication:true,
    requiredRole:'ROLE_ENSEIGNANT',
    routes : [
        coursPage,
        listeCoursPage
    ]
};




export const enseignantDemandeVideoPage = {
    type: "route",
    path: "/enseignant/demandeVideo/",
    icon:"fa fa-book",
    label:"Demande Ajout Video",
    component:DemandeVideo,
    requiredRole:'ROLE_ENSEIGNANT',
    requireAuthentication:true
};


export const teacherVideoRequestPage = {
    type: "route",
    path: "/enseignant/videoRequest/",
    icon:"fa fa-book",
    label:"Demande Ajout Video",
    component:VideoRequest,
    requiredRole:'ROLE_ENSEIGNANT',
    requireAuthentication:true
};


export const enseignantDemandeFichierPage = {
    type: "route",
    path: "/enseignant/demandeFichier/",
    icon:"fa fa-book",
    label:"Demande Ajout Fichier",
    component:DemandeFichier,
    requiredRole:'ROLE_ENSEIGNANT',
    requireAuthentication:true
};




export const CoursesListPage = {
    type:"route",
    path:"/enseignant/myCourses",
    label:"Mes Cours",
    component: ListeCours,
    icon:"fa fa-home",
    requireAuthentication:true,
    requiredRole:'ROLE_ENSEIGNANT',
};

export const CoursePage = {
    type:"route",
    path:"/enseignant/myCourses/course/:coursId",
    label:"Mes Cours",
    component: CoursPage,
    icon:"fa fa-home",
    requiredRole:'ROLE_ENSEIGNANT',
    requireAuthentication:true
};

export const ressourcePage = {
    type:"route",
    path:"/enseignant/myCourses/course/:coursId/section/:sectionId/ressource/:ressourceId",
    label:"Mes Cours",
    component: RessourceModal,
    icon:"fa fa-home",
    requiredRole:'ROLE_ENSEIGNANT',
    requireAuthentication:true
};

export const TeacherMyCoursesPage = {
    type:"route",
    path:"/enseignant/myCourses/",
    label:"Mes Cours",
    component: MyCourses,
    icon:"fa fa-book",
    requireAuthentication:true,
    requiredRole:'ROLE_ENSEIGNANT',
    routes : [
        CoursePage,
        CoursesListPage
    ]
};

const routes = [
    teacherVideoRequestPage,
    TeacherMyCoursesPage,
    ///enseignantDemandeVideoPage,
    //enseignantDemandeFichierPage,
];

export default routes;