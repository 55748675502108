import * as actionTypes from "../../actions/actionTypes"
import {updateObject} from "../../../utils/utils";

const initialState = {
    error404:false,
    error500:false
};

const errorsThrowError404 = (state,action) => {
    return updateObject(state, {error404: true});
};

const errorsRemoveError404 = (state,action) => {
    return updateObject(state, {error404: false});
};

const errorsThrowError500 = (state,action) => {
    return updateObject(state, {error500: true});
};

const errorsRemoveError500 = (state,action) => {
    return updateObject(state, {error500: false});
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ERRORS_THROW_ERROR_404: return errorsThrowError404(state,action);
        case actionTypes.ERRORS_REMOVE_ERROR_404: return errorsRemoveError404(state,action);
        case actionTypes.ERRORS_THROW_ERROR_500: return errorsThrowError500(state,action);
        case actionTypes.ERRORS_REMOVE_ERROR_500: return errorsRemoveError500(state,action);
        default : return state;
    }
};

export default reducer;