import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {

    loading : true,
    error : null,
    listeCours: []

};

const enseignantMesCoursFetchListeCoursStart = (state,action) => {
    return updateObject(state,{
        loading:true,
        error:null,
        listeCours: []
    });
};

const enseignantMesCoursFetchListeCoursSuccess = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:null,
        listeCours: action.payload.listeCours
    });
};

const enseignantMesCoursFetchListeCoursFail = (state,action) => {
    return updateObject(state,{
        loading:false,
        error:action.payload.error,
        listeCours: []
    });
};

const enseignantMesCoursFetchListeCoursReset = (state,action) => {
    return updateObject(state,initialState);
};



const reducer = (state = initialState,action) => {
    switch (action.type) {

        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_LISTE_COURS_START : return enseignantMesCoursFetchListeCoursStart(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_LISTE_COURS_SUCCESS : return enseignantMesCoursFetchListeCoursSuccess(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_LISTE_COURS_FAIL : return enseignantMesCoursFetchListeCoursFail(state,action);
        case actionTypes.ENSEIGNANT_MES_COURS_FETCH_LISTE_COURS_RESET : return enseignantMesCoursFetchListeCoursReset(state,action);
        default : return state;
    }
};

export default reducer;