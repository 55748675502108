import * as actionTypes from "../../../../actions/actionTypes";
import {updateObject} from "../../../../../utils/utils";

const initialState = {
    loading : true,
    error: null,
    listeOffres: []
};

const etudiantExplorerOffresFetchListeOffresStart = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : true,
        error: null,
        listeOffres: []
    });
};

const etudiantExplorerOffresFetchListeOffresSuccess = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: null,
        listeOffres: action.payload.listeOffres
    });
};

const etudiantExplorerOffresFetchListeOffresFail = (state,action) => {
    return updateObject(state,{
        ...state,
        loading : false,
        error: action.payload.error,
        listeOffres: []
    });
};

const etudiantExplorerOffresFetchListeOffresReset = (state,action) => {
    return updateObject(state,{
        ...state,
        ...initialState
    });
};

function reducer(state = initialState,action) {

    switch (action.type) {
        case actionTypes.ETUDIANT_EXPLORER_OFFRES_FETCH_LISTE_OFFRES_START : return etudiantExplorerOffresFetchListeOffresStart(state,action);
        case actionTypes.ETUDIANT_EXPLORER_OFFRES_FETCH_LISTE_OFFRES_SUCCESS : return etudiantExplorerOffresFetchListeOffresSuccess(state,action);
        case actionTypes.ETUDIANT_EXPLORER_OFFRES_FETCH_LISTE_OFFRES_FAIL : return etudiantExplorerOffresFetchListeOffresFail(state,action);
        case actionTypes.ETUDIANT_EXPLORER_OFFRES_FETCH_LISTE_OFFRES_RESET : return etudiantExplorerOffresFetchListeOffresReset(state,action);

        default : return state;
    }

}

export default reducer;